import { IsString } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { InvitationWithDataDO } from "../../trustedConnection/domainObjects";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/invitations/id/:hashedId";
export class Params {
	@IsString()
	public hashedId!: string;
}
export class Response extends InvitationWithDataDO {}
