import HdbParkingApi from "app/api/hdbParking";

/**
 * Deletes fields associated with the sid provided, from redis
 * @param sid sid to delete from redis
 */
export const deleteSid = async (sid?: string): Promise<void> => {
	if (sid) {
		try {
			await HdbParkingApi.deleteSid(sid);
		} catch {
			//empty catch
		}
	}
};
