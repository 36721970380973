import { IsBoolean, IsEmail, IsOptionalIf } from "@wog/mol-lib-api-contract/utils/validators";
import { Transform, Type } from "class-transformer";
import { IsEmpty, IsEnum, IsNotEmpty, IsOptional, IsString, MaxLength, ValidateIf, isEmpty } from "class-validator";
import moment from "moment";
import { ISO_DATE_ONLY_FORMAT } from "../../../date";
import { IsLocalPhoneNumber, IsMoment, IsNRIC } from "../../../validators";
import { momentToString, toDate, toLowercase, toUppercase } from "../../transformers";
import {
	DeathCertDownloadRequestValidationGroup,
	DeathRecordType,
	IdentificationType,
	RelationshipType,
} from "../enums";
import { EMAIL_MAX_CHARS } from "app/common/email";

export class DeathCertDownloadRequestIdentifierDO {
	@IsString()
	@IsNotEmpty()
	@MaxLength(36)
	@Transform(toUppercase)
	public requestId!: string;
}

export class DeathOrStillBirthDocumentDO {
	@IsEnum(DeathRecordType)
	@Transform(toLowercase)
	public type!: DeathRecordType;

	@IsString()
	public deathRegNumber!: string;

	@IsString()
	public dateOfDeath!: string;
}

export class DeathCertDownloadRequestPatchDO {
	@IsLocalPhoneNumber(true)
	@IsOptional({ always: true })
	public phoneNumber?: string;

	@IsEmail({ maxLength: EMAIL_MAX_CHARS })
	@IsOptional({ always: true })
	public email?: string;

	@IsBoolean()
	@IsOptional({ always: true })
	public notifyUpdate?: boolean;
}

export class DeathCertDownloadRequestDO {
	@IsString()
	public id!: string;
	// Only in response
	@IsEmpty()
	public requestId?: string;

	@IsLocalPhoneNumber(true, { always: true })
	@IsNotEmpty({ always: true, message: "either phoneNumber or email must be provided" })
	@IsOptionalIf((_value, args) => !!(args?.object as DeathCertDownloadRequestDO)?.email, { always: true })
	public phoneNumber?: string;

	@IsEmail({ maxLength: EMAIL_MAX_CHARS }, { always: true })
	@IsNotEmpty({ always: true, message: "either phoneNumber or email must be provided" })
	@IsOptionalIf((_value, args) => !!(args?.object as DeathCertDownloadRequestDO)?.phoneNumber, { always: true })
	public email?: string;

	@IsBoolean(undefined, { always: true })
	@IsNotEmpty({ always: true })
	public notifyUpdate!: boolean;

	@IsEmpty({ groups: [DeathCertDownloadRequestValidationGroup.USER_LOGGED_IN] })
	@IsEnum(IdentificationType, {
		groups: [DeathCertDownloadRequestValidationGroup.NRICFIN, DeathCertDownloadRequestValidationGroup.PASSPORT],
	})
	@IsNotEmpty({
		groups: [DeathCertDownloadRequestValidationGroup.NRICFIN, DeathCertDownloadRequestValidationGroup.PASSPORT],
	})
	@Transform(toUppercase)
	public identificationType?: IdentificationType;

	@IsEmpty({ groups: [DeathCertDownloadRequestValidationGroup.USER_LOGGED_IN] })
	@IsNRIC({
		groups: [DeathCertDownloadRequestValidationGroup.NRICFIN],
	})
	@MaxLength(20, {
		groups: [DeathCertDownloadRequestValidationGroup.PASSPORT],
	})
	@Transform(toUppercase)
	public identificationNumber?: string;

	@IsEmpty({ groups: [DeathCertDownloadRequestValidationGroup.USER_LOGGED_IN] })
	@IsString({
		groups: [DeathCertDownloadRequestValidationGroup.NRICFIN, DeathCertDownloadRequestValidationGroup.PASSPORT],
	})
	@IsNotEmpty({
		groups: [DeathCertDownloadRequestValidationGroup.NRICFIN, DeathCertDownloadRequestValidationGroup.PASSPORT],
	})
	@Transform(toUppercase)
	public name?: string;

	@IsEnum(DeathRecordType, { always: true })
	@IsNotEmpty({ always: true })
	@Transform(toLowercase)
	public certType!: DeathRecordType;

	@IsBoolean(undefined, { always: true })
	@IsNotEmpty({ always: false })
	@Transform((obj) => (isEmpty(obj.value) ? obj.value : obj.value === "true" || obj.value === true))
	public isGuardian!: boolean;

	@ValidateIf((o) => !o.isGuardian, { always: true })
	@IsBoolean(undefined, { always: true })
	@IsNotEmpty({ always: false })
	@Transform((obj) => (isEmpty(obj.value) ? obj.value : obj.value === "true" || obj.value === true))
	public hasPermissionFromGuardian?: boolean;

	@ValidateIf((o) => o.hasPermissionFromGuardian, { always: true })
	@IsNotEmpty({ message: "Enter the name of the next-of-kin", always: true })
	public guardianName?: string;

	@ValidateIf((o) => !o.isGuardian && !o.hasPermissionFromGuardian, { always: true })
	@IsNotEmpty({ message: "Enter your relationship to the person who has died", always: true })
	public relationshipDescription?: string;

	// only in response
	@IsEmpty()
	@Transform(toUppercase)
	public decName?: string;

	@IsString({ always: true })
	@MaxLength(20, { always: true })
	@Transform(toUppercase)
	public decIdentificationNumber!: string;

	@IsString({ always: true })
	@MaxLength(9, { always: true })
	@Transform(toUppercase)
	public decCertNo!: string;

	@IsMoment({ message: "must be date with format 'YYYY-MM-DD'", always: true })
	@Transform(momentToString(ISO_DATE_ONLY_FORMAT), { toPlainOnly: true })
	@Transform(toDate, { toClassOnly: true })
	@Type(() => moment)
	public decDateOfDeath!: moment.Moment;

	// only in response
	@IsEmpty()
	@IsMoment({ message: "must be date with format 'YYYY-MM-DD'" })
	@Transform(momentToString(ISO_DATE_ONLY_FORMAT), { toPlainOnly: true })
	@Transform(toDate, { toClassOnly: true })
	@Type(() => moment)
	public expiresAfter?: moment.Moment;

	public getRelationshipType(): RelationshipType {
		if (this.isGuardian) return RelationshipType.GUARDIAN;

		if (this.hasPermissionFromGuardian) return RelationshipType.PERMISSION_FROM_GUARDIAN;

		return RelationshipType.NO_PERMISSION_FROM_GUARDIAN;
	}
}
