import { Transform, Type } from "class-transformer";
import { IsNumber } from "class-validator";
import { IsValidSharedPlanArray } from "../../../validators/sharedPlanValidator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";
import { SharedPlanAccessSetting } from "../domainObjects";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/access/collection/:id";
export class Params {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
}

export class Response {
	@IsValidSharedPlanArray()
	@Type(() => SharedPlanAccessSetting)
	public planSettings!: SharedPlanAccessSetting[];
}
