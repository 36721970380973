import { IsEnum, IsString } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { FileScanResult } from "../enums";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/scanfile/:s3UniqueName";
export class Params {
	@IsString()
	public s3UniqueName!: string;
}
export class Response {
	@IsEnum(FileScanResult)
	public result!: FileScanResult;
}
