import { ArrowRightIcon, ArrowLeftIcon } from "@lifesg/react-icons";
import { Form, IFormFunctions } from "app/hooks/useForm";
import React from "react";

const RightArrow = (props: any): JSX.Element => (
	<div {...props}>
		<ArrowRightIcon />
	</div>
);

const LeftArrow = (props: any): JSX.Element => (
	<div {...props}>
		<ArrowLeftIcon />
	</div>
);

interface Props {
	form: IFormFunctions;
	backAction: () => void;
	nextAction: () => void;
	isEditingForm?: boolean;
	additionalValidations?: () => Promise<boolean>;
	isSubmit?: boolean;
}

export const FormButtons = (props: Props): JSX.Element => {
	const { form, backAction, nextAction, additionalValidations, isSubmit, isEditingForm } = props;

	if (isEditingForm) {
		return (
			<div className="death-cert-form__button-bar">
				<div>&nbsp;</div>
				<Form.Button
					form={form}
					name="Done"
					type="primary"
					action={nextAction}
					additionalValidations={additionalValidations}
				/>
			</div>
		);
	}

	return (
		<div className="death-cert-form__button-bar">
			<Form.Button
				form={form}
				name="Back"
				type="borderless"
				action={backAction}
				IconLeft={LeftArrow}
				className="button-no-padding-left"
			/>
			<Form.Button
				form={form}
				name={isSubmit ? "Submit" : "Next"}
				type="primary"
				action={nextAction}
				additionalValidations={additionalValidations}
				IconRight={isSubmit ? undefined : RightArrow}
			/>
		</div>
	);
};
