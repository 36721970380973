import { HdbParkingDO } from "app/common/api/hdbParking/domainObjects";
import * as contracts from "app/common/api/hdbParking/routes";
import API from "../client.contract";

const postHdbparkingBookingDetail = async (
	certificateNumber: string,
	deceasedIdentityNumber: string,
	identityNumber: string,
): Promise<contracts.PostHdbparkingBookingDetail.Response> => {
	return await API.callV2(contracts.PostHdbparkingBookingDetail, {
		body: {
			certificateNumber,
			deceasedIdentityNumber,
			identityNumber,
		},
	});
};

const postHdbParking = async (hdbParkingDO: HdbParkingDO): Promise<contracts.PostHdbParking.Response> => {
	return await API.callV2(contracts.PostHdbParking, {
		body: {
			...hdbParkingDO,
		},
	});
};

//Nprod only API
const deleteHdbParking = async (certificateNumber: string): Promise<contracts.DeleteHdbParking.Response> => {
	return await API.callV2(contracts.DeleteHdbParking, {
		params: {
			certificateNumber,
		},
	});
};

const getDeathRecord = async (): Promise<contracts.GetDeathCertDownloadRequest.Response> => {
	return await API.callV2<contracts.GetDeathCertDownloadRequest.Response>(contracts.GetDeathCertDownloadRequest);
};

const refreshSid = async (sid: string): Promise<contracts.PostRefreshSessionID.Response> => {
	return await API.callV2<contracts.PostRefreshSessionID.Response>(contracts.PostRefreshSessionID, {
		body: {
			sid,
		},
	});
};

const deleteSid = async (sid: string): Promise<contracts.DeleteSessionID.Response> => {
	return await API.callV2<contracts.DeleteSessionID.Response>(contracts.DeleteSessionID, {
		params: {
			sid,
		},
	});
};

const HdbParkingApi = {
	postHdbparkingBookingDetail,
	postHdbParking,
	getDeathRecord,
	deleteHdbParking,
	refreshSid,
	deleteSid,
};

export default HdbParkingApi;
