import { Transform } from "class-transformer";
import { IsBoolean, IsNumber } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";
import { SharedAccessTrustedConnectionDO } from "../domainObjects";

export const httpVerb = HTTP_VERBS.PATCH;
export const url = "/api/users/access/:accessID";
export class Params {
	@IsNumber()
	@Transform(toNumber)
	public accessID!: number;
}
export class Body {
	@IsBoolean()
	public isAccessibleBeforeDeath!: boolean;
}
export class Response extends SharedAccessTrustedConnectionDO {}
