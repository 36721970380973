import { isValidDateInputStr } from "app/common/validators";
import moment from "moment";
import validator from "validator";
import { ISO_DATE_ONLY_FORMAT, ISO_DATE_ONLY_REGEX, MYSQL_DATE_TIME_FORMAT } from "./../date";

export const toNumber = ({ value }: { value: string }): number => +value;

export const toBoolean = ({ value }: { value: string }): boolean => value.toLowerCase() === "true";
/**
 * @deprecated
 * convert to use js-joda
 */
export const toMoment = ({ value }: { value: string }): moment.Moment | string => {
	// checks if a given value is a ISOString date
	if (!isValidDateInputStr(value)) {
		return value;
	}

	const m = moment(value, MYSQL_DATE_TIME_FORMAT);
	if (m.isValid()) {
		return m;
	}

	return value;
};

/**
 * @deprecated
 * convert to use js-joda
 */
export const toDate = ({ value }: { value: string }): moment.Moment | string => {
	// checks if a given value is a ISOString date
	if (!ISO_DATE_ONLY_REGEX.test(value)) {
		return value;
	}

	const m = moment(value, ISO_DATE_ONLY_FORMAT);
	if (m.isValid()) {
		return m;
	}

	return value;
};

export const UNSAFE_toJsDate = ({ value }: { value: string }): Date | string => {
	if (!value) return value;
	return new Date(value);
};

export const toUppercase = ({ value }: { value: string }): string | null | undefined => {
	if (value === null || value === undefined || value === "") {
		return;
	}
	return value.toUpperCase();
};

export const toLowercase = ({ value }: { value: string }): string | null | undefined => {
	if (value === null || value === undefined || value === "") {
		return;
	}
	return value.toLowerCase();
};

/**
 * Replace <, >, &, ', " and / with HTML entities.
 */
export const escapeHTML = ({ value }: { value: string | string[] }): string | string[] => {
	if (Array.isArray(value)) {
		return value.map((string) => validator.escape(string));
	}
	return validator.escape(value);
};

export const momentToString =
	(format?: string) =>
	({ value }: { value: moment.Moment }): string | null => {
		if (!value) return null;
		if (!format) return value.utc().format();
		return value.format(format);
	};
