import React from "react";
import ReadOnlyField from "../../../../common/ReadOnlyField";

interface IProps {
	decIdentificationNumber: string;
	decCertNo: string;
	decDateOfDeath: string;
	decName?: string;
	showDateFormat?: boolean;
}

const DeceasedSection = (props: IProps): JSX.Element => {
	const { decIdentificationNumber, decCertNo, decDateOfDeath, decName, showDateFormat } = props;

	return (
		<>
			<h5>Deceased{"'"}s details</h5>

			{decName ? (
				<ReadOnlyField
					id="readonly-field__deceased-name"
					title="Full name (as in NRIC, FIN, or passport)"
					value={decName}
				/>
			) : null}

			<ReadOnlyField
				id="readonly-field__deceased-nric"
				title="NRIC or FIN or Passport number"
				value={decIdentificationNumber}
			/>

			<ReadOnlyField
				id="readonly-field__death-certificate-number"
				title="Death document number"
				value={decCertNo}
			/>

			<ReadOnlyField
				id="readonly-field__date-of-death"
				title={`Date of death${showDateFormat ? " (DD/MM/YYYY)" : ""}`}
				value={decDateOfDeath}
			/>
		</>
	);
};

export default DeceasedSection;
