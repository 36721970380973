import * as React from "react";
import { ReactNode, forwardRef } from "react";
import "./Segment.scss";

interface IProps {
	id?: string;
	className?: string;
	children: ReactNode;
	background?: "blue" | "white" | "grey" | "footer" | "dark-grey" | "medium-grey" | "light-blue" | "light-grey";
	paddingBottom?: 8 | 12 | 16 | 24 | 32 | 40 | 48 | 54 | 60 | 80 | 96 | 104;
}

const Segment = forwardRef<HTMLDivElement, IProps>(
	(props, ref): JSX.Element => (
		<div
			ref={ref}
			id={props.id}
			className={`segment ${props.className || ""} segment__${props.background || "white"}`}
			style={paddingBottomStyle(props.paddingBottom)}
		>
			<div className={"segment__container"}>{props.children}</div>
		</div>
	),
);

export default Segment;

const paddingBottomStyle = (paddingBottom: number | undefined): React.CSSProperties => {
	if (!paddingBottom) {
		return {};
	}

	return { paddingBottom };
};
