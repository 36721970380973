import { InnerRing1, InnerRing2, InnerRing3, InnerRing4, OuterRing } from "./styles";

/**
 * Component currently not extended by lifesg design system, no plans to export this for the time being
 * can remove once loading spinner is exported.
 */
export interface ComponentLoadingSpinnerProps {
	className?: string | undefined;
	/** Measurement in px */
	size?: number | undefined;
	color?: string | undefined;
}
/** @deprecated Can be remove because DS button comes with a loading prop */
export const ComponentLoadingSpinner = ({ color, className, size = 18 }: ComponentLoadingSpinnerProps): JSX.Element => {
	const borderWidth = 2;

	return (
		<OuterRing className={className} $size={size} $color={color}>
			<InnerRing1 id="inner1" $size={size - borderWidth} $borderWidth={borderWidth} $color={color} />
			<InnerRing2 id="inner2" $size={size - borderWidth} $borderWidth={borderWidth} $color={color} />
			<InnerRing3 id="inner3" $size={size - borderWidth} $borderWidth={borderWidth} $color={color} />
			<InnerRing4 id="inner4" $size={size - borderWidth} $borderWidth={borderWidth} $color={color} />
		</OuterRing>
	);
};
