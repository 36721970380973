import AlertBanner, { AlertBannerProps } from "app/components/composites/AlertBanner";
import { Container, Label, LabelContainer, ProgressBackground, ProgressFill, Subtitle, Title } from "./styles";
import { ProgressBarColors } from "./type";

interface IProgressBarProps {
	/** Title of progress bar */
	title?: string | JSX.Element;
	/** Subtitle of progress bar */
	subtitle?: string | JSX.Element;
	/** Percentage of progress bar */
	percentage: number;
	/** Displays errors if percentage is not 100% */
	validate?: boolean;
	/** Alert to display below the progress bar */
	alert?: JSX.Element;
}

const ProgressBar = (props: IProgressBarProps): JSX.Element => {
	const { title, subtitle, percentage, validate, alert } = props;

	const progressBackgroundColor =
		percentage === 0 ? ProgressBarColors.Neutral : ProgressBarColors.InformationBackground;

	const getProgressFillColor = () => {
		if (percentage === 100) {
			return ProgressBarColors.Success;
		}
		if (percentage > 100) {
			return ProgressBarColors.Error;
		}
		return ProgressBarColors.Primary;
	};

	const getDistributedLabelColor = () => {
		if (percentage === 100) {
			return ProgressBarColors.SuccessLabel;
		}
		if (percentage > 100) {
			return ProgressBarColors.ErrorLabel;
		}
		return ProgressBarColors.PrimaryLabel;
	};

	const getLeftLabelColor = () => {
		if (validate && percentage < 100) {
			return ProgressBarColors.ErrorLabel;
		}
		return ProgressBarColors.NeutralLabel;
	};

	const renderAlert = (): JSX.Element => {
		if (alert) {
			return alert;
		}

		const ProgressBarAlert = (props: AlertBannerProps) => (
			<AlertBanner {...props} data-testid="progress-bar-alert" type="error" />
		);
		if (percentage > 100) {
			return (
				<ProgressBarAlert>{`The total must be 100%. Reduce the distribution by ${
					percentage - 100
				}%.`}</ProgressBarAlert>
			);
		}
		if (validate && percentage < 100) {
			return (
				<ProgressBarAlert>{`The total must be 100%. Distribute the remaining ${
					100 - percentage
				}%.`}</ProgressBarAlert>
			);
		}
		return <></>;
	};

	return (
		<Container>
			{title && <Title weight="semibold">{title}</Title>}
			{subtitle && (
				<Subtitle weight="regular" color={ProgressBarColors.NeutralLabel}>
					{subtitle}
				</Subtitle>
			)}
			<ProgressBackground data-testid="progress-bar-background" color={progressBackgroundColor}>
				<ProgressFill
					data-testid="progress-bar-fill"
					percentage={Math.min(percentage, 100)}
					color={getProgressFillColor()}
				></ProgressFill>
			</ProgressBackground>
			<LabelContainer>
				<Label data-testid="progress-bar-distributed-label" color={getDistributedLabelColor()}>
					<span>{`${percentage}%`}</span>
					{" distributed"}
				</Label>
				{percentage < 100 && (
					<Label data-testid="progress-bar-percentage-left-label" color={getLeftLabelColor()}>
						<span>{`${100 - percentage}%`}</span>
						{" left"}
					</Label>
				)}
			</LabelContainer>
			{renderAlert()}
		</Container>
	);
};

export default ProgressBar;
