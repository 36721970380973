import { HTTP_VERBS } from "app/common/api/enums";
import { Transform } from "class-transformer";
import { IsString } from "class-validator";
import { escapeHTML } from "../../transformers";
import { GetHdbParkingResponseDO } from "../domainObjects";

export class PostHdbCarparkBookingDetailRequestDO {
	constructor(props: Readonly<PostHdbCarparkBookingDetailRequestDO>) {
		this.identityNumber = props?.identityNumber;
		this.certificateNumber = props?.certificateNumber;
		this.deceasedIdentityNumber = props?.deceasedIdentityNumber;
	}

	@IsString()
	@Transform(escapeHTML)
	public identityNumber!: string;

	@IsString()
	@Transform(escapeHTML)
	public deceasedIdentityNumber!: string;

	@IsString()
	@Transform(escapeHTML)
	public certificateNumber!: string;
}

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v1/hdb-parking/booking-detail";
export class Body extends PostHdbCarparkBookingDetailRequestDO {}
export class Response extends GetHdbParkingResponseDO {}
