import { DeathCertDownloadRequestDO } from "app/common/api/deathCert";
import { IdentificationType } from "app/common/api/deathCert/enums";
import { StringBoolean } from "app/common/api/enums";
import { EMAIL_MAX_CHARS } from "app/common/email";
import { IFormSchema } from "app/hooks/useForm";

export const FormKeys = {
	CERT_TYPE: "certType",
	IDENTIFICATION_TYPE: "identificationType",
	NAME: "name",
	IDENTIFICATION_NUMBER: "identificationNumber",
	PHONE: "phoneNumber",
	EMAIL: "email",
	DEC_IDENTIFICATION_NUMBER: "decIdentificationNumber",
	DEC_CERT_NO: "decCertNo",
	DEC_DEATH_DATE: "decDateOfDeath",
	NOTIFY_UPDATE: "notifyUpdate",
	IS_GUARDIAN: "isGuardian",
	HAS_PERMISSION_FROM_GUARDIAN: "hasPermissionFromGuardian",
	GUARDIAN_NAME: "guardianName",
	RELATIONSHIP_DESCRIPTION: "relationshipDescription",
};

export type ConsolidatedResponses = {
	[key in keyof DeathCertDownloadRequestDO]?: any;
};

//================================================================================================
// Schema
//================================================================================================

export const emailAddressSchema: IFormSchema = {
	[FormKeys.EMAIL]: {
		type: "text",
		maxChar: EMAIL_MAX_CHARS,
		constraints: [["isEmail", "Invalid email address. Try again."]],
	},
};

export const contactDetailsSchema: IFormSchema = {
	[FormKeys.PHONE]: {
		type: "phone",
		maxChar: 8,
		constraints: [["isLocalPhoneNumber", "Invalid mobile number. Try again."]],
	},
	...emailAddressSchema,
};

export const contactDetailsEditSchema: IFormSchema = {
	[FormKeys.PHONE]: {
		type: "numberString",
		maxChar: 9,
		constraints: [["isLocalPhoneNumber", "Invalid mobile number. Try again."]],
	},
	...emailAddressSchema,
};

export const identificationDetailsSchema: IFormSchema = {
	[FormKeys.IDENTIFICATION_TYPE]: {
		type: "dropdown",
		dropdownItems: [
			{ label: IdentificationType.NRIC },
			{ label: IdentificationType.FIN },
			{ label: IdentificationType.PASSPORT },
		],
		constraints: [["isRequired", "Select identification type"]],
	},
	[FormKeys.NAME]: {
		type: "text",
		maxChar: 255,
		constraints: [
			["isRequired", "Enter full name"],
			["isMaxLength", 66, "Please limit to 66 characters"],
		],
	},
	[FormKeys.IDENTIFICATION_NUMBER]: {
		type: "text",
		maxChar: 255,
		constraints: [
			["isRequired", "Enter identification number"],
			["isMaxLength", 20, "Please limit to 20 characters"],
		],
	},
};

export const requestorDetailsSchema: IFormSchema = {
	...identificationDetailsSchema,
	...contactDetailsSchema,
};

const deceaseIdentification: IFormSchema = {
	[FormKeys.DEC_IDENTIFICATION_NUMBER]: {
		type: "text",
		maxChar: 255,
		constraints: [
			["isRequired", "Enter NRIC, FIN, or passport number"],
			["isMaxLength", 25, "Please limit to 25 characters"],
		],
	},
};

const stillBirthDetailSchema: IFormSchema = {
	[FormKeys.DEC_CERT_NO]: {
		type: "text",
		maxChar: 255,
		constraints: [
			["isRequired", "Enter stillbirth document number"],
			["isAlphanumeric", "Invalid stillbirth document number. Try again."],
			["isMaxLength", 7, "Please limit to 7 characters"],
		],
	},
	[FormKeys.DEC_DEATH_DATE]: {
		type: "date",
		constraints: [
			["isRequired", "Enter date of stillbirth"],
			["isTodayOrBefore", "Date of stillbirth cannot be in the future."],
		],
	},
};

const deathDetailsSchema: IFormSchema = {
	[FormKeys.DEC_CERT_NO]: {
		type: "text",
		maxChar: 255,
		constraints: [
			["isRequired", "Enter death document number"],
			["isAlphanumeric", "Invalid death document number. Try again."],
			["isMaxLength", 7, "Please limit to 7 characters"],
		],
	},
	[FormKeys.DEC_DEATH_DATE]: {
		type: "date",
		constraints: [
			["isRequired", "Enter date of death"],
			["isTodayOrBefore", "Date of death cannot be in the future."],
		],
	},
};

export const notifyUpdateSchema: IFormSchema = {
	[FormKeys.NOTIFY_UPDATE]: {
		type: "radio",
		radioBoxItems: [
			{ label: "Yes", value: StringBoolean.TRUE },
			{ label: "No", value: StringBoolean.FALSE },
		],
		constraints: [["isRequired", "Select one"]],
	},
};

export const stillbirthHasPermission: IFormSchema = {
	[FormKeys.HAS_PERMISSION_FROM_GUARDIAN]: {
		type: "radio",
		radioBoxItems: [
			{ label: "Yes", value: StringBoolean.TRUE },
			{ label: "No", value: StringBoolean.FALSE },
		],
	},
};

export const relationshipSchema: IFormSchema = {
	[FormKeys.IS_GUARDIAN]: {
		type: "radio",
		radioBoxItems: [
			{ label: "Yes", value: StringBoolean.TRUE },
			{ label: "No", value: StringBoolean.FALSE },
		],
		constraints: [["isRequired", "Select one"]],
	},
	[FormKeys.HAS_PERMISSION_FROM_GUARDIAN]: {
		type: "radio",
		radioBoxItems: [
			{ label: "Yes", value: StringBoolean.TRUE },
			{
				label: "No. The deceased's next-of-kin is not known or not present in Singapore.",
				value: StringBoolean.FALSE,
			},
		],
	},
	[FormKeys.GUARDIAN_NAME]: {
		type: "text",
		maxChar: 100,
	},
	[FormKeys.RELATIONSHIP_DESCRIPTION]: {
		type: "text",
		maxChar: 100,
	},
};

//================================================================================================
// Forms
//================================================================================================

export const downloadDeathCertFormSchema: IFormSchema = {
	...requestorDetailsSchema,
	...deceaseIdentification,
	...deathDetailsSchema,
};

export const downloadStillbirthFormSchema: IFormSchema = {
	...requestorDetailsSchema,
	...deceaseIdentification,
	...stillBirthDetailSchema,
};

export const editDetailsFormSchema: IFormSchema = {
	...identificationDetailsSchema,
	...contactDetailsEditSchema,
	...notifyUpdateSchema,
};
