import { HTTP_VERBS } from "app/common/api/enums";
import { Transform } from "class-transformer";
import { IsString, MaxLength } from "class-validator";
import { escapeHTML, toUppercase } from "../../transformers";

//NProd only API for development
export const httpVerb = HTTP_VERBS.DELETE;
export const url = "/api/v1/hdb-parking/:certificateNumber";
export class Params {
	@IsString()
	@MaxLength(7)
	@Transform(toUppercase)
	@Transform(escapeHTML)
	public certificateNumber!: string;
}
export class Response {}
