export enum LpaStatus {
	NO_RECORD,
	DRAFT,
	DRAFT_PENDING_DONEE_ACCEPTANCE,
	DRAFT_DONEE_REJECTED,
	DRAFT_PENDING_CERTIFICATION,
	LPA_PENDING_PAYMENT,
	LPA_PENDING_ACCEPTANCE,
	LPA_PENDING_REGISTRATION,
	LPA_REGISTERED,
	LPA_PENDING_VERIFICATION,
	LPA_REJECTED,
	LPA_REGISTRATION_REVOKED,
	LPA_REGISTRATION_CANCELLED,
	LPA_REVOKED,
	DRAFT_DELETED,
	DRAFT_CANCELLED,
	DRAFT_PENDING_DONOR_ACCEPTANCE,
	LPA_REGISTRATION_ON_HOLD,
	LPA_SUSPENDED,
	ERROR = "ERROR",
}

export enum LpaDraftStatus {
	DRAFT_ON_LEGACY = "DRAFT_ON_LEGACY",
	ERROR = "ERROR",
}

export enum LpaErrorCodes {
	ValidationError = "OPGOML001",
	UserCreationError = "OPGOML002",
	ExistingApplicationError = "OPGOML003",
	ApplicationCreationError = "OPGOML004",
	UnauthorizedViewError = "OPGOML005",
}

export type OPGOInfo = {
	permissionsGranted: boolean;
	opgoLpaStatus?: LpaStatus;
};
