import TrustedPersonInfo from "app/components/composites/TrustedPersonInfo";
import { IFormFunctions } from "../Form";
import "../Form.scss";

interface IProps {
	nric: string;
	form: IFormFunctions;
	field: string;
}

const FormTrustedPersonInfo = (props: IProps): JSX.Element => {
	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	const className = `form-field-${key} form-field`;

	const onChangeHandler = (value: string): void => {
		props.form.updateFieldValue(key, value);
	};

	const onBlurHandler = async (): Promise<void> => {
		await props.form.validateFieldValue(formField);
	};

	const errorMessage = formField ? props.form.getError(props.field) : "";
	const value = formField ? formField.value : "";

	return (
		<div className={className}>
			<TrustedPersonInfo
				type="edit"
				value={value as string}
				nric={props.nric}
				errorMessage={errorMessage}
				updateValue={onChangeHandler}
				onBlur={onBlurHandler}
			/>
		</div>
	);
};

export default FormTrustedPersonInfo;
