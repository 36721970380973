import { Transform, Type } from "class-transformer";
import { IsNumber } from "class-validator";
import { toNumber } from "../../../api/transformers";
import { IsValidSharedPlanArray } from "../../../validators/sharedPlanValidator";
import { HTTP_VERBS } from "../../enums";
import { SharedPlanAccessSetting } from "../domainObjects";

export const httpVerb = HTTP_VERBS.PUT;
export const url = "/api/users/access/collection/:id";
export class Params {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
}
export class Body {
	@IsValidSharedPlanArray()
	@Type(() => SharedPlanAccessSetting)
	public planSettings!: SharedPlanAccessSetting[];
}

export class Response {}
