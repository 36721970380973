import { MaskedInput } from "@lifesg/react-design-system/masked-input";
import styled from "styled-components";

export const StyledMaskedInput = styled(MaskedInput)<{ width: number }>`
	position: absolute;
	width: fit-content;
	height: 1.625rem;

	> div:has(svg) {
		margin-left: 8px;
		div:has(svg) {
			width: fit-content;
		}
	}
	div {
		input {
			height: 1.625rem;
			width: ${(props) => props.width}px !important;
		}
	}
`;
export const HiddenSpan = styled.span`
	width: fit-content;
	display: inline-block;
	visibility: hidden;
	height: 1.625rem;
`;
