import { assignToDomainObject } from "../../../utils/domainObject";
import { Transform, Type } from "class-transformer";
import {
	ArrayMaxSize,
	ArrayMinSize,
	IsBoolean,
	IsNumber,
	IsOptional,
	IsString,
	Max,
	MaxLength,
	ValidateNested,
} from "class-validator";
import moment from "moment";
import { IsDate, IsMoment, IsNotWhiteSpace, IsTodayOrBefore } from "../../../validators";
import { type IDateValue } from "../../interface";
import { toMoment, toNumber } from "../../transformers";
import { ExecutorDO, FullExecutorDO } from "./executor";

export class WillsDO {
	@IsNumber(undefined, { always: true })
	@Max(4, { always: true })
	public currStep!: number;

	@IsOptional({ groups: ["step0"] })
	@IsDate({ always: true })
	@IsTodayOrBefore({ always: true })
	public creationDate!: IDateValue;

	@IsOptional({ groups: ["step0"] })
	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public lawFirm!: string;

	@IsOptional({ always: true })
	@IsString({ always: true })
	@MaxLength(1000)
	public additionalNotes!: string;

	@IsOptional({ groups: ["step0"] })
	@IsBoolean({ always: true })
	public isRegistered!: boolean;

	@ArrayMinSize(1, { groups: ["step2", "step3", "step4", "edit"] })
	@IsOptional({ groups: ["step0", "step1"] })
	@ArrayMaxSize(4, { groups: ["step2", "step3", "step4", "edit"] })
	@ValidateNested({ each: true, always: true })
	@Type(() => ExecutorDO)
	public executor!: ExecutorDO[];
}
const willsDOKeys: Array<keyof WillsDO> = [
	"currStep",
	"creationDate",
	"lawFirm",
	"additionalNotes",
	"isRegistered",
	"executor",
];

export class FullWillsDO extends WillsDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;

	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public createdAt!: moment.Moment;

	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public updatedAt!: moment.Moment;

	@ValidateNested({ each: true })
	@Type(() => FullExecutorDO)
	public executor: FullExecutorDO[] = [];

	@IsOptional({ always: true })
	public getBaseDO = (): WillsDO | FullWillsDO => {
		let executorArr: ExecutorDO[] | undefined;
		const { executor, ...rest } = this;
		if (executor) {
			executorArr = executor.map((exec: FullExecutorDO) => exec.getBaseDO());
		}

		// Returns object with only keys of specified DO.
		// NOTE: new DO(), instantiates to empty {} on backend
		return assignToDomainObject(new WillsDO(), { ...rest, executor: executorArr }, willsDOKeys);
	};

	@IsOptional({ always: true })
	@IsBoolean({ always: true })
	public isCompleted!: boolean;
}
