// Inspired by this post: https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

import { createContext, SetStateAction, useContext, useState } from "react";
import LinearProgressIndicator from "./LinearProgressIndicator";

const GlobalLoadingStateContext = createContext<[boolean, (setProgress: boolean) => void]>([
	false,
	(_: SetStateAction<boolean>): void => {},
]);

export const GlobalLoadingStateProvider = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
	const [showProgress, setShowProgress] = useState<boolean>(false);
	return (
		<GlobalLoadingStateContext.Provider value={[showProgress, setShowProgress]}>
			{showProgress && <LinearProgressIndicator />}
			{children}
		</GlobalLoadingStateContext.Provider>
	);
};

export const useGlobalLoadingState = (): [boolean, (setProgress: boolean) => void] =>
	useContext(GlobalLoadingStateContext);
