import { IsContactNumber, IsMoment, IsNRIC, IsNotWhiteSpace } from "app/common/validators";
import { Transform, Type } from "class-transformer";
import {
	ArrayMaxSize,
	ArrayMinSize,
	ArrayUnique,
	IsAlphanumeric,
	IsArray,
	IsBoolean,
	IsEnum,
	IsNotEmpty,
	IsNumber,
	IsNumberString,
	IsOptional,
	IsString,
	Length,
	Max,
	MaxLength,
	Min,
	ValidateIf,
	ValidateNested,
} from "class-validator";
import _ from "lodash";
import { type Nullable } from "@wog/mol-lib-api-contract/utils/data";
import moment from "moment";
import AcpForm from "server/models/advanceCarePlan/acpForm";
import { DoneeV2, DoneeV2Power } from "server/models/lastingPowerOfAttorney/doneeV2";
import LpaForm from "server/models/lastingPowerOfAttorney/lpaForm";
import LpaAcpCombinedForm from "server/models/lpaAcpCombinedForm";
import { LpaStatus } from "../../../constant/lpa";
import { IsDate } from "../../../validators";
import { BestInterests } from "../../acp";
import { type IDateValue } from "../../interface";
import { toMoment } from "../../transformers";
import { UserMyInfoDO } from "../../user";
import {
	MannerOfActing,
	PropertyConditionCourtApproval,
	PropertyConditionMakeGifts,
	WelfareConditionAllowTreatment,
} from "../enums";
import { EMAIL_MAX_CHARS } from "app/common/email";
import { IsEmail } from "@wog/mol-lib-api-contract/utils/validators";

export enum CombinedFormStep {
	DRAFT = "draft",
	PERSONAL_DETAILS = "personal-details",
	MAILING_ADDRESS_CONTACT_DETAILS = "mailing-address-contact-details",
	LPA_DONEES = "lpa-donees",
	LPA_PERSONAL_WELFARE = "lpa-personal-welfare",
	LPA_PROPERTY_AFFAIRS = "lpa-property-affairs",
	ACP_SPOKESPERSONS_CARE_PREFERENCES = "acp-spokespersons-care-preferences",
	REVIEW = "review",
	STATEMENTS = "statements",
}

export enum AcpFormStep {
	One = "acp-step-1",
	Two = "acp-step-2",
	Three = "acp-step-3",
	Four = "acp-step-4",
}

// =============================================================================
// Address
// =============================================================================
export class BaseAddress {
	@IsString({ always: true })
	type!: "local" | "unformatted";
}

export class LocalAddress extends BaseAddress {
	type = "local" as const;

	@IsNumberString(undefined, { always: true })
	@Length(6, 6, { message: "postalCode must be 6 characters", always: true })
	@IsNotWhiteSpace({ always: true })
	postalCode!: string;

	@IsString({ always: true })
	@MaxLength(120, { always: true })
	@IsNotWhiteSpace({ always: true })
	@IsOptional({ always: true })
	blockHouseNumber?: string;

	@IsString({ always: true })
	@MaxLength(120, { always: true })
	@IsNotWhiteSpace({ always: true })
	streetName!: string;

	@IsString({ always: true })
	@MaxLength(120, { always: true })
	@IsNotWhiteSpace({ always: true })
	@IsOptional({ always: true })
	buildingName?: string;

	@IsNotWhiteSpace({ always: true })
	@IsAlphanumeric(undefined, { always: true })
	@MaxLength(3, { always: true })
	floor!: string;

	@IsNotWhiteSpace({ always: true })
	@IsAlphanumeric(undefined, { always: true })
	@MaxLength(5, { always: true })
	unit!: string;

	constructor(props?: Readonly<Omit<LocalAddress, "type">>) {
		super();
		if (props) {
			Object.assign(this, props);
		}
	}
}

export class UnformattedAddress extends BaseAddress {
	type = "unformatted" as const;

	@IsString({ always: true })
	@MaxLength(200, { always: true })
	@IsNotWhiteSpace({ always: true })
	addressLine1!: string;

	@IsString({ always: true })
	@MaxLength(200, { always: true })
	addressLine2!: string;

	@IsString({ always: true })
	@MaxLength(200, { always: true })
	@IsNotWhiteSpace({ always: true })
	addressLine3!: string;

	constructor(props?: Readonly<UnformattedAddress>) {
		super();
		if (props) {
			Object.assign(this, props);
		}
	}
}

export class Codes {
	@IsString({ always: true })
	nationality!: string;

	@IsString({ always: true })
	residentialStatus!: string;

	@IsString({ always: true })
	gender!: string;

	@IsString({ always: true })
	race!: string;

	@IsString({ always: true })
	@IsOptional({ always: true })
	dialect!: string;
}

// =============================================================================
// MyInfo
// =============================================================================
export class MyInfoDataDO {
	@IsString({ always: true })
	@MaxLength(100, { always: true })
	name!: string;

	@IsNRIC({ always: true })
	nric!: string;

	@IsString({ always: true })
	dateOfBirth!: string;

	@Type(() => LocalAddress)
	@ValidateNested({ always: true })
	registeredAddress!: LocalAddress;

	@IsString({ always: true })
	nationality!: string;

	// mapped from myinfo to OPGO code
	@IsString({ always: true })
	residentialStatus!: string;

	// mapped from myinfo to OPGO code
	@IsString({ always: true })
	sex!: string;

	// mapped from myinfo to OPGO code
	@IsString({ always: true })
	race!: string;

	@IsString({ always: true })
	dialect!: string;

	@IsString({ always: true })
	lastRetrieved!: string;

	@Type(() => Codes)
	@ValidateNested({ always: true })
	codes!: Codes;

	public static toDO(myInfo: UserMyInfoDO): MyInfoDataDO {
		return {
			name: myInfo.name ?? "",
			nric: myInfo.uinfin ?? "",
			dateOfBirth: myInfo.dateOfBirth ?? "",
			registeredAddress: {
				type: "local",
				postalCode: myInfo.registeredAddress?.postal ?? "",
				blockHouseNumber: myInfo.registeredAddress?.block ?? "",
				buildingName: myInfo.registeredAddress?.building ?? "",
				streetName: myInfo.registeredAddress?.street ?? "",
				floor: myInfo.registeredAddress?.floor ?? "",
				unit: myInfo.registeredAddress?.unit ?? "",
			},
			nationality: myInfo.nationality ?? "",
			residentialStatus: myInfo.residentialStatus ?? "",
			sex: myInfo.sex ?? "",
			race: myInfo.race ?? "",
			dialect: myInfo.dialect ?? "",
			lastRetrieved: myInfo.lastRetrieved ?? "",
			codes: {
				nationality: myInfo.codes?.nationality ?? "",
				residentialStatus: myInfo.codes?.residentialStatus ?? "",
				gender: myInfo.codes?.gender ?? "",
				race: myInfo.codes?.race ?? "",
				dialect: myInfo.codes?.dialect ?? "",
			},
		};
	}
}

export class LPAMyInfoDataDO {
	@Type(() => MyInfoDataDO)
	@ValidateNested({ groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	myInfoData?: MyInfoDataDO;

	@IsContactNumber({ groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	phone?: string;

	@IsEmail({ maxLength: EMAIL_MAX_CHARS }, { groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	@IsOptional({ groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	email?: string;
}

// =============================================================================
// Form DOs
// =============================================================================
export class LpaFormDO extends LPAMyInfoDataDO {
	@IsNumber(undefined, { always: true })
	currStep!: number;

	@IsBoolean({ groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	@IsOptional({ groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	isSameCorrespondenceAddress?: boolean;

	@IsBoolean()
	@IsOptional({})
	didChooseSomeoneElse?: boolean;

	@Type(() => BaseAddress, {
		keepDiscriminatorProperty: true,
		discriminator: {
			property: "type",
			subTypes: [
				{ value: LocalAddress, name: "local" },
				{ value: UnformattedAddress, name: "unformatted" },
			],
		},
	})
	@ValidateNested({ groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	@ValidateIf((o: LpaFormDO) => o.isSameCorrespondenceAddress === false, {
		groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS],
	})
	correspondenceAddress?: Nullable<LocalAddress | UnformattedAddress>;

	@IsBoolean({ groups: [CombinedFormStep.MAILING_ADDRESS_CONTACT_DETAILS] })
	consentEmailSms?: boolean;

	@IsArray({
		groups: [
			CombinedFormStep.LPA_DONEES,
			CombinedFormStep.LPA_PERSONAL_WELFARE,
			CombinedFormStep.LPA_PROPERTY_AFFAIRS,
			CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES,
		],
	})
	@ValidateNested({
		groups: [
			CombinedFormStep.LPA_DONEES,
			CombinedFormStep.LPA_PERSONAL_WELFARE,
			CombinedFormStep.LPA_PROPERTY_AFFAIRS,
			CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES,
		],
		each: true,
	})
	@ArrayMinSize(1, {
		groups: [
			CombinedFormStep.LPA_DONEES,
			CombinedFormStep.LPA_PERSONAL_WELFARE,
			CombinedFormStep.LPA_PROPERTY_AFFAIRS,
			CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES,
		],
	})
	@ArrayMaxSize(3, {
		groups: [
			CombinedFormStep.LPA_DONEES,
			CombinedFormStep.LPA_PERSONAL_WELFARE,
			CombinedFormStep.LPA_PROPERTY_AFFAIRS,
			CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES,
		],
	})
	@Type(() => DoneeDO)
	public donees!: DoneeDO[];

	@IsEnum(MannerOfActing, { groups: [CombinedFormStep.LPA_PERSONAL_WELFARE] })
	@ValidateIf(
		(o: LpaFormDO) =>
			o.donees.filter((val) => !val.isReplacement && val.powers.some((pow) => pow.authorizePersonalWelfare))
				.length >= 2,
		{
			groups: [CombinedFormStep.LPA_PERSONAL_WELFARE],
		},
	)
	welfareConditionMannerOfActing?: Nullable<MannerOfActing>;

	@IsEnum(WelfareConditionAllowTreatment, { groups: [CombinedFormStep.LPA_PERSONAL_WELFARE] })
	@ValidateIf(
		(o: LpaFormDO) => o.donees.filter((val) => val.powers.some((pow) => pow.authorizePersonalWelfare)).length !== 0,
		{
			groups: [CombinedFormStep.LPA_PERSONAL_WELFARE],
		},
	)
	welfareConditionAllowTreatment?: Nullable<WelfareConditionAllowTreatment>;

	@IsEnum(MannerOfActing, { groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS] })
	@ValidateIf(
		(o: LpaFormDO) =>
			o.donees.filter((val) => val.powers.some((pow) => pow.authorizePropertyAffairs) && !val.isReplacement)
				.length > 1,
		{
			groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS],
		},
	)
	propertyConditionMannerOfActing?: Nullable<MannerOfActing>;

	@IsEnum(PropertyConditionCourtApproval, { groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS] })
	@ValidateIf(
		(o: LpaFormDO) =>
			o.donees.filter((val) => val.powers.some((pow) => pow.authorizePropertyAffairs) && !val.isReplacement)
				.length > 0,
		{
			groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS],
		},
	)
	propertyConditionCourtApproval?: Nullable<PropertyConditionCourtApproval>;

	@IsNotEmpty({ groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS] })
	@ValidateIf((o: LpaFormDO) => o.propertyConditionCourtApproval === PropertyConditionCourtApproval.YES, {
		groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS],
	})
	isSameResidentialAddress?: Nullable<boolean>;

	@IsNotEmpty({ groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS] })
	@ValidateIf((o: LpaFormDO) => o.propertyConditionCourtApproval === PropertyConditionCourtApproval.YES, {
		groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS],
	})
	residentialAddress?: Nullable<LocalAddress>;

	@IsEnum(PropertyConditionMakeGifts, { groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS] })
	@ValidateIf(
		(o: LpaFormDO) =>
			o.donees.filter((val) => val.powers.some((pow) => pow.authorizePropertyAffairs) && !val.isReplacement)
				.length > 0,
		{
			groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS],
		},
	)
	propertyConditionMakeGifts?: Nullable<PropertyConditionMakeGifts>;

	@IsNotEmpty({ groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS] })
	@ValidateIf((o: LpaFormDO) => o.propertyConditionMakeGifts === PropertyConditionMakeGifts.RESTRICTED, {
		groups: [CombinedFormStep.LPA_PROPERTY_AFFAIRS],
	})
	maxAnnualGiftAmount?: Nullable<number>;

	@IsBoolean({ groups: [CombinedFormStep.STATEMENTS] })
	agreedToTerms!: boolean;

	public static toDO(lpaForm: LpaForm): LpaFormDO {
		return {
			..._.omit(lpaForm, ["createdAt", "updatedAt"]),
			currStep: lpaForm.currStep ?? 0,
			donees: lpaForm.donees?.map((donee) => DoneeDO.toDO(donee)),
		};
	}
}

export class AcpFormDO {
	// TODO: to look at the validation group when we start working on ACP-only flow
	@IsNumber(undefined, { groups: [AcpFormStep.One, CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	currStep!: number;

	@IsArray({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	@IsEnum(BestInterests, { each: true })
	@ArrayMinSize(1, { groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	@ArrayUnique()
	bestInterests!: BestInterests[];

	@IsBoolean({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	careForYourself!: boolean;

	@IsBoolean({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	comfortOrSustain!: boolean;

	@IsString({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	@IsNotEmpty({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	@IsOptional({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	specialRequests?: string;

	public static toDO(acpForm: AcpForm): AcpFormDO {
		return {
			..._.omit(acpForm, ["createdAt", "updatedAt"]),
			currStep: acpForm.currStep ?? 0,
		};
	}
}

export class DoneeDO {
	@IsNumber(undefined, { always: true })
	id!: number;

	@IsString({
		groups: [CombinedFormStep.LPA_DONEES],
	})
	@MaxLength(120, {
		groups: [CombinedFormStep.LPA_DONEES],
	})
	@IsNotWhiteSpace({
		groups: [CombinedFormStep.LPA_DONEES],
	})
	name!: string;

	@IsEmail(
		{ maxLength: EMAIL_MAX_CHARS },
		{
			groups: [CombinedFormStep.LPA_DONEES],
		},
	)
	@IsOptional({ always: true })
	email!: Nullable<string>;

	@IsContactNumber({
		groups: [CombinedFormStep.LPA_DONEES],
	})
	contact!: Nullable<string>;

	@IsNumber(undefined, {
		groups: [CombinedFormStep.LPA_DONEES],
	})
	@Max(11, {
		groups: [CombinedFormStep.LPA_DONEES],
	}) // max value coming from `RelationshipCodesLabel`
	@Min(1, {
		groups: [CombinedFormStep.LPA_DONEES],
	})
	relationshipCode!: number;

	@IsNRIC({
		groups: [CombinedFormStep.LPA_DONEES],
	})
	nric!: string;

	@IsBoolean({
		groups: [CombinedFormStep.LPA_DONEES, CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES],
	})
	isCombinedFormSpokesperson!: boolean;

	@IsArray({
		groups: [
			CombinedFormStep.LPA_DONEES,
			CombinedFormStep.LPA_PERSONAL_WELFARE,
			CombinedFormStep.LPA_PROPERTY_AFFAIRS,
		],
	})
	@ValidateNested({
		groups: [
			CombinedFormStep.LPA_DONEES,
			CombinedFormStep.LPA_PERSONAL_WELFARE,
			CombinedFormStep.LPA_PROPERTY_AFFAIRS,
		],
	})
	@Type(() => DoneePowerDO)
	powers!: DoneePowerDO[];

	@IsOptional()
	public get isReplacement(): boolean {
		return this.powers.some((power) => power.replacingMainDonee) ?? false;
	}

	public static toDO(donee: DoneeV2): DoneeDO {
		const doneeData = { ..._.omit(donee, ["createdAt", "updatedAt"]) };
		const powerData = { powers: donee.powers ? donee.powers.map((power) => DoneePowerDO.toDO(power)) : [] };
		return Object.assign(new DoneeDO(), doneeData, powerData);
	}
}

export class DoneePowerDO {
	@IsNumber(undefined, { always: true })
	id!: number;

	@IsBoolean({ always: true })
	authorizePersonalWelfare!: boolean;

	@IsBoolean({ always: true })
	authorizePropertyAffairs!: boolean;

	@Type(() => DoneeDO)
	@IsOptional({ always: true })
	public replacingMainDonee!: Nullable<DoneeDO>;

	public static toDO(power: DoneeV2Power): DoneePowerDO {
		const replacingMainDonee = power.replacingMainDonee ? DoneeDO.toDO(power.replacingMainDonee) : null;
		const powerData = { ..._.omit(power, ["createdAt", "updatedAt"]) };
		return Object.assign(new DoneePowerDO(), powerData, { replacingMainDonee: replacingMainDonee });
	}
}

export class CombinedFormDO {
	id?: number;
	/**
	 * Represents the last completed step.
	 * Count starts from 1, i.e. 1 means step 1 is completed.
	 *
	 * Should take value of current step when updating the form
	 */
	@IsNumber(undefined, { always: true })
	@Max(8, { always: true })
	public currStep!: number;

	@Type(() => LpaFormDO)
	@ValidateNested({ always: true })
	public lpaForm!: LpaFormDO;

	@Type(() => AcpFormDO)
	@ValidateNested({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	@IsNotEmpty({ groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES] })
	@ValidateIf((o: CombinedFormDO) => o.lpaForm.donees.filter((d) => d.isCombinedFormSpokesperson).length > 0, {
		groups: [CombinedFormStep.ACP_SPOKESPERSONS_CARE_PREFERENCES],
	})
	public acpForm!: AcpFormDO | null;

	public updatedAt!: moment.Moment;

	public currStepStatus?: string[];

	public static toDO(combinedForm: LpaAcpCombinedForm): CombinedFormDO {
		return {
			currStep: combinedForm.currStep,
			lpaForm: LpaFormDO.toDO(combinedForm.lpa),
			acpForm: combinedForm.acp ? AcpFormDO.toDO(combinedForm.acp) : null,
			updatedAt: combinedForm.updatedAt,
		};
	}
}

// =============================================================================
// Vault Dashboard DOs
// =============================================================================
export class LpaV1DataForDashboard {
	@IsDate()
	dateRegistered!: IDateValue;

	@ArrayMinSize(1)
	@ArrayMaxSize(3)
	doneeNames!: string[];
}
export class LpaV2DashboardDO {
	@Type(() => LpaV1DataForDashboard)
	@IsOptional()
	lpaV1Data?: LpaV1DataForDashboard;

	@IsBoolean()
	@IsOptional()
	opgoPermissionGranted!: boolean;

	@IsEnum(LpaStatus)
	opgoLpaStatus?: LpaStatus;

	@IsBoolean()
	@IsOptional()
	isCombinedForm?: boolean;

	@IsOptional()
	@IsNumber()
	combinedFormStep?: number;

	@IsNumber()
	currStep!: number;

	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public updatedAt!: moment.Moment;
}

export class AcpV2DashboardDO {
	@IsBoolean()
	@IsOptional()
	isCombinedForm?: boolean;

	@IsOptional()
	@IsNumber()
	combinedFormStep?: number;

	@IsNumber()
	currStep!: number;

	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public updatedAt!: moment.Moment;
}
