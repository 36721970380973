import {
	isEmpty,
	isEnum,
	isNotEmpty,
	maxLength,
	registerDecorator,
	ValidationArguments,
	ValidationOptions,
} from "class-validator";

// tslint:disable-next-line: ban-types
export function IsValidRadioOption(
	radioOptionsEnum: any,
	optionsWithInput: string[],
	validationOptions?: ValidationOptions,
): (object: object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isValidRadioOption",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string): boolean {
					return validateRadioOption(value, radioOptionsEnum, optionsWithInput);
				},
				defaultMessage(args: ValidationArguments): string {
					return generateErrorMessage(args.value, optionsWithInput);
				},
			},
		});
	};
}

export const validateRadioOption = (value: string, enumEntity: any, optionsWithInput: string[]): boolean => {
	if (value === undefined) {
		return false;
	}
	// splits only on the first encounter of ': '
	const valueArray = value.split(/: (.+)/);

	// if 'others' is chosen, input must be there
	if (optionsWithInput.includes(valueArray[0])) {
		// split returns empty string as last item in array. not sure why
		if (valueArray.length < 2) {
			return false;
		}

		return isNotEmpty(valueArray[1]) && maxLength(valueArray[1], 120);
	}

	if (valueArray.length > 1) {
		return false;
	}

	return isEnum(valueArray[0], enumEntity);
};

const generateErrorMessage = (value: string, optionsWithInput: string[]): string => {
	if (value === undefined) {
		return `${value} is not a valid radio option`;
	}
	const valueArray = value.split(": ");

	if (optionsWithInput.includes(value) && valueArray.length < 2) {
		return "Please specify an input value";
	}

	if (valueArray.length > 1) {
		if (isEmpty(valueArray[1])) {
			return `Please specify a value for selected radio option ${value}`;
		}

		if (!maxLength(valueArray[1], 120)) {
			return "Input value must be less than 120 char";
		}
	}

	return `${value} is not a valid radio option`;
};
