import Button from "app/components/basic/Button";
import BasicLink from "app/components/basic/Link";
import { ReactNode } from "react";

interface IProps {
	editLink?: string;
	editAction?: () => void;
	id: string;
	title: string;
	last?: boolean;
	children: ReactNode;
}

const DisplayEditableSection = (props: IProps): JSX.Element => {
	const { id, editLink, editAction } = props;
	return (
		<div className={`display-section ${props.last ? "last-section" : ""}`} id={id}>
			<div className="display-section__title">
				<h5 className="semi-bold">{props.title}</h5>
				<div className="display-section__edit">
					{editLink && <BasicLink id={`${id}-button`} type="inline-link" name="Edit" href={editLink} />}
					{editAction && <Button id={`${id}-button`} type="link" name="Edit" onClick={editAction} />}
				</div>
			</div>
			{props.children}
		</div>
	);
};

export default DisplayEditableSection;
