export enum OTPType {
	ONBOARDING = "ONBOARDING",
	PROFILE = "PROFILE",
	INVITATION = "INVITATION",
}

export enum APIOTPType {
	ONBOARDING = "ONBOARDING",
	PROFILE = "PROFILE",
}

export enum OTPContactType {
	EMAIL = "EMAIL",
	SMS = "SMS",
}

export enum OTPStatus {
	EXPIRED = "EXPIRED",
	VALID = "VALID",
}

export enum OTPMaxAttempts {
	ONBOARDING = 6,
	PROFILE = 6,
	INVITATION = 6,
}
