import { assignToDomainObject } from "../../../utils/domainObject";
import { Transform, Type } from "class-transformer";
import { IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength } from "class-validator";
import moment from "moment";
import { IsContactNumber, IsMoment, IsNotWhiteSpace } from "../../../validators";
import { toMoment, toNumber } from "../../transformers";

export class HealthcareSpokespersonDO {
	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public name!: string;

	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public relationship!: string;

	@IsString({ always: true })
	@IsNotEmpty({ always: true })
	@IsContactNumber({ always: true })
	public phoneNumber!: string;
}
const healthcareSpokespersonDOKeys: Array<keyof HealthcareSpokespersonDO> = ["name", "relationship", "phoneNumber"];

export class FullHealthcareSpokespersonDO extends HealthcareSpokespersonDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public createdAt!: moment.Moment;
	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public updatedAt!: moment.Moment;

	@IsOptional({ always: true })
	public getBaseDO = (): FullHealthcareSpokespersonDO | HealthcareSpokespersonDO => {
		// Returns object with only keys of specified DO.
		// NOTE: new DO(), instantiates to empty {} on backend
		return assignToDomainObject(new HealthcareSpokespersonDO(), { ...this }, healthcareSpokespersonDOKeys);
	};
}
