import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

// tslint:disable-next-line: ban-types
export function xorConstraint(
	xorPropertyName: string,
	validationOptions?: ValidationOptions,
): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "xorConstraint",
			target: object.constructor,
			propertyName,
			constraints: [xorPropertyName],
			options: validationOptions,
			validator: {
				validate(value: string, args: ValidationArguments): boolean {
					// lifted from https://github.com/typestack/class-validator/issues/168#issuecomment-373944641
					return (
						(!!value && !args.object[args.constraints[0]]) || (!value && !!args.object[args.constraints[0]])
					);
				},
				defaultMessage(args: ValidationArguments): string {
					return `Failed XOR relation between "${args.property}" and "${args.constraints[0]}".`;
				},
			},
		});
	};
}
