import { Form as DSForm } from "@lifesg/react-design-system/form";
import { FormTextareaProps } from "@lifesg/react-design-system/form/types";
import { IFormInputBaseProps, withBaseInput } from "app/hooks/useForm/components/Form.Input";
import { FocusEvent, useEffect, useRef } from "react";
import { CharacterCountLabel } from "./styles";

export interface IFormTextAreaProps extends IFormInputBaseProps {
	/** To hide the counter label */
	hideCharCount?: boolean;
	/** Rows of text area */
	rows?: number;
	/** Resizable text area */
	isResizable?: boolean;
	/** ScrollToTop onBlur */
	scrollToTop?: boolean;
}

const MultiLineComponent = (props: FormTextareaProps & { origProps: IFormTextAreaProps }) => {
	const { rows, isResizable, scrollToTop, dataTestId } = props.origProps;
	const elementRef = useRef<HTMLTextAreaElement>(null);
	const minHeight = 48;
	useEffect(() => {
		if (elementRef.current && !isResizable) {
			elementRef.current.style.height = "inherit";
			elementRef.current.style.height = `${Math.max(elementRef.current.scrollHeight, minHeight)}px`;
		}
	}, [isResizable, props.value]);

	const blurHandler = (event: FocusEvent<HTMLTextAreaElement, Element>) => {
		if (props.onBlur) {
			props.onBlur(event);
		}
		if (scrollToTop && elementRef.current) {
			elementRef.current.scroll({ top: 0 });
		}
	};

	const renderCustomCounter = (maxLength: number, currentValueLength: number) => {
		const charsLeft = maxLength - currentValueLength;
		const testId = dataTestId ? `${dataTestId}-character-count` : "text-field-character-count";
		return (
			<CharacterCountLabel data-testid={testId} weight="semibold">{`${charsLeft} character${
				charsLeft !== 1 ? "s" : ""
			} left`}</CharacterCountLabel>
		);
	};

	return (
		<div>
			<div className="textarea-group">
				<DSForm.Textarea
					{...props}
					ref={elementRef}
					rows={rows ?? 1}
					style={{
						minHeight: minHeight,
						resize: `${isResizable ? "vertical" : "none"}`,
						overflow: `${isResizable ? "initial" : "hidden"}`,
					}}
					maxLength={props.maxLength ?? props.origProps.maxLength}
					onBlur={blurHandler}
					renderCustomCounter={renderCustomCounter}
				/>
			</div>
		</div>
	);
};

const FormTextArea = withBaseInput<IFormTextAreaProps>(MultiLineComponent);

export default FormTextArea;
