import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import { AccessType } from "../api/sharedAccess";
import { SharedPlanAccessSetting } from "../api/trustedConnection/domainObjects/sharedAccessSetting";
import { AccessSetting } from "../api/trustedConnection/enums";
/**
 * Ensure that all Plantypes are accounted for.
 *
 * @export
 * @param {ValidationOptions} [validationOptions]
 * @returns {(object: Object, propertyName: string) => void}
 */
// tslint:disable-next-line: ban-types
export function IsValidSharedPlanArray(
	validationOptions?: ValidationOptions,
): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "IsValidSharedPlanArray",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: SharedPlanAccessSetting[]): boolean {
					// Check that value is an array and length is equal to the number of AccessTypes
					if (false === Array.isArray(value) || value.length !== Object.keys(AccessType).length) {
						return false;
					}

					const hasAccessType: { [key in AccessType]: boolean } = {
						[AccessType.FuneralPlan]: false,
						[AccessType.AdvanceCarePlanV2]: false,
						[AccessType.CPF]: false,
						[AccessType.LastingPowerOfAttorney]: false,
						[AccessType.PersonalInfo]: false,
						[AccessType.Will]: false,
					};

					for (const v of value) {
						// Check that AccessSetting is an enum value and that no extra properties are included
						if (false === Object.values(AccessSetting).includes(v.setting) || Object.keys(v).length > 2) {
							return false;
						}
						hasAccessType[v.type] = true;
					}

					for (const v of Object.values(hasAccessType)) {
						if (!v) {
							return false;
						}
					}

					return true;
				},
				defaultMessage(args: ValidationArguments): string {
					return `InvalidSharedPlanArray. "${args.value}" should contain all AccessType plans with valid AccessSetting."`;
				},
			},
		});
	};
}
