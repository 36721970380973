import { EOLErrorCodes } from "app/common/errors";
import ResponseError from "app/common/responseError";
import LanguageSelector from "app/components/basic/LanguageSelector";
import { useGlobalLoadingState } from "app/components/basic/LinearProgressIndicator/GlobalLoadingStateProvider";
import UnexpectedError from "app/components/composites/ErrorBoundary/ErrorPages";
import { setLogout } from "app/utils/url";
import { useRouter } from "next/router";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

interface IErrorHandler {
	sendError: (type: ResponseError) => void;
}

const ErrorHandler = createContext<IErrorHandler>({
	sendError: (): void => {},
});

/**
 * Hook equivalent of throw to Error Boundary, use to throw error from stateless component
 * -For stateful use throwToErrorBoundary in ErrorBoundary
 * @example
 * const { sendError } = useErrorHandler();
 * ...catch (e) {
 *     sendError(e);
 * }...
 */
export const useErrorHandler = (): IErrorHandler => useContext(ErrorHandler);

export const ErrorHandlerProvider = (props: { children: ReactNode }): JSX.Element => {
	const [error, setError] = useState<null | ResponseError>(null);
	const [errorLocation, setErrorLocation] = useState<null | string>(null);
	const [, setIsLoading] = useGlobalLoadingState();
	const router = useRouter();

	const sendError = useCallback(
		(type: ResponseError): void => {
			void setErrorLocation(router.asPath);
			void setError(type);
		},
		[router],
	);

	useEffect(() => {
		if (errorLocation && router.asPath !== errorLocation) {
			void setError(null); // reset error
			void setErrorLocation(null); // reset error location
		}
	}, [errorLocation, router]);

	useEffect(() => {
		setIsLoading(false);

		if (error && error.name === EOLErrorCodes.UserNotFoundError) {
			setLogout();
		}
	}, [error, setIsLoading]);

	return (
		<ErrorHandler.Provider value={{ sendError }}>
			{error && error.name === EOLErrorCodes.UserNotFoundError && <></>}
			{error && (
				<>
					<LanguageSelector className="mt40" id="language-selector-top" error={true} />
					<UnexpectedError />
					<LanguageSelector
						className="mb104"
						id="language-selector-bottom"
						error={true}
						hideWhenTranslationUnavailable={true}
					/>
				</>
			)}
			{!error && <>{props.children}</>}
		</ErrorHandler.Provider>
	);
};
