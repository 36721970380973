import { RelationshipToDonee, RelationshipToDoneeFromCode, TRelationshipCode } from "app/common/api/lpaAcp";

export enum CombinedFormKeys {
	ID = "id",
	NAME = "name",
	NRIC = "nric",
	SEX = "sex",
	DATE_OF_BIRTH = "dateOfBirth",
	NATIONALITY = "nationality",
	DIALECT = "dialect",
	RACE = "race",
	PHONE_NUMBER = "contact",
	EMAIL_ADDRESS = "email",
	APPOINTED_PERSONS = "appointedPersons",
	LAST_RETRIEVED = "lastRetrieved",
	// registered address
	POSTAL_CODE = "postalCode",
	FLOOR_UNIT = "floorUnit",
	FLOOR = "floor",
	UNIT = "unit",
	BLOCK_OR_HOUSE_NUMBER = "blockOrHouseNumber",
	STREET_NAME = "streetName",
	BUILDING_NAME = "buildingName",
	RESIDENTIAL_STATUS = "residentialStatus",
	// mailing address
	SAME_MAILING_ADDRESS = "sameMailingAddress",
	MAILING_ADDRESS_TYPE = "mailingAddressType",
	MAILING_OVERSEAS_ADDRESS = "mailingOverseasAddress",
	MAILING_OVERSEAS_STREET_NAME = "mailingOverseasAddressStreetName",
	MAILING_OVERSEAS_UNIT_NUMBER = "mailingOverseasAddressUnitNumber",
	MAILING_OVERSEAS_CITY_STATE = "mailingOverseasAddressCityState",
	MAILING_OVERSEAS_POSTAL_CODE = "mailingOverseasAddressPostalCode",
	MAILING_OVERSEAS_COUNTRY_REGION = "mailingOverseasAddressCountryRegion",
	PRIVACY_AND_CONSENT = "privacyAndConsent",
	// LPA
	PERSONAL_WELFARE_MANNER_OF_ACTING = "canPersonalWelfareDecisionBeMadeSeparately",
	PROPERTY_AFFAIRS_MANNER_OF_ACTING = "canPropertyAndAffairsDecisionBeMadeSeparately",
	REPLACE_APPOINTED_PERSON = "replaceAppointedPerson",
	REPLACEMENTS = "replacements",
	PERSONAL_WELFARE_POWERS = "isConsentRequiredForPersonalWelfare",
	COURT_APPROVAL_TO_SELL_RESIDENTIAL_PROPERTY = "allowSellingResidentialProperty",
	RESIDENTIAL_PROPERTY_ADDRESS = "propertyAddress",
	IS_SAME_RESIDENTIAL_PROPERTY_ADDRESS = "isSamePropertyAddress",
	COURT_APPROVAL_TO_SELL_NON_RESIDENTIAL_PROPERTY = "allowSellingNonResidentialProperty",
	CASH_GIFT_RESTRICTION = "cashGiftRegistration",
	CASH_GIFT_MAX_AMOUNT = "maxCashGift",
	// ACP
	IMPORTANT_ACTIVITIES_FOR_DAILY_LIVING = "importantActivitiesForDailyLiving",
	ELEMENTS_OF_CARE = "elementsOfCare",
	GOAL_OF_CARE = "isComfortImportant",
	SPECIAL_REQUESTS = "specialRequests",
	//Donee
	DONEES = "donees",
	HEALTHCARE_SPOKESPERSONS = "healthcareSpokespersons",
	RELATIONSHIP_CODE = "relationshipCode",
	POWERS_GRANTED = "powersGranted",
	REPLACEMENT_DONEES = "replacement donees",
	// Terms and Conditions
	TERMS_AND_CONDITIONS = "termAndConditions",
}

export const RelationshipLabel: Record<RelationshipToDonee, string> = {
	[RelationshipToDonee.SPOUSE]: "Spouse",
	[RelationshipToDonee.CHILD]: "Child",
	[RelationshipToDonee.PARENT]: "Parent",
	[RelationshipToDonee.SIBLING]: "Sibling",
	[RelationshipToDonee.GRANDCHILD]: "Grandchild",
	[RelationshipToDonee.GRANDPARENT]: "Grandparent",
	[RelationshipToDonee.STEP_CHILD]: "Stepchild",
	[RelationshipToDonee.IN_LAW]: "In-law",
	[RelationshipToDonee.PD_ACTING_AS_DONEE]: "Professional Deputy (individual) acting as Donee",
	[RelationshipToDonee.OTHER_FAMILY_MEMBER]: "Other family member",
	[RelationshipToDonee.NON_FAMILY_MEMBER]: "Non-family member",
};

export const getRelationshipLabel = (code: number): string =>
	RelationshipLabel[RelationshipToDoneeFromCode[String(code) as TRelationshipCode]];

export enum DoneePowerLabel {
	AUTHORIZE_PERSONAL_WELFARE_LABEL = "1",
	AUTHORIZE_PROPERTY_AFFAIRS_LABEL = "2",
	BOTH_POWER_LABEl = "3",
}

export enum LocalAddressLabel {
	LABEL_POSTAL_CODE = "Postal code",
	LABEL_BLOCK_OR_HOUSE_NUMBER = "Block or house number",
	LABEL_STREET_NAME = "Street name",
	LABEL_BUILDING_NAME = "Building name (if applicable)",
	LABEL_FLOOR_UNIT = "Floor and unit number",
}

export enum OverseasAddressLabel {
	LABEL_OVERSEAS_STREET_NAME = "Street name",
	LABEL_OVERSEAS_UNIT_NUMBER = "Apartment, suite, or unit number",
	LABEL_OVERSEAS_CITY_STATE = "City, state, province",
	LABEL_OVERSEAS_POSTAL_CODE = "Zip or postal code",
	LABEL_OVERSEAS_COUNTRY_REGION = "Country or region",
}

export enum DraftKey {
	LPA_ACP = "LPA_ACP",
	LPA = "LPA",
}

export enum StepStatus {
	COMPLETED = "Completed",
	IN_PROGRESS = "In progress",
	NOT_STARTED = "Not started",
}
