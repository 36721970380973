import { FormLabelAddonProps } from "@lifesg/react-design-system/form/types";
import { DoneeDO, DoneePowerDO } from "app/common/api/lpaAcp/domainObjects";
import DoneeCard from "app/components/composites/DoneeCard";
import { DoneePowerLabel } from "app/components/templates/LPA-ACP/common/data";
import { IData, IFormFunctions } from "app/hooks/useForm";
import { FormLabel, FormLabelProps } from "app/hooks/useForm/components/Form.Label";
import { useEffect, useState } from "react";
import { Grid, SemanticWIDTHSNUMBER } from "semantic-ui-react";

interface IFormRadioProps extends Omit<FormLabelProps, "children"> {
	field: string;
	form: IFormFunctions;
	disabled?: boolean;
	className?: string;
	addonText?: string;
	beforeSelectItem?: (...args: any[]) => void;
	afterSelectItem?: (...args: any[]) => void;
	donees?: DoneeDO[];
	/** width of the input in mobile */
	mobileWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in computer */
	computerWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in tablet */
	tabletWidth?: SemanticWIDTHSNUMBER;
}

const FormDoneeCard = (props: IFormRadioProps): JSX.Element => {
	const [performAfterAction, setPerformAfterAction] = useState(false);

	useEffect(() => {
		if (performAfterAction && props.afterSelectItem) {
			props.afterSelectItem();
			setPerformAfterAction(false);
		}
	}, [performAfterAction]);

	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	let id = props.field + "__loading";
	const className = `form-field-${key} ${props.className ?? ""}`;

	if (!props.form.loading) {
		id = key;
	}

	const value = formField ? formField.value : [];
	const errorMessage = formField ? props.form.getError(props.field) : "";
	const doneeCardItems = formField ? formField.doneeCardItems : [];
	const isSubmitting = formField ? props.form.submittingFields[formField.key] : false;

	const selectItemHandler = (fieldsValues: IData) => {
		const existingPowers = value as DoneePowerDO[];
		const results: DoneePowerDO[] = [];
		for (const key of Object.keys(fieldsValues)) {
			const value = fieldsValues[key];
			if (value) {
				const replacementDoneePower = new DoneePowerDO();
				replacementDoneePower.id = -1;
				const mainDonee = props.donees?.filter((donee) =>
					donee.id < 0 ? donee.nric === key : donee.id.toString() === key,
				)[0];
				if (existingPowers.length !== 0) {
					const existingPower = existingPowers.filter((power) =>
						power.replacingMainDonee
							? power.replacingMainDonee.id < 0
								? power.replacingMainDonee.nric === key
								: power.replacingMainDonee.id.toString() === key
							: "",
					)[0];
					Object.assign(replacementDoneePower, existingPower); // for editing if this replacement donee already had replace this maindonee
				}

				if (mainDonee) {
					replacementDoneePower.authorizePersonalWelfare = true;
					replacementDoneePower.authorizePropertyAffairs = true;
					if (value === DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL) {
						replacementDoneePower.authorizePropertyAffairs = false;
					} else if (value === DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL) {
						replacementDoneePower.authorizePersonalWelfare = false;
					}

					replacementDoneePower.replacingMainDonee = mainDonee;

					results.push(replacementDoneePower);
				}
			}
		}
		return results;
	};

	const popupContent: FormLabelAddonProps = {
		"data-testid": `${id}__addon`,
		content: props.addonText || "",
		type: props.addonText ? "popover" : undefined,
	};

	const inputWidth = props.computerWidth ?? 12;

	return (
		<Grid className={className?.includes("storybook") ? "form-storybook" : "form"}>
			<Grid.Row>
				<Grid.Column
					className="no-margin no-padding"
					mobile={props.mobileWidth ?? inputWidth}
					tablet={props.tabletWidth ?? inputWidth}
					computer={inputWidth}
				>
					<FormLabel
						id={id}
						title={props.title}
						subtitle={props.subtitle}
						popupContent={popupContent}
						fullWidthLabel={props.fullWidthLabel}
						labelClassName={props.labelClassName}
						errorMessage={errorMessage}
						errorMessageTestId={`${id}__error`}
						formType={props.formType}
					>
						<div className={className}>
							<DoneeCard
								{...props}
								name={id}
								selectedValues={value as DoneePowerDO[]}
								updateSelectedValues={selectItemHandler}
								errorMessage={errorMessage}
								disabled={props.disabled || isSubmitting}
								items={doneeCardItems || []}
								doneeForm={props.form}
							/>
						</div>
					</FormLabel>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default FormDoneeCard;
