import { CrossIcon } from "@lifesg/react-icons/cross";
import Button, { IButtonProps, TButtonTypes } from "app/components/basic/Button";
import Message, { IMessageType } from "app/components/basic/Message";
import Divider from "app/components/widgets/Divider";
import { Form, IFormFunctions } from "app/hooks/useForm";
import { useIsLifeSgApp } from "app/hooks/useIsLifeSgApp";
import { lowercaseAndHyphen } from "app/utils/strings";
import { useEffect, useState } from "react";
import FocusTrap from "react-focus-lock";
import { Modal as SemanticModal } from "semantic-ui-react";
import "./Modal.scss";
import { MyLegacyModal } from "app/components/common/MyLegacyModal";

export type IModalButton = [string, TButtonTypes, () => void, boolean?, (() => Promise<boolean>)?];

export const ModalClassName = "modal-content";

export interface IMinimalModal {
	id: string;
	closeCallback: () => void;
	title: string;
	subTitle: string;
	button1: IModalButton;
	button2?: IModalButton;
}

interface IProps {
	id: string;
	isOpen: boolean;
	type: "mylegacyModalMessage" | "form" | "message";
	closeCallback: () => void; // function triggered when user close by the top right close button
	title?: string;
	subTitle?: string;
	form?: IFormFunctions;

	isLoading?: boolean;
	/**
	 * @param string name,
	 * @param TButtonTypes Button Types
	 * @param function action
	 * @param boolean disabled?
	 */
	button1?: IModalButton;
	/**
	 * @param string name,
	 * @param TButtonTypes Button Types
	 * @param function action
	 * @param boolean disabled?
	 */
	button2?: IModalButton;
	/**
	 * @param IMessageType type,
	 * @param string message content
	 */
	message?: [IMessageType, string];
	preventDismissOnClick?: boolean;
	children?: React.ReactNode;
	heading?: JSX.Element;
	actions?: JSX.Element;
}

const ModalComponent = (props: IProps): JSX.Element => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const { isLifeSgApp, mastheadHeight } = useIsLifeSgApp();

	useEffect(() => {
		// type = 'message' and 'mylegacyModalMessage' are the small non-full page modal
		if (!props.isOpen || props.type === "mylegacyModalMessage" || props.type === "message") {
			return;
		}
		// fixed scroll by screen width. 768px is the breakpoint for medium sized screens
		const shouldFixedScroll = window.innerWidth < 768 ? true : false;
		// if there the body is already fixed. don't do anything
		if (shouldFixedScroll && window.document.getElementsByTagName("body")[0].style.position !== "fixed") {
			// set current scroll position
			setScrollPosition(window.scrollY);
			window.document.getElementsByTagName("body")[0].style.position = "fixed";
		}

		return (): void => {
			window.document.getElementsByTagName("body")[0].style.position = "relative";
			if (scrollPosition !== 0) {
				window.scrollTo({ top: scrollPosition });
				setScrollPosition(0);
			}
		};
	}, [scrollPosition, props.isOpen, props.type]);

	useEffect(() => {
		const modal = document.getElementById(props.type === "form" ? `${props.id}__content` : props.id);
		if (modal && isLifeSgApp && props.type !== "mylegacyModalMessage") {
			modal.setAttribute("style", `margin-top:${mastheadHeight}px !important`);
		}
	}, [isLifeSgApp, mastheadHeight, props.id, props.type]);

	const renderCloseIcon = (): JSX.Element => {
		const CloseIcon = (props: any): JSX.Element => (
			<div {...props}>
				<CrossIcon className="modal-close-icon-svg" />
			</div>
		);

		return (
			<Button
				className="modal-close"
				id="modal-close-icon"
				name="close"
				type="borderless"
				onClick={props.closeCallback}
				IconOnly={CloseIcon}
			/>
		);
	};

	const renderFormHeader = (): JSX.Element => {
		if (props.heading) {
			return props.heading;
		}

		return (
			<>
				<div className="modal-large-title">
					{renderCloseIcon()}
					<div className="modal-large-title__words semi-bold">{props.title}</div>
				</div>
				<Divider />
			</>
		);
	};

	const renderActions = (): JSX.Element => {
		if (props.actions) {
			return props.actions;
		}

		return (
			<SemanticModal.Actions>
				{props.button2 && renderButton(props.button2)}
				{props.button1 && renderButton(props.button1)}
			</SemanticModal.Actions>
		);
	};

	const renderButton = (buttonConfig: IModalButton): JSX.Element => {
		const [name, type, onClick, disabled, additionalValidations] = buttonConfig;
		const id = props.id + "__" + lowercaseAndHyphen(name);
		const isSubmitting = props.form ? props.form.hasSubmittingField() : false;
		const action = async (): Promise<void> => await onClick();
		const buttonProps = { id, type, name, action, onClick, disabled, additionalValidations };

		if (props.form && (type === "primary" || type === "danger")) {
			return <Form.Button {...buttonProps} form={props.form} />;
		}

		return <Button {...buttonProps} disabled={isSubmitting || disabled} loading={props.isLoading} />;
	};

	if (props.type === "mylegacyModalMessage") {
		const renderButtons: IButtonProps[] = [];

		const renderMessageButton = (buttonConfig: IModalButton) => {
			if (buttonConfig) {
				const [name, type, onClick] = buttonConfig;
				const id = props.id + "__" + lowercaseAndHyphen(name);
				const buttonProps = { id, type, name, onClick };
				renderButtons.push(buttonProps);
			}
		};

		props.button1 && renderMessageButton(props.button1);
		props.button2 && renderMessageButton(props.button2);

		return (
			<MyLegacyModal
				zIndex={100000}
				title={props.title || ""}
				className={"modal-message"}
				description={props.subTitle || ""}
				id={props.id}
				show={true}
				onModalClose={props.closeCallback}
				buttons={renderButtons}
			/>
		);
	}

	return (
		<SemanticModal
			id={props.id}
			open={props.isOpen}
			onClose={props.closeCallback}
			closeOnEscape={props.preventDismissOnClick ? false : true}
			closeOnDimmerClick={props.preventDismissOnClick ? false : props.type === "form" ? true : false}
			className={props.type === "form" ? "modal-large" : "modal-message"}
		>
			<SemanticModal.Content className={ModalClassName} id={`${props.id}__content`}>
				<FocusTrap className="focus-lock">
					{props.type === "form" && renderFormHeader()}
					<SemanticModal.Description>
						{props.type === "message" && (
							<>
								<p className="modal-message-title semi-bold">{props.title}</p>
								{props.subTitle && <p className="modal-message-subtitle">{props.subTitle}</p>}
							</>
						)}
						{props.children}
						{props.message && <Message type={props.message[0]} content={props.message[1]} show={true} />}
					</SemanticModal.Description>

					{props.type === "message" && <Divider />}
					{renderActions()}
				</FocusTrap>
			</SemanticModal.Content>
		</SemanticModal>
	);
};

export default ModalComponent;
