import { Grid } from "semantic-ui-react";
import { IFormFunctions } from "../Form";
import "../Form.scss";
import FormCheckbox from "./Form.Checkbox";

interface IProps {
	title: string;
	subtitle: string;
	form: IFormFunctions;
	field: string;
}

const LongCheckBox = (props: IProps): JSX.Element => (
	<Grid className="mt40 mb0">
		<Grid.Row columns={12}>
			<Grid.Column mobile={12} tablet={12} computer={8} className="no-margin no-padding-vertical ">
				<h4 className="semi-bold">{props.title}</h4>
			</Grid.Column>
		</Grid.Row>
		<Grid.Row columns={12} className="long-checkbox__body">
			<Grid.Column mobile={12} tablet={12} computer={8} className="no-padding-vertical long-checkbox__top-space">
				<div>{props.subtitle}</div>
			</Grid.Column>
			<Grid.Column mobile={12} tablet={12} computer={4} className="no-padding-vertical">
				<FormCheckbox
					horizontal={true}
					form={props.form}
					field={props.field}
					title={props.title}
					hideTitle={true}
				/>
			</Grid.Column>
		</Grid.Row>
	</Grid>
);

export default LongCheckBox;
