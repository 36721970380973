import { DisclaimerLinks } from "@lifesg/react-design-system/footer";
import { Text } from "@lifesg/react-design-system/text";
import footerMetadata from "app/assets/files/translations/footer.json";
import { validateDateString } from "app/common/validators";
import { baseEndOfLifeUrl } from "app/components/templates/Guide/__common__/Topic";
import { useFeatureConfig } from "app/hooks/useFeatureConfig";
import { getFormattedJSJodaDate } from "app/utils/date";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import { ITranslatedObject } from "app/utils/internationalization/TranslationPageRenderer";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { showSimplifiedHeaderAndFooter } from "../util";
import {
	CreditsLogos,
	CreditsSection,
	DSFooter,
	FooterImage,
	Link,
	LinksColumn,
	LogoSection,
	MainSection,
} from "./styles";

const PRIVACY_LINK = "/privacy-statement";
const TERMS_LINK = "/terms-of-use";
const REPORTVULN_LINK = "https://tech.gov.sg/report_vulnerability";

interface FooterProps {
	isMinimalFooter?: boolean;
}

const Footer = (props: FooterProps): JSX.Element => {
	const { isMinimalFooter = false } = props;

	const pathname = typeof window !== "undefined" ? window.location.pathname : null;
	const [, selectedLanguage] = useGlobalLanguageState();
	const [content, setContent] = useState<ITranslatedObject[]>(footerMetadata["en"]);
	const router = useRouter();
	const { featureConfig } = useFeatureConfig();
	// Check for ACP 3.0
	const isRenderMinimalFooter = showSimplifiedHeaderAndFooter(pathname || "") || isMinimalFooter;

	const links = [
		[
			{
				title: `${content[1].translation}`,
				href: `/`,
			},
			{
				title: `${content[2].translation}`,
				href: baseEndOfLifeUrl,
			},
			{
				title: `${content[3].translation}`,
				href: `/when-death-happens`,
			},
			{
				title: `${content[4].translation}`,
				href: `/vault`,
			},
		],
		[
			{
				title: `${content[5].translation}`,
				href: `/find-a-service`,
			},
			...(featureConfig.EWILLS_TOGGLE ? [{ title: `${content[6].translation}`, href: `/find-a-tool/` }] : []),
			{
				title: `${content[7].translation}`,
				href: `/frequently-asked-questions`,
			},
			{
				title: `${content[8].translation}`,
				href: `/contact`,
			},
			{
				title: `${content[9].translation}`,
				href: `/about-us`,
			},
		],
	];

	const disclaimerLinks: DisclaimerLinks = {
		privacy: { href: PRIVACY_LINK },
		termsOfUse: { href: TERMS_LINK },
		reportVulnerability: { href: REPORTVULN_LINK },
	};

	const lastUpdatedDateString = validateDateString(featureConfig.LAST_UPDATED_DATE as string)
		? getFormattedJSJodaDate(featureConfig.LAST_UPDATED_DATE as string)
		: "";

	const overrideDisclaimerLinksText = (content: ITranslatedObject[]) => {
		const privacyLinkElement = document.querySelector(`a[href="${PRIVACY_LINK}"]`) as HTMLElement;
		const termsLinkElement = document.querySelector(`a[href="${TERMS_LINK}"]`) as HTMLElement;
		const reportVulnLinkElement = document.querySelector(`a[href="${REPORTVULN_LINK}"]`) as HTMLElement;
		if (privacyLinkElement) {
			(privacyLinkElement.childNodes[0] as Text).nodeValue = content[10].translation;
		}
		if (termsLinkElement) {
			(termsLinkElement.childNodes[0] as Text).nodeValue = content[11].translation;
		}
		if (reportVulnLinkElement) {
			(reportVulnLinkElement.childNodes[0] as Text).nodeValue = content[12].translation;
		}
	};

	useEffect(() => {
		overrideDisclaimerLinksText(footerMetadata["en"]);
	}, []);

	useEffect(() => {
		setContent(footerMetadata[selectedLanguage]);
		overrideDisclaimerLinksText(footerMetadata[selectedLanguage]);
	}, [selectedLanguage]);

	const renderFooterLinks = (links) => {
		return links.map(({ title, href }, index) => (
			<li key={index}>
				<Link id={`link-${href.replace(/\//g, "") || "home"}`} onClick={(event) => router.push(href)}>
					{title}
				</Link>
			</li>
		));
	};

	return (
		<DSFooter
			disclaimerLinks={disclaimerLinks}
			copyrightInfo={`© ${new Date().getFullYear()} MyLegacy@LifeSG, ${
				content[13].translation
			} ${lastUpdatedDateString}`}
		>
			{!isRenderMinimalFooter ? (
				<MainSection type="grid">
					<LogoSection>
						<img id="logo-mylegacy" src="/images/site-logo.png" alt="MyLegacy@LifeSG" />
					</LogoSection>
					{links?.[0] && <LinksColumn key="links-col-1">{renderFooterLinks(links[0])}</LinksColumn>}
					{links?.[1] && <LinksColumn key="links-col-2">{renderFooterLinks(links[1])}</LinksColumn>}
					<CreditsSection>
						<Text.XSmall id="disclaimer">{content[14].translation}</Text.XSmall>
						<CreditsLogos>
							<div>
								<FooterImage
									id="logo-govtech"
									src="/images/agencies/govtech-logo.png"
									alt={content[16].translation}
									$maxHeight="4.688rem"
									data-testid="logo_agency--govtech"
								/>
							</div>
							<div>
								<FooterImage
									id="logo-psd"
									src="/images/agencies/psd-logo.png"
									alt={content[15].translation}
									$maxHeight="4.406rem"
								/>
							</div>
							<div>
								<FooterImage
									id="logo-moh"
									src="/images/agencies/moh-logo.png"
									alt={content[17].translation}
									$maxHeight="4.25rem"
								/>
							</div>
						</CreditsLogos>
					</CreditsSection>
				</MainSection>
			) : (
				<></>
			)}
		</DSFooter>
	);
};

export default Footer;
