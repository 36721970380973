import Modal from "app/components/basic/Modal";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { useCallback, useEffect } from "react";
import { IAppointedPerson } from "../common/interfaces";
import { IAppointPersonModal } from "../common/utils";

const NAME = "name";
const RELATIONSHIP = "relationship";
const PHONE = "phone";

const fields: IFormSchema = {
	[NAME]: { type: "text", maxChar: 120, constraints: [["isRequired", "Name cannot be empty"]] },
	[RELATIONSHIP]: { type: "text", maxChar: 120, constraints: [["isRequired", "Relationship cannot be empty"]] },
	[PHONE]: {
		type: "text",
		maxChar: 20,
		constraints: [
			["isRequired", "Phone number cannot be empty"],
			["isLocalPhoneNumber", "Please enter a valid phone number"],
		],
	},
};

const NewAppointedPersonModal = ({
	personIndex,
	personList,
	closeCallback,
	updatePersonList,
}: IAppointPersonModal<IAppointedPerson>): JSX.Element => {
	const { form } = useForm();

	const { setupFormFields, warnChangesLostIfProceed, getValue } = form;

	const initFormModal = useCallback((): void => {
		if (undefined === personIndex) {
			return setupFormFields(fields, {}, NAME);
		}

		const coordinator: IAppointedPerson = personList[personIndex];
		const initialData = {
			[NAME]: coordinator.name,
			[RELATIONSHIP]: coordinator.relationship,
			[PHONE]: coordinator.phone,
		};
		setupFormFields(fields, initialData, NAME);
	}, [personList, personIndex, setupFormFields]);

	const closeModal = useCallback((): void => {
		warnChangesLostIfProceed(() => closeCallback());
	}, [warnChangesLostIfProceed, closeCallback]);

	const handleUpdatePersonList = useCallback(async (): Promise<void> => {
		const newOrUpdatedPerson: IAppointedPerson = {
			name: getValue(NAME),
			relationship: getValue(RELATIONSHIP),
			phone: getValue(PHONE),
		};

		const updatedList: IAppointedPerson[] = personList;
		if (undefined !== personIndex) {
			updatedList[personIndex] = newOrUpdatedPerson; // for editing
		} else {
			updatedList.push(newOrUpdatedPerson); // for new
		}

		updatePersonList(updatedList);
		closeCallback();
	}, [closeCallback, getValue, personIndex, personList, updatePersonList]);

	useEffect(() => initFormModal(), [initFormModal]);

	return (
		<Modal
			id="modal__add-coordinator"
			form={form}
			type="form"
			title="Add appointed person"
			isOpen={true}
			closeCallback={closeModal}
			button1={["Cancel", "secondary", closeModal]}
			button2={["Add", "primary", handleUpdatePersonList]}
		>
			<div style={{ marginTop: -24 }}>
				<Form.Input title="Name" field={NAME} form={form} />
				<Form.Input title="Relationship" field={RELATIONSHIP} form={form} computerWidth={6} mobileWidth={12} />
				<Form.Input title="Phone number" field={PHONE} form={form} computerWidth={6} mobileWidth={12} />
			</div>
		</Modal>
	);
};

export default NewAppointedPersonModal;
