import { IFormSchema } from "app/hooks/useForm";

export enum AddressSchemaFields {
	PROPERTY_TYPE = "property_type",
	POSTAL = "postal_code",
	BLOCK = "block",
	STREET = "street_name",
	BUILDING = "building_name",
	FLOOR_UNIT = "floor_unit",
}

enum PrivatePropertyTypes {
	APARTMENT = "Apartment",
	CONDOMINIUM = "Condominium",
	DETACHED_HOUSE = "Detached house",
	EXECUTIVE_CONDOMINIUM = "Executive condominium",
	SEMI_DETACHED_HOUSE = "Semi-detached house",
	TERRACE_HOUSE = "Terrace house",
}

enum HdbPropertyTypes {
	ONE_ROOM_FLAT = "1-room flat",
	TWO_ROOM_FLAT = "2-room flat",
	THREE_ROOM_FLAT = "3-room flat",
	FOUR_ROOM_FLAT = "4-room flat",
	FIVE_ROOM_FLAT = "5-room flat",
	EXECUTIVE_FLAT = "Executive flat",
	STUDIO_APARTMENT = "Studio apartment",
}

export const POSTALCODE_ERROR = "Enter postal code and select Find address.";
export const INVALID_POSTAL_CODE = "Invalid postal code.";

const baseAddressSchema: IFormSchema = {
	[AddressSchemaFields.BLOCK]: {
		type: "text",
	},
	[AddressSchemaFields.STREET]: {
		type: "text",
	},
	[AddressSchemaFields.BUILDING]: {
		type: "text",
	},
	[AddressSchemaFields.FLOOR_UNIT]: {
		type: "unitNumber",
		disableValidation: true,
	},
	[AddressSchemaFields.POSTAL]: {
		type: "number",
		constraints: [["isRequired", POSTALCODE_ERROR]],
		maxChar: 6,
	},
};

export const readOnlyhdbSchema: IFormSchema = {
	...baseAddressSchema,
	[AddressSchemaFields.PROPERTY_TYPE]: {
		type: "text",
	},
	[AddressSchemaFields.POSTAL]: {
		type: "number",
	},
};

export const privateSchema: IFormSchema = {
	[AddressSchemaFields.PROPERTY_TYPE]: {
		type: "dropdown",
		dropdownItems: Object.values(PrivatePropertyTypes).map((value) => {
			return {
				label: value,
				value: value,
			};
		}),
		constraints: [["isRequired", "Select the property type."]],
	},
	...baseAddressSchema,
};

export const hdbSchema: IFormSchema = {
	...baseAddressSchema,
	[AddressSchemaFields.PROPERTY_TYPE]: {
		type: "dropdown",
		dropdownItems: Object.values(HdbPropertyTypes).map((value) => {
			return {
				label: value,
				value: value,
			};
		}),
		constraints: [["isRequired", "Select the property type."]],
	},
	[AddressSchemaFields.FLOOR_UNIT]: {
		type: "unitNumber",
		disableValidation: false,
		constraints: [["isRequired", "Enter floor and unit number."]],
	},
};
