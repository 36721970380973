import { UserMyInfoDO, UpdateUserResponseDO } from "app/common/api/user/domainObjects";
import { EOLErrorCodes } from "app/common/errors/errorCodes";

/**
 * Validates `UserMyInfoDO`
 * @param data `UserMyInfoDO`
 * @throws `ValidationError` if uinfin is undefined, or if both email and phone is undefined
 */
export const validateUserMyInfoContact = (data: UserMyInfoDO | UpdateUserResponseDO): void => {
	if ((!data.email && !data.phone) || (data instanceof UserMyInfoDO && !data.uinfin)) {
		throw new ValidationError(EOLErrorCodes.MOLMyInfoMissingFieldError);
	}
};

class ValidationError extends Error {
	constructor(message: string) {
		super(message);
		this.name = message;
	}
}
