import Field, { IFieldProps } from "app/components/common/Field";
import { DownIcon } from "app/components/widgets/DownIcon";
import { UpIcon } from "app/components/widgets/UpIcon";
import { useCallback, useMemo, useRef, useState } from "react";
import { DropdownProps, Dropdown as SemanticDropdown } from "semantic-ui-react";
import "../Input/Input.scss";
import "./Dropdown.scss";

export interface IDropdownItem {
	key: string | number;
	text: string;
	value: string | number;
	disabled?: boolean;
	content?: JSX.Element;
}

export interface IDropdownItemTemplate {
	label: string;
	modifier?: "default";
	value?: string | number;
	contentData?: any;
	disabled?: boolean;
}

interface IProps extends IFieldProps {
	multiple?: boolean;
	placeholder?: string;
	noResultsMessage?: string;
	search?: ((options: any[], query: string) => any[]) | boolean;
	selectItem: (value: any) => void;
	additionLabel?: string;
	onAddItem?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
	onSearchChange?: (searchQuery: string) => void;
	hideIcon?: boolean;
	header?: JSX.Element | undefined;
	selectedValue?: string | string[] | number | number[];
	renderItem?: (object: any) => JSX.Element;
	clearSearch?: () => void;
	items: IDropdownItemTemplate[];
	errorMessage?: string;
	allowAdditions?: boolean;
}

const Dropdown = (props: IProps): JSX.Element => {
	const {
		name,
		disabled,
		multiple,
		placeholder,
		noResultsMessage,
		search,
		selectItem,
		additionLabel,
		onAddItem,
		onSearchChange,
		hideIcon,
		header,
		selectedValue,
		renderItem,
		clearSearch,
		items,
		errorMessage,
		allowAdditions,
	} = props;

	const ariaLabel = useMemo(() => {
		if (search) {
			return { "aria-labelledby": `${name}-dropdown__label` };
		}
		return {};
	}, [name, search]);

	const searchField = useRef<HTMLElement>();

	const [isOpen, setIsOpen] = useState(false);

	const handleSelect = useCallback(
		(e: React.SyntheticEvent<HTMLElement>, data?: DropdownProps): void => {
			if (data) {
				selectItem(data.value);
			}

			// always bring focus back to the dropdown when there's a click action
			if (searchField.current) {
				searchField.current.focus();
			}
		},
		[selectItem],
	);

	const handleSearchChange = useCallback(
		(e: React.SyntheticEvent<HTMLElement>, data?: DropdownProps): void => {
			if (onSearchChange) {
				let query = "";
				if (data) {
					query = data.searchQuery!;
				}
				onSearchChange(query);
			}
		},
		[onSearchChange],
	);

	const setupDropdownItems = useCallback(
		(): IDropdownItem[] =>
			items.map((item: IDropdownItemTemplate): IDropdownItem => {
				const dropdownItem: IDropdownItem = {
					key: (item.value as string) || item.label,
					text: item.label,
					value: (item.value as string) || item.label,
					disabled: item.disabled,
				};

				if (item.contentData && renderItem) {
					dropdownItem.content = renderItem(item.contentData);
				}

				if (item.modifier === "default" && !selectedValue) {
					selectItem(item.value || item.label);
				}

				return dropdownItem;
			}),
		[items, renderItem, selectItem, selectedValue],
	);

	// for role="combox", which is identified with the existence of this.props.search, the label should point to the input field of the component
	// reference: https://w3c.github.io/aria-practices/examples/combobox/combobox-autocomplete-none.html
	return (
		<Field
			{...props}
			componentId={search ? `${name}-dropdown__input` : `${name}-dropdown`}
			labelAsDiv={search ? false : true}
		>
			<SemanticDropdown
				{...ariaLabel}
				id={`${name}-dropdown`}
				additionPosition="top"
				allowAdditions={allowAdditions}
				additionLabel={additionLabel}
				onAddItem={onAddItem}
				onChange={handleSelect}
				icon={hideIcon ? null : <i className="dropdown-icon">{isOpen ? <UpIcon /> : <DownIcon />}</i>}
				deburr={true}
				fluid={true}
				selection={true}
				header={header}
				placeholder={!search ? placeholder : ""}
				noResultsMessage={noResultsMessage || (null as unknown as undefined)}
				search={search}
				onSearchChange={handleSearchChange}
				onOpen={() => {
					setIsOpen(true);
				}}
				onClose={() => {
					setIsOpen(false);
					if (search && clearSearch) {
						clearSearch();
					}
				}}
				className={errorMessage ? "input-error" : ""}
				multiple={multiple}
				value={selectedValue}
				options={setupDropdownItems()}
				upward={false}
				onClick={() => {
					if (searchField.current) {
						searchField.current.focus();
					}
				}}
				disabled={disabled}
				searchInput={{
					disabled: disabled,
					placeholder: placeholder,
					inputRef: (ref: HTMLElement): void => {
						if (search) {
							searchField.current = ref;
						}
					},
					id: `${name}-dropdown__input`,
				}}
			/>
		</Field>
	);
};

export default Dropdown;
