import { useCallback, useState } from "react";

export const useShowHide = (): [boolean, () => void, () => void] => {
	const [showModal, setShowModal] = useState(false);

	const showFn = useCallback(() => setShowModal(true), []);
	const hideFn = useCallback(() => setShowModal(false), []);

	return [showModal, showFn, hideFn];
};
