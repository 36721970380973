import React from "react";
import ReadOnlyField from "../../../../common/ReadOnlyField";
import SectionHeader from "../SectionHeader";
import NotGuardianFields from "./NotGuardianFields";

interface IProps {
	isGuardian: boolean;
	hasPermissionFromGuardian?: boolean;
	guardianName?: string;
	relationshipDescription?: string;
	onClickEdit?: () => void;
	isDeath: boolean;
}

const RelationshipSection = ({
	isGuardian,
	hasPermissionFromGuardian,
	guardianName,
	relationshipDescription,
	onClickEdit,
	isDeath,
}: IProps): JSX.Element => {
	const transformBoolean = (value: boolean) => (value ? "Yes" : "No");
	const title = isDeath ? "Are you a next-of-kin of the deceased?" : "Are you the parent of the stillborn child?";
	return (
		<>
			<SectionHeader id="edit-relationship-details" title="Relationship" onClickEdit={onClickEdit} />
			<ReadOnlyField id="readonly-field__is-nok" title={title} value={transformBoolean(isGuardian)} />
			{!isGuardian && (
				<NotGuardianFields
					isDeath={isDeath}
					hasPermissionFromGuardian={hasPermissionFromGuardian}
					guardianName={guardianName}
					relationshipDescription={relationshipDescription}
				/>
			)}
		</>
	);
};

export default RelationshipSection;
