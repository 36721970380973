import {
	ValidationArguments,
	ValidationOptions,
	ValidatorConstraint,
	ValidatorConstraintInterface,
	registerDecorator,
} from "class-validator";
import { DraftContentDto } from "../api/acp/domainObjects/acpDraft";
import { validateEmail } from "./emailValidator";
import { validateLocalPhoneNumber } from "./localPhoneNumberValidator";

// Validator constraint class
@ValidatorConstraint({ name: "IsACPContact" })
class IsACPContactConstraint implements ValidatorConstraintInterface {
	validate(value: DraftContentDto): boolean {
		if (value.currentStep > 0 && value.personalDetails) {
			const { email, mobileNo } = value.personalDetails;
			return validateEmail(email ?? "") && validateLocalPhoneNumber(mobileNo ?? "");
		}
		return value.currentStep === 0;
	}

	defaultMessage(args: ValidationArguments): string {
		return `"Must be a valid contact"`;
	}
}

// Custom decorator function
export function IsACPContact(validationOptions?: ValidationOptions) {
	return function (object: Object, propertyName: string) {
		registerDecorator({
			target: object.constructor,
			propertyName: propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsACPContactConstraint,
		});
	};
}
