import { Transform } from "class-transformer";
import { IsNumber, IsOptional, IsString } from "class-validator";
import moment from "moment";
import { IsMoment } from "../../../validators";
import { HTTP_VERBS } from "../../enums";
import { TrustedPersonDO } from "../domainObjects";
import { toMoment, toNumber } from "./../../transformers";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/users/trustedpersons";
export class Body extends TrustedPersonDO {}
export class Response {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
	@IsString()
	public nric!: string;
	@IsString()
	public nickname!: string;
	@IsOptional()
	@IsString()
	public email!: string;
	@IsOptional()
	@IsString()
	public phone!: string;
	@IsMoment()
	@Transform(toMoment)
	public invitedOn!: moment.Moment;
	@IsNumber()
	@Transform(toNumber)
	public inviteAttemptsLeft!: number;
}
