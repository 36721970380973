import { MyLegacyTheme } from "@lifesg/react-design-system/theme";
import "app/assets/styles/main.scss";
import { GlobalLoadingStateProvider } from "app/components/basic/LinearProgressIndicator/GlobalLoadingStateProvider";
import Maintenance from "app/components/hoc/Maintenance";
import { AlertFunctionsProvider } from "app/hooks/useAlert";
import { AlertFunctionsProviderV2 } from "app/hooks/useAlertV2";
import DeathCertFormProvider from "app/hooks/useDeathCertFormContext";
import { FeatureConfigProvider } from "app/hooks/useFeatureConfig";
import { InboxProvider } from "app/hooks/useInbox";
import { DoneeProvider } from "app/hooks/useLpaDonee";
import { UserProvider } from "app/hooks/useUser/provider";
import { AcpProvider } from "app/modules/acp/hooks/useAcpForm/provider";
import { EWillsProvider } from "app/modules/eWills/hooks/useEWillsForm/provider";
import { HdbParkingProvider } from "app/modules/hdb-car-parking/hooks/useHdbParkingForm/provider";
import { GlobalLanguageProvider } from "app/utils/internationalization/GlobalLanguageProvider";
import { ThemeProvider } from "styled-components";

interface ContextProviderProps {
	children: JSX.Element | JSX.Element[];
}

export const ContextProvider = ({ children }: ContextProviderProps): JSX.Element => {
	return (
		<ThemeProvider theme={MyLegacyTheme}>
			<GlobalLoadingStateProvider>
				<GlobalLanguageProvider>
					<FeatureConfigProvider>
						<Maintenance>
							<AlertFunctionsProviderV2>
								<AlertFunctionsProvider>
									<DeathCertFormProvider>
										<InboxProvider>
											<UserProvider>
												<EWillsProvider>
													<HdbParkingProvider>
														<AcpProvider>
															<DoneeProvider>{children}</DoneeProvider>
														</AcpProvider>
													</HdbParkingProvider>
												</EWillsProvider>
											</UserProvider>
										</InboxProvider>
									</DeathCertFormProvider>
								</AlertFunctionsProvider>
							</AlertFunctionsProviderV2>
						</Maintenance>
					</FeatureConfigProvider>
				</GlobalLanguageProvider>
			</GlobalLoadingStateProvider>
		</ThemeProvider>
	);
};
