import OTPApi from "app/api/otp";
import { OTPContactType, OTPType } from "app/common/api/otp";
import { ResponseOtpDO, VerifyResponseOtpDO } from "app/common/api/otp/domainObjects";
import { EOLErrorCodes } from "app/common/errors";
import Button from "app/components/basic/Button";
import Message from "app/components/basic/Message";
import Modal from "app/components/basic/Modal";
import ErrorMessage from "app/components/widgets/ErrorMessage";
import { GenericNetworkErrorMsg } from "app/constants/networkErrors";
import { useAlert } from "app/hooks/useAlert";
import { useErrorHandler } from "app/hooks/useErrorHandler";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { useShowHide } from "app/hooks/useShowHide";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import "./OTPModal.scss";

interface IProps {
	isOpen: boolean;
	OTPCreated: ResponseOtpDO;
	title: string;
	type: OTPType;
	contactType: OTPContactType;
	contact: string;
	invitationId?: number;
	disabled?: boolean;
	closeCallback: (locked?: boolean) => void;
	successCallback: (verifyOTPResponse?: VerifyResponseOtpDO) => void;
}

const OTP_FIELD = "otp";
const OTP_LABEL = "OTP";

const formFieldSchema: IFormSchema = {
	[OTP_FIELD]: {
		type: "numberString",
		maxChar: 6,
		constraints: [
			["isRequired", "OTP cannot be empty"],
			["isMinLength", 6, "Please enter a 6-digit OTP"],
		],
	},
};

const OTPModal = ({
	isOpen,
	OTPCreated,
	title,
	type,
	contactType,
	contact,
	invitationId,
	disabled,
	closeCallback,
	successCallback,
}: IProps): JSX.Element => {
	const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [boxedErrorMessage, setBoxedErrorMessage] = useState<string>("");
	const [showResendMessage, setShowResendMessage] = useState(false);
	const [countdownTimer, setCountdownTimer] = useState(-1);
	const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
	const [otpInputDisabled, setOtpInputDisabled] = useState(false);
	const [isLocked, setIsLocked] = useState(false);
	const [timerMessage, showTimerMessage, hideTimerMessage] = useShowHide();

	const disableResend = useCallback((): void => setResendButtonDisabled(true), []);
	const enableResend = useCallback((): void => setResendButtonDisabled(false), []);

	const { form } = useForm(formFieldSchema, {}, OTP_FIELD);
	const { getError, getValue, updateFieldValue, resetFieldError } = form;
	const { sendError } = useErrorHandler();
	const { inlineSnackbar, setInlineSnackbarMessage } = useAlert();

	const contactTypeString = contactType === OTPContactType.EMAIL ? "email address" : "mobile number";
	const verifyTypeString = type === OTPType.INVITATION ? "access" : "contact details";

	// sendOTP is called when modal is opened.
	// if there exists a OTP for user + contact + onboardingType, no new OTP will be sent.
	// Sends a new OTP otherwise.
	const initOTP = useCallback(() => {
		if (disabled) {
			hideTimerMessage();
			setErrorMessage(""); // to clear other messages
			setBoxedErrorMessage(
				"You could not verify your access. Please ask them to resend their invitation to the correct contact.",
			);
			setResendButtonDisabled(true);
			setOtpInputDisabled(true);
			setVerifyButtonDisabled(true);
			setIsLocked(true);
			return;
		}
		const now = moment();
		const { createdAt, remainingAttempts } = OTPCreated;
		// do not allow resend if user cannot send anymore.
		if (remainingAttempts < 1) {
			hideTimerMessage();
			disableResend();
			return;
		}

		showTimerMessage();
		if (now.isAfter(moment(createdAt).add(1, "minutes"))) {
			setCountdownTimer(-1);
			enableResend();
		} else {
			setCountdownTimer(moment(createdAt, "YYYY-MM-DDTHH:mm:ss.SSSSZ").add(1, "minute").diff(now));
		}
	}, [disableResend, enableResend, hideTimerMessage, showTimerMessage, OTPCreated, disabled]);

	// a new code can be sent by pressing resend, but resend is only possible at least 1 minute after the last sent code
	const resendOTP = useCallback(async (): Promise<void> => {
		let createdAt;
		let remainingAttempts;
		updateFieldValue(OTP_FIELD, "");
		setOtpInputDisabled(false);
		setVerifyButtonDisabled(false);
		resetFieldError(OTP_FIELD);
		disableResend();
		try {
			const result = await OTPApi.resendOTP(type, contactType, contact, invitationId);
			createdAt = result.createdAt;
			remainingAttempts = result.remainingAttempts;
			setShowResendMessage(true);

			// @TODO: remove the message for timer
		} catch (error: any) {
			const errorName = error[0].name;
			setShowResendMessage(false);
			if (error.name === EOLErrorCodes.UserNotFoundError) {
				return window.location.replace("/");
			}
			if (errorName === EOLErrorCodes.UserNotFoundError) {
				return window.location.replace("/");
			}
			if (errorName === EOLErrorCodes.AlreadyOnboardedError) {
				return window.location.replace("/vault");
			}

			// InvalidInvitationStatusError  thrown when user is trying to accept an invitation that is not in INVITED status
			// WrongContactError thrown when contact used to resend OTP does not match contact inside invitation
			if (
				errorName === EOLErrorCodes.InvalidInvitationStatusError ||
				errorName === EOLErrorCodes.WrongContactError
			) {
				setErrorMessage(""); // to clear other messages
				setBoxedErrorMessage("An error was encountered when sending the OTP. Please refresh your browser");
				setOtpInputDisabled(true);
				setVerifyButtonDisabled(true);
				setResendButtonDisabled(true);
				return;
			}
			// sendError does not work for onboarding page because onboarding is outside of error boundary
			sendError(error);
		}

		if (remainingAttempts < 1) {
			hideTimerMessage();
			disableResend();
			return;
		}

		showTimerMessage();
		const now = moment();
		if (now.isAfter(moment(createdAt).add(1, "minutes"))) {
			setCountdownTimer(-1);
			enableResend();
		} else {
			setCountdownTimer(moment(createdAt).add(1, "minute").diff(now));
		}
	}, [
		disableResend,
		enableResend,
		hideTimerMessage,
		showTimerMessage,
		sendError,
		resetFieldError,
		updateFieldValue,
		contact,
		contactType,
		invitationId,
		type,
	]);

	const closeOTPModal = useCallback(() => {
		setInlineSnackbarMessage(undefined);
		closeCallback(isLocked);
	}, [isLocked, setInlineSnackbarMessage, closeCallback]);

	// one single code can only be verified 6 times
	// otp will be deleted when successfully verified.
	const verifyOTP = useCallback(async (): Promise<void> => {
		try {
			const userInput = getValue(OTP_FIELD);
			const response = await OTPApi.verifyOTP(type, userInput, contact, invitationId);
			successCallback(response);
			closeOTPModal();
		} catch (error: any) {
			const errorName = error[0].name;
			// hide resend message on all errors
			setShowResendMessage(false);
			if (error.name === EOLErrorCodes.UserNotFoundError) {
				return window.location.replace("/");
			}
			if (errorName === EOLErrorCodes.UserNotFoundError) {
				return window.location.replace("/");
			}
			if (errorName === EOLErrorCodes.AlreadyOnboardedError) {
				return window.location.replace("/vault");
			}
			// error returned in post call is always an array (as handled by api/client);
			if (errorName === EOLErrorCodes.OTPMismatchError || errorName === EOLErrorCodes.OTPNotFoundError) {
				setBoxedErrorMessage(""); // to clear other messages
				setErrorMessage("The OTP that you entered was incorrect. Please try again.");
				return;
			}
			// will only show on 7th call to the server for one code
			if (errorName === EOLErrorCodes.OTPMaxAttemptsReachedError) {
				setBoxedErrorMessage(""); // to clear other messages
				setErrorMessage("You cannot verify this OTP. Please request a new OTP.");
				setOtpInputDisabled(true);
				setVerifyButtonDisabled(true);
				return;
			}
			// shows when user tries to verify an expired code. Each code expires after 3 minutes
			if (errorName === EOLErrorCodes.OTPExpiredError) {
				setBoxedErrorMessage(""); // to clear other messages
				setErrorMessage("The OTP is no longer valid. Please request a new OTP.");
				setOtpInputDisabled(true);
				setVerifyButtonDisabled(true);
				return;
			}

			// 6 codes has already been sent to user
			if (errorName === EOLErrorCodes.OTPMaxAttemptsForUserReachedError) {
				setErrorMessage(""); // to clear other messages
				if (type === OTPType.INVITATION) {
					setBoxedErrorMessage(
						"You could not verify your access. Please ask them to resend their invitation to the correct contact.",
					);
				} else {
					setBoxedErrorMessage(
						"Your contact details could not be verified. Please check again or contact us if you need help.",
					);
				}
				setResendButtonDisabled(true);
				setOtpInputDisabled(true);
				setVerifyButtonDisabled(true);
				setIsLocked(true);
				return;
			}

			if (errorName === EOLErrorCodes.InvalidInvitationStatusError) {
				setErrorMessage(""); // to clear other messages
				setBoxedErrorMessage(
					"The invitation cannot be accepted. Please check again or contact us if you need help.",
				);
				setOtpInputDisabled(true);
				setVerifyButtonDisabled(true);
				setResendButtonDisabled(true);
				return;
			}

			// all other network errors for verify OTP will be shown inline
			setInlineSnackbarMessage(GenericNetworkErrorMsg);
		}
	}, [closeOTPModal, getValue, setInlineSnackbarMessage, contact, invitationId, type, successCallback]);

	const onInputBlur = useCallback((): void => {
		setErrorMessage("");
		setShowResendMessage(false);
		return;
	}, []);

	const getTimerMessage = useCallback((): string => {
		if (countdownTimer < 1000) {
			return "You can request a new OTP now.";
		}
		let timeInSeconds = (countdownTimer / 1000).toString().substr(0, 2);
		timeInSeconds = timeInSeconds.split(".")[0];
		return `You can request a new OTP in ${timeInSeconds}s`;
	}, [countdownTimer]);

	useEffect(() => void initOTP(), [initOTP]);

	useEffect(() => {
		// do not show the 'You can request...' text, therefore timer does not need to be set
		// workaround for when modal first opens but user already has 6 otp codes
		if (!timerMessage) {
			return;
		}

		if (countdownTimer <= 1000) {
			enableResend();
			return;
		}

		if (countdownTimer > 1000) {
			disableResend();
			setTimeout(() => setCountdownTimer(countdownTimer - 1000), 1000);
		}
	}, [countdownTimer, timerMessage, disableResend, enableResend]);

	// @TODO: form.input gets re-rendered with the countdown timer.
	// is there a way we don't re-render it?
	return (
		<Modal
			type="form"
			id="otp-modal"
			form={form}
			isOpen={isOpen}
			title={title}
			closeCallback={closeOTPModal}
			button1={["Cancel", "secondary", closeOTPModal]}
			button2={["Verify", "primary", verifyOTP, verifyButtonDisabled]}
			preventDismissOnClick={true}
		>
			<p className="otp-modal__description">
				To verify your {verifyTypeString}, please enter the one-time password (OTP) that was sent to{" "}
				<span className="semi-bold">{contact}</span>
			</p>
			{type === OTPType.INVITATION && (
				<p className="font-type-two-xs mt8 mb0">
					The {contactTypeString} was provided by the person who invited you. If it is incorrect, please ask
					them to resend their invitation to the correct contact.
				</p>
			)}
			<div className="otp-modal__form-group">
				<Form.Input
					title={OTP_LABEL}
					field={OTP_FIELD}
					form={form}
					onBlurHandler={onInputBlur}
					hideErrorMessage={true}
					placeholder="6-digit OTP"
					disabled={otpInputDisabled}
					disableBlurValidation={true}
				/>
				<div className="otp-modal__resend-group">
					{!boxedErrorMessage && timerMessage && (
						<span className="otp-modal__resend-timer font-type-xs">{getTimerMessage()}</span>
					)}
					{!boxedErrorMessage && (
						<Button
							className="font-type-xs"
							id="otp-modal__resend"
							type="link"
							name="Resend OTP"
							onClick={resendOTP}
							disabled={resendButtonDisabled}
						/>
					)}
				</div>
			</div>
			{!showResendMessage && (getError(OTP_FIELD) || errorMessage) && (
				<ErrorMessage id="otp-modal__error" type="error">
					{getError(OTP_FIELD) || errorMessage}
				</ErrorMessage>
			)}
			{boxedErrorMessage && (
				<>
					<div className="mt24" />
					<Message type="failure" show={boxedErrorMessage !== ""} content={boxedErrorMessage} />
				</>
			)}
			{showResendMessage && (
				<ErrorMessage id="otp-modal__resend-message" type="info">
					We have sent you a new OTP. Please try again with this new OTP.
				</ErrorMessage>
			)}
			{inlineSnackbar}
		</Modal>
	);
};

export default OTPModal;
