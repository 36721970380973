import { IsOptional } from "class-validator";
import { HTTP_VERBS } from "../../enums";

export const httpVerb = HTTP_VERBS.DELETE;
export const url = "/api/users/combinedForm";
export class Body {
	/* class-validator not happy with us sending empty body, although we
	 * declared that we don't expect anything in request body already.
	 * Thus we need this optional attribute to pass the validation
	 * with empty request body
	 */
	@IsOptional()
	public _trigger?: boolean;
}
export class Response {}
