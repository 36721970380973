import { assignToDomainObject } from "../../../utils/domainObject";
import { Transform } from "class-transformer";
import { IsNumber, IsOptional, IsString, MaxLength } from "class-validator";
import moment from "moment";
import { IsContactNumber, IsMoment, IsNotWhiteSpace } from "../../../validators";
import { toMoment, toNumber } from "../../transformers";

export class ExecutorDO {
	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public name!: string;

	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public relationship!: string;

	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@IsContactNumber({ always: true })
	public contact!: string;
}
const executorDOKeys: Array<keyof ExecutorDO> = ["name", "relationship", "contact"];

export class FullExecutorDO extends ExecutorDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;

	@IsMoment()
	@Transform(toMoment)
	public createdAt!: moment.Moment;

	@IsMoment()
	@Transform(toMoment)
	public updatedAt!: moment.Moment;

	@IsOptional()
	public getBaseDO = (): ExecutorDO | FullExecutorDO => {
		// Return object with only keys of specified DO.
		// NOTE: new DO(), instantiates to empty {} on backend
		return assignToDomainObject(new ExecutorDO(), { ...this }, executorDOKeys);
	};
}
