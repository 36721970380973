import Input from "app/components/basic/Input";
import Field, { IFieldProps } from "app/components/common/Field";
import InputElement from "app/components/common/InputElement";
import { lowercaseAndHyphen } from "app/utils/strings";
import * as React from "react";
import { Radio as SemanticRadio } from "semantic-ui-react";
import "./Radio.scss";

const INPUT_SEPARATOR = ": ";
const INPUT_MESSAGE = " (Please specify)";

export interface IRadioItem {
	label: string;
	modifier?: "input" | "default";
}

export interface IRadioProps extends IFieldProps {
	horizontal?: boolean;
	horizontalOnDesktop?: boolean;
	items: IRadioItem[];
	selectItem: (item: any) => void;
	selectedValue: string;
	inputThatHasError?: string;
	validateRadioInput?: (key: string, inputLabel: string) => void;
	bindRef?: (ref: any, key: string) => void;
}

interface IState {
	inputArchive: { [key: string]: string };
}

class Radio extends React.Component<IRadioProps, IState> {
	public static getDerivedStateFromProps(nextProps: IRadioProps, prevState: IState): IState {
		if (nextProps && nextProps.selectedValue && nextProps.selectedValue.indexOf(INPUT_SEPARATOR) >= 0) {
			const key = nextProps.selectedValue.split(INPUT_SEPARATOR)[0];
			const value = nextProps.selectedValue.split(key + INPUT_SEPARATOR).join("");
			return {
				inputArchive: {
					...prevState.inputArchive,
					[key]: value,
				},
			};
		}
		return prevState;
	}

	public radioRefs: any = {};

	constructor(props: IRadioProps) {
		super(props);
		this.state = {
			inputArchive: this.archiveInitialInputs(),
		};
	}

	public render = (): JSX.Element => {
		let className = `radio-default mt8 radio--group${this.props.horizontal ? "--horizontal" : ""}`;
		const horizontalClassName = this.props.horizontalOnDesktop ? " radio--group--responsive" : "";
		className = className + horizontalClassName;

		if (this.props.errorMessage) {
			className += ` has-error-checkbox`;
		}

		return (
			<Field {...this.props} labelAsDiv={true}>
				<div className={className}>{this.renderRadioGroup()}</div>
			</Field>
		);
	};

	private renderRadioGroup = (): JSX.Element => {
		const radioArray: any = [];

		this.props.items.forEach((item: IRadioItem) => {
			radioArray.push(this.renderRadioItem(item));
			if (item.modifier === "input" && getOtherKey(this.props.selectedValue) === item.label) {
				radioArray.push(this.renderRadioInput(item));
			}
		});

		return radioArray;
	};

	private renderRadioItem = (item: IRadioItem): JSX.Element => {
		let id = `radio_${this.props.name}_${lowercaseAndHyphen(item.label)}`;

		if (this.props.disabled) {
			id += "--disabled";
		}

		let radioValue = item.label;
		let label = item.label;
		if (item.modifier === "input") {
			label += INPUT_MESSAGE;
			radioValue = item.label + INPUT_SEPARATOR + (this.state.inputArchive[item.label] || "");
		}

		const onClickHandler = (): void => {
			this.props.selectItem(radioValue);
		};

		return (
			<SemanticRadio
				disabled={this.props.disabled}
				id={id}
				key={id}
				name={this.props.name}
				label={label}
				value={radioValue}
				onClick={onClickHandler}
				checked={getOtherKey(this.props.selectedValue) === item.label}
			/>
		);
	};

	private renderRadioInput = (item: IRadioItem): JSX.Element => {
		const id = `radio-${this.props.name}-${lowercaseAndHyphen(item.label)}-input`;
		const errorMessage: string =
			this.props.inputThatHasError && this.props.inputThatHasError === item.label
				? "This field cannot be empty"
				: "";

		const bindInputRef = (ref: HTMLElement): void => {
			if (this.props.bindRef) {
				this.props.bindRef(ref, this.props.name);
			}
		};

		const onBlurHandler = (): void => {
			this.validateInputNotNull(item.label, this.state.inputArchive[item.label]);
		};

		const onChangeHandler = (value: string): void => {
			this.updateInput(item.label, value);
		};

		return (
			<div key={id} className="radio__input__wrapper">
				<Input
					disabled={this.props.disabled}
					bindRef={bindInputRef}
					componentId={id}
					className="radio__input"
					name={id}
					onTextChange={onChangeHandler}
					onBlur={onBlurHandler}
					errorMessage={errorMessage}
					maxChar={120}
					value={this.state.inputArchive[item.label] || ""}
					ariaLabel={`${item.label} ${INPUT_MESSAGE}`}
				/>
			</div>
		);
	};

	private updateInput = (value: string, selected: string): void => {
		const valueToReturn = value + INPUT_SEPARATOR + selected;
		this.props.selectItem(valueToReturn);
	};

	private validateInputNotNull = (label: string, value: string): void => {
		if (this.props.validateRadioInput) {
			if (value === "") {
				this.props.validateRadioInput(this.props.name, label);
			} else {
				this.props.validateRadioInput(this.props.name, "");
			}
		}
	};

	private archiveInitialInputs = (): { [key: string]: string } => {
		const inputArchive: { [key: string]: string } = {};
		this.props.items.forEach((item: IRadioItem) => {
			if (item.modifier === "input") {
				let inputValueToArchive = "";
				if (this.props.selectedValue && getOtherKey(this.props.selectedValue) === item.label) {
					inputValueToArchive = this.props.selectedValue;
				}
				inputArchive[item.label] = inputValueToArchive;
			}
		});
		return inputArchive;
	};
}

//FIXME: add more type safety check for this
export const getOtherKey = (value: string): string => {
	return String(value).split(":")[0];
};

//FIXME: add more type safety check for this
//currently it accepts a boolean and number
export const cleanRadioInput = (value: string): string => {
	if (String(value).indexOf(INPUT_SEPARATOR) >= 0) {
		const key = value.split(INPUT_SEPARATOR)[0];
		const inputValue = value.split(INPUT_SEPARATOR)[1];
		value = key + INPUT_SEPARATOR + inputValue.trim();
	}
	return value;
};

export default InputElement(Radio);
