import { Form, IFormFunctions } from "app/hooks/useForm";
import React from "react";
import { FormKeys } from "./schemas";

export interface QnProps {
	form: IFormFunctions;
	field?: string;
	afterSelectItem?: () => void;
}
interface Props extends QnProps {
	question: string;
	description?: string;
	id?: string;
}

export const YesOrNoShort = (props: Props): JSX.Element => {
	const { afterSelectItem, form, question, description, field } = props;
	const key = field === undefined ? FormKeys.IS_GUARDIAN : field;

	return (
		<Form.RadioBox
			title={question}
			subtitle={description}
			columns={2}
			form={form}
			field={key}
			afterSelectItem={afterSelectItem}
			className="death-cert-form__yes-or-no-short"
		/>
	);
};

export const YesOrNoLong = (props: Props): JSX.Element => {
	const { afterSelectItem, form, question } = props;

	return (
		<Form.RadioBox
			title={question}
			columns={1}
			form={form}
			field={FormKeys.HAS_PERMISSION_FROM_GUARDIAN}
			afterSelectItem={afterSelectItem}
			className="death-cert-form__download-permission"
		/>
	);
};

interface PropsFinal extends Props {
	field: string;
}

export const RemarkField = (props: PropsFinal): JSX.Element => {
	const { form, question, description, field } = props;
	return <Form.Input title={question} subtitle={description} field={field} form={form} />;
};
