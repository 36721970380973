export const formatUnitNumberValue = (unit: string | string[]): string[] => {
	/** ['floor' , 'unit'] */
	if (unit === "") {
		return [];
	}

	if (unit === "Invalid unit number") {
		return ["Invalid unit number", "Invalid unit number"];
	}

	// to check the unit is string[]
	if (Array.isArray(unit)) {
		if (unit.length === 2) return unit;
		return ["Invalid unit number", "Invalid unit number"];
	}

	return unit.split("-");
};

export const unitToString = (unit: string[]): string => {
	if (unit.length !== 2 || unit.includes("Invalid unit number")) {
		return "";
	}

	return unit.join("-");
};

/**
 * Checks if floor and unit is valid
 * @param floor string
 * @param unit string
 * @returns `true` if valid, `false` otherwise
 */
export const isFloorUnitValid = (floor: string, unit: string): boolean => {
	return !(!/^[0-9A-Z]{2,3}$/.exec(floor) || !/^[0-9A-Z]{2,5}$/.exec(unit));
};
