import { PersonFillIcon } from "@lifesg/react-icons/person-fill";
import { initialFromName } from "app/utils/strings";
import "./UserAvatar.scss";

interface IProps {
	name: string;
	nric: string;
	invited: boolean;
}

const AvatarIcon = (): JSX.Element => (
	<div className="icon-avatar">
		<PersonFillIcon />
	</div>
);

const UserAvatar = (props: IProps): JSX.Element => {
	return (
		<div className="user-avatar">
			<div
				className={`semi-bold user-avatar__icon ${props.invited ? "invited" : ""}${
					initialFromName(props.name) ? "" : " icon--svg"
				}`}
			>
				{initialFromName(props.name) || <AvatarIcon />}
			</div>
			<div className="user-avatar__bottom">
				<div className="user-avatar__bottom__name">{props.name}</div>
				<div className="user-avatar__bottom__nric">{props.nric}</div>
			</div>
		</div>
	);
};

export default UserAvatar;
