import { Form as DSForm } from "@lifesg/react-design-system/form";
import { FormInputGroupProps, FormLabelAddonProps } from "@lifesg/react-design-system/form/types";
import { AddonProps } from "@lifesg/react-design-system/input-group/types";
import { FormLabel, FormLabelProps } from "app/hooks/useForm/components/Form.Label";
import React from "react";
import { Grid, SemanticWIDTHSNUMBER } from "semantic-ui-react";
import { IFormField, IFormFunctions } from "../Form";
import { FormType } from "../types";

export interface IFormInputBaseProps extends Omit<FormLabelProps, "children"> {
	/** The input field name */
	field: string;
	/** The form submission function */
	form: IFormFunctions;
	/** The placeholder value, should the value be undefined */
	placeholder?: string;
	/** A function that handles the input on blur function */
	onBlurHandler?: (formField?: IFormField) => void;
	/** A function that handles the input on onfocus function */
	onFocus?: () => void;
	/** Any validate function */
	additionalValidations?: () => Promise<boolean>;
	/** To disable the input on blur function */
	disableBlurValidation?: boolean;
	/** To hide the error message */
	hideErrorMessage?: boolean;
	/** To disable the input */
	disabled?: boolean;
	/** To specify the maxChar of the input */
	maxLength?: number;
	/** To transform the input value to upper case */
	transformUppercase?: boolean;
	/** To specify the icon and the popout content */
	popupContent?: FormLabelAddonProps;
	/** Sets to readonly style */
	readOnly?: boolean;
	/** Additional CSS class name */
	className?: string;
	/** aria-label of the input */
	accessibleLabel?: string;
	/** width of the input in mobile */
	mobileWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in computer */
	computerWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in tablet */
	tabletWidth?: SemanticWIDTHSNUMBER;
	/** Regex pattern to replace invalid characters for text inputs */
	regexPattern?: RegExp;
	/** Transform field value for display purpose, does not overwrite the field itself */
	transformDisplayValue?: (value: string) => string;
	/**
	 * Callback on input change
	 * @param key form field name
	 * @param value input value
	 */
	onInputChange?: (key: string, value: string) => void;
	dataTestId?: string;
	formType?: FormType;
}

export interface IFormInputProps extends IFormInputBaseProps {
	/** The prefix of the input */
	prefix?: string;
	/** The icon of the input */
	icon?: JSX.Element;
	/** To specify the icon on the left side or right side of the icon */
	iconPosition?: "left" | "right";
	/** The type of the input mode */
	inputMode?: "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
	/** Sets the number of characters before a space is added (works only with type <code>tel</code> input) */
	spacing?: number;
}

export const withBaseInput =
	<T extends IFormInputBaseProps>(Component: (props: any) => JSX.Element) =>
	(props: T) => {
		const {
			form,
			field,
			disabled,
			title,
			placeholder,
			readOnly,
			hideErrorMessage,
			disableBlurValidation,
			onBlurHandler,
			transformUppercase,
			computerWidth,
			mobileWidth,
			tabletWidth,
			className,
			regexPattern,
			transformDisplayValue,
			onInputChange,
			onFocus,
			dataTestId,
		} = props;

		const formField = form.getField(field);
		const key = formField ? formField.key : "";
		const errorMessageTestId = dataTestId ? dataTestId + "-error" : field + "_form_input__error";
		const isSubmitting = formField ? !!form.submittingFields[formField.key] : false;

		let id = field + "__loading";
		if (!form.loading) {
			id = key + "_form_input";
		}
		if (disabled) {
			id = key + "_form_input--disabled";
		}

		const errorMessage = formField && !hideErrorMessage ? form.getError(field) : "";
		const value = formField ? (formField.value as string) : "";
		const maxChar = formField ? formField.maxChar : undefined;

		let fieldClassName = `form-field form-field-${key} ${className ?? ""} ${readOnly ? "readOnly" : ""} ${
			errorMessage ? "error" : ""
		}`;
		if (disabled || (isSubmitting && !readOnly)) {
			fieldClassName += " form-field__input--disabled";
		}

		const blurHandler = async (): Promise<void> => {
			if (!disableBlurValidation) {
				onBlurHandler?.(formField);
				props.form.validateFieldValue(formField);
				await props.additionalValidations?.();
			}
		};

		const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			let value = event.target.value;

			if (transformUppercase) value = value.toUpperCase();

			if (maxChar !== undefined) {
				value = value.substring(0, maxChar);
			}

			// to ensure the phone number input only can key in number
			if (formField.type === "phone") {
				value = value.replace(/[^0-9]/, "");
			}

			if (regexPattern) {
				value = value.replace(regexPattern, "");
			}

			if (onInputChange) {
				onInputChange(field, value);
			}

			form.updateFieldValue(key, value);
		};

		const inputWidth = computerWidth ?? 12;

		return (
			<Grid
				className={
					className?.includes("storybook")
						? "form-storybook"
						: className?.includes("no-margin-grid")
						? ""
						: "form"
				}
			>
				<Grid.Row>
					<Grid.Column
						className="no-margin no-padding"
						mobile={mobileWidth ?? inputWidth}
						tablet={tabletWidth ?? inputWidth}
						computer={inputWidth}
					>
						<FormLabel
							id={id}
							title={title}
							subtitle={props.subtitle}
							popupContent={props.popupContent}
							fullWidthLabel={props.fullWidthLabel}
							labelClassName={props.labelClassName}
							formType={props.formType}
						>
							<div className={fieldClassName}>
								<Component
									onFocus={onFocus}
									id={id}
									name={key}
									maxLength={maxChar}
									errorMessage={errorMessage}
									value={transformDisplayValue ? transformDisplayValue(value) : value}
									onChange={changeHandler}
									onBlur={blurHandler}
									disabled={disabled || isSubmitting}
									onInput={changeHandler}
									placeholder={placeholder}
									readOnly={readOnly}
									data-error-testid={errorMessageTestId}
									data-testid={dataTestId}
									origProps={props}
								/>
							</div>
						</FormLabel>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	};

const SingleLineComponent = (props: FormInputGroupProps<unknown, unknown> & { origProps: IFormInputProps }) => {
	const { prefix, icon, iconPosition, inputMode, spacing } = props.origProps;

	let addon: AddonProps<unknown, unknown> | undefined = undefined;
	if (prefix) {
		addon = { attributes: { value: prefix } };
	} else if (icon) {
		const position = iconPosition || "left";
		addon = { type: "custom", position: position, attributes: { children: icon } };
	}

	return (
		<DSForm.InputGroup
			{...props}
			inputMode={inputMode}
			type={inputMode}
			addon={addon}
			spacing={spacing}
			className={prefix || icon ? "form-field__input--prefix" : "form-field__input"}
		/>
	);
};

const FormInput = withBaseInput<IFormInputProps>(SingleLineComponent);

export default FormInput;
