import { EMAIL_MAX_CHARS } from "../email";

export const acpV3PropsMaxLength = {
	personalDetails: {
		email: EMAIL_MAX_CHARS,
		mobileNo: 8,
	},
	healthConcerns: {
		description: 100,
	},
	thingsYouEnjoyInLife: {
		DAILY_TASKS: 150,
		SPENDING_TIME: 150,
		CARING_ACTIVITIES: 150,
		CARING: 150,
		EXCERCISING_ACTIVITIES: 40,
		EXCERCISING: 150,
		TRAVELLING: 150,
		VOLUNTEERING: 150,
		HOBBIES_ACTIVITIES: 40,
		HOBBIES: 150,
		SPIRITUAL: 150,
		WORKING_ACTIVITIES: 40,
		WORKING: 150,
		OTHERS_ACTIVITY: 40,
		OTHERS_DESCRIPTION_IMPORTANCES: 250,
		TOP_ACTIVITY: 11,
	},
	sourceOfSupport: {
		otherSource: {
			OTHER_SOURCES: 40,
		},
		description: 150,
	},
	carePreferences: {
		CARE_TOP_ACTIVITY: 8,
		PAIN_MANAGEMENT: 150,
		COMPANIONSHIP_AND_EMOTIONAL_SUPPORT: 150,
		PERSONAL_HYGIENE: 150,
		MOBILITY_AND_EXERCISE: 150,
		CULTURAL_AND_SPIRITIAL_NEEDS: 150,
		PESONAL_COMFORT_ITEMS: 150,
		CARE_OTHERS_ACTIVITY: 40,
		CARE_OTHERS_IMPORTANCES: 250,
	},
	personalExperiences: {
		statement: 250,
	},
	fears: {
		description: 150,
		topFears: 7,
		others: {
			title: 40,
			description: 250,
		},
	},
	treatmeanGoal: {
		description: 250,
	},
	nhs: {
		email: EMAIL_MAX_CHARS,
		mobile: 8,
	},
	nhsAdditionalRequests: {
		request: 500,
	},
};

export enum AcpDraftStatus {
	DRAFT_ON_LEGACY = "DRAFT_ON_LEGACY",
	ERROR = "ERROR",
}

export enum AcpStatus {
	NO_RECORD, // E001
	ACP_REGISTERED,
	ERROR_E004 = "E004", // E004 - No published form for Request ID
	ERROR = "ERROR", // E002, E003 - Generic Error
	SYS_ERROR = "SYS_ERROR", // System Error
}

export enum AcpStatusFromAIC {
	PROCESSED = "Processed",
	FAILED = "Failed",
}

export const mapAcpStepToSmsStep = {
	"0": "1",
	"1": "2",
	"2": "2",
	"3": "3",
	"4": "3",
	"5": "4",
	"6": "5",
	"7": "6",
	"8": "7",
	"9": "7",
	"10": "8",
};
