import PersonComboBox from "app/components/composites/PersonComboBox";
import { useEffect, useState } from "react";
import { IFormFunctions } from "../Form";

interface IFormTrustedPersonProps {
	field: string;
	form: IFormFunctions;
	sharedIDs?: number[];
	beforeSelectItem?: (...args: any[]) => void;
	afterSelectItem?: (...args: any[]) => void;
	largeTitle?: boolean;
}

const FormTrustedPerson = (props: IFormTrustedPersonProps): JSX.Element => {
	const [performAfterAction, setPerformAfterAction] = useState(false);

	useEffect(() => {
		if (performAfterAction && props.afterSelectItem) {
			props.afterSelectItem();
			setPerformAfterAction(false);
		}
	}, [performAfterAction]);

	const formField = props.form.getField(props.field);

	const key = formField ? formField.key : "";
	const value = formField ? formField.value : [];
	const isSubmitting = formField ? props.form.submittingFields[formField.key] : false;
	const error = formField ? props.form.getError(props.field) : "";

	if (!value && !formField) {
		return <div />;
	}

	const selectItemHandler = async (value: string): Promise<void> => {
		if (props.beforeSelectItem) {
			await props.beforeSelectItem();
		}
		await props.form.updateFieldValue(key, value);
		if (props.afterSelectItem) {
			await setPerformAfterAction(true);
		}
	};

	return (
		<PersonComboBox
			{...props}
			value={value as number[]}
			selectItem={selectItemHandler}
			disabled={isSubmitting}
			error={error}
		/>
	);
};

export default FormTrustedPerson;
