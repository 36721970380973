import { Color } from "@lifesg/react-design-system/color";
import { Divider } from "@lifesg/react-design-system/divider";
import { Text } from "@lifesg/react-design-system/text";
import styled from "styled-components";

export const Page = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const ContentContainer = styled.div`
	margin: 4rem 0;
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 2rem;
	align-items: center;
`;

export const ContentDivider = styled(Divider)`
	height: 1px;
	color: ${Color.Neutral[4]};
`;

export const DescriptionSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
`;

export const Section = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const DescriptionTitle = styled(Text.H2)`
	margin-top: 0;
`;

export const TextBody = styled(Text.Body)`
	margin-top: 0;
`;
