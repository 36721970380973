import { arrayBufferToBase64 } from "app/common/utils/base64Utils";

/**
 * Convert string to ArrayBuffer
 */
const str2ab = (str: string) => {
	const buf = new ArrayBuffer(str.length);
	const bufView = new Uint8Array(buf);
	for (let i = 0, strLen = str.length; i < strLen; i++) {
		bufView[i] = str.charCodeAt(i);
	}
	return buf;
};

/**
 * Import public key in PEM PKCS#8 format with no linebreaks
 */
const importPublicKey = (pem: string) => {
	// fetch the part of the PEM string between header and footer
	const pemHeader = "-----BEGIN PUBLIC KEY-----";
	const pemFooter = "-----END PUBLIC KEY-----";
	const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length).replace(/\\n/gm, "");
	// base64 decode the string to get the binary data
	const binaryDerString = window.atob(pemContents);
	const binaryDer = str2ab(binaryDerString);

	return window.crypto.subtle.importKey(
		"spki",
		binaryDer,
		{
			name: "RSA-OAEP",
			hash: "SHA-256",
		},
		true,
		["encrypt"],
	);
};

const encrypt = async (str: string, key: CryptoKey): Promise<ArrayBuffer> => {
	const encoded = new TextEncoder().encode(str);
	return await window.crypto.subtle.encrypt(
		{
			name: "RSA-OAEP",
		},
		key,
		encoded,
	);
};

export const encryptToBase64 = async (input: string, key: string): Promise<string> => {
	const publicKey = await importPublicKey(key);
	const cipher = await encrypt(input, publicKey);
	return arrayBufferToBase64(cipher);
};
