import { isNotEmpty, registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

// tslint:disable-next-line: ban-types
export function IsNotWhiteSpace(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isNotWhiteSpace",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string): boolean {
					if (typeof value !== "string") {
						return false;
					}

					// trim white spaces
					let trimmedString = value;
					if (value) {
						trimmedString = value.trim();
					}
					return isNotEmpty(trimmedString);
				},
				defaultMessage(args: ValidationArguments): string {
					if (typeof args.value !== "string") {
						return `${args.property} is not a valid string`;
					}

					return `${args.property} is not a valid string. String must not be empty`;
				},
			},
		});
	};
}
