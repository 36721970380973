import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { GetTrustedPersonDO } from "./../domainObjects";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/trustedpersons";
export class Response {
	@ValidateNested({ each: true })
	@Type(() => GetTrustedPersonDO)
	public trustedPersons!: GetTrustedPersonDO[];
}
