import { formatFileName, getSizeWithUnit } from "app/utils/file";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import Modal from "../Modal";
import FileInputRow from "./FileInput.Row";
import "./FileInput.scss";
import { IFileObject } from "./type";

interface IProps {
	fileTypes: string[];
	name: string;
	id: string;
	label?: string;
	maximumFileSize?: number;
	information?: string;
	buttonLabel?: string;
	uploadedFiles: IFileObject[];
	maximumFileNumber?: number;
	width?: 75 | 100;
	onFileUpload: (file: IFileObject) => void;
	onFileRemove: (index: number) => void;
	onFileUploadComplete: (file: IFileObject, index: number, hasError: boolean) => void;
	bindRef?: (ref: HTMLDivElement) => void;
}

interface IState {
	fileToUpload: File | null;
	showInvalidModal: boolean;
	invalidModalContent: IInvalidFileContent;
	canUploadFile: boolean;
}

interface IInvalidFileContent {
	title: string;
	message: string;
}

// only allows for one file selection at a time
class FileInput extends React.Component<IProps, IState> {
	public state: IState = {
		fileToUpload: null,
		showInvalidModal: false,
		invalidModalContent: {} as IInvalidFileContent,
		canUploadFile: true,
	};

	public inputRef: any = React.createRef<any>();
	public formRef: any = React.createRef<any>();

	public componentDidMount = (): void => {
		// check for max file length
		if (!this.props.uploadedFiles || !this.props.maximumFileNumber) {
			return;
		}

		if (this.props.uploadedFiles.length === this.props.maximumFileNumber) {
			this.setState({ canUploadFile: false });
		}
	};

	public componentDidUpdate = (prevProps: IProps): void => {
		if (!this.props.maximumFileNumber) {
			return;
		}

		const prevUploadedFileLength = prevProps.uploadedFiles ? prevProps.uploadedFiles.length : 0;
		const currentUploadedFileLength = this.props.uploadedFiles ? this.props.uploadedFiles.length : 0;
		if (prevUploadedFileLength === currentUploadedFileLength) {
			return;
		}

		if (currentUploadedFileLength > this.props.maximumFileNumber - 1) {
			this.setState({ canUploadFile: false });
		} else {
			this.setState({ canUploadFile: true });
		}
	};

	public render = (): JSX.Element => (
		<>
			<div className="input__file">
				{this.props.label && <div className="input__file__label">{this.props.label}</div>}
				{this.props.information && (
					<div className="input__file__information__full">{this.props.information}</div>
				)}
				<div className="input__file__information">
					<div className="input__file__information__item">
						<p className="input__file__information__title">Supported file types:</p>
						<p className="input__file__information__value input__file__information__file-types">
							{this.props.fileTypes.join(", ")}
						</p>
					</div>
					{this.props.maximumFileSize && (
						<div className="input__file__information__item">
							<p className="input__file__information__title">Maximum file size:</p>
							<p className="input__file__information__value input__file__information__file-size">
								{getSizeWithUnit(this.props.maximumFileSize)} each file
							</p>
						</div>
					)}
				</div>
				{this.props.uploadedFiles && (
					<div
						className={`input__file__uploaded__wrapper mt40 input__file__uploaded__wrapper--${
							this.props.width ? this.props.width : "100"
						}`}
					>
						{this.renderUploadedFiles()}
					</div>
				)}
				<div>
					<div className="input__file__actions">
						<form name="form__file-upload" ref={this.formRef} id="form__file-upload">
							{/* {@TODO: change this to button} */}
							<label
								className={`input__file__actions__label ${
									this.state.canUploadFile ? "button-secondary" : "button-disabled"
								}`}
								htmlFor={this.props.name}
							>
								{this.props.buttonLabel ? this.props.buttonLabel : "Upload file"}
							</label>
							<input
								hidden={true}
								disabled={!this.state.canUploadFile}
								className="input__file__actions__input"
								type="file"
								id={this.props.id}
								name={this.props.name}
								accept={this.props.fileTypes.join(",")}
								onChange={this.onInputChange}
								ref={this.inputRef}
							/>
						</form>
						<div className="input__file__total">
							{this.props.uploadedFiles ? this.props.uploadedFiles.length : "0"} of{" "}
							{this.props.maximumFileNumber} files
						</div>
					</div>
				</div>
				{this.renderInvalidModal()}
			</div>
		</>
	);

	private getFileName = (file: File): string => {
		const fileName = formatFileName(file.name);

		if (!this.props.uploadedFiles) {
			return fileName;
		}

		const matchingNames = _.filter(this.props.uploadedFiles, (uploadedFile: IFileObject) => {
			return uploadedFile.displayName === fileName;
		});

		if (matchingNames.length > 0) {
			const extension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
			return fileName.replace(/\.[^/.]+$/, "") + "-" + moment().format("YYYYMMDDHHmmss") + "." + extension;
		}

		return fileName;
	};

	private checkFile = (file: File, path: string): boolean => {
		const pattern = new RegExp(".*(" + this.props.fileTypes.join("|").replace(/\./g, "\\.") + ")$", "i");
		// invalid file type
		if (!pattern.test(path)) {
			this.setState({
				showInvalidModal: true,
				invalidModalContent: { title: "File format invalid", message: "Please upload a valid file format." },
			});
			return false;
		}

		// invalid file size
		if (this.props.maximumFileSize && file.size > this.props.maximumFileSize) {
			this.setState({
				showInvalidModal: true,
				invalidModalContent: {
					title: "File size exceeded",
					message: `Please upload maximum file size of ${getSizeWithUnit(this.props.maximumFileSize)}`,
				},
			});
			return false;
		}

		return true;
	};

	private onInputChange = (): void => {
		const { files, value } = this.inputRef.current;
		if (files.length === 0) {
			return;
		}
		this.setState({ fileToUpload: files[0] });
		const isValid = this.checkFile(files[0], value);

		if (isValid) {
			this.props.onFileUpload({
				file: files[0],
				isSubmitting: true,
				key: "file-" + this.props.uploadedFiles.length,
				displayName: this.getFileName(files[0]),
				size: files[0].size,
			});
		}

		this.formRef.current.reset();
	};

	private onFileUploadComplete = (file: IFileObject, index: number, hasError: boolean): void => {
		this.props.onFileUploadComplete(file, index, hasError);
	};

	private onFileRemove = (index: number): void => {
		this.props.onFileRemove(index);
	};

	private closeInvalidModelCallback = (): void => {
		this.setState({ showInvalidModal: false });
	};

	private renderUploadedFiles = (): JSX.Element[] | null => {
		if (!this.props.uploadedFiles) {
			return null;
		}

		return this.props.uploadedFiles.map((file: IFileObject, index: number) => {
			return (
				<FileInputRow
					key={index}
					fileObject={file}
					index={index}
					onRemove={this.onFileRemove}
					onUploadComplete={this.onFileUploadComplete}
					bindRef={this.props.bindRef}
				/>
			);
		});
	};

	private renderInvalidModal = (): JSX.Element => (
		<Modal
			id="modal__invalid-file"
			type="message"
			title={this.state.invalidModalContent.title}
			subTitle={this.state.invalidModalContent.message}
			isOpen={this.state.showInvalidModal}
			closeCallback={this.closeInvalidModelCallback}
			button1={["Okay", "primary", this.closeInvalidModelCallback]}
		/>
	);
}

export default FileInput;
