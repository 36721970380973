import _ from "lodash";
import moment from "moment";
import { useState } from "react";

export const isUnderScheduledMaintenance = (time: string, currentTime = moment()) => {
	const isScheduled = false;
	let isDown = false;

	if (_.isEmpty(time)) {
		return { isDown, isScheduled };
	}

	const [startTimeStr, endTimeStr] = time.split(",");
	const startTime = moment(startTimeStr);
	const endTime = moment(endTimeStr ?? null);
	const isStartTimeValid = startTime.isValid();
	const isEndTimeValid = endTime.isValid();

	if (!isStartTimeValid) return { isDown, isScheduled };

	if (isEndTimeValid) {
		isDown = currentTime.isBetween(startTime, endTime);
		return { isDown, isScheduled: isDown };
	}

	return { isDown: currentTime.isAfter(startTime), isScheduled };
};

export const useCIRISMaintenance = (
	currentTime = moment(),
): [isUnderMaintenance: boolean, isScheduled: boolean, syncStatus: (time: string) => void] => {
	const [underMaintenance, setMaintenance] = useState(false);
	const [isScheduled, setScheduled] = useState<boolean>(false);

	const checkMaintenanceStatus = (time: string) => {
		const { isDown, isScheduled } = isUnderScheduledMaintenance(time, currentTime);
		setMaintenance(isDown);
		setScheduled(isScheduled);
	};

	return [underMaintenance, isScheduled, checkMaintenanceStatus];
};
