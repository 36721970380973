import { lowercaseAndHyphen } from "app/utils/strings";
import Link from "next/link";

interface IProps {
	href: string;
	title: string | JSX.Element;
	active?: boolean;
	isMobile?: boolean;
	isProfile?: boolean;
	type?: "secondary" | "avatar";
	maxWidth?: number;
}

const NavLink = (props: IProps): JSX.Element => {
	const className = `${props.type ? `navlink--${props.type}` : "navlink"}${props.active ? " navlink--active" : ""}${
		props.isProfile ? " navlink--profile" : ""
	}`;
	const id = `navlink--${typeof props.title === "string" ? lowercaseAndHyphen(props.title) : "icon"}${
		props.isMobile ? "--mobile" : ""
	}`;

	return (
		<Link
			href={props.href}
			passHref={true}
			tabIndex={0}
			className={className}
			style={maxWidthStyle(props.maxWidth, props.isMobile)}
			id={id}
		>
			{props.title}
		</Link>
	);
};

const maxWidthStyle = (maxWidth: number | undefined, isMobile: boolean | undefined): React.CSSProperties => {
	if (!maxWidth) {
		return {};
	}

	return isMobile ? {} : { maxWidth };
};

export default NavLink;
