import { Transform, Type } from "class-transformer";
import { IsBoolean, ValidateNested, IsEnum } from "class-validator";
import { toNumber, escapeHTML } from "../../transformers";
import { CsatType } from "../../csat/enums";
import { IsNumber, IsString } from "@wog/mol-lib-api-contract/utils";

/**
 * Response DOs
 */

export class ResponseGetUnreadNotificationsDO {
	@IsNumber()
	public count!: number;
}

export class ResponseGetAllNotificationsDO {
	@ValidateNested({ each: true })
	@Type(() => InboxNotificationDO)
	public notifications: InboxNotificationDO[] = [];
}

export class InboxNotificationDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;

	@IsString()
	public dateString!: string;

	@IsString()
	public title!: string;

	@IsString()
	public description!: string;

	@IsString()
	public contentHTML!: string;

	@IsBoolean()
	public isUnread!: boolean;
}

export class CsatDO {
	@Transform(escapeHTML)
	@IsString()
	public nric!: string;

	@IsNumber({ min: 1, max: 5 })
	public rating!: number;

	@Transform(escapeHTML)
	@IsString({ isOptional: true })
	public message!: string;

	@Transform(escapeHTML)
	@IsString({ isOptional: true })
	public referenceId?: string;

	@Transform(escapeHTML)
	@IsEnum(CsatType)
	public csatType!: CsatType;

	// Mobile specific fields
	@Transform(escapeHTML)
	@IsString({ isOptional: true })
	os?: string;

	@Transform(escapeHTML)
	@IsString({ isOptional: true })
	deviceModel?: string;

	@Transform(escapeHTML)
	@IsString({ isOptional: true })
	appVersion?: string;
}
