import { EOLErrorCodes, EOLErrorMessages } from "app/common/errors";
import ResponseError from "app/common/responseError";
import { getCookie } from "app/utils/cookies";
import axios, { AxiosRequestConfig } from "axios";

const BASE_URL: string = process.env.REACT_APP_API_URL || "";

// axios default config
if (axios.defaults && axios.defaults.headers) {
	axios.defaults.headers.common["Accept"] = "application/json";
}

const redirectToVaultIntro = (): void => {
	const lastKnownPath = window.location.pathname;
	const redirectPath = lastKnownPath === "/vault/" ? "" : `?redirect=${lastKnownPath}`;
	window.location.assign("/vault" + redirectPath);
};

const generateCSRFHeader = (): AxiosRequestConfig => {
	const csrfCookie = getCookie("koa-csrf");

	if (csrfCookie) {
		return {
			headers: {
				"X-CSRF-Token": csrfCookie,
			},
		};
	}

	return {};
};

// NOTE: We will throw all errors out, page/component that calls the api will catch
// unknown errors will be formatted to an error we know

const api = {
	/**
	 * Performs an Axios GET call to the server
	 * @param path the pathname of the api, e.g. /api/path
	 * @param preventRedirect don't redirect to vault page if api fails
	 */
	get: async <Response = any>(path: string, preventRedirect = false): Promise<Response | undefined> => {
		try {
			const response = await axios.get(urlBuilder(path));
			return response.data.data as Response;
		} catch (err: any) {
			if (err.response && err.response.status === 401 && !preventRedirect) {
				redirectToVaultIntro();
				return;
			} else if (err.response && err.response.data) {
				if (err.response.data.errors) {
					throw err.response.data.errors;
				}
				throw err.response.data;
			} else if (err instanceof Error && err.message === "Network Error") {
				// format network error
				throw new ResponseError(EOLErrorCodes.NetworkError, EOLErrorMessages.NetworkError);
			} else {
				throw new ResponseError(EOLErrorCodes.UnknownFrontEndError, EOLErrorMessages.UnknownFrontEndError);
			}
		}
	},
	/**
	 * Performs an Axios POST call to the server
	 * @param path the pathname of the api, e.g. /api/path
	 * @param body the payload of data to send to the server, e.g. { key: 'value' }
	 */
	post: async <Response = any>(path: string, body: any): Promise<Response | undefined> => {
		try {
			const response = await axios.post(urlBuilder(path), body, generateCSRFHeader());
			return response.data.data as Response;
		} catch (err: any) {
			if (err.response && err.response.status === 401) {
				redirectToVaultIntro();
				return;
			} else if (err.response && err.response.data) {
				if (err.response.data.errors instanceof Array) {
					throw err.response.data.errors;
				}
				if (err.response.data.errors) {
					throw [err.response.data.errors];
				}
				throw err.response.data;
			} else if (err instanceof Error && err.message === "Network Error") {
				// format network error
				throw [new ResponseError(EOLErrorCodes.NetworkError, EOLErrorMessages.NetworkError)];
			} else {
				throw [new ResponseError(EOLErrorCodes.UnknownFrontEndError, EOLErrorMessages.UnknownFrontEndError)];
			}
		}
	},
	/**
	 * Performs an Axios PATCH call to the server
	 * @param path the pathname of the api, e.g. /api/path
	 * @param body the payload of data to send to the server, e.g. { key: 'value' }
	 */
	patch: async <Response = any>(path: string, body: any): Promise<Response | undefined> => {
		try {
			const response = await axios.patch(urlBuilder(path), body, generateCSRFHeader());
			return response.data.data as Response;
		} catch (err: any) {
			if (err.response && err.response.status === 401) {
				redirectToVaultIntro();
				return;
			} else if (err.response && err.response.data) {
				if (err.response.data.errors instanceof Array) {
					throw err.response.data.errors;
				}
				throw [err.response.data.errors];
			} else if (err instanceof Error && err.message === "Network Error") {
				// format network error
				throw [new ResponseError(EOLErrorCodes.NetworkError, EOLErrorMessages.NetworkError)];
			} else {
				throw [new ResponseError(EOLErrorCodes.UnknownFrontEndError, EOLErrorMessages.UnknownFrontEndError)];
			}
		}
	},
	/**
	 * Performs an Axios PUT call to the server
	 * @param path the pathname of the api, e.g. /api/path
	 * @param body the payload of data to send to the server, e.g. { key: 'value' }
	 */
	put: async <Response = any>(path: string, body: any): Promise<Response | undefined> => {
		try {
			const response = await axios.put(urlBuilder(path), body, generateCSRFHeader());
			return response.data.data as Response;
		} catch (err: any) {
			if (err.response && err.response.status === 401) {
				redirectToVaultIntro();
				return;
			} else if (err.response && err.response.data) {
				if (err.response.data.errors instanceof Array) {
					throw err.response.data.errors;
				}
				throw [err.response.data.errors];
			} else if (err instanceof Error && err.message === "Network Error") {
				// format network error
				throw [new ResponseError(EOLErrorCodes.NetworkError, EOLErrorMessages.NetworkError)];
			} else {
				throw [new ResponseError(EOLErrorCodes.UnknownFrontEndError, EOLErrorMessages.UnknownFrontEndError)];
			}
		}
	},
	/**
	 * Performs an Axios PUT call to the server
	 * @param path the pathname of the api, e.g. /api/path
	 * @param body the payload of data to send to the server, e.g. { key: 'value' }
	 */
	delete: async <Response = any>(path: string): Promise<Response | undefined> => {
		try {
			const response = await axios.delete(urlBuilder(path), generateCSRFHeader());
			return response.data.data as Response;
		} catch (err: any) {
			if (err.response && err.response.status === 401) {
				redirectToVaultIntro();
				return;
			} else if (err.response && err.response.data) {
				throw err.response.data.errors;
			} else if (err instanceof Error && err.message === "Network Error") {
				// format network error
				throw new ResponseError(EOLErrorCodes.NetworkError, EOLErrorMessages.NetworkError);
			} else {
				throw new ResponseError(EOLErrorCodes.UnknownFrontEndError, EOLErrorMessages.UnknownFrontEndError);
			}
		}
	},
	generateCSRFHeader,
};

const urlBuilder = (path: string): string => {
	if (path.charAt(0) === "/") {
		return BASE_URL + path;
	}
	return BASE_URL + "/" + path;
};

export default api;
