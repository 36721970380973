import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

let propertyShouldBePresent = false;
// tslint:disable-next-line: ban-types
export function IsOptionalIfFieldNotPresent(
	relatedProperty: string,
	allowedRelatedPropertyValue: string[] | string,
	validationOptions?: ValidationOptions,
): (object: object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isOptionalIfFieldNotPresent",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string, args: ValidationArguments): boolean {
					const { object } = args;

					// if the select with the option 'others' is undefined
					// this current property should not have another value other than ""
					// if the select is not 'others'
					// this current property should not have another value other than ""
					if (
						object[relatedProperty] === undefined ||
						(typeof allowedRelatedPropertyValue === "string" &&
							object[relatedProperty] !== allowedRelatedPropertyValue)
					) {
						propertyShouldBePresent = false;
						return !value || value === "";
					}

					// if allowedRelatedPropertyValue is an array
					if (
						object[relatedProperty] === undefined ||
						!allowedRelatedPropertyValue.includes(object[relatedProperty])
					) {
						propertyShouldBePresent = false;
						return !value || value === ""; // if not empty string, it is not allowed
					}

					// if the select is 'others'
					// this current property must be present
					if (
						typeof allowedRelatedPropertyValue === "string" &&
						object[relatedProperty] === allowedRelatedPropertyValue
					) {
						propertyShouldBePresent = true;
						return !!value && value !== ""; // must have value and value cannot be empty
					}

					if (allowedRelatedPropertyValue.includes(object[relatedProperty])) {
						propertyShouldBePresent = true;
						return !!value && value !== "";
					}

					return true;
				},
				defaultMessage(args: ValidationArguments): string {
					if (propertyShouldBePresent) {
						return `${args.property} should be present`;
					}

					return `${args.property}: ${args.value} should not be present`;
				},
			},
		});
	};
}
