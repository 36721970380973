import { FileInputSummary } from "app/components/basic/FileInput";

import { IFileObject } from "../FileInput/type";
import "./Display.scss";

interface IProps {
	id: string;
	title?: string;
	attachments?: IFileObject[];
	sharedAccessId?: number;
	width?: 75 | 100;
}

const DisplayFile = (props: IProps): JSX.Element => {
	const titleId = props.id + "-title";
	const attachmentsGroupId = props.id + "-files";
	return (
		<div className="display-content">
			{props.title && (
				<small className="title" id={titleId}>
					{props.title}
				</small>
			)}
			<div className={`attachments attachments--${props.width ? props.width : "100"}`} id={attachmentsGroupId}>
				{renderFileList(props)}
			</div>
		</div>
	);
};

const renderFileList = (props: IProps): JSX.Element | JSX.Element[] => {
	if (!props.attachments || props.attachments.length === 0) {
		return <>-</>;
	}
	return props.attachments.map((attachment: IFileObject, index: number) => {
		return renderFile(attachment, props, index);
	});
};

const renderFile = (attachment: IFileObject, props: IProps, index: number): JSX.Element => (
	<FileInputSummary key={index} index={index} attachment={attachment} sharedAccessId={props.sharedAccessId} />
);

export default DisplayFile;
