import { AccessSetting } from "app/common/api/trustedConnection";
import BasicLink from "app/components/basic/Link";
import { lowercaseAndHyphen } from "app/utils/strings";
import { Radio as SemanticRadio } from "semantic-ui-react";
import "./styles.scss";
import { TickIcon, LockFillIcon, PlaceholderIcon } from "@lifesg/react-icons";

const NOW = "Now";
const AFTER = "After death";
const NEVER = "Never";

const mappedValue = {
	[NOW]: AccessSetting.ACCESSIBLE_BEFORE_DEATH,
	[AFTER]: AccessSetting.ACCESSIBLE_AFTER_DEATH,
	[NEVER]: AccessSetting.NOT_ALLOWED,
};

const mappedLabel = {
	[AccessSetting.ACCESSIBLE_BEFORE_DEATH]: NOW,
	[AccessSetting.ACCESSIBLE_AFTER_DEATH]: AFTER,
	[AccessSetting.NOT_ALLOWED]: NEVER,
};

const ViewIcon = (value?: string): JSX.Element => {
	if (value === AccessSetting.ACCESSIBLE_BEFORE_DEATH) {
		return <TickIcon className="share-radio-view-icon" />;
	} else if (value === AccessSetting.ACCESSIBLE_AFTER_DEATH) {
		return <LockFillIcon className="share-radio-view-icon" />;
	} else {
		return <PlaceholderIcon className="share-radio-view-icon" />;
	}
};

interface IProps {
	header: [string, string];
	type: "view" | "radio";
	selectItem?: (item: AccessSetting) => void;
	value: AccessSetting;
	disableNow?: boolean;
	disableAfter?: boolean;
	disableNever?: boolean;
}

interface IShareRadioItem {
	id: string;
	disabled?: boolean;
	selected?: boolean;
	label: "Now" | "After death" | "Never";
	selectItem: (item: AccessSetting) => void;
}

const ShareRadio = (props: IProps): JSX.Element => {
	const [title, link] = props.header;

	const id = lowercaseAndHyphen(title) + "-radio-share";
	const headerId = id + "-link";

	const HeaderLink = (): JSX.Element => <BasicLink id={headerId} href={link} name={title} />;

	if (!props.selectItem || props.type === "view") {
		const radioValueId = `${id}-${mappedLabel[props.value]}`;
		const ShareInfo = (): JSX.Element => (
			<div className="share-radio-info">
				{ViewIcon(props.value)}
				<span id={radioValueId}>{mappedLabel[props.value]}</span>
			</div>
		);

		return (
			<div className="share-radio-group view">
				<div className="title view">
					<HeaderLink />
				</div>
				<ShareInfo />
			</div>
		);
	}

	return (
		<div className="share-radio-group">
			<div className="title radio">
				<HeaderLink />
			</div>
			<div className="share-radio-options">
				<ShareRadioItem
					id={headerId}
					label={NOW}
					disabled={props.disableNow}
					selectItem={props.selectItem}
					selected={props.value === AccessSetting.ACCESSIBLE_BEFORE_DEATH}
				/>
				<ShareRadioItem
					id={headerId}
					label={AFTER}
					disabled={props.disableAfter}
					selectItem={props.selectItem}
					selected={props.value === AccessSetting.ACCESSIBLE_AFTER_DEATH}
				/>
				<ShareRadioItem
					id={headerId}
					label={NEVER}
					disabled={props.disableNever}
					selectItem={props.selectItem}
					selected={props.value === AccessSetting.NOT_ALLOWED}
				/>
			</div>
		</div>
	);
};

const ShareRadioItem = (props: IShareRadioItem): JSX.Element => {
	let optionId = props.id + "-" + lowercaseAndHyphen(props.label);
	const onClick = (): void => props.selectItem(mappedValue[props.label]);

	if (props.disabled) {
		optionId += "--disabled";
	}

	return (
		<SemanticRadio
			id={optionId}
			name={props.id}
			label={props.label}
			value={props.label}
			checked={props.selected}
			disabled={props.disabled}
			onClick={onClick}
		/>
	);
};

export default ShareRadio;
