import { IsNumber, IsEmail } from "@wog/mol-lib-api-contract/utils";
import { escapeHTML } from "app/common/api/transformers";
import { Transform, Type } from "class-transformer";
import { ArrayMaxSize, ArrayMinSize, IsString, ValidateNested } from "class-validator";
import { VehicleDetailsDO } from "./hdbParking";
import { EMAIL_MAX_CHARS } from "app/common/email";

export class HdbCarparkingEmailRequestDO {
	@IsString()
	@Transform(escapeHTML)
	public name!: string;

	@IsEmail({ maxLength: EMAIL_MAX_CHARS })
	@Transform(escapeHTML)
	public email!: string;

	@IsString()
	@Transform(escapeHTML)
	public bookingNumber!: string;

	@IsString()
	@Transform(escapeHTML)
	public selectedCarpark!: string;

	@IsString()
	@Transform(escapeHTML)
	public location!: string;

	@IsString()
	@Transform(escapeHTML)
	public bookingDate!: string;

	@IsNumber({ min: 1, max: 5 })
	public numberOfVeh!: number;

	@IsString()
	@Transform(escapeHTML)
	createdAppDate!: string;

	@ArrayMinSize(1, { always: true })
	@ArrayMaxSize(5, { always: true })
	@ValidateNested({ each: true })
	@Type(() => VehicleDetailsDO)
	public vehicleDetails: VehicleDetailsDO[] = [];
}
