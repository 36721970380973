import { useSessionStorage } from "app/modules/utils/hooks/useSessionStorage";
import { useContext } from "react";
import { IHdbparkingPersonalDetails } from "../../pages/PersonalDetails/data";
import { HdbParkingContext, initialValues } from "./provider";
import {
	HdbParkingActionType,
	IApplicationResponse,
	IBookingDetails,
	IHdbParkingState,
	IParkingDetails,
	IRegisteredVehicles,
} from "./type";

export const useHdbParkingForm = () => {
	const { dispatch, state } = useContext(HdbParkingContext);
	const { setHdbparkingDataInSessionStorage } = useSessionStorage();

	const resetForm = () => {
		setHdbparkingDataInSessionStorage(
			JSON.stringify({
				...initialValues,
				currentStep: 2,
				latestStep: 2,
				isSingpassFlow: state.isSingpassFlow,
			}),
		);
		dispatch({ type: HdbParkingActionType.RESET_FORM });
	};

	const setFormRendering = (loading: boolean) => {
		setTimeout(
			() => {
				dispatch({ type: HdbParkingActionType.SET_RENDERING_FORM, payload: loading });
			},
			loading ? 0 : 800,
		);
	};

	const setLoading = (loading: boolean) => {
		dispatch({ type: HdbParkingActionType.SET_LOADING_STATE, payload: loading });
	};
	const enableSubmitButton = (checkbox: boolean) => {
		dispatch({ type: HdbParkingActionType.ENABLE_SUBMIT_BUTTON, payload: checkbox });
	};

	const setIsSingpassFlow = (isSingpassFlow: boolean) => {
		dispatch({ type: HdbParkingActionType.SET_IS_SINGPASS_FLOW, payload: isSingpassFlow });
	};

	const setHasRedirected = () => {
		dispatch({
			type: HdbParkingActionType.SET_HAS_REDIRECTED,
			payload: true,
		});
	};

	const setLatestStep = (step: number) => {
		dispatch({
			type: HdbParkingActionType.SET_LATEST_STEP,
			payload: step,
		});
	};

	const showDevMenu = (show: boolean) => {
		dispatch({ type: HdbParkingActionType.SHOW_DEV_MENU, payload: show });
	};

	const setPersonalDetails = (payload: IHdbparkingPersonalDetails) => {
		dispatch({ type: HdbParkingActionType.SET_PERSONAL_DETAILS, payload });
	};

	const setRegisteredVehicles = (registeredVehicles: IRegisteredVehicles) => {
		dispatch({ type: HdbParkingActionType.SET_REGISTERED_VEHICLES, payload: registeredVehicles });
	};

	const setBookingDetails = (payload: IBookingDetails) => {
		dispatch({ type: HdbParkingActionType.SET_BOOKING_DETAILS, payload });
	};
	const setParkingDetails = (payload: IParkingDetails) => {
		dispatch({ type: HdbParkingActionType.SET_PARKING_DETAILS, payload });
	};

	const setApplicationResponse = (payload: IApplicationResponse) => {
		dispatch({ type: HdbParkingActionType.SET_APPLICATION_RESPONSE, payload });
	};

	const setHdbparkingData = (payload: IHdbParkingState) => {
		dispatch({ type: HdbParkingActionType.SET_SESSION_STORAGE_DATA, payload });
	};

	const setMockHdbApi = (payload: { mockApiError: boolean }) => {
		dispatch({ type: HdbParkingActionType.SET_USE_MOCK_HDB_API, payload });
	};

	const resetFlow = () => {
		dispatch({ type: HdbParkingActionType.SET_RESET_FLOW });
	};

	const setSessionId = (payload: string) => {
		dispatch({ type: HdbParkingActionType.SET_SESSION_ID, payload });
	};

	const setDeathCertificateErrorType = (payload: number) => {
		dispatch({ type: HdbParkingActionType.SET_DEATH_CERTIFICATE_ERROR_TYPE, payload });
	};

	return {
		// states
		isRenderingForm: state.isRenderingForm,
		isLoading: state.isLoading,
		isSubmitButtonEnabled: state.isSubmitButtonEnabled,
		isSingpassFlow: state.isSingpassFlow,
		hasRedirected: state.hasRedirected,
		latestStep: state.latestStep,
		devMenu: state.showDevMenu,
		sid: state.sid,
		deathCertificateErrorType: state.deathCertificateErrorType,

		// page data
		bookingDetails: state.bookingDetails,
		personalDetails: state.personalDetails,
		registeredVehicles: state.registeredVehicles,
		deathDocumentPage: state.deathDocumentPage,
		parkingDetails: state.parkingDetails,
		applicationResponse: state.applicationResponse,

		setPersonalDetails,
		setRegisteredVehicles,
		setBookingDetails,
		setApplicationResponse,
		setParkingDetails,

		// handles navigation
		data: state,
		setHdbparkingData,
		hasFetchedSessionStorage: state.hasFetchedSessionStorage,
		currentStep: state.currentStep,

		// mocking of apis
		mockHdbApiError: state.mockHdbApiError,
		setMockHdbApi,

		// dispatches
		resetForm,
		setFormRendering,
		setLoading,
		enableSubmitButton,
		setIsSingpassFlow,
		setHasRedirected,
		setLatestStep,
		showDevMenu,
		resetFlow,
		setSessionId,
		setDeathCertificateErrorType,
	};
};
