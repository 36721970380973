import { IsDateString } from "app/common/validators";
import { Transform } from "class-transformer";
import { IsNumber, IsOptional, IsString } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v3/acp/update-user-draft-reminder";

export class Body {
	@IsNumber()
	@Transform(toNumber)
	reminderSequence!: number;

	@IsString()
	uinfin!: string;

	@IsOptional()
	@IsDateString()
	createdAt?: string;

	@IsOptional()
	@IsDateString()
	expiresAfter?: string;
}
export class Response {}
