import FormDistributionInput from "app/components/basic/DistributionInput";
import ProgressBar from "app/components/basic/ProgressBar";
import { useEwillsForm } from "app/modules/eWills/hooks/useEWillsForm";
import { IBasicFormField, IFormField, IFormFunctions, IFormSchema, useForm } from "app/hooks/useForm";
import { useEffect, useMemo, useState } from "react";

export interface IPropertyAllocationProps {
	/** Parent form */
	form: IFormFunctions;
	/** Beneficiaries checkbox field key in parent form */
	beneficiariesKey: string;
	/** Field name of this component */
	field: string;
	/** Disable check form change before routing */
	warnifChange?: boolean;
	/** Handles loading state  */
	disabled?: boolean;
	/** support custom title and subtitle */
	title?: string | JSX.Element;
	subtitle?: string | JSX.Element;
}

export interface IPropertyAllocationItem {
	/** Beneficiaries key in form */
	beneficiariesKey: string;
}

export interface IPropertyAllocationValue {
	/**
	 * key: beneficiary
	 * value: percentage
	 */
	[key: string]: number;
}

const ErrorMessage = "Enter the share to gift. It must be at least 1%. If not, remove this person from the list above.";

const fieldSchema: IBasicFormField = {
	type: "number",
	constraints: [
		["isRequired", ErrorMessage],
		["isZero", ErrorMessage],
	],
	maxChar: 2,
};

const fieldSchemaWithError: IBasicFormField = { ...fieldSchema, errorMessage: ErrorMessage };

const PropertyAllocation = (props: IPropertyAllocationProps): JSX.Element => {
	const { beneficiaries } = useEwillsForm();
	const { form: parentForm, beneficiariesKey, field, warnifChange = true, disabled, title, subtitle } = props;

	const { form: propertyAllocationForm } = useForm();
	const [percentage, setPercentage] = useState(0);
	const [isLoaded, setIsloaded] = useState(false);
	const [selectedBeneficiaries, setBeneficiaries] = useState<IFormField>();
	const [error, setErrors] = useState<string>("");
	const [allocation, setAllocation] = useState<{ [key: string]: number }>({});
	const RESET_ERROR = "RESET_ERROR";

	const beneficiariesMap = useMemo(() => {
		return beneficiaries?.map((beneficiary) => beneficiary.nric);
	}, [beneficiaries]);

	useEffect(() => {
		if (!warnifChange) {
			propertyAllocationForm.forceWarnIfExit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warnifChange]);

	/** On change of selected beneficiaries */
	useEffect(() => {
		setBeneficiaries(parentForm.fields[beneficiariesKey]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentForm.fields[beneficiariesKey]]);

	/**
	 * Initialise propertyAllocationForm
	 * and updates allocation inputs on change of selected beneficiaries
	 */
	useEffect(() => {
		if (isLoaded) {
			const beneficiariesList = selectedBeneficiaries?.value as string[];
			const schema: IFormSchema = {};
			const updatedAllocation = { ...allocation };

			//Remove beneficiary from component if not in parent list
			Object.keys(updatedAllocation).forEach((key) => {
				if (!(key in beneficiariesList)) {
					delete updatedAllocation[key];
				}
			});

			//Init or add beneficiary from saved values and persist its error if any
			Object.values(beneficiariesList).forEach((value) => {
				//Prune removed beneficiaries
				if (beneficiariesMap?.includes(value)) {
					const shouldPersistError = value in { ...allocation } && propertyAllocationForm.getError(value);
					schema[value] = shouldPersistError ? fieldSchemaWithError : fieldSchema;
					updatedAllocation[value] = allocation[value] ?? 0;
				}
			});
			setAllocation(updatedAllocation);

			if (error) {
				/**
				 * resets the error to trigger errors on re-added beneficiaries on subsequent
				 * "submit" click on parentForm
				 */
				parentForm.updateFieldError(field, RESET_ERROR);
			}
			propertyAllocationForm.setupFormFields(schema, updatedAllocation);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBeneficiaries, isLoaded, beneficiariesMap]);

	/** Update parentForm on allocation change */
	useEffect(() => {
		if (isLoaded) {
			parentForm.updateFieldValue(field, { ...allocation });
			setPercentage(getPercentage());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allocation]);

	/** Update input errors if parentForm has error */
	useEffect(() => {
		const fieldError = parentForm.errors[field] as string;
		setErrors(fieldError);
		if (fieldError) {
			if (fieldError !== RESET_ERROR) {
				propertyAllocationForm.validateForm();
			}
		} else {
			propertyAllocationForm.resetAllFieldError();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentForm.errors[field]]);

	/**
	 * Initialise component
	 * Load component from saved propertyAllocation field from parent form
	 */
	useEffect(() => {
		if (!isLoaded && (selectedBeneficiaries?.value as string[])?.length) {
			const propertyAllocationValue = parentForm.getValue(field) as IPropertyAllocationValue;
			setAllocation(propertyAllocationValue);
			setIsloaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoaded, selectedBeneficiaries]);

	const changeHandler = (key: string, value: string) => {
		const updatedAllocation = { ...allocation };
		updatedAllocation[key] = Number(value);
		setAllocation(updatedAllocation);
		setPercentage(getPercentage());
	};

	/** Get total percentage from allocation */
	const getPercentage = (): number => {
		let total = 0;
		Object.values(allocation).forEach((value) => {
			total += value;
		});
		return total;
	};

	const renderDistributionInputs = (beneficiaries: IFormField) => {
		const checkboxItems = beneficiaries?.checkboxItems;
		const selectedBeneficiaries = beneficiaries.value as string[];
		const inputValues: { label: string; value: string }[] = [];

		/** to persist the order from selectedBeneficiaries field */
		checkboxItems?.forEach((checkboxItem) => {
			if (checkboxItem.value && selectedBeneficiaries.findIndex((x) => checkboxItem.value === x) !== -1) {
				inputValues.push({ label: checkboxItem.label, value: checkboxItem.value });
			}
		});

		return inputValues.map((inputValue, index) => (
			<FormDistributionInput
				key={index}
				field={inputValue.value}
				disabled={disabled}
				form={propertyAllocationForm}
				label={inputValue.label}
				width="5.5rem"
				minWidth="5.5rem"
				truncate={true}
				lines={3}
				attribute={{ value: "%", position: "right" }}
				/**
				 * [^0-9] - only allow numbers
				 * (?<=^0{1})(.*) - do not allow leading 0s
				 */
				regexPattern={/[^0-9]|(?<=^0{1})(.*)/gi}
				onInputChange={changeHandler}
				dataTestId={`distribution-input-${index}`}
			/>
		));
	};

	return (
		<>
			{selectedBeneficiaries && (
				<>
					<ProgressBar
						title={title ?? "Distribution of this property"}
						subtitle={subtitle ?? "Percentage of property distributed"}
						percentage={percentage}
						validate={error !== ""}
					></ProgressBar>
					{renderDistributionInputs(selectedBeneficiaries)}
				</>
			)}
		</>
	);
};

export const validatePropertyAllocation = (field: IFormField): string => {
	let error = "";
	if (field.constraints?.length) {
		let total = 0;
		Object.entries(field.value).forEach(([key, value], _) => {
			if (value) {
				total += value as number;
			} else {
				error += `${key}: 0 or empty is entered\n`;
			}
		});
		error += total !== 100 ? "incorrect Allocation" : "";
	}
	return error;
};

export default PropertyAllocation;
