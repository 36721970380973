import { ReactNode } from "react";

interface IProps {
	className?: string;
	children: ReactNode;
}
/**
 * This wraps the fields in the form to have correct margins
 */
const FormContainer = (props: IProps): JSX.Element => (
	<div className={`form-container ${props.className}`}>{props.children}</div>
);

export default FormContainer;
