import { LogLevel } from "server/middleware/loggerMiddleware";
import { EOLErrorCodes } from "./errors";

export default class ResponseError {
	public readonly name: EOLErrorCodes;
	public readonly details: string;
	public readonly fieldName?: string;
	public readonly fieldValue?: string;
	public readonly customError?: { [key: string]: unknown };
	public readonly logLevel: LogLevel = "error";

	constructor(
		errorName: EOLErrorCodes,
		errorDetails: string,
		logLevel?: LogLevel,
		fieldName?: string,
		fieldValue?: string,
		customError?: { [key: string]: unknown },
	) {
		this.name = errorName;
		this.details = errorDetails;
		if (undefined !== fieldName) {
			this.fieldName = fieldName;
		}
		if (undefined !== fieldValue) {
			this.fieldValue = fieldValue;
		}
		if (undefined !== customError) {
			this.customError = customError;
		}
		if (undefined !== logLevel) {
			this.logLevel = logLevel;
		}
	}

	public toJSON(): Record<string, unknown> {
		const { name, details, fieldName, fieldValue, customError } = this;
		return { name, details, fieldName, fieldValue, customError };
	}

	public toString(): string {
		const { name, details, fieldName, fieldValue, customError } = this;
		return `errName: ${name}, errDetails: ${details}, errFieldName: ${fieldName}, errFieldDetails: ${fieldValue}, customError: ${JSON.stringify(
			customError,
		)}`;
	}
}
