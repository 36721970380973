import { HTTP_VERBS } from "../../../enums";
import { DeathCertDocumentEmailDO, DeathCertDownloadRequestIdentifierDO } from "../..";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v1/death-cert-download-requests/:requestId/email";

export class Params extends DeathCertDownloadRequestIdentifierDO {}

export class Body extends DeathCertDocumentEmailDO {}

export class Response {}
