import { ChevronLeftIcon } from "@lifesg/react-icons/chevron-left";
import Breadcrumb from "app/components/basic/Breadcrumb";
import { ILinkItem } from "app/utils/interfaces";
import React from "react"; // React needs to be imported for the inline svg import to work
import BasicButton from "../Button";
import BasicLink from "../Link";
import "./styles.scss";

interface IProps extends IBreadcrumbProps {
	extraTitle?: string;
	title?: string;
	subtitle?: string;
	caption?: string;
}

export interface IBreadcrumbProps {
	backLink?: [string?, string?, string?, boolean?];
	backAction?: [string, () => void];
	hideFirstCrumbInDesktop?: boolean;
	crumbs?: Array<[string, string?]>;
	floatRightButton?: JSX.Element;
}

const StyledBackArrow = (): React.ReactElement => (
	<div className="page-back-icon">
		<ChevronLeftIcon />
	</div>
);

const Header = ({
	extraTitle,
	title,
	subtitle,
	caption,
	floatRightButton,
	...breadcrumbProps
}: IProps): JSX.Element => (
	<div className="header1">
		<BreadcrumbHeader {...breadcrumbProps} />

		{extraTitle && (
			<h5 id="page-extra-title" className="page-extra-title">
				{extraTitle}
			</h5>
		)}

		{title && (
			<div className="page-title-with-button">
				<h1 id="page-title" className="page-title">
					{title}
				</h1>
				{floatRightButton && <>{floatRightButton}</>}
			</div>
		)}

		{subtitle && (
			<p id="page-subtitle" className="page-subtitle">
				{subtitle}
			</p>
		)}
		{caption && (
			<p id="page-caption" className="page-caption">
				{caption}
			</p>
		)}
	</div>
);

const BreadcrumbHeader = ({
	backLink,
	backAction,
	crumbs,
	hideFirstCrumbInDesktop,
}: IBreadcrumbProps): JSX.Element | null => {
	if (backLink) {
		const [name, href, hrefAs, keepScrollPosition] = backLink;
		return (
			<div className="page-breadcrumbs">
				<BasicLink
					id="page__back-link"
					IconLeft={ChevronLeftIcon}
					name={name}
					href={href}
					hrefAs={hrefAs}
					keepScrollPosition={keepScrollPosition}
				/>
			</div>
		);
	}

	if (backAction) {
		const [name, action] = backAction;
		return (
			<div className="page-breadcrumbs">
				<BasicButton id="page__back-link" type="link" IconLeft={StyledBackArrow} name={name} onClick={action} />
			</div>
		);
	}

	if (!crumbs || crumbs.length <= 0) {
		return null;
	}

	const pages: ILinkItem[] = [];
	const [pageName] = crumbs[crumbs.length - 1]!;

	for (const [name, path] of crumbs) {
		if (path) {
			pages.push({ name, path });
		}
	}

	return (
		<div className="page-breadcrumbs">
			<Breadcrumb hideFirstCrumbInDesktop={hideFirstCrumbInDesktop} pages={pages} currentPageName={pageName} />
		</div>
	);
};

export default Header;
