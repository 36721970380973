import { ConfigCatClientV3 } from "@wog/mol-web-common";
import { getEnvVar } from "app/utils/environmentVariable";
import isEmpty from "lodash/isEmpty";
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfigCatKeys, defaultFeatureConfigValues } from "../../common/constant/featureConfig";
import merge from "lodash/merge";
import { localOverrides } from "./localOverrides";

const BaseFunction = (): Promise<void> => {
	return new Promise((resolve) => resolve());
};

export interface IFeatureConfigFunctions {
	fetchFeatureConfig: () => Promise<void>;
	featureConfig: ConfigCatKeys;
	hasFetchedFeatureConfig: boolean;
}

export const FeatureConfig = createContext<IFeatureConfigFunctions>({
	fetchFeatureConfig: BaseFunction,
	featureConfig: defaultFeatureConfigValues,
	hasFetchedFeatureConfig: false,
});

export const useFeatureConfig = (): IFeatureConfigFunctions => useContext(FeatureConfig);

export const refreshFeatureConfig = async (): Promise<ConfigCatKeys> => {
	try {
		const client = new ConfigCatClientV3(getEnvVar("CONFIGCAT_KEYS"));
		return await client.getConfigs(defaultFeatureConfigValues);
	} catch (error) {
		return defaultFeatureConfigValues;
	}
};

export const FeatureConfigProvider = (props: { children?: ReactNode }): JSX.Element => {
	const [featureConfig, setFeatureConfig] = useState(defaultFeatureConfigValues);
	const [hasFetchedFeatureConfig, setHasFetchedFeatureConfig] = useState(false);
	const [origin, setOrigin] = useState("");
	const client = useRef<ConfigCatClientV3>();
	const cache = useRef<{ [key: string]: string }>({});

	useEffect(() => {
		if (typeof window !== "undefined") {
			setOrigin(window.location.origin);
		}
	}, []);

	const initialize = async () => {
		if (client.current) {
			await fetchFeatureConfig();
		}
	};

	const fetchFeatureConfig = useCallback(async (): Promise<void> => {
		if (!client.current) {
			return;
		}

		try {
			const featureConfigData = await client.current.getConfigs(defaultFeatureConfigValues);
			// Apply local overrides only if the environment is local
			const mergedConfig = origin?.includes("localhost")
				? merge({}, featureConfigData, localOverrides)
				: featureConfigData;
			setFeatureConfig(isEmpty(mergedConfig) ? defaultFeatureConfigValues : mergedConfig);
		} catch (error) {
			// Fail gracefully
		} finally {
			setHasFetchedFeatureConfig(true);
		}
	}, [origin]);

	useEffect(() => {
		client.current = new ConfigCatClientV3(getEnvVar("CONFIGCAT_KEYS"), undefined, {
			cache: { get, set },
		});
	}, []);

	useEffect(() => {
		void initialize();
	}, [client.current]);

	/**
	 * Custom Cache to fix Config Cat default cache not refreshing when feature values are updated
	 */
	const get = (key: string): string => {
		return cache.current[key];
	};

	const set = (key: string, value: string) => {
		cache.current[key] = value;
	};

	return (
		<FeatureConfig.Provider value={{ fetchFeatureConfig, featureConfig, hasFetchedFeatureConfig }}>
			{props.children}
		</FeatureConfig.Provider>
	);
};
