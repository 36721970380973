import "reflect-metadata";
import AppointPerson from "./components/Form.AppointPerson";
import AppointPersonV2 from "./components/Form.AppointPersonV2";
import Button from "./components/Form.Button";
import ButtonGroup from "./components/Form.ButtonGroup";
import Checkbox from "./components/Form.Checkbox";
import CompositeOption from "./components/Form.CompositeOption";
import Container from "./components/Form.Container";
import Date from "./components/Form.Date";
import DoneeCard from "./components/Form.DoneeCard";
import Dropdown from "./components/Form.Dropdown";
import FileInput from "./components/Form.FileInput";
import Input from "./components/Form.Input";
import LongCheckbox from "./components/Form.LongCheckbox";
import PhoneNumber from "./components/Form.PhoneNumber";
import Radio from "./components/Form.Radio";
import RadioBox from "./components/Form.RadioBox";
import ShareRadio from "./components/Form.ShareRadio";
import TextArea from "./components/Form.TextArea";
import TrustedPerson from "./components/Form.TrustedPerson";
import TrustedPersonInfo from "./components/Form.TrustedPersonInfo";
import UnitNumber from "./components/Form.UnitNumber";

export * from "./Form";

export const Form = {
	Input,
	TextArea,
	Radio,
	Checkbox,
	LongCheckbox,
	Date,
	Dropdown,
	FileInput,
	Button,
	ButtonGroup,
	Container,
	RadioBox,
	DoneeCard,
	TrustedPerson,
	AppointPerson,
	TrustedPersonInfo,
	ShareRadio,
	PhoneNumber,
	AppointPersonV2,
	UnitNumber,
	CompositeOption,
};
