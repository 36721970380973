import { HTTP_VERBS } from "../../enums";
import { IsString } from "class-validator";
import { AcpFormSimpleDashboardDO } from "../domainObjects";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/advancecareplanV2/:hashedId";

export class Params {
	@IsString()
	public hashedId!: string;
}

export class Response extends AcpFormSimpleDashboardDO {}
