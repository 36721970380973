import { Form, IFormFunctions } from "app/hooks/useForm";
import React from "react";
import { TWidths } from "app/components/common/Field";
import { EditIcon } from "app/components/widgets/EditIcon";
import "./Display.scss";

interface IProps {
	id: string;
	title: string;
	content?: string;
	width: TWidths;
	field: string;
	form: IFormFunctions;
	disabled?: boolean;
	showEditForm?: boolean;
	onEdit: () => void;
	onCancel: () => void;
	onSubmit: () => void;
	submitLoading?: boolean;
}

const DisplayEdit = (props: IProps): React.ReactElement => {
	const showForm = (): void => {
		props.onEdit();
	};

	const submit = async (): Promise<void> => {
		await props.onSubmit();
	};

	const cancel = async (): Promise<void> => {
		await props.onCancel();
	};

	const titleId = props.id + "-title";
	const valueId = props.id + "-value";
	const editBtnId = props.id + "-edit-btn";
	const emptyMessage = <i>No record</i>;

	let displayClassName = "display-content";

	if (props.disabled) {
		displayClassName += " disabled";
	}

	if (props.showEditForm) {
		props.form.focusField(props.field);

		return (
			<div className={displayClassName}>
				<small className="display-content__title" id={titleId}>
					{props.title}
				</small>
				<Form.Input disableBlurValidation={true} title="" field={props.field} form={props.form} />
				<div className="display-form-button-group ">
					<Form.Button
						type="primary"
						form={props.form}
						name="Save changes"
						action={submit}
						loading={props.submitLoading}
					/>
					<Form.Button
						type="secondary"
						form={props.form}
						name="Cancel"
						action={cancel}
						disabled={props.submitLoading}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={displayClassName}>
			<div>
				<small className="display-content__title" id={titleId}>
					{props.title}
				</small>
				<button
					id={editBtnId}
					className="display-edit-button button button-link fit"
					onClick={showForm}
					disabled={props.disabled}
				>
					<div className="button__text">Edit</div>
					<EditIcon className="display-edit-button__icon" />
				</button>
			</div>
			<p className="content" id={valueId}>
				{props.content || emptyMessage}
			</p>
		</div>
	);
};

export default DisplayEdit;
