import { HTTP_VERBS } from "app/common/api/enums";
import { IsString } from "class-validator";

//NProd only API for development
export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/mock/bereavedparking/v1/submitApplication";
export const path = "/bereavedparking/v1/submitApplication";
export class Body {
	@IsString()
	public encryptedPayload!: string;

	@IsString()
	encryptedAesKey!: string;
}
export class Response {}
