import { IsNotWhiteSpace } from "app/common/validators";
import { assignToDomainObject } from "../../../utils/domainObject";
import { Transform, Type } from "class-transformer";
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength } from "class-validator";
import moment from "moment";
import { IsContactNumber, IsMoment } from "../../../validators";
import { toMoment, toNumber } from "../../transformers";
import { DoneeType } from "../enums";

export class DoneeDO {
	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public name!: string;

	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public relationship!: string;

	@IsString({ always: true })
	@IsNotEmpty({ always: true })
	@IsContactNumber({ always: true })
	public contact!: string;

	@IsEnum(DoneeType, { always: true })
	@IsNotEmpty({ always: true })
	public doneeType!: DoneeType;
}
const doneeDOKeys: Array<keyof DoneeDO> = ["name", "relationship", "contact", "doneeType"];

export class FullDoneeDO extends DoneeDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;

	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public createdAt!: moment.Moment;

	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public updatedAt!: moment.Moment;

	@IsOptional({ always: true })
	public getBaseDO = (): DoneeDO | FullDoneeDO => {
		// Returns object with only keys of specified DO.
		// NOTE: new DO(), instantiates to empty {} on backend
		return assignToDomainObject(new DoneeDO(), { ...this }, doneeDOKeys);
	};
}
