import {
	ExclamationCircleFillIcon,
	CrossIcon,
	TickCircleFillIcon,
	ExclamationTriangleFillIcon,
} from "@lifesg/react-icons";
import "./Message.scss";

export type IMessageType = "success" | "failure" | "warning";

interface IProps {
	show: boolean;
	type: IMessageType;
	content: JSX.Element | string;
	showCancel?: boolean;
	closeMessageCallback?: () => void;
	className?: string;
}

const Message = (props: IProps): JSX.Element => {
	return (
		<div className={`message message__${props.type} ${!props.show && "hidden"} ${props.className}`}>
			<span className="message__icon_container">
				{props.type === "failure" && <ExclamationCircleFillIcon className="message__icon" />}
				{props.type === "success" && <TickCircleFillIcon className="message__icon" />}
				{props.type === "warning" && <ExclamationTriangleFillIcon className="message__icon" />}
			</span>
			<span className="message__content">{props.content}</span>
			{props.showCancel && (
				<div className="message__close" onClick={props.closeMessageCallback}>
					<CrossIcon className={`close-icon close-icon--${props.type}`} />
				</div>
			)}
		</div>
	);
};

export default Message;
