import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

// allows for international number

// tslint:disable-next-line: ban-types
export function IsContactNumber(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isContactNumber",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string): boolean {
					return validateContactNumber(value);
				},
				defaultMessage(args: ValidationArguments): string {
					return `"${args.value}" must be a valid contact number."`;
				},
			},
		});
	};
}

/**
 * Using RegEx checks whether the given contact number {String} matches the following rules
 *
 * '+' is allowed and optional only for first char.
 * '-' or '(' or ')' or '<space>' is allowed.
 * +999 and +995 is accepted (provided 5 char is met).
 * +6599X, 6599X, 99X is not accepted.
 *
 * @param value contact number {String} to validate
 */
export const validateContactNumber = (value: string): boolean => {
	if (!value || !value.toString().trim().length) {
		return false;
	}
	return /^(?!\(?\+?65[- ()]*9[- ()]*9)(?!9[- ()]*9)(\(?\+?[1-9\+][\d- ()]{4,19})$|^$/.test(value);
};
