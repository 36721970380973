import * as React from "react";
import "./LinearProgressIndicator.scss";

const LinearProgressIndicator = (): JSX.Element => (
	<div className="linear-progress-container">
		<div className="linear-progress">
			<div className="bar bar-one" />
			<div className="bar bar-two" />
		</div>
	</div>
);

export default LinearProgressIndicator;
