import {
	InboxNotificationDO,
	ResponseGetAllNotificationsDO,
	ResponseGetUnreadNotificationsDO,
} from "app/common/api/notification/domainObjects";
import * as notificationContracts from "app/common/api/notification/routes";
import API from "../client.contract";

// =============================================================================
// Notifications
// =============================================================================
const getUnreadNotifications = async (): Promise<ResponseGetUnreadNotificationsDO> => {
	const response = await API.callV2<notificationContracts.getUnreadNotifications.Response>(
		notificationContracts.getUnreadNotifications,
	);
	return response;
};

const getAllNotifications = async (): Promise<ResponseGetAllNotificationsDO> => {
	const response = await API.callV2<notificationContracts.getAllNotifications.Response>(
		notificationContracts.getAllNotifications,
	);
	return response;
};

const getNotification = async (id: number): Promise<InboxNotificationDO> => {
	const p = new notificationContracts.getNotification.Params();
	p.id = id;
	const response = await API.callV2<notificationContracts.getNotification.Response>(
		notificationContracts.getNotification,
		{ params: p },
	);
	return response;
};

const updateUnreadNotification = async (id: number): Promise<void> => {
	const p = new notificationContracts.patchUnreadNotification.Params();
	p.id = id;
	await API.callV2<notificationContracts.patchUnreadNotification.Response>(
		notificationContracts.patchUnreadNotification,
		{ params: p },
	);
};

const updateAllUnreadNotifications = async (): Promise<void> => {
	await API.callV2<notificationContracts.patchAllUnreadNotifications.Response>(
		notificationContracts.patchAllUnreadNotifications,
	);
};

const NotificationAPI = {
	getUnreadNotifications,
	getAllNotifications,
	getNotification,
	updateUnreadNotification,
	updateAllUnreadNotifications,
};

export default NotificationAPI;
