import { IsBoolean, IsOptional } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { UpdateUserResponseDO } from "../domainObjects";

export const httpVerb = HTTP_VERBS.PATCH;
export const url = "/api/users/myinfo";
export class Body {
	@IsBoolean()
	@IsOptional()
	public hasAgreed?: boolean;
}
export class Response extends UpdateUserResponseDO {}
