import ErrorMessage from "app/components/widgets/ErrorMessage";
import { initialFromName } from "app/utils/strings";
import React from "react";
import "./styles.scss";
import { PersonFillIcon } from "@lifesg/react-icons/person-fill";
import { FORM_FIELD_WITH_ERROR_CLASSNAME } from "app/hooks/useForm";

interface IProps {
	value: string;
	nric: string;
	type: "view" | "edit";
	updateValue?: (newName: string) => void;
	onBlur?: () => void;
	errorMessage?: string;
}

const TrustedPersonInfo = (props: IProps): JSX.Element => {
	const Avatar = (): JSX.Element => (
		<div className="icon-avatar">
			<PersonFillIcon />
		</div>
	);
	let UserNameField = (
		<h3 id="trusted-person-name" className="semi-bold">
			{props.value}
		</h3>
	);
	let AvatarIcon = initialFromName(props.value) || <Avatar />;

	const onNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const updatedName = e.target.value;
		if (props.updateValue) {
			props.updateValue(updatedName);
		}
	};

	if (props.type === "edit") {
		let fieldClassName = "first-focusable trusted-person-field";

		if (props.errorMessage) {
			fieldClassName += " error has-error ";
		}

		UserNameField = (
			<input
				id="trusted-person-field"
				className={fieldClassName}
				value={props.value}
				maxLength={120}
				onChange={onNameChange}
				onInput={onNameChange}
				onBlur={props.onBlur}
				aria-label="Name of trusted person"
			/>
		);
		AvatarIcon = <Avatar />;
	}

	return (
		<div className={props.errorMessage ? FORM_FIELD_WITH_ERROR_CLASSNAME : ""}>
			<div className="trusted-person-group">
				<div
					className={`trusted-person-avatar ${props.type} ${initialFromName(props.value) ? "" : "icon--svg"}`}
				>
					{AvatarIcon}
				</div>
				<div className="trusted-person-info">
					{UserNameField}
					<span id="trusted-person-nric">{props.nric}</span>
				</div>
			</div>
			{props.errorMessage && (
				<div className="trusted-person-error">
					<ErrorMessage id="trusted-person-info__error">{props.errorMessage}</ErrorMessage>
				</div>
			)}
		</div>
	);
};

export default TrustedPersonInfo;
