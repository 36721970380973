//TODO: remove this
export function generateURL(url: string, params: any, query: any): string {
	let finalURL = url;

	const paramKeys = Object.getOwnPropertyNames(params);
	for (const p of paramKeys) {
		finalURL = finalURL.replace(`:${p}`, params[p]);
	}

	const queryKeys = Object.getOwnPropertyNames(query);
	const queries = queryKeys.filter((k: string) => {
		return query[k] !== undefined && query[k] !== null;
	});

	if (0 === queries.length) {
		return finalURL;
	}

	finalURL += "?";
	for (const q of queries) {
		finalURL += `${q}=${query[q]}&`;
	}
	return finalURL.slice(0, -1);
}

export function generateURLV2(url: string, params?: any, query?: any): string {
	let finalURL = url;

	if (params) {
		const paramKeys = Object.getOwnPropertyNames(params);
		for (const p of paramKeys) {
			finalURL = finalURL.replace(`:${p}`, params[p]);
		}
	}

	if (query === undefined) {
		return finalURL;
	}

	const queryKeys = Object.getOwnPropertyNames(query);
	const queries = queryKeys.filter((k: string) => {
		return query[k] !== undefined && query[k] !== null;
	});

	finalURL += "?";
	for (const q of queries) {
		finalURL += `${q}=${query[q]}&`;
	}
	return finalURL.slice(0, -1);
}

/**
 * Helper function to filter an array with a function that returns a promise
 *
 * Returns the elements of an array that meet the condition specified in a callback function.
 * @param arr A typed or untyped array of values
 * @param callback  A function that accepts one argument. The asyncFilter method calls the callbackfn function one time for each element in the array
 */
export async function asyncFilter<T>(arr: T[], callback: (item: T) => Promise<boolean>): Promise<T[]> {
	const fail = Symbol();
	return (await Promise.all(arr.map(async (item: T) => ((await callback(item)) ? item : fail)))).filter(
		(i: T | symbol) => i !== fail,
	) as T[];
}
