export enum DeathRecordType {
	Death = "death",
	Stillbirth = "stillbirth",
}

export enum IdentificationType {
	NRIC = "NRIC",
	FIN = "FIN",
	PASSPORT = "PASSPORT",
}

export enum DeathCertDownloadRequestValidationGroup {
	USER_LOGGED_IN = "USER_LOGGED_IN",
	NRICFIN = "NRICFIN",
	PASSPORT = "PASSPORT",
}

export enum RelationshipType {
	GUARDIAN = "GUARDIAN",
	PERMISSION_FROM_GUARDIAN = "PERMISSION_FROM_GUARDIAN",
	NO_PERMISSION_FROM_GUARDIAN = "NO_PERMISSION_FROM_GUARDIAN",
}
