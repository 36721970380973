import { ExclamationCircleFillIcon, ExclamationTriangleFillIcon } from "@lifesg/react-icons";
import { ReactNode } from "react";
import "./ErrorMessage.scss";

interface IProps {
	type?: "error" | "warning" | "info";
	className?: string;
	id?: string;
	children: ReactNode;
}

const ErrorMessage = (props: IProps): JSX.Element => (
	<div
		id={props.id || "input__error"}
		className={`input--error ${props.type ? `input--error--${props.type}` : "input--error--error"} ${
			props.className ? props.className : ""
		}`}
	>
		{props.type === "info" && (
			<div className="input--error--icon input--error--icon--info">
				<ExclamationCircleFillIcon />
			</div>
		)}

		{props.type === "warning" && (
			<div>
				<ExclamationTriangleFillIcon className="input--error--icon input--error--icon--warning" />
			</div>
		)}
		<span className="input--error--text">{props.children || "Error"}</span>
	</div>
);

export default ErrorMessage;
