import Button, { TButtonTypes } from "app/components/basic/Button";
import { IFormFunctions } from "../Form";

interface IFormButtonProps {
	form: IFormFunctions;
	name: string;
	type: TButtonTypes;
	IconLeft?: any;
	IconRight?: any;
	IconOnly?: any;
	action: () => void;
	additionalValidations?: () => Promise<boolean>;
	id?: string;
	fromModal?: boolean;
	disabled?: boolean;
	className?: string;
	loading?: boolean;
}

const FormButton = (props: IFormButtonProps): JSX.Element => {
	const isSubmitting = props.form.hasSubmittingField();

	const formSubmitAction = async (): Promise<void> => {
		if (props.type === "primary") {
			await props.form.submitForm(props.action, props.additionalValidations);
		} else {
			await props.action();
		}
	};

	let id = props.id || `${props.fromModal ? "modal_" : ""}${props.type}_form_button`;

	if (props.disabled) {
		id += "--disabled";
	}

	if (props.loading) {
		id += "--loading";
	}

	return <Button {...props} id={id} loading={isSubmitting || props.loading} onClick={formSubmitAction} />;
};

export default FormButton;
