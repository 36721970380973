import React from "react";
import { RemarkField, QnProps, YesOrNoShort, YesOrNoLong } from "./Questions";
import { FormKeys } from "./schemas";

export const DeathCertRelationshipQnNextOfKin = (props: QnProps): JSX.Element => {
	const { afterSelectItem, form } = props;

	return (
		<YesOrNoShort
			question="Are you a next-of-kin of the deceased?"
			description="Next-of-kin refers to a spouse, parent, child, sibling, grandparent, grandchild, aunt, uncle, nephew, niece, step-child, step or half-sibling."
			form={form}
			afterSelectItem={afterSelectItem}
		/>
	);
};

export const DeathCertRelationshipQnHavePermission = (props: QnProps): JSX.Element => {
	const { afterSelectItem, form } = props;

	return (
		<YesOrNoLong
			question="Are you authorised by the deceased’s next-of-kin to download the death certificate?"
			form={form}
			afterSelectItem={afterSelectItem}
		/>
	);
};

// if the answer is yes for question2
export const DeathCertRelationshipFinalQnPermissionGiver = (props: QnProps): JSX.Element => {
	const { form } = props;

	return (
		<RemarkField
			question="Who authorised you to download the death certificate?"
			description="Enter the full name of the next-of-kin."
			field={FormKeys.GUARDIAN_NAME}
			form={form}
		/>
	);
};

// if the answer is no for question2
export const DeathCertRelationshipFinalQnRelationship = (props: QnProps): JSX.Element => {
	const { form } = props;

	return (
		<RemarkField
			question="Describe your relationship to the deceased"
			description="This should explain why you need the death certificate. For example, employer, will executor, embassy staff."
			field={FormKeys.RELATIONSHIP_DESCRIPTION}
			form={form}
		/>
	);
};
