import { EOLErrorCodes } from "app/common/errors";
import LanguageSelector from "app/components/basic/LanguageSelector";
import { setLogout } from "app/utils/url";
import * as React from "react";
import UnexpectedError from "./ErrorPages";

interface IState {
	error: null | EOLErrorCodes;
}

/**
 * Throws to the Error Boundary
 * -Use if you want to throw error from a stateful class component
 * -For stateless use useErrorHandler hook
 *
 * @example
 * ...catch (e) {
 *  this.setState(throwToErrorBoundary(e));
 * }...
 *
 * @param type
 */
export const throwToErrorBoundary = (type: EOLErrorCodes): any => {
	return (): void => {
		throw type;
	};
};
class ErrorBoundary extends React.Component<{}, IState> {
	public static getDerivedStateFromError = (error: Error): IState => {
		const err = error.name ? error.name : EOLErrorCodes.UnknownFrontEndError;
		if (err === EOLErrorCodes.UserNotFoundError) {
			setLogout();
		}
		return { error: error.name as EOLErrorCodes };
	};
	public state: IState = {
		error: null,
	};

	public componentDidUpdate = (prevProps: React.PropsWithChildren<{}>): void => {
		if (prevProps.children !== this.props.children) {
			this.setState({ error: null });
		}
	};

	public render = (): JSX.Element => {
		if (
			this.state.error === EOLErrorCodes.UserNotFoundError ||
			this.state.error === EOLErrorCodes.OnboardingError
		) {
			return <></>;
		}
		if (this.state.error) {
			return (
				<>
					<LanguageSelector className="mt40" id="language-selector-top" error={true} />
					<UnexpectedError />
					<LanguageSelector
						className="mb104"
						id="language-selector-bottom"
						error={true}
						hideWhenTranslationUnavailable={true}
					/>
				</>
			);
		}
		return <>{this.props.children}</>;
	};
}

export default ErrorBoundary;
