import TrustedConnectionAPI from "app/api/trustedConnection";
import { GetTrustedPersonDO, TrustedPersonDO } from "app/common/api/trustedConnection/domainObjects";
import * as contracts from "app/common/api/trustedConnection/routes";
import PersonComboBox, { IPersonComboBoxProps as Props } from "./PersonComboBox";

const fetchTrustedPeople = async (): Promise<GetTrustedPersonDO[]> => {
	return await TrustedConnectionAPI.fetchTrustedPeople();
};

const createTrustedPerson = async (
	newTrustedPerson: TrustedPersonDO,
): Promise<contracts.postTrustedPerson.Response> => {
	return await TrustedConnectionAPI.createTrustedPerson(newTrustedPerson);
};

const PersonComboBoxWithAPI = (props: Props): JSX.Element => (
	<PersonComboBox
		api_createTrustedPerson={createTrustedPerson}
		api_fetchTrustedPeople={fetchTrustedPeople}
		{...props}
	/>
);

export default PersonComboBoxWithAPI;
