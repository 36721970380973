import { AcpUrl } from "app/modules/acp/constants";
import { NodUrl } from "app/modules/notice-of-death/constants";

export interface AllowAccessProps {
	children: JSX.Element;
	serviceType: ServiceType;
}

export enum ServiceType {
	ACP = "ACP",
	NOD = "NOD",
}

export const listLandingPageUrl = {
	[ServiceType.ACP]: AcpUrl.landingPage,
	[ServiceType.NOD]: NodUrl.landingPage,
};

export const AllowAccessTitle = {
	[ServiceType.ACP]: "Do you allow access to your ACP information?",
	[ServiceType.NOD]: "Allow access to your information?",
};

export const VaultParams = {
	[ServiceType.ACP]: "acptoast",
	[ServiceType.NOD]: "nodtoast",
};
