import { DeathRecordType } from "app/common/api/deathCert";
import Header from "app/components/basic/Header";
import Segment from "app/components/page/Segment";
import DeceasedSection from "app/components/templates/DeathCert/common/DeceasedSection";
import StillbirthSection from "app/components/templates/DeathCert/common/StillbirthSection";
import UserDetailsSection from "app/components/templates/DeathCert/common/UserDetailsSection";
import { DeathCertFormContext } from "app/components/templates/DeathCert/Forms/DeathCertFormContext";
import { isStillBirth, NotifyUpdateFormRows, STEPS_TITLE } from "app/components/templates/DeathCert/Forms/formUtils";
import { notifyUpdateSchema } from "app/components/templates/DeathCert/Forms/schemas";
import PublicPage from "app/components/templates/PublicPage";
import ErrorMessage from "app/components/widgets/ErrorMessage";
import { getGTMFormName } from "app/hooks/ga/death-or-stillbirth-certificate";
import { useForm } from "app/hooks/useForm";
import { GTMUtils } from "app/utils/analytics/gtm-helper";
import { GTMEvent, GTMFormBasicParams } from "app/utils/analytics/types";
import { useCallback, useContext, useEffect } from "react";
import { Divider, Grid } from "semantic-ui-react";
import RelationshipSection from "../common/RelationshipSection";
import { DeathCertStepper } from "./DeathCertStepper";
import { FormButtons } from "./FormButtons";
import "./style.scss";

const ReviewForm = (): JSX.Element => {
	const context = useContext(DeathCertFormContext);
	const { formResponses, jumpToPersonalDetails, jumpToRelationshipForm, goBack, goNext, error } = context;
	const { form } = useForm(notifyUpdateSchema, context.formResponses);
	const { forceWarnIfExit, getFields } = form;

	const {
		certType,
		name,
		identificationNumber,
		phoneNumber,
		email,
		decIdentificationNumber,
		decCertNo,
		decDateOfDeath,
		isGuardian,
		hasPermissionFromGuardian,
		guardianName,
		relationshipDescription,
	} = formResponses;

	const { day, month, year } = decDateOfDeath;
	const decDateOfDeathString = `${day}/${month}/${year}`;

	useEffect(() => {
		forceWarnIfExit(true);
	}, [forceWarnIfExit]);

	const editPersonalDetails = useCallback(() => {
		jumpToPersonalDetails(getFields());
	}, [jumpToPersonalDetails, getFields]);

	const editRelationship = useCallback(() => {
		jumpToRelationshipForm(getFields());
	}, [jumpToRelationshipForm, getFields]);

	const goBackAction = useCallback(() => {
		goBack(getFields());
	}, [goBack, getFields]);

	const submitAction = useCallback(() => {
		GTMUtils.fireEvent<GTMFormBasicParams>(GTMEvent.FORM_COMPLETE, {
			form_name: getGTMFormName(certType as DeathRecordType),
		});
		goNext(getFields());
	}, [goNext, getFields, certType]);

	const pageTitle = `Download ${certType} certificate`;

	return (
		<>
			<PublicPage title={pageTitle}>
				<Segment paddingBottom={104}>
					{error === "ApiError" && (
						<ErrorMessage className="death-cert-form__error-message" type="error" id={"test"}>
							There was a problem retrieving the death certificate. Please try again.
						</ErrorMessage>
					)}
					<Header title={pageTitle} />
					<Grid className="death-cert-form death-cert-form--review">
						<Grid.Row columns={12}>
							<DeathCertStepper steps={STEPS_TITLE} currentStep={2} />
							<Grid.Column className="no-margin no-padding-bottom" mobile={12} tablet={12} computer={8}>
								<div>Review all details carefully before proceeding</div>

								<UserDetailsSection
									title="Personal Details"
									name={name}
									identificationNumber={identificationNumber}
									phoneNumber={phoneNumber}
									email={email}
									onClickEdit={editPersonalDetails}
								/>

								{isStillBirth(context) ? (
									<StillbirthSection
										decIdentificationNumber={decIdentificationNumber}
										decCertNo={decCertNo}
										decDateOfDeath={decDateOfDeathString}
									/>
								) : (
									<DeceasedSection
										decIdentificationNumber={decIdentificationNumber}
										decCertNo={decCertNo}
										decDateOfDeath={decDateOfDeathString}
										showDateFormat
									/>
								)}

								<Divider className="death-cert-form__divider" />

								<RelationshipSection
									isDeath={!isStillBirth(context)}
									isGuardian={isGuardian === "true"}
									hasPermissionFromGuardian={hasPermissionFromGuardian === "true"}
									guardianName={guardianName}
									relationshipDescription={relationshipDescription}
									onClickEdit={editRelationship}
								/>

								<Divider className="death-cert-form__divider" />

								<NotifyUpdateFormRows certType={certType} form={form} />

								<FormButtons
									form={form}
									backAction={goBackAction}
									nextAction={submitAction}
									isSubmit={true}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</PublicPage>
		</>
	);
};

export default ReviewForm;
