import { EOLErrorCodes } from "../../errors";

export const hashID = (id: number): string => {
	// randomly generate 3 letters in front and
	return encodeBase32(id.toString());
};

export const unhashID = (hashedId: string): number => {
	const idString = decodeBase32(hashedId);
	const unhashedID = parseInt(idString, 10);
	return unhashedID;
};

const BASE32_ENCODE_CHAR = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567".split("");
const BASE32_DECODE_CHAR = {
	A: 0,
	B: 1,
	C: 2,
	D: 3,
	E: 4,
	F: 5,
	G: 6,
	H: 7,
	I: 8,
	J: 9,
	K: 10,
	L: 11,
	M: 12,
	N: 13,
	O: 14,
	P: 15,
	Q: 16,
	R: 17,
	S: 18,
	T: 19,
	U: 20,
	V: 21,
	W: 22,
	X: 23,
	Y: 24,
	Z: 25,
	"2": 26,
	"3": 27,
	"4": 28,
	"5": 29,
	"6": 30,
	"7": 31,
};

const encodeBase32 = (input: string): string => {
	return encodeAscii(input);
};

const encodeAscii = (str: string) => {
	let v1,
		v2,
		v3,
		v4,
		v5,
		base32Str = "";
	const length = str.length;
	let count;
	let i;
	for (i = 0, count = (length / 5) * 5; i < count; ) {
		v1 = str.charCodeAt(i++);
		v2 = str.charCodeAt(i++);
		v3 = str.charCodeAt(i++);
		v4 = str.charCodeAt(i++);
		v5 = str.charCodeAt(i++);
		base32Str +=
			BASE32_ENCODE_CHAR[v1 >>> 3] +
			BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
			BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
			BASE32_ENCODE_CHAR[((v2 << 4) | (v3 >>> 4)) & 31] +
			BASE32_ENCODE_CHAR[((v3 << 1) | (v4 >>> 7)) & 31] +
			BASE32_ENCODE_CHAR[(v4 >>> 2) & 31] +
			BASE32_ENCODE_CHAR[((v4 << 3) | (v5 >>> 5)) & 31] +
			BASE32_ENCODE_CHAR[v5 & 31];
	}

	// remain char
	const remain = length - count;
	if (remain === 1) {
		v1 = str.charCodeAt(i);
		base32Str += BASE32_ENCODE_CHAR[v1 >>> 3] + BASE32_ENCODE_CHAR[(v1 << 2) & 31] + "======";
	} else if (remain === 2) {
		v1 = str.charCodeAt(i++);
		v2 = str.charCodeAt(i);
		base32Str +=
			BASE32_ENCODE_CHAR[v1 >>> 3] +
			BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
			BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
			BASE32_ENCODE_CHAR[(v2 << 4) & 31] +
			"====";
	} else if (remain === 3) {
		v1 = str.charCodeAt(i++);
		v2 = str.charCodeAt(i++);
		v3 = str.charCodeAt(i);
		base32Str +=
			BASE32_ENCODE_CHAR[v1 >>> 3] +
			BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
			BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
			BASE32_ENCODE_CHAR[((v2 << 4) | (v3 >>> 4)) & 31] +
			BASE32_ENCODE_CHAR[(v3 << 1) & 31] +
			"===";
	} else if (remain === 4) {
		v1 = str.charCodeAt(i++);
		v2 = str.charCodeAt(i++);
		v3 = str.charCodeAt(i++);
		v4 = str.charCodeAt(i);
		base32Str +=
			BASE32_ENCODE_CHAR[v1 >>> 3] +
			BASE32_ENCODE_CHAR[((v1 << 2) | (v2 >>> 6)) & 31] +
			BASE32_ENCODE_CHAR[(v2 >>> 1) & 31] +
			BASE32_ENCODE_CHAR[((v2 << 4) | (v3 >>> 4)) & 31] +
			BASE32_ENCODE_CHAR[((v3 << 1) | (v4 >>> 7)) & 31] +
			BASE32_ENCODE_CHAR[(v4 >>> 2) & 31] +
			BASE32_ENCODE_CHAR[(v4 << 3) & 31] +
			"=";
	}
	return base32Str;
};

const decodeBase32 = (base32Str: string): string => {
	if (false === /^[A-Z2-7=]+$/.test(base32Str)) {
		throw EOLErrorCodes.InvalidInvitationIdError;
	}
	let v1,
		v2,
		v3,
		v4,
		v5,
		v6,
		v7,
		v8,
		str = "";
	let length = base32Str.indexOf("=");
	let count;
	if (length === -1) {
		length = base32Str.length;
	}

	let i;
	// 8 char to 5 bytes
	for (i = 0, count = (length >> 3) << 3; i < count; ) {
		v1 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v2 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v3 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v4 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v5 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v6 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v7 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v8 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		str +=
			String.fromCharCode(((v1 << 3) | (v2 >>> 2)) & 255) +
			String.fromCharCode(((v2 << 6) | (v3 << 1) | (v4 >>> 4)) & 255) +
			String.fromCharCode(((v4 << 4) | (v5 >>> 1)) & 255) +
			String.fromCharCode(((v5 << 7) | (v6 << 2) | (v7 >>> 3)) & 255) +
			String.fromCharCode(((v7 << 5) | v8) & 255);
	}

	// remain bytes
	const remain = length - count;
	if (remain === 2) {
		v1 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v2 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		str += String.fromCharCode(((v1 << 3) | (v2 >>> 2)) & 255);
	} else if (remain === 4) {
		v1 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v2 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v3 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v4 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		str +=
			String.fromCharCode(((v1 << 3) | (v2 >>> 2)) & 255) +
			String.fromCharCode(((v2 << 6) | (v3 << 1) | (v4 >>> 4)) & 255);
	} else if (remain === 5) {
		v1 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v2 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v3 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v4 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v5 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		str +=
			String.fromCharCode(((v1 << 3) | (v2 >>> 2)) & 255) +
			String.fromCharCode(((v2 << 6) | (v3 << 1) | (v4 >>> 4)) & 255) +
			String.fromCharCode(((v4 << 4) | (v5 >>> 1)) & 255);
	} else if (remain === 7) {
		v1 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v2 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v3 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v4 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v5 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v6 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		v7 = BASE32_DECODE_CHAR[base32Str.charAt(i++)];
		str +=
			String.fromCharCode(((v1 << 3) | (v2 >>> 2)) & 255) +
			String.fromCharCode(((v2 << 6) | (v3 << 1) | (v4 >>> 4)) & 255) +
			String.fromCharCode(((v4 << 4) | (v5 >>> 1)) & 255) +
			String.fromCharCode(((v5 << 7) | (v6 << 2) | (v7 >>> 3)) & 255);
	}
	return str;
};
