import { createContext, useReducer } from "react";
import { HdbParkingAction, HdbParkingActionType, IHdbParkingContextType, IHdbParkingState } from "./type";

// =============================================================================
// DEFAULT VALUES
// =============================================================================
export const initialValues: IHdbParkingState = {
	isLoading: false,
	isRenderingForm: false,
	isSingpassFlow: undefined,
	hasRedirected: false,
	latestStep: 0,
	showDevMenu: false,
	personalDetails: undefined,
	registeredVehicles: undefined,
	bookingDetails: undefined,
	parkingDetails: undefined,
	hasFetchedSessionStorage: false,
	currentStep: 0,
	mockHdbApiError: false,
	sid: undefined,
	deathCertificateErrorType: undefined,
	isSubmitButtonEnabled: false,
};

// =============================================================================
// REDUCER
// =============================================================================
export const hdbParkingReducer = (state: IHdbParkingState, action: HdbParkingAction): IHdbParkingState => {
	switch (action.type) {
		case HdbParkingActionType.RESET_FORM: {
			return {
				...initialValues,
				hasFetchedSessionStorage: state.hasFetchedSessionStorage,
				latestStep: 2,
				currentStep: 2,
				hasRedirected: state.hasRedirected,
				isSingpassFlow: state.isSingpassFlow,
			};
		}
		case HdbParkingActionType.SET_LOADING_STATE: {
			return {
				...state,
				isLoading: action.payload,
			};
		}
		case HdbParkingActionType.ENABLE_SUBMIT_BUTTON: {
			return {
				...state,
				isSubmitButtonEnabled: action.payload,
			};
		}
		case HdbParkingActionType.SET_RENDERING_FORM: {
			return {
				...state,
				isRenderingForm: action.payload,
			};
		}
		case HdbParkingActionType.SET_IS_SINGPASS_FLOW: {
			return {
				...state,
				isSingpassFlow: action.payload,
			};
		}
		case HdbParkingActionType.SET_LATEST_STEP: {
			return {
				...state,
				latestStep: action.payload,
			};
		}
		case HdbParkingActionType.SET_HAS_REDIRECTED: {
			return {
				...state,
				hasRedirected: action.payload,
			};
		}
		case HdbParkingActionType.SHOW_DEV_MENU: {
			return {
				...state,
				showDevMenu: action.payload,
			};
		}
		case HdbParkingActionType.SET_PERSONAL_DETAILS: {
			return {
				...state,
				personalDetails: action.payload,
			};
		}
		case HdbParkingActionType.SET_REGISTERED_VEHICLES: {
			return {
				...state,
				registeredVehicles: action.payload,
			};
		}
		case HdbParkingActionType.SET_BOOKING_DETAILS: {
			return {
				...state,
				deathDocumentPage: {
					certificationNumber: action.payload.certificationNumber,
					identification: action.payload.nric,
				},
				bookingDetails: action.payload,
			};
		}
		case HdbParkingActionType.SET_PARKING_DETAILS: {
			return {
				...state,
				parkingDetails: action.payload,
			};
		}
		case HdbParkingActionType.SET_APPLICATION_RESPONSE: {
			return {
				...state,
				applicationResponse: action.payload,
			};
		}
		case HdbParkingActionType.SET_SESSION_STORAGE_DATA: {
			return {
				...action.payload,
				hasFetchedSessionStorage: true,
				isLoading: false,
				isRenderingForm: false,
				isSingpassFlow:
					state.isSingpassFlow === undefined ? action.payload.isSingpassFlow : state.isSingpassFlow,
			};
		}

		case HdbParkingActionType.SET_USE_MOCK_HDB_API: {
			return {
				...state,
				mockHdbApiError: action.payload.mockApiError,
			};
		}

		case HdbParkingActionType.SET_RESET_FLOW: {
			return {
				...initialValues,
				hasFetchedSessionStorage: true,
			};
		}

		case HdbParkingActionType.SET_SESSION_ID: {
			return {
				...state,
				sid: action.payload,
			};
		}

		case HdbParkingActionType.SET_DEATH_CERTIFICATE_ERROR_TYPE: {
			return {
				...state,
				deathCertificateErrorType: action.payload,
			};
		}

		default:
			return state;
	}
};

// =============================================================================
// CONTEXT
// =============================================================================
export const HdbParkingContext = createContext<IHdbParkingContextType>({
	state: initialValues,
	dispatch: () => null,
});

// =============================================================================
// CONTEXT PROVIDER
// =============================================================================
export const HdbParkingProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
	const [state, dispatch] = useReducer(hdbParkingReducer, initialValues);

	return <HdbParkingContext.Provider value={{ state, dispatch }}>{children}</HdbParkingContext.Provider>;
};
