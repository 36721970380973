import { NotificationBanner } from "@lifesg/react-design-system/notification-banner";
import { ServiceMaintenanceHelper } from "@wog/mol-web-common";
import { useCIRISMaintenance } from "app/hooks/useCIRISMaintenance";
import { useFeatureConfig } from "app/hooks/useFeatureConfig";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BannerContent, NotificationBannerWrapper } from "./styles";
import { ITopBannerV2 } from "./type";
import { shouldHideBanner, hideNotificationBannerV2From, hideICAMaintenanceBannerV2From } from "./constants";
import { useRouter } from "next/router";

const TopBanner = (): JSX.Element => {
	const [isCIRISUnderMaintenance, isScheduled, checkMaintenanceState] = useCIRISMaintenance();
	const { featureConfig, hasFetchedFeatureConfig } = useFeatureConfig();
	const [showMaintenanceBanner, setShowMaintenanceBanner] = useState(false);
	const [showBannerV2, setShowBannerV2] = useState(false);
	const router = useRouter();

	/* -------------------------------------------------------------------------- */
	/*                         Evaluate Config Values                             */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		checkMaintenanceState(featureConfig.ICA_CIRIS_MAINTENANCE as string);
	}, [featureConfig.ICA_CIRIS_MAINTENANCE, checkMaintenanceState]);

	const { NOTIFICATION_BANNER_V2 } = featureConfig;

	useEffect(() => {
		try {
			let { pathname } = window.location;
			if (pathname.endsWith("/")) {
				pathname = pathname.slice(0, -1);
			}

			const bannerV2 = JSON.parse(NOTIFICATION_BANNER_V2 as string) as ITopBannerV2;

			const showV2 =
				ServiceMaintenanceHelper.checkOnMaintenance(bannerV2.period) &&
				!shouldHideBanner(pathname, hideNotificationBannerV2From);
			const showMaintenance =
				isCIRISUnderMaintenance && !shouldHideBanner(pathname, hideICAMaintenanceBannerV2From);

			setShowBannerV2(showV2);
			setShowMaintenanceBanner(showMaintenance);
		} catch (error) {}
	}, [NOTIFICATION_BANNER_V2, isCIRISUnderMaintenance, router]);

	/* -------------------------------------------------------------------------- */
	/*                             Get Banner Content                             */
	/* -------------------------------------------------------------------------- */

	const getRawBannerContent = useCallback(() => {
		try {
			if (showMaintenanceBanner) {
				return constructMaintenanceMessage();
			}
			if (showBannerV2) {
				const parsedMessage = JSON.parse(NOTIFICATION_BANNER_V2 as string) as ITopBannerV2;
				if (parsedMessage.message) {
					return <BannerContent dangerouslySetInnerHTML={{ __html: parsedMessage.message }}></BannerContent>;
				}
			}
		} catch {}
		return <></>;
	}, [showMaintenanceBanner, showBannerV2, NOTIFICATION_BANNER_V2]);

	const constructMaintenanceMessage = () => {
		if (!isScheduled) {
			return (
				<BannerContent>
					The{" "}
					<NotificationBanner.Link href="/find-a-service/download-death-or-stillbirth-certificate/">
						download death or stillbirth certificate service
					</NotificationBanner.Link>{" "}
					is currently down for maintenance. Please check back later.
				</BannerContent>
			);
		}

		const maintenanceTimings = (featureConfig.ICA_CIRIS_MAINTENANCE as string)?.split(",");
		const startTimeStr = moment(maintenanceTimings[0]?.trim()).format("ddd, D MMM, h:mma");
		const endTimeStr = moment(maintenanceTimings[1]?.trim()).format("ddd, D MMM, h:mma");
		return (
			<BannerContent>
				Maintenance alert: You’ll not be able to{" "}
				<NotificationBanner.Link href="/find-a-service/download-death-or-stillbirth-certificate/">
					download death or stillbirth certificate
				</NotificationBanner.Link>{" "}
				from <b>{startTimeStr}</b> to <b>{endTimeStr}</b>.
			</BannerContent>
		);
	};

	return (
		<>
			{hasFetchedFeatureConfig && (showBannerV2 || showMaintenanceBanner) ? (
				<NotificationBannerWrapper id="top-banner" dismissible={false}>
					{getRawBannerContent()}
				</NotificationBannerWrapper>
			) : (
				<></>
			)}
		</>
	);
};

export default TopBanner;
