import "./Divider.scss";
interface IProps {
	marginTop?: number;
	marginBottom?: number;
	color?: string;
	id?: string;
}

const Divider = (props: IProps): JSX.Element => {
	const style = {
		marginTop: props.marginTop || 0,
		marginBottom: props.marginBottom || 0,
	};
	if (props.color) {
		Object.assign(style, { background: props.color });
	}
	return <div className="custom-divider" id={props.id} style={style} />;
};

export default Divider;
