import { default as baseAPI } from "app/api/client";
import { DeathRecordType } from "app/common/api/deathCert/enums";
import * as contracts from "app/common/api/deathCert/routes";
import { type IDateValue } from "app/common/api/interface";
import { generateURLV2 } from "app/common/api/util";
import { ConsolidatedResponses } from "app/components/templates/DeathCert/Forms/schemas";
import axios from "axios";
import { isEmpty, omitBy } from "lodash";
import API from "../client.contract";
import { encryptToBase64 } from "./cryptoUtils";
import { base64ToUrlSafe } from "app/common/utils/base64Utils";

// =============================================================================
// Death Record
// =============================================================================

const getDeathRecord = async (args: {
	certificateNumber: string;
	type: DeathRecordType;
	identificationNumber: string;
	dateOfDeath: IDateValue;
	key: string;
}): Promise<contracts.getDeathRecord.Response> => {
	const { certificateNumber, type, identificationNumber, dateOfDeath, key } = args;
	const encryptedIdentificationNumber = base64ToUrlSafe(await encryptToBase64(identificationNumber, key));

	const params: contracts.getDeathRecord.Params = {
		certificateNumber,
	};
	const { day, month, year } = dateOfDeath;
	const query = {
		type,
		encryptedIdentificationNumber,
		dateOfDeath: `${year}-${month}-${day}`,
	};
	return await API.callV2<contracts.getDeathRecord.Response>(contracts.getDeathRecord, { query, params });
};

const getDeathCertDownloadRequest = async (
	requestId: string,
): Promise<contracts.getDeathCertDownloadRequest.Response> => {
	return await API.callV2<contracts.getDeathCertDownloadRequest.Response>(contracts.getDeathCertDownloadRequest, {
		params: {
			requestId,
		},
	});
};

// TODO type the response
const getDeathDocument = async (requestId: string): Promise<any> => {
	const config = Object.assign({ responseType: "blob" }, baseAPI.generateCSRFHeader());

	const url = generateURLV2(contracts.getDeathCertDocumentDownload.url, { requestId });
	const res = await axios.get(url, config);
	return res;
};

const postDeathCertDownloadRequest = async (
	formDetails: ConsolidatedResponses,
): Promise<contracts.postDeathCertDownloadRequest.Response> => {
	const { day, month, year } = formDetails.decDateOfDeath as IDateValue;
	const body = {
		...omitBy(formDetails, isEmpty),
		isGuardian: formDetails.isGuardian === "true",
		hasPermissionFromGuardian: formDetails.hasPermissionFromGuardian === "true",
		notifyUpdate: formDetails.notifyUpdate === "true",
		decDateOfDeath: `${year}-${month}-${day}`,
	};

	return await API.callV2(contracts.postDeathCertDownloadRequest, { body });
};

const patchDeathCertDownloadRequest = async (
	requestId: string,
	body: ConsolidatedResponses,
): Promise<contracts.patchDeathCertDownloadRequest.Response> => {
	return await API.callV2(contracts.patchDeathCertDownloadRequest, { params: { requestId }, body });
};

const postDeathDocumentEmail = async (
	requestId: string,
	email: string,
): Promise<contracts.postDeathCertDocumentEmail.Response> => {
	const params = { requestId };
	const body = { email };
	return await API.callV2(contracts.postDeathCertDocumentEmail, { params, body });
};

const DeathCertAPI = {
	getDeathRecord,
	getDeathCertDownloadRequest,
	postDeathCertDownloadRequest,
	patchDeathCertDownloadRequest,
	getDeathDocument,
	postDeathDocumentEmail,
};

export default DeathCertAPI;
