import { ServiceMaintenanceHelper } from "@wog/mol-web-common";
import { useEffect, useState } from "react";
import { useFeatureConfig } from "../useFeatureConfig";

export interface MaintenanceConfig {
	startDatetime: string;
	endDatetime: string;
}

export enum ServiceMaintenancePeriodKey {
	SHOW_MAINTENANCE_PAGE_V2 = "SHOW_MAINTENANCE_PAGE_V2",
}

export const useMaintenance = (
	maintenancePeriodKey: ServiceMaintenancePeriodKey,
): {
	isOnMaintenance: boolean;
	maintenanceEndDatetime: string;
	isConfigReady: boolean;
} => {
	const { featureConfig, hasFetchedFeatureConfig: isConfigLoaded } = useFeatureConfig();
	const maintenancePeriod = featureConfig[maintenancePeriodKey];

	const [isOnMaintenance, setIsOnMaintenance] = useState(false);
	const [maintenanceEndDatetime, setMaintenanceEndDatetime] = useState<string>("");
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (maintenancePeriod) {
			switch (maintenancePeriodKey) {
				case ServiceMaintenancePeriodKey.SHOW_MAINTENANCE_PAGE_V2: {
					if (!isConfigLoaded) {
						return;
					}

					try {
						const { startDatetime, endDatetime }: MaintenanceConfig = JSON.parse(
							maintenancePeriod as string,
						);

						if (ServiceMaintenanceHelper.checkOnMaintenance(`${startDatetime}, ${endDatetime}`)) {
							setMaintenanceEndDatetime(endDatetime);
							setIsOnMaintenance(true);
						}
					} catch (err) {} // malformed configcat messages are ignored
				}
			}
			setIsReady(true);
		}
	}, [maintenancePeriod, maintenancePeriodKey, isConfigLoaded]);

	return { isOnMaintenance, maintenanceEndDatetime, isConfigReady: isReady };
};
