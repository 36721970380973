import { default as baseAPI } from "app/api/client";
import { generateURL } from "app/common/api/util";
import { ConfigData } from "server/services/config";

const getConfigData = async (): Promise<ConfigData> => {
	//TODO:replace wiht v2
	const response = await baseAPI.get(generateURL("/config", {}, {}));
	return response as ConfigData;
};

const ConfigAPI = {
	getConfigData,
};

export default ConfigAPI;
