import { forwardRef } from "react";
import "./styles.scss";

export type TButtonTypes =
	| "primary"
	| "secondary"
	| "danger"
	| "borderless"
	| "danger-secondary"
	| "danger-borderless"
	| "link"
	| "inline-link"
	| "danger-link";

export interface IButtonProps {
	id: string;
	name: string | JSX.Element;
	type: TButtonTypes;
	loading?: boolean;
	disabled?: boolean;
	disabledSuccess?: boolean;
	className?: string;
	onClick?: () => void;
	IconLeft?: any;
	IconRight?: any;
	IconOnly?: any;
	disableFocus?: boolean;
	size?: "small" | "default";
	fullWidthOnMobile?: boolean;
	/**
	 * triggers the click in case it's not triggered due to 'onBlur' validations
	 */
	forcedClick?: boolean;
}
/**
 * @deprecated use ButtonV2
 */
const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref): JSX.Element => {
	let className = `button-${props.type}`;
	const size = props.size || "default";

	if (props.disabled) {
		className = "button-disabled";
	}

	if (props.disabled && props.type.includes("secondary")) {
		className = "button-disabled-secondary";
	}

	if (props.disabled && props.type.includes("borderless")) {
		className = "button-disabled-borderless";
	}

	if (props.disabled && props.type.includes("link")) {
		className = "button-disabled-link";
	}

	if (props.disabledSuccess) {
		className = "button-disabled button-disabled--success";
	}

	if (props.loading) {
		className += " button-loading";
	}

	if (props.className) {
		className += ` ${props.className}`;
	}

	className += ` ${size === "small" ? "button-size-small" : "button-size-default"}`;

	if (props.fullWidthOnMobile) {
		className += ` button-full-width-mobile`;
	}

	const { id, onClick, disabled, name, IconLeft, IconRight, IconOnly, loading, forcedClick } = props;

	return (
		<button
			tabIndex={props.disableFocus ? -1 : 0}
			id={id}
			key={id}
			className={className}
			name={id}
			onClick={forcedClick ? undefined : onClick}
			disabled={disabled || loading}
			ref={ref}
			onMouseDown={forcedClick ? onClick : undefined}
		>
			{IconLeft && <IconLeft className="button-icon-left" />}
			{IconOnly && <IconOnly className="button-icon-only" />}
			{!IconOnly && name}
			{IconRight && <IconRight className="button-icon-right" />}
		</button>
	);
});

export default Button;
