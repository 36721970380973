import ReadOnlyField from "app/components/common/ReadOnlyField";

interface NotGuardianFieldsProps {
	hasPermissionFromGuardian?: boolean;
	guardianName?: string;
	relationshipDescription?: string;
	isDeath: boolean;
}

const NotGuardianFields = ({
	hasPermissionFromGuardian,
	guardianName,
	relationshipDescription,
	isDeath,
}: NotGuardianFieldsProps): JSX.Element => {
	if (hasPermissionFromGuardian) {
		return (
			<>
				<ReadOnlyField
					id="readonly-field__permission-to-download"
					title={
						isDeath
							? "Are you authorised by the deceased’s next-of-kin to download the death certificate?"
							: "Are you authorised by the child’s parent to download the stillbirth certificate?"
					}
					value="Yes"
				/>
				<ReadOnlyField
					id="readonly-field__relationship-description"
					title={
						isDeath
							? "Who authorised you to download the death certificate?"
							: "Full name of the parent who authorised the download"
					}
					value={guardianName}
				/>
			</>
		);
	}

	const title = isDeath
		? "Are you authorised by the deceased’s next-of-kin to download the death certificate?"
		: "Are you authorised by the child’s parent to download the stillbirth certificate?";

	return (
		<>
			<ReadOnlyField id="readonly-field__permission-to-download" title={title} value="No" />
			<ReadOnlyField
				id="readonly-field__relationship-description"
				title={`Describe your relationship to the ${isDeath ? "deceased" : "baby or the baby’s parents"}`}
				value={relationshipDescription}
			/>
		</>
	);
};

export default NotGuardianFields;
