import { HTTP_VERBS } from "app/common/api/enums";
import { Transform } from "class-transformer";
import { IsString } from "class-validator";
import { escapeHTML } from "../../transformers";
export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v1/hdb-parking/sid/refresh";

export class Body {
	@IsString()
	@Transform(escapeHTML)
	sid!: string;
}

export class Response {}
