import { Transform } from "class-transformer";
import { IsNumber, IsOptional } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";
import { OtpDO, ResponseOtpDO } from "../domainObjects/OTP";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/users/otp/resend";
export class Body extends OtpDO {
	@IsNumber()
	@Transform(toNumber)
	@IsOptional()
	public invitationId?: number;
}
export class Response extends ResponseOtpDO {}
