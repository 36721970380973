import { Transform, Type } from "class-transformer";
import { IsBoolean, IsEnum, IsNumber, IsString, ValidateNested } from "class-validator";
import moment from "moment";
import { IsMoment, xorConstraint } from "../../../validators";
import { ResponseOtpDO } from "../../otp/domainObjects";
import { BaseSharedAccessDO, SharedAccessDataDO } from "../../sharedAccess/domainObjects";
import { toMoment, toNumber, toUppercase } from "../../transformers";
import { TrustedConnectionStatusTypes } from "../enums";

class BaseInvitationDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
	@IsEnum(TrustedConnectionStatusTypes)
	public status!: TrustedConnectionStatusTypes;
	@IsMoment()
	@Transform(toMoment)
	public invitedOn!: moment.Moment;
	@IsString()
	public hashedId!: string;
	@IsString()
	public senderName!: string;
	@xorConstraint("email")
	public phone!: string;
	@xorConstraint("phone")
	public email!: string;
}

export class InvitationDO extends BaseInvitationDO {
	@ValidateNested({ each: true })
	@Type(() => BaseSharedAccessDO)
	public sharedAccess!: BaseSharedAccessDO[];
}

export class InvitationWithDataDO extends BaseInvitationDO {
	@IsString()
	@Transform(toUppercase)
	public senderNRIC!: string;
	@ValidateNested({ each: true })
	@Type(() => SharedAccessDataDO)
	public sharedAccess!: SharedAccessDataDO[];
}

export class AcceptInvitationDO extends ResponseOtpDO {
	@IsBoolean()
	public invitationLocked!: boolean;
}
