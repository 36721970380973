import { lowercaseAndHyphenSpecialChar } from "app/utils/strings";

export interface ITopic {
	type: TopicType;
	description: string;
	image: any;
	hidden?: boolean;
}

// String corresponds to the Topic title
export enum TopicType {
	WhenDeathHappens = "When death happens",
	endOfLifePlanning = "End-of-life planning",
}

// The below 5 categories are how the guides were split in the first iteration of our Information Architecture (IA). After the IA was changed to just have 2 big categories (When death happens & End-of-life planning), it was decided that we maintain these 5 categories for grouping of the related guides section in each guide, because the grouping does not appear out of place for users reading the guide
export enum TopicListType {
	planningAhead = "planning-ahead",
	palliativeCare = "palliative-care",
	reportingADeath = "reporting-a-death",
	funeralMatters = "funeral-matters",
	estateSettlements = "estate-settlements",
}

// String corresponds to the Guide title
export enum PlanningAheadGuidesList {
	whyPlanAhead = "Why plan ahead?",
	writeAWill = "Write a will",
	makeCPFNomination = "Make a CPF nomination",
	advanceCarePlan = "Advance Care Planning",
	makeLPA = "Make a lasting power of attorney",
	otherHealthcareDecisions = "Other healthcare decisions",
	shareFuneralWishes = "Share your funeral wishes",
	organiseImportantDocs = "Organise important documents",
}

export enum PalliativeCareGuidesTitles {
	whatIsPalliativeCare = "What is palliative care?",
	whereCanYouGet = "Where can you get palliative care?",
	caregiverSupport = "Caregiver support",
	managingPain = "Managing pain and other symptoms",
	homeCareEquipment = "Home care equipment",
	activitiesOfDailyLiving = "Activities of Daily Living",
	transferPatient = "Ways to transfer a patient safely",
	financialAssistance = "Financial assistance for home care",
	whatToExpect = "What to expect in the last days",
	terminalDischarge = "Considerations for terminal discharge",
}
export enum ReportingADeathGuidesList {
	reportDeath = "Report the death",
	overseas = "When a death happens overseas",
	// tslint:disable-next-line: quotemark
	coronerCase = "Coroner's case at Mortuary@HSA",
}

export enum FuneralMattersGuidesTitles {
	arrangeTheFuneral = "Arrange the funeral",
	manageTheBurial = "Manage the burial",
	manageTheCremation = "Manage the cremation",
	storeAshesInAColumbarium = "Store ashes in a columbarium",
	seaBurial = "Sea burial",
	inlandAshScattering = "Inland Ash Scattering",
}

export enum EstateSettlementsGuidesTitles {
	checklist = "Checklist of assets",
	willsAndInheritance = "Wills and inheritance",
	grantOfProbate = "Apply for a Grant of Probate",
	lettersOfAdministration = "Apply for Letters of Administration",
	cpfWithdrawal = "Settle CPF withdrawal",
	propertyInheritance = "Settle property inheritance",
	vehicleOwnership = "Settle vehicle ownership",
	insuranceClaims = "Settle insurance claims and benefits",
	taxesAndBills = "Settle outstanding bills and taxes",
	closeAccountsAndCancelSubscriptions = "Close accounts and cancel subscriptions",
}

export type IAllGuideListType =
	| PlanningAheadGuidesList
	| PalliativeCareGuidesTitles
	| ReportingADeathGuidesList
	| FuneralMattersGuidesTitles
	| EstateSettlementsGuidesTitles;

export const MultipageGuideList = {
	[ReportingADeathGuidesList.overseas]: [
		"Overview",
		"Get help from MFA",
		"Register the death overseas",
		"Bringing the body home",
		"Bringing the ashes home",
		"Report the death to ICA",
	],
	[PlanningAheadGuidesList.whyPlanAhead]: [
		"Overview",
		"Helping your loved ones",
		"Understanding the end of life",
		"Coping with a terminal illness",
		"Start your plan",
	],
	[PlanningAheadGuidesList.advanceCarePlan]: [
		"About Advance Care Planning",
		"Steps to ACP",
		"Ways to prepare an ACP",
		"Speak to someone",
		"Choose your NHS",
	],
	[PlanningAheadGuidesList.makeCPFNomination]: [
		"Overview",
		"Choose your nominee",
		"How to make a CPF nomination",
		"Update or change your CPF nomination",
		"Check your CPF nomination details",
		// tslint:disable-next-line: quotemark
		"If you don't have a CPF nomination",
	],
	[PlanningAheadGuidesList.writeAWill]: [
		"Overview",
		"How to write a will",
		"Make sure your will is legal",
		"Update or change your will",
		// tslint:disable-next-line: quotemark
		"If you don't have a will",
	],
	[PlanningAheadGuidesList.makeLPA]: [
		"Overview",
		"Choose 1 or 2 Donees",
		"How to make a lasting power of attorney",
		"Change your lasting power of attorney",
		"Revoke your lasting power of attorney",
		"Situations that cancel a lasting power of attorney",
	],
	[PalliativeCareGuidesTitles.whatIsPalliativeCare]: [
		"Overview",
		"Understanding palliative care",
		"Start advance care planning",
		"Talk to someone",
	],
	[EstateSettlementsGuidesTitles.taxesAndBills]: [
		"Overview",
		"File their individual income tax",
		"File their estate or trust income tax",
		"Settle their outstanding bills and accounts",
	],
	[EstateSettlementsGuidesTitles.willsAndInheritance]: [
		"Overview",
		"What you need to do",
		// tslint:disable-next-line: quotemark
		"Check if there's a will",
		"If the person left a will",
		"If the person did not leave a will",
		"Muslim inheritance law in Singapore",
	],
	[EstateSettlementsGuidesTitles.grantOfProbate]: [
		"Overview",
		"Who can be an executor",
		"Duties of the executor",
		"Applying for a Grant of Probate",
		"If you do not wish to be an executor",
	],
	[EstateSettlementsGuidesTitles.lettersOfAdministration]: [
		"Overview",
		"Who can be an administrator",
		"Duties of the administrator",
		"Applying for letters of administration",
		"Intestate succession act",
		"If you do not wish to be an administrator",
	],
};

export type IAllMultipageGuideListType = keyof typeof MultipageGuideList;

export const getTopicHref = (topicTitle: TopicType): string => {
	return `/${lowercaseAndHyphenSpecialChar(topicTitle)}`;
};

export const getGuideHref = (topicTitle: TopicType, guideTitle: IAllGuideListType): string => {
	return `/${lowercaseAndHyphenSpecialChar(topicTitle)}/${lowercaseAndHyphenSpecialChar(guideTitle)}`;
};

export const getMultiStepGuideHref = (
	topicTitle: TopicType,
	guideTitle: IAllMultipageGuideListType,
	stepNumber: number,
): string => {
	if (stepNumber === 0) {
		return getGuideHref(topicTitle, guideTitle); // prevent '/overview' from being included in url
	}

	return `/${lowercaseAndHyphenSpecialChar(topicTitle)}/${lowercaseAndHyphenSpecialChar(
		guideTitle,
	)}/${lowercaseAndHyphenSpecialChar(MultipageGuideList[guideTitle][stepNumber])}`;
};

// @TODO: to update after all URLs are updated.
// Maybe after the home page is done, there's no need for this variable anymore.
// Probably no need to have description and image anymore, cos no more /guides page. Also means can delete the image or the guidesTitles?
export const Topics: { [key in TopicType]: ITopic } = {
	[TopicType.WhenDeathHappens]: {
		type: TopicType.WhenDeathHappens,
		// tslint:disable-next-line: quotemark
		description:
			"How to report a death, arrange a funeral, manage the deceased’s assets, get counselling and support.",
		image: "/images/guides/guides-reporting-a-death.png",
	},
	[TopicType.endOfLifePlanning]: {
		type: TopicType.endOfLifePlanning,
		// tslint:disable-next-line: quotemark
		description:
			"How to report a death, arrange a funeral, manage the deceased’s assets, get counselling and support.",
		image: "/images/guides/guides-reporting-a-death.png",
	},
};

export const baseEndOfLifeUrl = "/end-of-life-planning";
export const endOfLifeUrl = {
	advanceCarePlanning: baseEndOfLifeUrl + "/advance-care-planning",
	healthCareSpokesperson: baseEndOfLifeUrl + "/advance-care-planning/choose-your-healthcare-spokesperson",
	findAnAcpFacilitator: baseEndOfLifeUrl + "/advance-care-planning/find-an-acp-facilitator",
	makeALastingPowerOfAttorney: baseEndOfLifeUrl + "/make-a-lasting-power-of-attorney",
	whyPlanAhead: baseEndOfLifeUrl + "/why-plan-ahead",
};
