import crypto from "crypto";
export const encrypt = (text: string, secret: string) => {
	try {
		const iv = crypto.randomBytes(16);
		const key = crypto.createHash("sha256").update(secret).digest("base64").substr(0, 32);
		const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);

		let encrypted = cipher.update(text);
		encrypted = Buffer.concat([encrypted, cipher.final()]);
		return iv.toString("hex") + ":" + encrypted.toString("hex");
	} catch (error) {
		return undefined;
	}
};

export const decrypt = (encryptedText: string, secret: string) => {
	try {
		const textParts = encryptedText.split(":");
		const ivHex = textParts.shift();
		if (ivHex) {
			const iv = Buffer.from(ivHex, "hex");

			const encryptedData = Buffer.from(textParts.join(":"), "hex");
			const key = crypto.createHash("sha256").update(secret).digest("base64").substr(0, 32);
			const decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);

			const decrypted = decipher.update(encryptedData);
			const decryptedText = Buffer.concat([decrypted, decipher.final()]);
			return decryptedText.toString();
		}
		return undefined;
	} catch (error) {
		return undefined;
	}
};

export const encryptV2 = (text: string, keyBase64: string): string => {
	// Decode the base64 key
	const keyBuffer = Buffer.from(keyBase64, "base64");

	// Generate a random 16-byte initialization vector
	const iv = crypto.randomBytes(16);

	// Initialize the cipher
	const cipher = crypto.createCipheriv("aes-256-cbc", keyBuffer, iv);

	// Encrypt the data
	let encrypted = cipher.update(text, "utf8", "base64");
	encrypted += cipher.final("base64");

	// Prepend the IV (in Base64) to the encrypted data and return
	return iv.toString("base64") + encrypted;
};

export const decryptV2 = (text: string, keyBase64: string): string => {
	// Decode the base64 key
	const keyBuffer = Buffer.from(keyBase64, "base64");

	// Extract the IV from the first 24 characters of the input text and decode from base64
	const ivBase64 = text.substring(0, 24);
	const iv = Buffer.from(ivBase64, "base64");

	// The encrypted data is the rest of the input text, decoded from base64
	const encryptedData = Buffer.from(text.substring(24), "base64");

	// Initialize the decipher
	const decipher = crypto.createDecipheriv("aes-256-cbc", keyBuffer, iv);

	// Decrypt the data
	let decrypted = decipher.update(encryptedData, undefined, "utf8");
	decrypted += decipher.final("utf8");

	return decrypted;
};
