import { IsNumber } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { Transform } from "class-transformer";
import { toNumber } from "../../transformers";

export const httpVerb = HTTP_VERBS.PATCH;
export const url = "/api/users/notification/unread/:id";

export class Params {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
}

export class Response {}
