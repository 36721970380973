import Modal, { IMinimalModal } from "app/components/basic/Modal";
import Snackbar from "app/components/basic/Snackbar";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

type TSnackbarType = "success" | "failure";
type ISetInlineSnackbar = (message: string | undefined, snackbarType?: TSnackbarType) => void;

interface IAlertFunctions {
	toast: (type: TSnackbarType, content: any, timeout?: number | "never") => void;
	alertModal: (modalProps: IMinimalModal) => void;
	dismissAlertModal: () => void;
	setInlineSnackbarMessage: ISetInlineSnackbar;
	inlineSnackbar?: JSX.Element;
}

const VoidFunction = (): void => {};

const AlertFunctions = createContext<IAlertFunctions>({
	toast: VoidFunction,
	alertModal: VoidFunction,
	setInlineSnackbarMessage: VoidFunction,
	dismissAlertModal: VoidFunction,
	inlineSnackbar: undefined,
});

export const useAlert = (): IAlertFunctions => useContext(AlertFunctions);

export const AlertFunctionsProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [snackbarList, updateSnackbarList] = useState<JSX.Element[]>([]);
	const [inlineSnackbar, setInlineSnackbar] = useState<JSX.Element>();
	const [modal, setModal] = useState<JSX.Element>();

	const dismissAlertModal = useCallback(() => setModal(undefined), []);

	useEffect(() => {
		void setInlineSnackbar(undefined);
		void setModal(undefined);
	}, [children]);

	const toast = useCallback((type: TSnackbarType, content: any, timeout?: number | "never"): void => {
		const newMessage = <Snackbar type={type} key={new Date().toISOString()} message={content} timeout={timeout} />;
		updateSnackbarList((prv) => {
			const temp = [...prv];
			temp.unshift(newMessage);
			// set to top 5 messages
			return temp.slice(0, 5);
		});
	}, []);

	const alertModal = useCallback(
		(modalProps: IMinimalModal): void => {
			const onClose = (): void => {
				modalProps.closeCallback();
				dismissAlertModal();
			};
			setModal(<Modal {...modalProps} type="mylegacyModalMessage" isOpen={true} closeCallback={onClose} />);
		},
		[dismissAlertModal],
	);

	const setInlineSnackbarMessage = useCallback(
		(message: string | undefined, snackbarType: TSnackbarType = "failure"): void => {
			let inlineSnackbar;
			if (message) {
				inlineSnackbar = <Snackbar message={message} type={snackbarType} isInline={true} />;
			}
			setInlineSnackbar(inlineSnackbar);
		},
		[],
	);

	return (
		<AlertFunctions.Provider
			value={{ toast, inlineSnackbar, setInlineSnackbarMessage, alertModal, dismissAlertModal }}
		>
			{children}
			{modal}
			<div className="snackbar__list">{snackbarList}</div>
		</AlertFunctions.Provider>
	);
};
