import "./ActionBox.scss";

interface IProps {
	className?: string;
}
const ActionBoxLoading = (props: IProps): JSX.Element => {
	return (
		<>
			<div className={`actionbox-container actionbox-container__single-row ${props.className}`}>
				<div className="actionbox-content actionbox-content__loading" />
				<div className="actionbox-button actionbox-button__loading" />
			</div>
		</>
	);
};

export default ActionBoxLoading;
