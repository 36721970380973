import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { Toast, ToastProps } from "@lifesg/react-design-system/toast";
import { v4 as uuid } from "uuid";
import { useRouter } from "next/router";

// Define custom props for toast with an additional ID
export interface ToastPropsCustom extends ToastProps {
	id: string;
}

// Define the context type for alert functions
interface IAlertFunctionsV2 {
	toast: (toastProps: ToastPropsCustom) => void;
	removeToast: (id: string) => JSX.Element[];
}

// Create a context for alert functions with a default empty implementation
const AlertFunctionsV2 = createContext<IAlertFunctionsV2>({
	toast: (): void => {},
	removeToast: (): JSX.Element[] => [],
});

// Custom hook to use alert functions from context
export const useAlert = (): IAlertFunctionsV2 => useContext(AlertFunctionsV2);

export const generateIdForToast = (): string => {
	return uuid();
};

// Provider component to manage toast notifications
export const AlertFunctionsProviderV2 = ({ children }: { children: ReactNode }): JSX.Element => {
	const router = useRouter();

	// State to manage the list of toasts
	const [toastList, setToastList] = useState<JSX.Element[]>([]);

	// Callback to remove a toast from the list
	const removeToast = useCallback((id: string) => {
		const newToastList = toastList.filter((toast) => (toast.key as string) !== id);
		setToastList(newToastList);
		return newToastList;
	}, []);

	useEffect(() => {
		// Reset toast list when route change
		const handleRouteChange = (url) => {
			setToastList([]);
		};

		router.events.on("routeChangeStart", handleRouteChange);

		// Cleanup the event listener on component unmount
		return () => {
			router.events.off("routeChangeStart", handleRouteChange);
		};
	}, [router.events]);

	// Create a toast
	const createToast = useCallback((props: ToastPropsCustom) => {
		const newToast = (
			<div key={props.id} id={"id" + props.id}>
				<Toast
					{...props}
					fixed={!!props.fixed}
					onDismiss={() => {
						if (props.onDismiss) {
							props.onDismiss();
						}
					}}
					autoDismiss={props.autoDismiss ?? props.type !== "error"}
				/>
				<br />
			</div>
		);

		return newToast;
	}, []);

	// Function to update or add a toast to the list
	const updateOrAddToast = useCallback(
		(props: ToastPropsCustom) => {
			const { id } = props;

			const existingIndex = toastList.findIndex((toast) => toast?.key === id);

			if (existingIndex !== -1) {
				const existingToast = toastList[existingIndex];
				const existingProps = existingToast?.props?.children[0]?.props as ToastPropsCustom;

				if (existingProps.type !== props.type && existingProps.label !== props.label) {
					// Remove current toast first before we replace with new toast for same toast id
					const toastListEle = document.getElementsByClassName("toast-list")[0];
					if (toastListEle) {
						const toastEle = toastListEle.querySelector(`#id${id}`);

						if (toastEle) {
							const button = toastEle.querySelector("button");

							if (button) {
								button.click();
								// Programmatically clicks the button
							}
						}
					}

					// Remove replace toast after sec. We wait because existing toast will take some time to vanish
					setTimeout(() => {
						const newList = removeToast(id);
						setToastList([createToast(props), ...newList]);
					}, 1000);
				} else {
					const newList = removeToast(id);
					setToastList([createToast(props), ...newList]);
				}
			} else {
				// Add new toast
				setToastList((list) => {
					return [createToast(props), ...list];
				});
			}
		},
		[createToast, toastList],
	);

	// Render the provider with the updated toast list and children
	return (
		<AlertFunctionsV2.Provider value={{ toast: updateOrAddToast, removeToast }}>
			{children}
			<div className="toast-list">{toastList}</div>
		</AlertFunctionsV2.Provider>
	);
};
