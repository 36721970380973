import { IDropdownItemTemplate } from "app/components/basic/Dropdown";
import { IFormInputBaseProps, withBaseInput } from "./Form.Input";
import React from "react";
import { AddonProps } from "@lifesg/react-design-system/input-group";
import { Form as DSForm } from "@lifesg/react-design-system/form";
import { FormInputGroupProps } from "@lifesg/react-design-system/form/types";

// we should have inheritance of these props for the documentation
export interface IFormPhoneNumberProps extends IFormInputBaseProps {
	/** Field name in IForm schema for the country codes. Leave empty for local number (+65) */
	countryCodeField?: string;
}

const PhoneComponent = (props: FormInputGroupProps<unknown, unknown> & { origProps: IFormPhoneNumberProps }) => {
	const { countryCodeField, form, readOnly, disabled } = props.origProps;
	const countryCodeFormField = countryCodeField ? form.getField(countryCodeField) : undefined;
	const countryCodeKey = countryCodeFormField?.key ?? "";

	const onSelectCountry = (item: IDropdownItemTemplate, value: string | IDropdownItemTemplate) => {
		form.updateFieldValue(countryCodeKey, value as string);
	};

	const countryCodeValue = countryCodeFormField?.value;
	const countryCodeItem = countryCodeValue
		? countryCodeFormField?.dropdownItems?.find((item) => item.value === countryCodeValue)
		: undefined;

	let className = `${props.className} form-field__input--prefix ${countryCodeValue ? "selected" : ""}`;

	// country code dropdown
	let addon;
	if (!countryCodeFormField || readOnly || disabled) {
		// dropdown becomes just a prefix label, if not interactive
		// missing `countryCodeFormField` -> local phone number
		addon = {
			type: "label",
			attributes: { value: (countryCodeFormField?.value as string) ?? "+65" },
		} as AddonProps<string, string>;
	} else {
		addon = {
			type: "list",
			attributes: {
				listExtractor: (item: IDropdownItemTemplate) => item.label,
				valueExtractor: (item: IDropdownItemTemplate) => item.value as string,
				displayValueExtractor: (item: IDropdownItemTemplate) => item.value as string,
				onSelectOption: onSelectCountry,
				placeholder: "Select",
				value: countryCodeItem,
				options: countryCodeFormField.dropdownItems,
			},
		} as AddonProps<IDropdownItemTemplate, string>;
		className += " selection-group";
	}

	return (
		<DSForm.InputGroup {...props} inputMode="tel" type={"tel"} addon={addon} spacing={4} className={className} />
	);
};

const FormPhoneNumber = withBaseInput<IFormPhoneNumberProps>(PhoneComponent);

export default FormPhoneNumber;
