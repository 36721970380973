import { registerDecorator, ValidationOptions, ValidationArguments } from "class-validator";

// tslint:disable-next-line: ban-types
export function IsAcpObjectItem(
	maxLength?: Object,
	validationOptions?: ValidationOptions,
): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "IsAcpObjectItem",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: Object): boolean {
					const { isValid } = validateAcpObject(value, maxLength);
					return isValid;
				},
				defaultMessage(args: ValidationArguments): string {
					const value = args.object;
					const { errorMessage } = validateAcpObject(value, maxLength);
					return errorMessage;
				},
			},
		});
	};
}

const enum ERROR_MESSAGES {
	TYPE = "_item must be a string",
	MAX_LENGTH = "_item value must be equal or less than _maxLength char.",
	ARRAY_MAX_LENGTH = "_item must contain equal or less than _maxLength items.",
}

const validateAcpObject = (value: Object, maxLengthObj?: Object): { isValid: boolean; errorMessage: string } => {
	const objectMLKey = maxLengthObj ? Object.keys(maxLengthObj) : [];

	if (typeof value !== "object") return { isValid: false, errorMessage: "Input value must be an object" };
	for (const key in value) {
		const objItem = value[key];
		if (Array.isArray(objItem)) {
			if (objectMLKey.includes(key) && objItem.length > maxLengthObj?.[key]) {
				return {
					isValid: false,
					errorMessage: ERROR_MESSAGES.ARRAY_MAX_LENGTH.replace("_item", key).replace(
						"_maxLength",
						`${maxLengthObj?.[key]}`,
					),
				};
			} else {
				for (const item of objItem) {
					if (typeof item !== "string") {
						return { isValid: false, errorMessage: ERROR_MESSAGES.TYPE.replace("_item", key) };
					}
				}
			}
		} else if (typeof objItem === "object") {
			const dataResult = validateAcpObject(objItem, maxLengthObj);
			if (!dataResult.isValid) {
				return dataResult;
			}
		} else if (typeof objItem !== "string") {
			return { isValid: false, errorMessage: ERROR_MESSAGES.TYPE.replace("_item", key) };
		} else if (objectMLKey.includes(key) && objItem.length > maxLengthObj?.[key]) {
			return {
				isValid: false,
				errorMessage: ERROR_MESSAGES.MAX_LENGTH.replace("_item", key).replace(
					"_maxLength",
					`${maxLengthObj?.[key]}`,
				),
			};
		}
	}
	return { isValid: true, errorMessage: "" };
};
