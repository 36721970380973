import { FormLabelAddonProps } from "@lifesg/react-design-system/form/types";
import RadioBox, { IRadioBoxItem } from "app/components/basic/Radio.Box";
import { FormLabel, FormLabelProps } from "app/hooks/useForm/components/Form.Label";
import { useEffect, useState } from "react";
import { Grid, SemanticWIDTHSNUMBER } from "semantic-ui-react";
import { IFormFunctions } from "../Form";
import { FormType } from "../types";

export interface IFormRadioBoxProps extends Omit<FormLabelProps, "children"> {
	field: string;
	form: IFormFunctions;
	columns?: 1 | 2;
	beforeSelectItem?: (...args: any[]) => void;
	afterSelectItem?: (...args: any[]) => void;
	disabled?: boolean;
	className?: string;
	alert?: string;
	/** width of the input in mobile */
	mobileWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in computer */
	computerWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in tablet */
	tabletWidth?: SemanticWIDTHSNUMBER;
	addonText?: string;
	additionalValidations?: () => Promise<boolean>;
	/**
	 * Number of lines of a label to display before truncation
	 * Used for very long names
	 */
	labelLines?: number;
	/** child component that shares same error as parent */
	children?: JSX.Element | JSX.Element[];
	formType?: FormType;
}

const FormRadioBox = ({ children, ...props }: IFormRadioBoxProps): JSX.Element => {
	const [performAfterAction, setPerformAfterAction] = useState(false);
	useEffect(() => {
		if (performAfterAction && props.afterSelectItem) {
			props.afterSelectItem();
			setPerformAfterAction(false);
		}
	}, [performAfterAction]);

	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	let id = props.field + "__loading";

	if (!props.form.loading) {
		id = key;
	}

	const value = formField ? formField.value : "";
	const radioItems = formField ? formField.radioBoxItems : [];
	const isSubmitting = formField ? props.form.submittingFields[formField.key] : false;

	let errorMessage = formField ? props.form.getError(props.field) : "";
	let inputWithError = "";
	if (errorMessage && radioItems?.find((item: IRadioBoxItem) => item.label === errorMessage)) {
		inputWithError = errorMessage;
		errorMessage = "";
	}

	const selectItemHandler = (value: string) => {
		if (props.beforeSelectItem) {
			props.beforeSelectItem(value);
		}
		props.form.updateFieldValue(key, value);
		if (props.afterSelectItem) {
			setPerformAfterAction(true);
		}
	};

	const popupContent: FormLabelAddonProps = {
		"data-testid": `${id}__addon`,
		content: props.addonText || "",
		type: props.addonText ? "popover" : undefined,
	};

	const inputWidth = props.computerWidth ?? 12;

	useEffect(() => {
		if (props.additionalValidations) {
			void props.additionalValidations();
		}
	}, [value]);

	return (
		<Grid className={props.className?.includes("storybook") ? "form-storybook" : "form"}>
			<Grid.Row>
				<Grid.Column
					className="no-margin no-padding"
					mobile={props.mobileWidth ?? inputWidth}
					tablet={props.tabletWidth ?? inputWidth}
					computer={inputWidth}
				>
					<FormLabel
						id={id}
						title={props.title}
						subtitle={props.subtitle}
						popupContent={popupContent}
						fullWidthLabel={props.fullWidthLabel}
						labelClassName={props.labelClassName}
						errorMessage={errorMessage}
						errorMessageTestId={`${id}__error`}
						formType={props.formType}
					>
						<div className={`form-field ${props.className ?? ""}`}>
							<RadioBox
								{...props}
								form={props.form}
								field={props.field}
								items={radioItems || []}
								updateRadioInputError={props.form.updateFieldError}
								inputWithError={inputWithError}
								selectedValue={value as string}
								selectItem={selectItemHandler}
								name={id}
								disabled={props.disabled || isSubmitting}
								errorMessage={errorMessage}
							/>
							{children}
						</div>
					</FormLabel>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default FormRadioBox;
