import Button from "app/components/basic/Button";
import "./ActionBox.scss";

interface IProps {
	title: string;
	iconSrc: string;
	altText: string;
	message: string;
	disabled?: boolean;
	button: {
		name: string;
		action: () => void;
	};
}

const ActionBox = (props: IProps): JSX.Element => {
	let actionBoxClassName = "actionbox-container";

	if (props.disabled) {
		actionBoxClassName += " disabled";
	}

	return (
		<div className={actionBoxClassName}>
			<div className="actionbox-content">
				<div className="actionbox-title">
					<img src={props.iconSrc} className="actionbox-icon" alt={props.altText} />
					<h5>{props.title}</h5>
				</div>
				<span> {props.message} </span>
			</div>
			<div className="actionbox-button">
				<Button
					id="actionbox-button"
					name={props.button.name}
					type="secondary"
					onClick={props.button.action}
					disabled={props.disabled}
				/>
			</div>
		</div>
	);
};

export default ActionBox;
