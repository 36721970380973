import { createContext } from "react";
import { ConsolidatedResponses } from "./schemas";

export type ErrorType = "NotFound" | "ApiError" | null;

export type DeathCertFormData = {
	formResponses: ConsolidatedResponses;
	jumpToPersonalDetails: (responses?: ConsolidatedResponses) => void;
	jumpToRelationshipForm: (responses?: ConsolidatedResponses) => void;
	isEditingForm: boolean;
	goBack: (responses?: ConsolidatedResponses) => void;
	goNext: (responses?: ConsolidatedResponses) => void;
	error: ErrorType;
	stepIndex: number;
};

export const DeathCertFormContext = createContext<DeathCertFormData>({
	formResponses: {},
	jumpToPersonalDetails: () => {},
	jumpToRelationshipForm: () => {},
	isEditingForm: false,
	goBack: () => {},
	goNext: () => {},
	error: null,
	stepIndex: 0,
});
