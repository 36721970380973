import { IsString } from "class-validator";
import { HTTP_VERBS } from "../../enums";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/download/:s3UniqueName";
export class Params {
	@IsString()
	public s3UniqueName!: string;
}
export class Response {}
