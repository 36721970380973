import PageHeader from "app/components/composites/PageHeader";
import { ReactNode } from "react";

interface IProps {
	className?: string;
	title?: string;
	children: ReactNode;
}

const PublicPage = (props: IProps): JSX.Element => {
	const title = props.title ? `${props.title} -  My Legacy` : "My Legacy";

	return (
		<div className={`page__public ${props.className ?? ""}`}>
			<PageHeader title={title} />
			{props.children}
		</div>
	);
};

export default PublicPage;
