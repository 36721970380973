import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import { type IDateValue } from "../api/interface";

// tslint:disable-next-line: ban-types
export function IsDate(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isDate",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: IDateValue): boolean {
					// add additional check for numbers. if user passes in true/false, changing it to int becomes 0/1
					const dayCheck = /^[0-9]{1,2}$/;
					const monthCheck = /^[0-9]{1,2}$/;
					const yearCheck = /^[0-9]{4}$/;
					if (!dayCheck.test(value.day) || !monthCheck.test(value.month) || !yearCheck.test(value.year)) {
						return false;
					}

					const date = new Date(+value.year, +value.month - 1, +value.day);

					// need to check day, month and year as some browsers may overflow given date to next month or year
					return (
						date.getDate() === +value.day &&
						date.getMonth() === +value.month - 1 &&
						date.getFullYear() === +value.year
					);
				},
				defaultMessage(args: ValidationArguments): string {
					return `"${JSON.stringify(args.property)}" must be a valid date`;
				},
			},
		});
	};
}
