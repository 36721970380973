import { IsNRIC } from "app/common/validators";
import { IsString } from "class-validator";
import { DeathCertDownloadRequest } from "server/models/deathCert";

export class GetDeathCertDetailsFromDownloadResponseDO {
	constructor(props: Readonly<DeathCertDownloadRequest>) {
		this.uinfin = props?.deathRecord?.identificationNumber;
		this.certificationNumber = props?.deathRecord?.certificateNumber;
	}

	@IsNRIC()
	public uinfin!: string;

	@IsString()
	public certificationNumber!: string;
}
