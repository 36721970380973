import { Transform } from "class-transformer";
import { IsEnum, IsNumber } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";
import { TrustedConnectionStatusTypes } from "../enums";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/users/invitations/:invitationId/decline";
export class Params {
	@IsNumber()
	@Transform(toNumber)
	public invitationId!: number;
}
export class Response {
	@IsEnum(TrustedConnectionStatusTypes)
	public status!: string;
}
