import { LocalDate } from "@js-joda/core";
import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

// tslint:disable-next-line: ban-types
export function IsDateString(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isDateString",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string): boolean {
					return validateDateString(value);
				},
				defaultMessage(args: ValidationArguments): string {
					return `${args.property} must be a valid date`;
				},
			},
		});
	};
}

/** Validates that dateString is of `YYYY-MM-DD` format */
export const validateDateString = (value: string): boolean => {
	try {
		LocalDate.parse(value);
		return true;
	} catch {
		return false;
	}
};
