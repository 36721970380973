import styled from "styled-components";

export const DistributionOfResiduaryEstate = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.6875rem 0;
	row-gap: 1rem;
`;

export const UserAllocatedDistribution = styled.div`
	display: flex;
`;
