import { DateTimeFormatter, LocalDate, ZoneId, ZonedDateTime, LocalTime } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";

export const MYSQL_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZZ";
export const MYSQL_DATE_TIME_QUERY_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const ACP_SMS_TIME_FORMAT = "dd MMM yyyy";

// Taken from class-validator: https://github.com/typestack/class-validator/blob/master/src/validation/Validator.ts
export const ISO_DATE_TIME_STRING_REGEX =
	/^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?(?:Z|\+[0-2]\d(?:\:[0-5]\d)?)?$/;

export const ISO_DATE_ONLY_FORMAT = "YYYY-MM-DD";
export const ISO_DATE_ONLY_REGEX = /^\d{4}-[01]\d-[0-3]\d$/;

export const isUserAgeBelowThreshold = (birthday: Date, age = 21): boolean => {
	const dateOfBirth = LocalDate.of(birthday.getFullYear(), birthday.getMonth() + 1, birthday.getDate());
	const legalDate = dateOfBirth.plusYears(age);
	return LocalDate.now().isBefore(legalDate);
};
/**
 * Converts date to string
 * @param date Date
 * @returns date string in the `YYYY-MM-DD` format
 */
export const dateToString = (date: Date): string => {
	return date.toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
};

export const zonedDateTimeFormatter = (datetime: ZonedDateTime, dateFormatPattern: string): string => {
	const dateFormat = DateTimeFormatter.ofPattern(dateFormatPattern);
	const formattedDate = datetime
		?.withZoneSameInstant(ZoneId.of("Asia/Singapore"))
		.format(dateFormat.withLocale(Locale.US));
	return formattedDate;
};

export const isZonedDateTimeToday = (zonedDateTime: ZonedDateTime): boolean => {
	const now = ZonedDateTime.now();
	// Compare the date components of both ZonedDateTime objects
	return zonedDateTime.toLocalDate().isEqual(now.toLocalDate());
};

export const lastSavedTimeText = (dateTime: ZonedDateTime): string => {
	const newDateTime = convertTypedZonedDateTimeStringToZonedDateTimeInstance(dateTime);

	const timeText = `Last saved at ${
		isZonedDateTimeToday(newDateTime)
			? zonedDateTimeFormatter(newDateTime, "h:mma").toLocaleLowerCase()
			: zonedDateTimeFormatter(newDateTime, "d MMM")
	}`;

	return timeText;
};

export const compareZonedDateTime = (timeA: ZonedDateTime, timeB: ZonedDateTime): number => {
	const newDateTimeA = convertTypedZonedDateTimeStringToZonedDateTimeInstance(timeA);
	const newDateTimeB = convertTypedZonedDateTimeStringToZonedDateTimeInstance(timeB);
	return newDateTimeA.compareTo(newDateTimeB);
};

export const convertTypedZonedDateTimeStringToZonedDateTimeInstance = (
	dateTime: ZonedDateTime | string,
): ZonedDateTime => {
	if (typeof dateTime === "string") {
		return ZonedDateTime.parse(dateTime);
	}
	return dateTime;
};

// Function to convert date string to formatted date string
export const convertDateStringToFormattedString = (
	dateString: string,
	time = LocalTime.MIDNIGHT,
	timeZone = "Asia/Singapore",
	pattern = "d MMMM yyyy",
): string => {
	// Parse the string as LocalDate
	const localDate = LocalDate.parse(dateString);

	// Define the time zone
	const zoneId = ZoneId.of(timeZone);

	// Create a ZonedDateTime instance
	const zonedDateTime = ZonedDateTime.of(localDate, time, zoneId);

	return zonedDateTimeFormatter(zonedDateTime, pattern);
};

export const convertDateStringToCustomPattern = (date: string, pattern: string): string => {
	const dateFormated = convertTypedZonedDateTimeStringToZonedDateTimeInstance(new Date(date).toISOString());
	return zonedDateTimeFormatter(dateFormated, pattern);
};
