import { IsNumber } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { InboxNotificationDO } from "../domainObjects";
import { toNumber } from "../../transformers";
import { Transform } from "class-transformer";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/notification/:id";

export class Params {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
}

export class Response extends InboxNotificationDO {}
