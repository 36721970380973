import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import moment, { isMoment } from "moment";

// tslint:disable-next-line: ban-types
export function IsMoment(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isMoment",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: moment.Moment | string): boolean {
					return isMoment(value);
				},
				defaultMessage(args: ValidationArguments): string {
					return `"${args.value}" must be a valid moment object."`;
				},
			},
		});
	};
}
