import moment from "moment";
import "./PersonComboBox.scss";

export interface IPersonItem {
	nickname: string;
	name?: string;
	nric: string;
	shared?: boolean;
	invitedOn?: string | moment.Moment;
	status?: string;
}

const PersonItem = (props: IPersonItem): JSX.Element => (
	<div className="person-combobox-item">
		<div className="person-combobox-item-name-group">
			<span className="person-combobox-item-nickname">{props.nickname}</span>
			{props.shared && <span className="person-combobox-item-shared ignore-opacity">Shared</span>}
		</div>
		{props.name && <span className="person-combobox-item-nricname">{props.name}</span>}
		<span className="person-combobox-item-nric">{props.nric}</span>
	</div>
);

export default PersonItem;
