import { Transform } from "class-transformer";
import { IsNumber, IsOptional, IsString, MaxLength } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";

export const httpVerb = HTTP_VERBS.PATCH;
export const url = "/api/users/trustedpersons/:id";
export class Params {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
}
export class Body {
	@IsString()
	@MaxLength(120)
	public nickname!: string;
}
export class Response {
	@IsString()
	@IsOptional()
	public nickname?: string;
}
