import { Form } from "@lifesg/react-design-system/form";
import { FormInputGroupProps } from "@lifesg/react-design-system/form/types";
import { IFormInputProps, withBaseInput } from "app/hooks/useForm/components/Form.Input";
import { Container, ErrorMessage, InputGroupContainer, Title } from "./styles";

interface IAttributes {
	value: string;
	position: "left" | "right";
}

interface IDistributionInput extends IFormInputProps {
	label: string;
	attribute?: IAttributes;
	width?: string;
	minWidth?: string;
	errorMessage?: string;
	truncate?: boolean;
	lines?: number;
	dataTestId?: string;
}

const DistributionInput = (
	props: FormInputGroupProps<unknown, unknown> & { origProps: IDistributionInput },
): JSX.Element => {
	const { label, attribute, width, minWidth, truncate, lines, dataTestId } = props.origProps;

	return (
		<>
			<Container>
				<Title data-testid={`${dataTestId || "distribution-input"}-label`} truncate={truncate} lines={lines}>
					{label}
				</Title>
				<InputGroupContainer style={{ minWidth: minWidth, width: width }}>
					<Form.InputGroup
						data-testid={`${dataTestId || "distribution-input"}-input`}
						className={props.errorMessage ? "distribution-input--error" : ""}
						{...props}
						errorMessage={undefined}
						addon={{
							attributes: {
								value: attribute?.value,
							},
							position: attribute?.position,
						}}
					/>
				</InputGroupContainer>
			</Container>
			{props.errorMessage && (
				<ErrorMessage data-testid={`${dataTestId || "distribution-input"}-error-message`}>
					{props.errorMessage}
				</ErrorMessage>
			)}
		</>
	);
};

const FormDistributionInput = withBaseInput<IDistributionInput>(DistributionInput);

export default FormDistributionInput;
