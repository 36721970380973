import { FormLabelAddonProps } from "@lifesg/react-design-system/form/types";
import Checkbox from "app/components/basic/Checkbox";
import { FormLabel, FormLabelProps } from "app/hooks/useForm/components/Form.Label";
import { useEffect, useState } from "react";
import { Grid, SemanticWIDTHSNUMBER } from "semantic-ui-react";
import { IFormFunctions } from "../Form";

export interface IFormRadioProps extends Omit<FormLabelProps, "children"> {
	field: string;
	form: IFormFunctions;
	title?: string | JSX.Element;
	hideTitle?: boolean;
	subtitle?: string | JSX.Element;
	addonText?: string;
	horizontal?: boolean;
	className?: string;
	beforeSelectItem?: (...args: any[]) => void;
	afterSelectItem?: (...args: any[]) => void;
	disabled?: boolean;
	/** width of the input in mobile */
	mobileWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in computer */
	computerWidth?: SemanticWIDTHSNUMBER;
	/** width of the input in tablet */
	tabletWidth?: SemanticWIDTHSNUMBER;
	/** additonal validations on value change */
	additionalValidations?: () => Promise<boolean>;
	/**
	 * Number of lines of a label to display before truncation
	 * Used for very long names
	 */
	labelLines?: number;

	/** child component that shares same error as parent */
	children?: JSX.Element | JSX.Element[];
}

const FormCheckbox = ({ children, ...props }: IFormRadioProps): JSX.Element => {
	const [performAfterAction, setPerformAfterAction] = useState(false);

	useEffect(() => {
		if (performAfterAction) {
			if (props.afterSelectItem) {
				props.afterSelectItem();
			}
			if (props.additionalValidations) {
				props.additionalValidations();
			}
			setPerformAfterAction(false);
		}
	}, [performAfterAction]);

	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	let id = props.field + "__loading";
	let className = `form-field form-field-${key}`;

	if (props.className) {
		className += " " + props.className;
	}

	if (!props.form.loading) {
		id = key;
	}

	const value = formField ? formField.value : "";
	const errorMessage = formField ? props.form.getError(props.field) : "";
	const checkboxItems = formField ? formField.checkboxItems : [];
	const isSubmitting = formField ? props.form.submittingFields[formField.key] : false;
	const disabled = props.disabled ?? isSubmitting;

	const selectItemHandler = async (value: string[]): Promise<void> => {
		const sortedValue = value.sort();
		if (props.beforeSelectItem) {
			await props.beforeSelectItem();
		}
		await props.form.updateFieldValue(key, sortedValue);
		if (props.afterSelectItem || props.additionalValidations) {
			await setPerformAfterAction(true);
		}
	};

	const popupContent: FormLabelAddonProps = {
		"data-testid": `${id}__addon`,
		content: props.addonText || "",
		type: props.addonText ? "popover" : undefined,
	};

	const inputWidth = props.computerWidth ?? 12;

	return (
		<Grid className={props.className?.includes("storybook") ? "form-storybook" : "form"}>
			<Grid.Row>
				<Grid.Column
					className="no-margin no-padding"
					mobile={props.mobileWidth ?? inputWidth}
					tablet={props.tabletWidth ?? inputWidth}
					computer={inputWidth}
				>
					<FormLabel
						id={id}
						title={props.title}
						subtitle={props.subtitle}
						popupContent={popupContent}
						fullWidthLabel={props.fullWidthLabel}
						labelClassName={props.labelClassName}
						errorMessage={errorMessage}
						errorMessageTestId={`${id}__error`}
						formType={props.formType}
					>
						<div className={className}>
							<Checkbox
								{...props}
								name={id}
								selectedValues={value as string[]}
								updateSelectedValues={selectItemHandler}
								errorMessage={errorMessage}
								disabled={disabled}
								items={checkboxItems || []}
							/>
							{children}
						</div>
					</FormLabel>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default FormCheckbox;
