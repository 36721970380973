// For Web API ArrayBuffer

// https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string
export const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
	let binary = "";
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
};

// For nodejs Buffer

export const base64ToBuffer = (base64: string): Buffer => {
	return Buffer.from(base64, "base64");
};

// For making base64 url safe

/**
 * Makes a base64 string safe to use in URLs.
 */
export const base64ToUrlSafe = (base64: string): string => {
	return base64.replace(/\//g, "_").replace(/\+/g, "-").replace(/=/g, ".");
};

/**
 * Reverts changes to the base64 string.
 */
export const urlSafeToBase64 = (urlSafeBase64: string): string => {
	return urlSafeBase64.replace(/_/g, "/").replace(/-/g, "+").replace(/\./g, "=");
};
