import { IsDateString } from "app/common/validators/dateStringValidator";
import { Transform, Type } from "class-transformer";
import { ArrayMaxSize, ArrayMinSize, IsEnum, IsString, MaxLength, ValidateNested } from "class-validator";
import { escapeHTML, toUppercase } from "../../transformers";
import { IsNumber, IsEmail } from "@wog/mol-lib-api-contract/utils/validators";
import { CarParkType, ParkingSystem } from "@wog/mol-lib-api-contract/oneservice";
import { IsDate } from "app/common/validators";
import { EMAIL_MAX_CHARS } from "app/common/email";

export class VehicleDetailsDO {
	@IsString()
	@Transform(toUppercase)
	@Transform(escapeHTML)
	@MaxLength(12)
	public carplate!: string;

	@IsString()
	@Transform(toUppercase)
	@Transform(escapeHTML)
	@MaxLength(10)
	public iu!: string;
}

export class ApplicationDO {
	@IsString()
	@Transform(toUppercase)
	@Transform(escapeHTML)
	@MaxLength(20)
	public referenceId!: string;

	@ArrayMinSize(0, { always: true })
	@ArrayMaxSize(5, { always: true })
	@ValidateNested({ each: true })
	@Type(() => VehicleDetailsDO)
	public vehicleDetails!: VehicleDetailsDO[];

	constructor(id: string, vehicleDetails: VehicleDetailsDO[]) {
		this.referenceId = id;
		this.vehicleDetails = vehicleDetails;
	}
}

export class LocationDetailsDO {
	@IsString()
	public address!: string;

	@IsString()
	public blockNo!: string;

	@IsString()
	public building!: string;

	@IsString()
	public roadName!: string;

	@IsString()
	public postalCode!: string;

	@IsNumber()
	public lat!: number;

	@IsNumber()
	public lng!: number;

	@IsNumber()
	public x!: number;

	@IsNumber()
	public y!: number;

	constructor(locationDetailsDO: Readonly<LocationDetailsDO>) {
		Object.assign(this, locationDetailsDO);
	}
}

export class CarparkDetailsDO {
	@IsString()
	@MaxLength(5)
	@Transform(toUppercase)
	@Transform(escapeHTML)
	public number!: string;

	@IsString()
	public name!: string;

	@IsNumber()
	public distance!: number;

	@IsNumber()
	public lat!: number;

	@IsNumber()
	public lng!: number;

	@IsEnum(CarParkType)
	public type!: CarParkType;

	@IsEnum(ParkingSystem)
	public parkingSystem!: ParkingSystem;

	constructor(carparkDetailsDO: Readonly<CarparkDetailsDO>) {
		Object.assign(this, carparkDetailsDO);
	}
}

export class HdbParkingDO {
	@ValidateNested()
	@Type(() => CarparkDetailsDO)
	public carparkDetails!: CarparkDetailsDO;

	@ValidateNested()
	@Type(() => LocationDetailsDO)
	public locationDetails!: LocationDetailsDO;

	@IsDateString()
	@Transform(escapeHTML)
	public startDate!: string;

	@IsString()
	@MaxLength(60)
	@Transform(escapeHTML)
	public applicantName!: string;

	@IsString()
	@MaxLength(20)
	@Transform(toUppercase)
	@Transform(escapeHTML)
	public applicantUIN!: string;

	@IsEmail({ maxLength: EMAIL_MAX_CHARS })
	@Transform(escapeHTML)
	public applicantEmail!: string;

	@IsString()
	@MaxLength(7)
	@Transform(toUppercase)
	@Transform(escapeHTML)
	public certificateNumber!: string;

	@IsNumber({ min: 1, max: 5 })
	public numberOfVeh!: number;

	@ArrayMinSize(0, { always: true })
	@ArrayMaxSize(5, { always: true })
	@ValidateNested({ each: true })
	@Type(() => VehicleDetailsDO)
	public vehicleDetails!: VehicleDetailsDO[];

	constructor(hdbParkingDO: HdbParkingDO) {
		Object.assign(this, hdbParkingDO);
	}
}

export class GetHdbParkingResponseDO {
	@ArrayMinSize(0, { always: true })
	@ArrayMaxSize(5, { always: true })
	@ValidateNested({ each: true })
	@Type(() => VehicleDetailsDO)
	public vehicleDetails: VehicleDetailsDO[] = [];

	@IsString()
	public key: string;

	@IsDate()
	public startDate?: Date;

	constructor(vehicleDetails: VehicleDetailsDO[], key: string, startDate?: Date) {
		this.vehicleDetails = vehicleDetails;
		this.key = key;
		this.startDate = startDate;
	}
}

export enum ApplicationStatus {
	SUCCESS = "success",
	FAILURE = "failure",
}

export class SaveHdbParkingResponseDO {
	@IsString()
	@Transform(toUppercase)
	@Transform(escapeHTML)
	@MaxLength(20)
	public referenceId!: string;

	@IsEnum(ApplicationStatus)
	public status!: ApplicationStatus;
}

export class VehiclesItemDO {
	@IsString()
	@MaxLength(12)
	@Transform(escapeHTML)
	NUM_VHCL!: string;

	@IsString()
	@MaxLength(10)
	@Transform(escapeHTML)
	NUM_IU!: string;
}

export class BereavedParkingResponseDO {
	public NUM_BKNG!: string;

	public STATUS!: string;

	public ERROR_CODE!: number;

	public ERROR_MESSAGE!: string;
}
