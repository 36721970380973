import AppointPersonGroupV2 from "app/components/composites/AppointPerson/AppointPersonGroupV2";
import { AppointPersonEnum } from "app/components/composites/AppointPerson/common/types";
import React from "react";
import { FORM_FIELD_WITH_ERROR_CLASSNAME, IFormFunctions } from "../Form";
import { DoneeDO } from "app/common/api/lpaAcp/domainObjects";

interface IFormAppointPersonProps {
	id?: string;
	field: string;
	form: IFormFunctions;
	type: AppointPersonEnum;
	typeDescriptor?: string;
	max: number;
	title?: string;
	donees?: DoneeDO[];
	disabled?: boolean;
	loading?: boolean;
	userNric?: string;
}

const FormAppointPersonV2 = (props: IFormAppointPersonProps): JSX.Element => {
	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	const value = formField ? formField.value : [];

	const isSubmitting = formField ? props.form.submittingFields[formField.key] : false;
	const error = formField ? props.form.getError(props.field) : "";
	const className = `form-field-${key}`;

	if (props.loading || (!value && !formField)) {
		return <div />;
	}

	return (
		<div
			id={props.id}
			className={`${className} ${props.title ? "form-field" : ""}${
				error ? ` ${FORM_FIELD_WITH_ERROR_CLASSNAME}` : ""
			}`}
		>
			<AppointPersonGroupV2
				{...props}
				value={value as DoneeDO[]}
				disabled={props.disabled || isSubmitting}
				errorMessage={error}
			/>
		</div>
	);
};

export default FormAppointPersonV2;
