import { InvitationDO } from "app/common/api/trustedConnection/domainObjects/invitations";
import { SharedPlanAccessSetting } from "app/common/api/trustedConnection/domainObjects/sharedAccessSetting";
import { GetTrustedPersonDO, TrustedPersonDO } from "app/common/api/trustedConnection/domainObjects/trustedPersons";
import * as contracts from "app/common/api/trustedConnection/routes";
import API from "../client.contract";

const fetchTrustedPeople = async (): Promise<GetTrustedPersonDO[]> => {
	const response = await API.callV2<contracts.getAllTrustedPersons.Response>(contracts.getAllTrustedPersons);
	return response.trustedPersons;
};

const createTrustedPerson = async (
	newTrustedPerson: TrustedPersonDO,
): Promise<contracts.postTrustedPerson.Response> => {
	return await API.callV2<contracts.postTrustedPerson.Response>(contracts.postTrustedPerson, {
		body: newTrustedPerson,
	});
};

const updateTrustedPerson = async (invitationId: number, nickname: string): Promise<void> => {
	const params = new contracts.patchTrustedPerson.Params();
	params.id = invitationId;
	const body = new contracts.patchTrustedPerson.Body();
	body.nickname = nickname;
	await API.callV2<contracts.patchTrustedPerson.Response>(contracts.patchTrustedPerson, { params, body });
};

const listInvitations = async (): Promise<InvitationDO[]> => {
	const response = await API.callV2<contracts.getAllReceivedTrustedPersonInvitations.Response>(
		contracts.getAllReceivedTrustedPersonInvitations,
	);
	return response.invitations;
};

const getInvitationWithHash = async (hash: string): Promise<contracts.getHashedTrustedPersonInvitation.Response> => {
	const params = new contracts.getHashedTrustedPersonInvitation.Params();
	params.hashedId = hash;
	return await API.callV2<contracts.getHashedTrustedPersonInvitation.Response>(
		contracts.getHashedTrustedPersonInvitation,
		{ params },
	);
};

const deleteTrustedPerson = async (id: number): Promise<contracts.deleteTrustedPerson.Response> => {
	const params = new contracts.deleteTrustedPerson.Params();
	params.id = id;
	return await API.callV2<contracts.deleteTrustedPerson.Response>(contracts.deleteTrustedPerson, { params });
};

const getSharedAccessCollection = async (id: number): Promise<contracts.getSharedAccessCollection.Response> => {
	const params = new contracts.getSharedAccessCollection.Params();
	params.id = id;
	return await API.callV2<contracts.getSharedAccessCollection.Response>(contracts.getSharedAccessCollection, {
		params,
	});
};

const putSharedAccessCollection = async (
	id: number,
	planSettings: SharedPlanAccessSetting[],
): Promise<contracts.putSharedAccessCollection.Response> => {
	const params = new contracts.putSharedAccessCollection.Params();
	params.id = id;
	const body = new contracts.putSharedAccessCollection.Body();
	body.planSettings = planSettings;
	return await API.callV2<contracts.putSharedAccessCollection.Response>(contracts.putSharedAccessCollection, {
		params,
		body,
	});
};

const acceptInvitation = async (invitationId: number): Promise<contracts.postAcceptInvitation.Response> => {
	const params = new contracts.postAcceptInvitation.Params();
	params.invitationId = invitationId;
	return await API.callV2<contracts.postAcceptInvitation.Response>(contracts.postAcceptInvitation, { params });
};

const declineInvitation = async (invitationId: number): Promise<contracts.postDeclineInvitation.Response> => {
	const params = new contracts.postDeclineInvitation.Params();
	params.invitationId = invitationId;
	return await API.callV2<contracts.postDeclineInvitation.Response>(contracts.postDeclineInvitation, { params });
};

const TrustedConnectionAPI = {
	fetchTrustedPeople,
	createTrustedPerson,
	updateTrustedPerson,
	deleteTrustedPerson,
	listInvitations,
	getInvitationWithHash,
	acceptInvitation,
	declineInvitation,
	getSharedAccessCollection,
	putSharedAccessCollection,
};

export default TrustedConnectionAPI;
