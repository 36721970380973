import { IsString } from "class-validator";
import { HTTP_VERBS } from "../../enums";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/uploadurl";
export class Query {
	@IsString()
	public contentType!: string;
}
export class Response {
	@IsString()
	public url!: string;
	@IsString()
	public uniqueFileName!: string;
}
