import { IsNRIC } from "app/common/validators";
import { Transform } from "class-transformer";
import { IsString } from "class-validator";
import { escapeHTML } from "../../transformers";

export class PostSessionIdRequestDO {
	constructor(props: Readonly<PostSessionIdRequestDO>) {
		this.uinfin = props?.uinfin;
		this.certificationNumber = props?.certificationNumber;
		this.deceasedUinfin = props?.deceasedUinfin;
	}

	@IsNRIC()
	@Transform(escapeHTML)
	public uinfin!: string;

	@IsNRIC()
	@Transform(escapeHTML)
	public deceasedUinfin!: string;

	@IsString()
	@Transform(escapeHTML)
	public certificationNumber!: string;
}

export class PostSessionIdResponseDO {
	@IsString()
	public sessionID!: string;
}
