import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import { OTPContactType } from "../api/otp";
import { validateEmail } from "./emailValidator";
import { validateLocalPhoneNumber } from "./localPhoneNumberValidator";

// Checks whether given value is valid mobile or email address

// tslint:disable-next-line: ban-types
export function IsOTPContact(
	typePropertyName: string,
	validationOptions?: ValidationOptions,
): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "IsMobileOrEmail",
			target: object.constructor,
			propertyName,
			constraints: [typePropertyName],
			options: validationOptions,
			validator: {
				validate(value: string, args: ValidationArguments): boolean {
					const contactType: OTPContactType = args.object[args.constraints[0]];
					switch (contactType) {
						case OTPContactType.EMAIL:
							return validateEmail(value);
						case OTPContactType.SMS:
							return validateLocalPhoneNumber(value);
						default:
							return validateEmail(value) || validateLocalPhoneNumber(value);
					}
				},
				defaultMessage(args: ValidationArguments): string {
					const contactType: OTPContactType = args.object[args.constraints[0]];
					return `"${args.value}" must be a valid ${
						contactType === OTPContactType.EMAIL ? "email address" : "mobile number"
					}."`;
				},
			},
		});
	};
}
