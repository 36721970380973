import { DownloadIcon } from "@lifesg/react-icons/download";
import FileManagementAPI from "app/api/fileManagement";
import SharedAccessAPI from "app/api/sharedAccess";
import { EOLErrorCodes } from "app/common/errors";
import { GenericNetworkErrorMsg } from "app/constants/networkErrors";
import { useAlert } from "app/hooks/useAlert";
import { getSizeWithUnit } from "app/utils/file";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { IFileObject } from "./type";

interface IProps {
	id?: string;
	index: number;
	attachment: IFileObject;
	sharedAccessId?: number;
	invertColor?: boolean;
	displayFileNameOnly?: boolean;
	disabled?: boolean;
}

const FileInputSummary = (props: IProps): JSX.Element => {
	const { inlineSnackbar, setInlineSnackbarMessage } = useAlert();
	const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
	const aRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		checkOverflow();
		window.addEventListener("resize", checkOverflow);

		return (): void => {
			window.removeEventListener("resize", checkOverflow);
			setInlineSnackbarMessage(undefined);
		};
	}, []);

	const download = async (): Promise<void> => {
		const { s3UniqueName } = props.attachment;
		const { sharedAccessId } = props;

		try {
			if (!s3UniqueName) {
				throw new Error("s3UniqueName is required");
				return;
			}
			const attachment: AxiosResponse = sharedAccessId
				? await SharedAccessAPI.getSharedDocument(sharedAccessId, s3UniqueName)
				: await FileManagementAPI.getFile(s3UniqueName);

			if (aRef && aRef.current) {
				aRef.current.href = window.URL.createObjectURL(
					new Blob([attachment.data], { type: attachment.headers["content-type"] }),
				);
				aRef.current.download = props.attachment.displayName;
				aRef.current.click();
			}
		} catch (err) {
			const { isAxiosError, response } = err as AxiosError;
			if (isAxiosError && response && response.data) {
				const text = await new Response(response.data as BodyInit).text();
				const { errors } = JSON.parse(text);
				if (errors && errors.name === EOLErrorCodes.SharedAccessNotFoundError) {
					setInlineSnackbarMessage(
						"The sharing permissions for this document has been changed. Please refresh your browser.",
						"failure",
					);
					return;
				}
			}

			setInlineSnackbarMessage(GenericNetworkErrorMsg, "failure");
		}
	};

	const checkOverflow = (): void => {
		const nameElement = document.getElementById(`summary__attachment__name--${props.index}`);
		if (nameElement === null || nameElement.firstElementChild === null) {
			setIsOverflowing(false);
			return;
		}

		if (nameElement.firstElementChild.scrollWidth < nameElement.clientWidth + 1) {
			setIsOverflowing(false);
			return;
		}

		setIsOverflowing(true);
	};

	const getPartialFileName = (): string => {
		// get last 10 chars of file name including file extensions
		// Changing the value 10 will affect the css for span.summary__attachment__name--full
		const fileDisplayName = props.attachment.displayName;
		const endingFileName = fileDisplayName.substring(fileDisplayName.length - 10, fileDisplayName.length);
		return endingFileName;
	};

	return (
		<div id={props.id} className={`summary__attachment file-${props.index}`}>
			<div
				id={`summary__attachment__file--${props.index}`}
				className={`summary__attachment__details ${
					props.invertColor ? "summary__attachment__details--inverted" : ""
				} ${props.disabled ? "summary__attachment__details--disabled" : ""}`}
				onClick={props.disabled ? () => {} : download}
			>
				<div className="summary__attachment__details--left">
					<div
						className={`summary__attachment__name ${
							isOverflowing ? "summary__attachment__name--overflowing" : ""
						}`}
						id={`summary__attachment__name--${props.index}`}
						data-partialname={getPartialFileName()}
					>
						<span className="summary__attachment__name--full">{props.attachment.displayName}</span>
						{isOverflowing && (
							<span className="summary__attachment__name--partial">{getPartialFileName()}</span>
						)}
					</div>
					{!props.displayFileNameOnly && (
						<div className="summary__attachment__size">{getSizeWithUnit(props.attachment.size)}</div>
					)}
				</div>
				<DownloadIcon className="summary__attachment__download" />
			</div>
			<a ref={aRef} download={true} />

			{inlineSnackbar && (
				<>
					<div className="mt24" />
					{inlineSnackbar}
				</>
			)}
		</div>
	);
};

export default FileInputSummary;
