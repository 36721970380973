import Button from "app/components/basic/Button";
import "./ActionBox.scss";

interface IProps {
	message: string;
	button: {
		name: string;
		action: () => void;
		IconLeft: any;
	};
	className?: string;
}

const ActionBoxWithoutIcon = (props: IProps): JSX.Element => {
	return (
		<div className={`actionbox-container actionbox-container__single-row ${props.className}`}>
			<div className="actionbox-content">
				<span> {props.message} </span>
			</div>
			<div className="actionbox-button">
				<Button
					id="actionbox-button"
					name={props.button.name}
					type="link"
					onClick={props.button.action}
					IconLeft={props.button.IconLeft}
					size="small"
				/>
			</div>
		</div>
	);
};

export default ActionBoxWithoutIcon;
