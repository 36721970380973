export const isValidCarplate = (carplate: string): boolean => {
	const carplateWithoutSuffix = carplate.slice(0, -1);
	const checksum = [9, 4, 5, 4, 3, 2];
	const checksumLetter = [
		"A",
		"Z",
		"Y",
		"X",
		"U",
		"T",
		"S",
		"R",
		"P",
		"M",
		"L",
		"K",
		"J",
		"H",
		"G",
		"E",
		"D",
		"C",
		"B",
	];
	const letterPrefixGroup = carplateWithoutSuffix.match(/[A-Z]+/g) ?? [];
	const numbersGroup = carplateWithoutSuffix.match(/[0-9]+/g) ?? [];
	if (!letterPrefixGroup || !numbersGroup || letterPrefixGroup.length !== 1 || numbersGroup.length !== 1) {
		return false;
	}

	const values: number[] = [];

	letterPrefixGroup[0]
		.padStart(3, "@")
		.slice(1)
		.split("")
		.forEach((letter) => values.push(getLetterIndex(letter)));

	numbersGroup[0]
		.padStart(4, "0")
		.split("")
		.forEach((number) => values.push(Number(number)));

	if (values.length !== checksum.length) {
		return false;
	}

	const checksumRemainder =
		values
			.map((number, index) => number * checksum[index])
			.reduce((prev, value) => {
				return prev + value;
			}, 0) % 19;

	return carplate[carplate.length - 1] === checksumLetter[checksumRemainder];
};

const getLetterIndex = (char: string): number => {
	return char.charCodeAt(0) - 64;
};
