import AppointPerson, { TAppointPersonDO } from "app/components/composites/AppointPerson";
import { TAppointPerson } from "app/components/composites/AppointPerson/common/types";
import { useEffect, useState } from "react";
import { FORM_FIELD_WITH_ERROR_CLASSNAME, IFormFunctions } from "../Form";

interface IFormAppointPersonProps {
	field: string;
	form: IFormFunctions;
	type: TAppointPerson;
	values?: TAppointPersonDO[];
	max?: number;
	beforeSelectItem?: (...args: any[]) => void;
	afterSelectItem?: (...args: any[]) => void;
	title?: string;
	disabled?: boolean;
	loading?: boolean;
}

const FormAppointPerson = (props: IFormAppointPersonProps): JSX.Element => {
	const [performAfterAction, setPerformAfterAction] = useState(false);

	useEffect(() => {
		if (performAfterAction && props.afterSelectItem) {
			props.afterSelectItem();
			setPerformAfterAction(false);
		}
	}, [performAfterAction]);

	const formField = props.form.getField(props.field);

	const key = formField ? formField.key : "";
	const value = formField ? formField.value : [];
	const isSubmitting = formField ? props.form.submittingFields[formField.key] : false;
	const error = formField ? props.form.getError(props.field) : "";
	const className = `form-field-${key}`;

	if (props.loading || (!value && !formField)) {
		return <div />;
	}

	const selectItemHandler = async (value: TAppointPersonDO[]): Promise<void> => {
		if (props.beforeSelectItem) {
			await props.beforeSelectItem();
		}
		await props.form.updateFieldValue(key, value);
		if (props.afterSelectItem) {
			await setPerformAfterAction(true);
		}
	};

	return (
		<div
			className={`${className} ${props.title ? "form-field" : ""}${
				error ? ` ${FORM_FIELD_WITH_ERROR_CLASSNAME}` : ""
			}`}
		>
			<AppointPerson
				{...props}
				value={value as TAppointPersonDO[]}
				updateValue={selectItemHandler}
				disabled={props.disabled || isSubmitting}
				errorMessage={error}
			/>
		</div>
	);
};

export default FormAppointPerson;
