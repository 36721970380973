import { HealthcareSpokespersonDO } from "app/common/api/acp";
import Modal from "app/components/basic/Modal";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { useCallback, useEffect } from "react";
import { IAppointPersonModal } from "../common/utils";

const NAME = "name";
const RELATIONSHIP = "relationship";
const CONTACT = "phoneNumber";

const fields: IFormSchema = {
	[NAME]: { type: "text", maxChar: 120, constraints: [["isRequired", "Name cannot be empty"]] },
	[RELATIONSHIP]: { type: "text", maxChar: 120, constraints: [["isRequired", "Relationship cannot be empty"]] },
	[CONTACT]: {
		type: "text",
		maxChar: 20,
		constraints: [
			["isRequired", "Phone number cannot be empty"],
			["isInternationalPhoneFormat", "Please enter a valid phone number"],
		],
	},
};

const NewSpokespersonModal = (props: IAppointPersonModal<HealthcareSpokespersonDO>): JSX.Element => {
	const { personIndex, personList, closeCallback, updatePersonList: _updatePersonList } = props;
	const { form } = useForm();
	const { setupFormFields, warnChangesLostIfProceed, getValue } = form;

	const initFormModal = useCallback((): void => {
		if (undefined === personIndex) {
			return setupFormFields(fields, {}, NAME);
		}

		const spokesperson: HealthcareSpokespersonDO = personList[personIndex];
		const initialData = {
			[NAME]: spokesperson.name,
			[RELATIONSHIP]: spokesperson.relationship,
			[CONTACT]: spokesperson.phoneNumber,
		};
		setupFormFields(fields, initialData, NAME);
	}, [personIndex, personList, setupFormFields]);

	const closeModal = useCallback((): void => {
		warnChangesLostIfProceed(() => closeCallback());
	}, [closeCallback, warnChangesLostIfProceed]);

	const updatePersonList = useCallback(() => {
		const newOrUpdatedCoordinator = new HealthcareSpokespersonDO();
		newOrUpdatedCoordinator.name = getValue(NAME);
		newOrUpdatedCoordinator.relationship = getValue(RELATIONSHIP);
		newOrUpdatedCoordinator.phoneNumber = getValue(CONTACT);

		const updatedList: HealthcareSpokespersonDO[] = personList;
		if (undefined !== personIndex) {
			updatedList[personIndex] = newOrUpdatedCoordinator; // for editing
		} else {
			updatedList.push(newOrUpdatedCoordinator); // for new
		}

		_updatePersonList(updatedList);
		closeCallback();
	}, [_updatePersonList, closeCallback, getValue, personIndex, personList]);

	useEffect(() => initFormModal(), [initFormModal]);

	return (
		<Modal
			id="modal__add-coordinator"
			form={form}
			type="form"
			title="Add spokesperson"
			isOpen={true}
			closeCallback={closeModal}
			button1={["Cancel", "secondary", closeModal]}
			button2={["Add", "primary", updatePersonList]}
		>
			<div style={{ marginTop: -24 }}>
				<Form.Input title="Name" field={NAME} form={form} />
				<div className="coordinator__form__row">
					<Form.Input title="Relationship" field={RELATIONSHIP} form={form} />
					<Form.Input title="Phone number" field={CONTACT} form={form} />
				</div>
			</div>
		</Modal>
	);
};

export default NewSpokespersonModal;
