import { IFormInputBaseProps } from "./Form.Input";
import { Form as DSForm } from "@lifesg/react-design-system/form";
import { IDateValue } from "app/components/basic/Date";
import { dateToString, formatDateValue } from "app/components/basic/Date/utils";
import { FormLabel } from "app/hooks/useForm/components/Form.Label";
import { Grid } from "semantic-ui-react";

export interface IFormDateProps extends IFormInputBaseProps {
	/** The minimum date that can be selected (inclusive) in 'YYYY-MM-DD' format */
	minDate?: string | undefined;
	/** The maximum date that can be selected (inclusive) in 'YYYY-MM-DD' format */
	maxDate?: string | undefined;
	/** With button - Specifies if the "Done" and "Cancel" action buttons should be rendered */
	withButton?: boolean;
	zIndex?: number | undefined;
}

const FormDate = ({
	form,
	field,
	title,
	subtitle,
	popupContent,
	disabled,
	className,
	hideErrorMessage,
	disableBlurValidation,
	additionalValidations,
	readOnly,
	accessibleLabel,
	computerWidth,
	mobileWidth,
	tabletWidth,
	minDate,
	maxDate,
	withButton,
	zIndex,
	...otherProps
}: IFormDateProps): JSX.Element => {
	const formField = form.getField(field);
	const key = formField ? formField.key : "";
	const errorMessageTestId = field + "_form_date__error";

	let id = field + "__loading";

	if (!form.loading) {
		id = key + "_form_date";
	}

	if (disabled) {
		id = key + "_form_date--disabled";
	}

	const errorMessage = formField && !hideErrorMessage ? form.getError(field) : "";
	const value = dateToString(formField.value as IDateValue);
	const isSubmitting = formField ? form.submittingFields[formField.key] : false;

	let fieldClassName = `form-field form-field-${key} ${className ?? ""} form-field__input--date ${
		readOnly ? "readOnly" : ""
	} ${errorMessage ? "error" : ""}`;
	if (disabled || (isSubmitting && !readOnly)) {
		fieldClassName += " form-field__input--date--disabled";
	}

	const onBlurHandler = (): void => {
		if (!disableBlurValidation) {
			const field = formField;
			form.validateFieldValue(field);
			void additionalValidations?.();
		}
	};

	const onChangeHandler = (value: string): void => {
		formField["value"] = formatDateValue(value);
		form.validateFieldValue(formField);
		void additionalValidations?.();
		form.updateFieldValue(key, formatDateValue(value));
	};

	const inputWidth = computerWidth ?? 12;

	return (
		<Grid className={className?.includes("storybook") ? "form-storybook" : "form"}>
			<Grid.Row>
				<Grid.Column
					className="no-margin no-padding"
					mobile={mobileWidth ?? inputWidth}
					tablet={tabletWidth ?? inputWidth}
					computer={inputWidth}
				>
					<FormLabel
						id={id}
						title={title}
						subtitle={subtitle}
						popupContent={popupContent}
						fullWidthLabel={otherProps.fullWidthLabel}
						labelClassName={otherProps.labelClassName}
						formType={otherProps.formType}
					>
						<div className={fieldClassName}>
							<DSForm.DateInput
								zIndex={zIndex || undefined}
								id={id}
								errorMessage={errorMessage}
								value={value || undefined}
								onChange={onChangeHandler}
								onBlur={onBlurHandler}
								disabled={disabled || isSubmitting}
								aria-label={accessibleLabel}
								data-error-testid={errorMessageTestId}
								readOnly={readOnly}
								minDate={minDate}
								maxDate={maxDate}
								withButton={withButton}
								className="form-date-input"
								{...otherProps}
							/>
						</div>
					</FormLabel>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default FormDate;
