/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IsACPContact } from "app/common/validators/acpContactValidator";
import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { DraftContentDto, PdfDto } from "./acpDraft";

export class AcpSaveDraftDto {
	@ValidateNested({ each: true, always: true })
	@Type(() => DraftContentDto)
	@IsACPContact()
	draftContent!: DraftContentDto;
}
export class AcpPdfDto {
	@ValidateNested({ each: true, always: true })
	@Type(() => PdfDto)
	@IsACPContact()
	data!: PdfDto;
}
