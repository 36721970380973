import UserAPI from "app/api/user";
import { TrustedConnectionStatusTypes, TrustedPersonDO } from "app/common/api/trustedConnection";
import * as contracts from "app/common/api/trustedConnection/routes";
import { EMAIL_MAX_CHARS } from "app/common/email";
import { EOLErrorCodes } from "app/common/errors";
import { IDropdownItemTemplate } from "app/components/basic/Dropdown";
import Message from "app/components/basic/Message";
import Modal, { IModalButton } from "app/components/basic/Modal";
import UserAvatar from "app/components/basic/UserAvatar";
import ErrorMessage from "app/components/widgets/ErrorMessage";
import { GenericNetworkErrorMsg } from "app/constants/networkErrors";
import { useAlert } from "app/hooks/useAlert";
import { useErrorHandler } from "app/hooks/useErrorHandler";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { getFormattedDate, getFormattedExpiryDate } from "app/utils/date";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReplayIcon } from "@lifesg/react-icons/replay";

const NAME = "name";
const NRIC = "nric";
const CONTACT_TYPE = "contact";

const PHONE = "phone";
const PHONE_LABEL = "Mobile Phone";
const EMAIL = "email";
const EMAIL_LABEL = "Email";
const SEND_INVITATION = "Send invitation";

const DEFAULT_ERROR = "Sorry, we were unable to send your invitation. Please check your connection, and try again";

const fieldSchema: IFormSchema = {
	[NAME]: {
		type: "text",
		maxChar: 120,
		constraints: [["isRequired", "Name cannot be empty."]],
	},
	[NRIC]: {
		type: "text",
		maxChar: 9,
		constraints: [
			["isRequired", "NRIC / FIN cannot be empty."],
			["isNRIC", "Please enter a valid NRIC / FIN."],
		],
	},
	[CONTACT_TYPE]: {
		type: "radio",
		radioBoxItems: [{ label: PHONE_LABEL, modifier: "default" }, { label: EMAIL_LABEL }],
	},
	[PHONE]: {
		type: "numberString",
		maxChar: 8,
		constraints: [
			["isRequired", "Mobile No cannot be empty."],
			["isLocalPhoneNumber", "Please enter an 8-digit local phone number."],
		],
	},
	[EMAIL]: {
		type: "email",
		maxChar: EMAIL_MAX_CHARS,
		disableValidation: true,
		constraints: [
			["isRequired", "Email cannot be empty."],
			["isEmail", "Please enter a valid email."],
		],
	},
};

interface IProps {
	showModal: boolean;
	initialSearchQuery?: string;
	initialPersonList?: IDropdownItemTemplate[];
	selectedPersons?: number[];
	saveAction: (newPerson: TrustedPersonDO) => Promise<contracts.postTrustedPerson.Response>;
	onCloseHandler: () => void;
	selectMatchingHandler: (id: number) => void;
}

interface IModalContent {
	title: string;
	subTitle?: string;
	secondaryButtonName: string;
	cancelAsSecondaryAction: boolean;
}

interface IMatchingPerson extends IDropdownItemTemplate {
	selected: boolean;
}

const newInviteModalContent: IModalContent = {
	title: "Invite Trusted Person",
	subTitle:
		"Invite someone to be a trusted connection. Once they have accepted your invitation, you can quickly share other documents with them.",
	secondaryButtonName: "Cancel",
	cancelAsSecondaryAction: true,
};

const inviteSentModalContent: IModalContent = {
	title: "Invitation sent!",
	subTitle: "",
	secondaryButtonName: "",
	cancelAsSecondaryAction: false,
};

const NewTrustedPerson = ({
	showModal: _showModal,
	initialSearchQuery,
	initialPersonList,
	selectedPersons,
	saveAction,
	onCloseHandler,
	selectMatchingHandler,
}: IProps): JSX.Element => {
	const isFirstLoad = useRef(true);
	const [loaded, setLoaded] = useState(false);
	const [modal, showModal] = useState(_showModal);
	const [error, setError] = useState<string>();
	const [modalContent, setModalContent] = useState<IModalContent>(newInviteModalContent);
	const [primaryButtonText, setPrimaryButtonText] = useState(SEND_INVITATION);
	const [matchingPeople, setMatchingPeople] = useState<IMatchingPerson[]>([]);
	const [selectedPersonForResending, setSelectedPersonForResending] = useState<IMatchingPerson | null>(null);
	const [invitedOn, setInvitedOn] = useState(moment());
	const initialSearchTerm2 = useRef(initialSearchQuery);

	const { form } = useForm();
	const {
		setupFormFields,
		updateFieldError,
		getValue,
		warnChangesLostIfProceed,
		resetAllFieldError,
		setMultipleFieldShouldValidate,
		resetFieldError,
		ignoreChangesMade,
	} = form;
	const { toast } = useAlert();
	const { sendError } = useErrorHandler();

	useEffect(() => {
		if (initialSearchQuery) {
			initialSearchTerm2.current = initialSearchQuery;
		}
	}, [initialSearchQuery]);

	const initForm = useCallback(async (): Promise<void> => {
		try {
			const initialFormFields = {};
			const query = initialSearchTerm2.current;

			const updatedSchema: IFormSchema = fieldSchema;

			const LOGGED_IN_USER = await UserAPI.fetchUser();
			updatedSchema[NRIC].constraints = [
				...fieldSchema[NRIC].constraints!,
				["isNot", LOGGED_IN_USER.nric, "Please enter the NRIC or FIN of the person you want to invite."],
			];

			if (query && /^[STFG]\d{7}[A-Z]$/i.test(query)) {
				initialFormFields[NRIC] = query.toUpperCase();
				setupFormFields(updatedSchema, initialFormFields, NRIC);

				if (LOGGED_IN_USER.nric.toUpperCase() === query.toUpperCase()) {
					updateFieldError(NRIC, "Please enter the NRIC or FIN of the person you want to invite.");
				}
			} else {
				initialFormFields[NAME] = query;
				setupFormFields(updatedSchema, initialFormFields);
			}

			setLoaded(true);
		} catch (err: any) {
			if (err.name === EOLErrorCodes.NetworkError) {
				toast("failure", GenericNetworkErrorMsg);
				return;
			}

			sendError(err);
		}
	}, [sendError, setupFormFields, toast, updateFieldError]);

	const submit = useCallback(async (): Promise<void> => {
		// reset error
		setError(undefined);
		// if there are matching people, do not allow user to submit
		if (matchingPeople.length > 0) {
			setError("Select one of your existing Trusted Persons, or enter a different Name or NRIC / FIN.");
			return;
		}

		const newPerson = new TrustedPersonDO();
		newPerson.nric = getValue(NRIC);
		newPerson.nickname = getValue(NAME);

		const contactType = getValue(CONTACT_TYPE);
		if (contactType === PHONE_LABEL) {
			newPerson.phone = getValue(PHONE);
		} else if (contactType === EMAIL_LABEL) {
			newPerson.email = getValue(EMAIL);
		}

		try {
			const createdPerson = await saveAction(newPerson);
			if (createdPerson && createdPerson.id) {
				setModalContent(inviteSentModalContent);
				setPrimaryButtonText("Okay");
				setInvitedOn(createdPerson.invitedOn);
			} else {
				setError(DEFAULT_ERROR);
				setPrimaryButtonText(SEND_INVITATION);
			}
		} catch (err: any) {
			setError(err.details ? err.details : DEFAULT_ERROR);
			setPrimaryButtonText(SEND_INVITATION);
		}
	}, [getValue, saveAction, matchingPeople.length]);

	const closeFormModal = useCallback((): void => {
		warnChangesLostIfProceed(onCloseHandler, () => showModal(true));
	}, [onCloseHandler, warnChangesLostIfProceed]);

	const closeSuccessModal = useCallback((): void => {
		showModal(false);
		onCloseHandler();
	}, [onCloseHandler]);

	const dismissErrorMessage = useCallback((): void => {
		setError(undefined);
	}, []);

	const resetForm = useCallback((): void => {
		// reset headers, unselect selected match
		setModalContent(newInviteModalContent);
		setSelectedPersonForResending(null);
		setMatchingPeople([]);
		setupFormFields(fieldSchema);
	}, [setupFormFields]);

	const resetFormValidation = useCallback(
		(hasMatchingPeople: boolean) => {
			if (hasMatchingPeople) {
				// if there are matching people, contact is not required
				resetAllFieldError();
				setMultipleFieldShouldValidate({ [PHONE]: false, [EMAIL]: false });
				return;
			} else if (getValue(CONTACT_TYPE) === EMAIL_LABEL) {
				// if there are no matching people, set required accordingly
				resetFieldError(PHONE);
				setMultipleFieldShouldValidate({ [PHONE]: false, [EMAIL]: true });
			} else {
				resetFieldError(EMAIL);
				setMultipleFieldShouldValidate({ [PHONE]: true, [EMAIL]: false });
			}
		},
		[getValue, resetAllFieldError, setMultipleFieldShouldValidate, resetFieldError],
	);

	const checkIfPendingOrAccepted = useCallback(
		(status: TrustedConnectionStatusTypes) =>
			status === TrustedConnectionStatusTypes.ACCEPTED || status === TrustedConnectionStatusTypes.INVITED,
		[],
	);

	const checkSelected = useCallback(
		(id: number): boolean => (selectedPersons as number[])?.indexOf(id) > -1,
		[selectedPersons],
	);

	const checkMatchingPeople = useCallback(() => {
		// @TODO: revoked invitation should not be part of this match
		setSelectedPersonForResending(null);
		setError(undefined);

		const lowerCaseName = getValue(NAME).toLowerCase();
		const lowerCaseNric = getValue(NRIC).toLowerCase();
		const matchingPeople: IMatchingPerson[] = [];

		if (initialPersonList && initialPersonList.length > 0) {
			for (const person of initialPersonList) {
				const { nickname, nric } = person.contentData;
				const personObject = { ...person, selected: checkSelected(person.value as number) };

				if (nickname.toLowerCase() === lowerCaseName) {
					matchingPeople.unshift(personObject);
				} else if (nric.toLowerCase() === lowerCaseNric) {
					matchingPeople.push(personObject);
				}
			}
		}

		resetFormValidation(matchingPeople.length > 0);
		setMatchingPeople(matchingPeople);
	}, [checkSelected, getValue, initialPersonList, resetFormValidation]);

	const onClickMatching = useCallback(
		(person: IMatchingPerson): any =>
			() => {
				// if user is already shared or selected, cannot click again
				if (person.contentData.shared || person.selected) {
					return;
				}

				// if person has declined or invitation expired or invitation had been removed by sender or invitation was locked by max otp attempts
				// allow the sender to resend
				if (false === checkIfPendingOrAccepted(person.contentData.status)) {
					// check remaining attempts
					if (person.contentData.inviteAttemptsLeft === 0) {
						return;
					}

					const newInviteModalContent: IModalContent = {
						title: "Resend invitation",
						secondaryButtonName: modalContent.secondaryButtonName,
						cancelAsSecondaryAction: modalContent.cancelAsSecondaryAction,
					};

					setModalContent(newInviteModalContent);

					// pre-fill modal
					const initialFormFields = {
						[NAME]: person.contentData.nickname,
						[NRIC]: person.contentData.nric,
					};

					const updatedFieldSchema = fieldSchema;
					// @TODO: do we allow change from phone to email and vice versa?
					// empty out the old value
					if (person.contentData.email) {
						initialFormFields[EMAIL] = person.contentData.email;
						initialFormFields[CONTACT_TYPE] = EMAIL_LABEL;
						// change required in fieldSchema
						updatedFieldSchema[EMAIL].disableValidation = false;
						updatedFieldSchema[PHONE].disableValidation = true;
					}

					if (person.contentData.phone) {
						initialFormFields[PHONE] = person.contentData.phone;
						initialFormFields[CONTACT_TYPE] = PHONE_LABEL;
						// change required in fieldSchema
						updatedFieldSchema[EMAIL].disableValidation = true;
						updatedFieldSchema[PHONE].disableValidation = false;
					}

					setupFormFields(updatedFieldSchema, initialFormFields);
					// @TODO: callback to dismiss matching, update message
					setError(undefined);
					setSelectedPersonForResending(person);
					setMatchingPeople([]);
				} else {
					// select user to selected
					selectMatchingHandler(person.value as number);
					ignoreChangesMade();
					closeSuccessModal();
				}
			},
		[
			checkIfPendingOrAccepted,
			closeSuccessModal,
			ignoreChangesMade,
			modalContent.cancelAsSecondaryAction,
			modalContent.secondaryButtonName,
			selectMatchingHandler,
			setupFormFields,
		],
	);

	const afterSelectContactInformation = useCallback((): void => {
		if (getValue(CONTACT_TYPE) === EMAIL_LABEL) {
			resetFieldError(PHONE);
			setMultipleFieldShouldValidate({ [PHONE]: false, [EMAIL]: true });
		} else {
			resetFieldError(EMAIL);
			setMultipleFieldShouldValidate({ [PHONE]: true, [EMAIL]: false });
		}
	}, [getValue, resetFieldError, setMultipleFieldShouldValidate]);

	useEffect(() => void initForm(), [initForm]);

	useEffect(() => {
		if (loaded && isFirstLoad.current) {
			void checkMatchingPeople();
			isFirstLoad.current = false;
		}
	}, [loaded, checkMatchingPeople]);

	if (!loaded) {
		return <div />;
	}

	let warningMessage;
	if (selectedPersonForResending && selectedPersonForResending.contentData.inviteAttemptsLeft < 2) {
		warningMessage = `Your previous invitation was declined/expired. You have ${selectedPersonForResending.contentData.inviteAttemptsLeft} more attempt to invite him/her to be a Trusted Person.`;
	} else if (selectedPersonForResending) {
		warningMessage = `Your previous invitation was declined/expired. You have ${selectedPersonForResending.contentData.inviteAttemptsLeft} more attempts to invite him/her to be a Trusted Person.`;
	}

	let ModalButton1: IModalButton = ["Cancel", "secondary", closeFormModal];
	let ModalButton2: IModalButton | undefined = [primaryButtonText, "primary", submit];

	if (modalContent.title === "Invitation sent!") {
		ModalButton1 = [primaryButtonText, "primary", closeSuccessModal];
		ModalButton2 = undefined;
	}

	const InviteSuccessMessage: JSX.Element = (
		<>
			<p>
				Your invitation to <b>{getValue(NAME)}</b> was sent successfully.
			</p>
			<p>
				Your invitation will expire after 3 weeks, on <b>{getFormattedExpiryDate(invitedOn)}</b>.
			</p>
		</>
	);

	const InviteSuccessModal = (): JSX.Element => (
		<div className="invitation__success__modal">
			<Message show={true} type="success" content={InviteSuccessMessage} />
			<div>
				<p>You can now start sharing your documents with them!</p>
				<p>They will be able to see these documents once they have accepted the invitation.</p>
			</div>
		</div>
	);

	const ReloadIcon = (props: any): JSX.Element => (
		<div className={props.className}>
			<ReplayIcon />
		</div>
	);

	return (
		<Modal
			id="modal__new-trusted-person"
			type="form"
			form={modalContent.title !== "Invitation sent!" ? form : undefined}
			isOpen={modal}
			title={modalContent.title}
			subTitle={modalContent.subTitle}
			closeCallback={modalContent.title !== "Invitation sent!" ? closeFormModal : closeSuccessModal}
			button2={ModalButton2}
			button1={ModalButton1}
		>
			{modalContent.title === "Invitation sent!" ? (
				<InviteSuccessModal />
			) : (
				<form noValidate={true} className="new-trusted-person" id="form__trustedPerson">
					{selectedPersonForResending === null && (
						<div
							className={`new-trusted-person__name-nric ${
								matchingPeople.length > 0 ? " remove-margin-bottom" : ""
							}`}
						>
							<Form.Input title="Name" field={NAME} form={form} onBlurHandler={checkMatchingPeople} />
							<Form.Input
								title="NRIC / FIN"
								field={NRIC}
								form={form}
								onBlurHandler={checkMatchingPeople}
								transformUppercase={true}
							/>
						</div>
					)}

					{selectedPersonForResending && (
						<div className="new-trusted-person__select-existing">
							<Message type="warning" content={warningMessage} show={true} />
							<div className="new-trusted-person__resend__subtitle">
								<p className="new-trusted-person__resend__subtitle--left">
									You are resending the invitation to:
								</p>
								<div
									className="new-trusted-person__resend__subtitle--reset new-trusted-person__resend__subtitle--reset--desktop"
									onClick={resetForm}
								>
									<ReloadIcon className="new-trusted-person__resend__subtitle__icon" />
									Invite new person
								</div>
							</div>
							<UserAvatar
								name={selectedPersonForResending.contentData.nickname}
								nric={selectedPersonForResending.contentData.nric}
								invited={true}
							/>
							<div
								className="new-trusted-person__resend__subtitle--reset new-trusted-person__resend__subtitle--reset--mobile"
								onClick={resetForm}
							>
								<ReloadIcon className="new-trusted-person__resend__subtitle__icon" />
								Invite new person
							</div>
						</div>
					)}

					{matchingPeople.length !== 0 && (
						<ErrorMessage className="error__matching-person">
							Select the existing Trusted Person, or enter a different Name or NRIC / FIN.
						</ErrorMessage>
					)}
					{
						<div className="new-trusted-person__matching">
							{matchingPeople.map((person: IMatchingPerson, index: number) => {
								const disabled =
									(false === checkIfPendingOrAccepted(person.contentData.status) &&
										person.contentData.inviteAttemptsLeft === 0) ||
									person.contentData.shared ||
									person.selected;
								return (
									<div
										className={`new-trusted-person__matching__person${disabled ? " disabled" : ""}`}
										key={index}
										onClick={onClickMatching(person)}
									>
										<UserAvatar
											name={person.contentData.nickname}
											nric={person.contentData.nric}
											invited={person.contentData.status === TrustedConnectionStatusTypes.INVITED}
										/>
										{person.contentData.shared && (
											<span className="new-trusted-person__matching__person--shared person-tag ignore-opacity">
												Shared
											</span>
										)}
										{person.selected && (
											<span className="new-trusted-person__matching__person--selected person-tag ignore-opacity">
												Selected
											</span>
										)}
										{person.contentData.status === TrustedConnectionStatusTypes.DECLINED && (
											<span className="new-trusted-person__matching__person--declined person-tag ignore-opacity">
												Declined
											</span>
										)}
										{person.contentData.status === TrustedConnectionStatusTypes.EXPIRED && (
											<span className="new-trusted-person__matching__person--expired person-tag ignore-opacity">
												Expired
											</span>
										)}
										{person.contentData.status === TrustedConnectionStatusTypes.INVITED &&
											person.contentData.invitedOn && (
												<div className="new-trusted-person__matching__person__invitation-date">
													Invitation sent on {getFormattedDate(person.contentData.invitedOn)}
												</div>
											)}
										{false === checkIfPendingOrAccepted(person.contentData.status) && (
											<div className="new-trusted-person__matching__person__invitation-attempts">
												{person.contentData.inviteAttemptsLeft > 0
													? person.contentData.inviteAttemptsLeft +
													  " invitation attempt(s) remaining"
													: "No invitation attempts remaining"}
											</div>
										)}
									</div>
								);
							})}
						</div>
					}

					{matchingPeople.length === 0 && (
						<Form.RadioBox
							title="Contact Information"
							subtitle="We will send a notification to invite them to view your plans."
							field={CONTACT_TYPE}
							form={form}
							afterSelectItem={afterSelectContactInformation}
						/>
					)}

					{matchingPeople.length === 0 && form.getValue(CONTACT_TYPE) === EMAIL_LABEL && (
						<Form.Input title="Email" field={EMAIL} form={form} />
					)}
					{matchingPeople.length === 0 && form.getValue(CONTACT_TYPE) === PHONE_LABEL && (
						<Form.Input
							title="Mobile Phone Number"
							subtitle="Please use a local phone number (+65)"
							field={PHONE}
							computerWidth={6}
							mobileWidth={12}
							tabletWidth={12}
							form={form}
						/>
					)}

					{error && (
						<div className="mt40">
							<Message
								type="failure"
								content={error}
								show={true}
								closeMessageCallback={dismissErrorMessage}
							/>
						</div>
					)}
				</form>
			)}
		</Modal>
	);
};

export default NewTrustedPerson;
