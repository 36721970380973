import { ClassTransformers } from "@wog/mol-lib-api-contract/utils";
import { Transform } from "class-transformer";
import { IsString } from "class-validator";
import { ZonedDateTime } from "@js-joda/core";

export class AcpDraftDO {
	@IsString()
	draftId!: string;

	@IsString()
	draftType!: string;

	@IsString()
	data!: string;

	@Transform(ClassTransformers.ZonedDateTimeClassTransformer)
	updatedDate!: string;

	@Transform(ClassTransformers.ZonedDateTimeClassTransformer)
	expireDate?: ZonedDateTime;
}
