import { IsObject } from "class-validator";
import { HTTP_VERBS } from "../../enums";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v3/acp/generate-pdf";

export class Body {
	@IsObject()
	data!: object;
}
export class Response {}
