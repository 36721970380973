import { IsDate, IsNotWhiteSpace } from "app/common/validators";
import { assignToDomainObject } from "../../../utils/domainObject";
import { Transform, Type } from "class-transformer";
import {
	ArrayMaxSize,
	ArrayMinSize,
	IsBoolean,
	IsNumber,
	IsOptional,
	IsString,
	Max,
	MaxLength,
	ValidateNested,
} from "class-validator";
import moment from "moment";
import { IsMoment, IsTodayOrBefore } from "../../../validators";
import { DocumentDO } from "../../commonDomainObjects";
import { type IDateValue } from "../../interface";
import { toMoment, toNumber } from "../../transformers";
import { FullHealthcareSpokespersonDO, HealthcareSpokespersonDO } from "./healthcarespokesperson";

export class AdvanceCarePlanDO {
	@IsNumber(undefined, { always: true })
	@Max(5, { always: true })
	public currStep!: number;

	@IsOptional({ groups: ["step0"] })
	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public facilitationCentre!: string;

	@IsOptional({ groups: ["step0"] })
	@IsDate({ always: true })
	@IsTodayOrBefore({ always: true })
	public creationDate!: IDateValue;

	@ArrayMinSize(1, { groups: ["step2", "step3", "step4", "edit"] })
	@ArrayMaxSize(4, { always: true })
	@ValidateNested({ each: true, always: true })
	@Type(() => HealthcareSpokespersonDO)
	public healthcareSpokespersons: HealthcareSpokespersonDO[] = [];

	@IsOptional({ always: true })
	@IsString({ always: true })
	@MaxLength(120, { always: true })
	public documentLocation!: string;

	@ArrayMinSize(0, { always: true })
	@ArrayMaxSize(10, { always: true })
	@ValidateNested({ each: true })
	@Type(() => DocumentDO)
	public documents: DocumentDO[] = [];

	@IsOptional({ always: true })
	@IsString({ always: true })
	@MaxLength(1000)
	public additionalNotes!: string;
}
const advanceCarePlanDOKeys: Array<keyof AdvanceCarePlanDO> = [
	"currStep",
	"facilitationCentre",
	"creationDate",
	"healthcareSpokespersons",
	"documentLocation",
	"documents",
	"additionalNotes",
];

export class FullAdvanceCarePlanDO extends AdvanceCarePlanDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public createdAt!: moment.Moment;
	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public updatedAt!: moment.Moment;

	@ValidateNested({ each: true })
	@Type(() => FullHealthcareSpokespersonDO)
	public healthcareSpokespersons: FullHealthcareSpokespersonDO[] = [];

	@IsOptional({ always: true })
	public getBaseDO = (): FullAdvanceCarePlanDO | AdvanceCarePlanDO => {
		let spokespersonArray: HealthcareSpokespersonDO[] | undefined;
		const { healthcareSpokespersons, ...rest } = this;
		if (healthcareSpokespersons) {
			spokespersonArray = healthcareSpokespersons.map((spokesperson: FullHealthcareSpokespersonDO) =>
				spokesperson.getBaseDO(),
			);
		}
		// Returns object with only keys of specified DO.
		// NOTE: new DO(), instantiates to empty {} on backend
		return assignToDomainObject(
			new AdvanceCarePlanDO(),
			{ ...rest, healthcareSpokespersons: spokespersonArray },
			advanceCarePlanDOKeys,
		);
	};

	@IsOptional({ always: true })
	@IsBoolean({ always: true })
	public isCompleted!: boolean;
}
