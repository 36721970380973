const InputElement = (ChildComponent: any): any => {
	// remove all selection on focus
	const handleFocus = (): void => {
		// do not remove selection if it is a text input
		if (document.activeElement && document.activeElement.className.includes("radio__input")) {
			return;
		}
		window.getSelection()?.empty();
	};

	return (props: any, state: any): JSX.Element => (
		<div onFocus={handleFocus}>
			<ChildComponent {...props} {...state} />
		</div>
	);
};

export default InputElement;
