import { IsEmpty, IsEnum, IsNotEmpty, IsOptional, IsString, MaxLength } from "class-validator";
import { Transform, Type } from "class-transformer";
import { toDate, toLowercase, toUppercase } from "../../../api/transformers";
import { DeathRecordType } from "../enums";
import { IsMoment } from "../../../validators";
import moment from "moment";
import { IsOptionalIf } from "@wog/mol-lib-api-contract/utils/validators";

export class DeathRecordIdentifierDO {
	@IsString()
	@IsNotEmpty()
	@MaxLength(7)
	@Transform(toUppercase)
	public certificateNumber!: string;
}

export class DeathRecordAdditionalIdentifierDO {
	@IsEnum(DeathRecordType)
	@Transform(toLowercase)
	public type!: DeathRecordType;

	@IsString()
	@IsNotEmpty()
	@Transform(toUppercase)
	@MaxLength(25)
	@IsOptionalIf((value, args) => !!(args?.object as DeathRecordGetRequestDO)?.encryptedIdentificationNumber)
	public identificationNumber?: string;

	@IsMoment({ message: "must be date with format 'YYYY-MM-DD'" })
	@Transform(toDate)
	@Type(() => moment)
	public dateOfDeath!: moment.Moment;
}

// Used by GET, included the encrypted info
// TODO: to merge back to DeathRecordAdditionalIdentifierDO when we require ICA to send encrypted NRIC later
export class DeathRecordGetRequestDO extends DeathRecordAdditionalIdentifierDO {
	@IsString()
	@IsNotEmpty()
	public encryptedIdentificationNumber?: string;

	// Only in response
	@IsEmpty()
	public redactedIdentificationNumber?: string;
}

// Used by PUT, without the encrypted fields
export class DeathRecordPutRequestDO extends DeathRecordAdditionalIdentifierDO {
	@IsString()
	@IsNotEmpty()
	@MaxLength(66)
	@Transform(toUppercase)
	public name!: string;

	@IsMoment({ message: "must be date with format 'YYYY-MM-DD'" })
	@Transform(toDate)
	@IsOptional()
	@Type(() => moment)
	public expiresAfter?: moment.Moment;

	// Confirmation of Death: temporary number that can be used instead of actual `certificateNumber`
	@IsString()
	@IsNotEmpty()
	@MaxLength(7)
	@Transform(toUppercase)
	@IsOptional()
	public codNumber?: string;
}

export class DeathRecordEntityDO extends DeathRecordPutRequestDO {
	// We need everything in the response, but we can only extend one class
	public encryptedIdentificationNumber?: string;
	public redactedIdentificationNumber?: string;
	public createdAt!: string;
	public updatedAt!: string;
}
