import IContract from "app/common/api/contract";
import { HTTP_VERBS } from "app/common/api/enums";
import { generateURL, generateURLV2 } from "app/common/api/util";
import { EOLErrorCodes, EOLErrorMessages } from "app/common/errors";
import ResponseError from "app/common/responseError";
import { plainToInstance } from "class-transformer";
import { default as baseAPI } from "./client";

const API = {
	call: async <R>(contract: IContract, P: any, Q: any, B: any): Promise<R> => {
		//TODO: replace with V2
		const url = generateURL(contract.url, P, Q);
		let response;
		switch (contract.httpVerb) {
			case HTTP_VERBS.GET: {
				response = await baseAPI.get(url);
				break;
			}
			case HTTP_VERBS.DELETE: {
				response = await baseAPI.delete(url);
				break;
			}
			case HTTP_VERBS.PATCH: {
				response = await baseAPI.patch(url, B);
				break;
			}
			case HTTP_VERBS.POST: {
				response = await baseAPI.post(url, B);
				break;
			}
			case HTTP_VERBS.PUT: {
				response = await baseAPI.put(url, B);
				break;
			}
			default:
				throw new ResponseError(EOLErrorCodes.UnknownFrontEndError, EOLErrorMessages.UnknownFrontEndError);
		}
		return plainToInstance(contract.Response, response) as unknown as R;
	},
	callV2: async <R>(contract: IContract, contractParams?: { params?: any; query?: any; body?: any }): Promise<R> => {
		const url = generateURLV2(
			contract.url,
			(contractParams && contractParams.params) || {},
			(contractParams && contractParams.query) || {},
		);
		let response;
		switch (contract.httpVerb) {
			case HTTP_VERBS.GET: {
				response = await baseAPI.get(url, contractParams?.body?.preventRedirect ?? false);
				break;
			}
			case HTTP_VERBS.DELETE: {
				response = await baseAPI.delete(url);
				break;
			}
			case HTTP_VERBS.PATCH: {
				response = await baseAPI.patch(url, contractParams && contractParams.body);
				break;
			}
			case HTTP_VERBS.POST: {
				response = await baseAPI.post(url, contractParams && contractParams.body);
				break;
			}
			case HTTP_VERBS.PUT: {
				response = await baseAPI.put(url, contractParams && contractParams.body);
				break;
			}
			default:
				throw new ResponseError(EOLErrorCodes.UnknownFrontEndError, EOLErrorMessages.UnknownFrontEndError);
		}
		return plainToInstance(contract.Response, response) as unknown as R;
	},
};

export default API;
