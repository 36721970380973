import moment from "moment";

export enum SessionStatusType {
	inactive = "inactive",
	refresh = "refresh",
	warning = "warning",
	timeout = "timeout",
	active = "active",
}
const determineSessionStatus = (
	expiryCookie: string | null,
	heartbeat?: boolean,
	sessionModalDisplayTime?: number,
): SessionStatusType => {
	if (expiryCookie === null) {
		return SessionStatusType.inactive;
	}
	const msToExpiry = +expiryCookie - moment.now().valueOf();

	if (msToExpiry < 0) {
		return SessionStatusType.timeout;
	}
	if (heartbeat) {
		return SessionStatusType.refresh;
	}
	if (sessionModalDisplayTime && msToExpiry < sessionModalDisplayTime) {
		return SessionStatusType.warning;
	}
	return SessionStatusType.active;
};

export default determineSessionStatus;
