import { getMyInfo } from "app/common/api/user";
import { useContext } from "react";
import { IBeneficiary } from "../../pages/Steps/Beneficiaries/data";
import { ISpecificEstate } from "../../pages/Steps/EstateSpecific/data";
import { IExecutor } from "../../pages/Steps/Executor/type";
import { IResiduaryEstate } from "../../pages/Steps/ResiduaryEstate/data";
import { EWillsContext } from "./provider";
import { EWillsActionType, IEwillState, IExecutorPageDetails, IExecutors } from "./type";

export const useEwillsForm = () => {
	const { dispatch, state } = useContext(EWillsContext);

	const setBeneficiaries = (beneficiaries?: IBeneficiary[]) => {
		dispatch({ type: EWillsActionType.SET_BENEFICIARIES, payload: beneficiaries });
	};

	const setPersonalDetails = (personalDetails: getMyInfo.Response) => {
		dispatch({ type: EWillsActionType.SET_PERSONAL_DETAILS, payload: personalDetails });
	};

	const setHasSpecificEstate = (hasSpecificEstate: string) => {
		dispatch({ type: EWillsActionType.SET_HAS_SPECIFIC_ESTATE, payload: hasSpecificEstate });
	};

	const setSpecificEstate = (specificEstate?: ISpecificEstate) => {
		dispatch({ type: EWillsActionType.SET_SPECIFIC_ESTATE, payload: specificEstate });
	};

	const setResiduaryEstate = (residuaryEstate: IResiduaryEstate) => {
		dispatch({ type: EWillsActionType.SET_RESIDUARY_ESTATE, payload: residuaryEstate });
	};

	const resetForm = () => {
		dispatch({ type: EWillsActionType.RESET_FORM });
	};

	const showDevMenu = (show: boolean) => {
		dispatch({ type: EWillsActionType.SHOW_DEV_MENU, payload: show });
	};

	const setFormRendering = (loading: boolean) => {
		setTimeout(
			() => {
				dispatch({ type: EWillsActionType.SET_RENDERING_FORM, payload: loading });
			},
			loading ? 0 : 800,
		);
	};

	const setLoading = (loading: boolean) => {
		dispatch({ type: EWillsActionType.SET_EWILLS_LOADING_STATE, payload: loading });
	};

	const setIsSingpassFlow = (isSingpassFlow: boolean) => {
		dispatch({ type: EWillsActionType.SET_IS_SINGPASS_FLOW, payload: isSingpassFlow });
	};

	// ============== step 5 content management ==============
	const setExecutor = (payload: IExecutor) => {
		dispatch({
			type: EWillsActionType.SET_EXECUTOR,
			payload,
		});
	};
	const setHasReplacementExecutor = (hasReplacementExecutor: string) => {
		dispatch({ type: EWillsActionType.SET_HAS_REPLACEMENT_EXECUTOR, payload: hasReplacementExecutor });
	};
	const setReplacementExecutorToTakePlace = (replacementExecutorToTakePlace: string) => {
		dispatch({
			type: EWillsActionType.SET_REPLACEMENT_EXECUTOR_TO_TAKE_PLACE,
			payload: replacementExecutorToTakePlace,
		});
	};

	const setExecutors = (payload: IExecutors) => {
		dispatch({
			type: EWillsActionType.SET_EXECUTORS,
			payload,
		});
	};

	const setReplacementExecutor = (payload: IExecutor) => {
		dispatch({
			type: EWillsActionType.SET_REPLACEMENT_EXECUTOR,
			payload,
		});
	};

	const removeExecutor = (key: string) => {
		dispatch({ type: EWillsActionType.REMOVE_EXECUTORS, payload: key });
	};

	const setExecutorPageDetails = (payload: IExecutorPageDetails) => {
		dispatch({ type: EWillsActionType.SET_EXECUTOR_DETAILS, payload });
	};

	const setExecutorNric = (key: string, nric: string) => {
		dispatch({
			type: EWillsActionType.SET_EXECUTOR_NRIC,
			payload: {
				key,
				nric,
			},
		});
	};

	const setEwillsData = (data: IEwillState) => {
		dispatch({
			type: EWillsActionType.SET_EWILLS_DATA,
			payload: data,
		});
	};

	const setHasRedirected = () => {
		dispatch({
			type: EWillsActionType.SET_HAS_REDIRECTED,
			payload: true,
		});
	};

	const setLatestStep = (step: number) => {
		dispatch({
			type: EWillsActionType.SET_LATEST_STEP,
			payload: step,
		});
	};

	const setHasFetchedSessionData = () => {
		dispatch({
			type: EWillsActionType.SET_HAS_FETCH_SESSION_STORAGE,
		});
	};

	const setPreFormSteps = (step: number) => {
		dispatch({
			type: EWillsActionType.SET_PRE_FORM_STEP,
			payload: step,
		});
	};

	return {
		// states
		data: state,
		personalDetails: state.personalDetails,
		beneficiaries: state.beneficiaries,
		hasSpecificEstate: state.hasSpecificEstate,
		specificEstate: state.specificEstate,
		residuaryEstate: state.residuaryEstate,
		executorPageDetails: state.executorPageDetails,
		beneficiariesHistoryMap: state.beneficiariesHistoryMap,
		beneficiariesNricMap: state.beneficiariesNricMap,
		isSingpassFlow: state.isSingpassFlow,

		// navigation handling
		latestStep: state.latestStep,
		hasRedirected: state.hasRedirected,
		devMenu: state.showDevMenu,
		hasAttemptedToFetchSessionStorage: state.hasAttemptedToFetchSessionStorage,
		preformSteps: state.preFormSteps,

		// step 5 content management
		executors: state.executors,
		executorCount: state.executorCount,
		executorNric: state.executorNric,
		hasReplacementExecutor: state.hasReplacementExecutor,
		replacementExecutorToTakePlace: state.replacementExecutorToTakePlace,
		replacementExecutor: state.replacementExecutor,

		// loading state
		isRenderingForm: state.isRenderingForm,
		isLoading: state.isLoading,

		// dispatches
		setPersonalDetails,
		setBeneficiaries,
		setSpecificEstate,
		setHasSpecificEstate,
		setResiduaryEstate,
		resetForm,
		setLoading,
		setFormRendering,
		showDevMenu,
		setIsSingpassFlow,
		setExecutor,
		setHasReplacementExecutor,
		setReplacementExecutorToTakePlace,
		setExecutors,
		setReplacementExecutor,
		removeExecutor,
		setExecutorPageDetails,
		setExecutorNric,
		setEwillsData,
		setHasRedirected,
		setLatestStep,
		setHasFetchedSessionData,
		setPreFormSteps,
	};
};
