import { Color } from "@lifesg/react-design-system/color";
import { MediaQuery } from "@lifesg/react-design-system/media";
import NricLabel from "app/components/common/NricLabel";
import { Grid } from "semantic-ui-react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

export const ModalMessage = styled.p`
	margin: 0 auto;
	word-break: break-all;

	${MediaQuery.MinWidth.mobileL} {
		display: block;

		> a {
			font-weight: 600;
			display: block;
			margin: 0 auto;
		}
	}
`;

export const ModalWrapper = styled.p`
	margin: 0.5rem 0 2rem;
	display: block;
	text-align: center;

	* a {
		display: inline-block;
		font-weight: 600;
	}

	${MediaQuery.MaxWidth.mobileL} {
		> p {
			display: inline;
			margin: 0 auto;
			word-break: keep-all;
		}

		* a {
			display: inline;
			word-break: keep-all;
		}
	}
`;

export const getTruncateStyle = (lines?: number): FlattenSimpleInterpolation =>
	css`
		display: -webkit-box !important;
		-webkit-line-clamp: ${lines ?? 3};
		-webkit-box-orient: vertical;
		overflow: hidden;
		word-break: break-word;
	`;

export const BoldedSpan = styled.span`
	font-weight: 600;
`;

export const HdbPropertySubtitle = styled.p`
	margin: 0;
`;

export const GridColumnWithoutPaddingBlock = styled(Grid.Column)<{ $mobileMargin?: string }>`
	padding-block: 0 !important;
	padding: 0 !important;

	@media screen and (max-width: 1199px) {
		:not(:first-child) {
			${({ $mobileMargin }) =>
				css`
					margin: ${$mobileMargin ?? "1rem 0 0"};
				`}
		}

		* .ui {
			margin: 1rem 0;
		}
	}
`;

export const ContentTitle = styled.p`
	color: ${Color.Neutral[1]};
	font-size: 1.375rem;
	font-weight: 400;
	line-height: 1.75rem;
	margin: 0rem;
	padding: 0 !important;
`;

export const MaskedBody = styled(NricLabel)`
	margin: 1.1875rem 0;

	${MediaQuery.MinWidth.mobileL} {
		margin-right: 2rem;
	}
`;

export const ContentBody = styled.p`
	color: ${Color.Neutral[2]};
	margin: 0;
	padding: 0.6875rem 0;
	font-weight: normal !important;

	${MediaQuery.MinWidth.mobileL} {
		margin-right: 2rem;
	}
`;

export const ContentWrapper = styled.div`
	max-width: 55.3125rem;
	${MediaQuery.MaxWidth.tablet} {
		max-width: 100%;
	}
`;

export const Ul = styled.ul`
	margin: 0.5rem 0;
	padding-left: 1.5rem;
`;

export const Li = styled.li`
	::marker {
		font-size: 0.75rem;
		color: ${Color.Neutral[2]};
	}
	:not(:first-child) {
		margin-top: 0.5rem;
	}
	list-style-type: disc;
`;
