import { isEqual } from "lodash";
import { createContext, useReducer } from "react";
import { EWillsAction, EWillsActionType, IEwillContextType, IEwillState } from "./type";
// =============================================================================
// DEFAULT VALUES
// =============================================================================
export const initialValues: IEwillState = {
	beneficiaries: undefined,
	personalDetails: undefined,
	hasSpecificEstate: "",
	specificEstate: undefined,
	residuaryEstate: undefined,
	isRenderingForm: false,
	showDevMenu: false,
	isSingpassFlow: undefined,
	isLoading: false,
	beneficiariesHistoryMap: {},
	beneficiariesNricMap: {},
	executors: {},
	hasReplacementExecutor: "",
	replacementExecutorToTakePlace: "",
	executorCount: 0,
	executorPageDetails: {
		additionalExecutors: {},
		selectExecutors: [],
	},
	executorNric: {},
	replacementExecutor: {},
	hasRedirected: false,
	latestStep: 0,
	hasAttemptedToFetchSessionStorage: false,
	preFormSteps: 0,
};

// =============================================================================
// REDUCER
// =============================================================================
export const eWillsReducer = (state: IEwillState, action: EWillsAction): IEwillState => {
	switch (action.type) {
		case EWillsActionType.SET_PERSONAL_DETAILS: {
			return {
				...state,
				personalDetails: action.payload,
			};
		}
		case EWillsActionType.SET_BENEFICIARIES: {
			const beneficiariesHistoryMap = state.beneficiariesHistoryMap;
			let beneficiariesNricMap = {};
			let count = Object.keys(beneficiariesHistoryMap).length;
			if (action.payload) {
				action.payload.forEach(
					(beneficiary) =>
						(beneficiariesHistoryMap[beneficiary.nric] = {
							name: beneficiary.name,
							index: beneficiariesHistoryMap[beneficiary.nric]?.index ?? count++,
						}),
				);
				beneficiariesNricMap =
					action.payload?.reduce((nricMap, beneficiary) => {
						return { ...nricMap, [beneficiary.nric]: beneficiary.name };
					}, {}) ?? {};
			}
			return {
				...state,
				beneficiaries: action.payload,
				beneficiariesHistoryMap,
				beneficiariesNricMap,
			};
		}
		case EWillsActionType.RESET_FORM: {
			return {
				...initialValues,
				beneficiariesHistoryMap: initialValues.beneficiariesHistoryMap,
				executorPageDetails: {
					additionalExecutors: {},
					selectExecutors: [],
				},
				executors: {},
				// do not reset these fields
				showDevMenu: state.showDevMenu,
				isSingpassFlow: state.isSingpassFlow,
				hasAttemptedToFetchSessionStorage: state.hasAttemptedToFetchSessionStorage,
				hasRedirected: state.hasRedirected,
				preFormSteps: state.preFormSteps,
			};
		}
		case EWillsActionType.SET_RENDERING_FORM: {
			return {
				...state,
				isRenderingForm: action.payload,
			};
		}
		case EWillsActionType.SET_IS_SINGPASS_FLOW: {
			return {
				...state,
				isSingpassFlow: action.payload,
			};
		}
		case EWillsActionType.SHOW_DEV_MENU: {
			return {
				...state,
				showDevMenu: action.payload,
			};
		}
		case EWillsActionType.SET_EWILLS_LOADING_STATE: {
			return {
				...state,
				isLoading: action.payload,
			};
		}
		case EWillsActionType.SET_HAS_SPECIFIC_ESTATE: {
			return {
				...state,
				hasSpecificEstate: action.payload,
			};
		}
		case EWillsActionType.SET_HAS_REPLACEMENT_EXECUTOR: {
			return {
				...state,
				hasReplacementExecutor: action.payload,
			};
		}
		case EWillsActionType.SET_REPLACEMENT_EXECUTOR_TO_TAKE_PLACE: {
			return {
				...state,
				replacementExecutorToTakePlace: action.payload,
			};
		}
		case EWillsActionType.SET_SPECIFIC_ESTATE: {
			return {
				...state,
				specificEstate: action.payload,
			};
		}
		case EWillsActionType.SET_RESIDUARY_ESTATE: {
			return {
				...state,
				residuaryEstate: action.payload,
			};
		}
		case EWillsActionType.SET_EXECUTOR: {
			//Only increment if the key does not already exist
			const executorCount =
				state.executorCount + (!action.payload.key || !state.executors[action.payload.key] ? 1 : 0);

			return {
				...state,
				executors: {
					...state.executors,
					[action.payload.key ?? ""]: action.payload,
				},
				executorCount,
			};
		}
		case EWillsActionType.SET_REPLACEMENT_EXECUTOR: {
			return {
				...state,
				replacementExecutor: action.payload,
			};
		}
		case EWillsActionType.REMOVE_EXECUTORS: {
			delete state.executors[action.payload];
			delete state.executorNric[action.payload];
			const executorPageDetails = state.executorPageDetails;
			if (executorPageDetails) {
				if (action.payload in executorPageDetails.additionalExecutors) {
					delete executorPageDetails.additionalExecutors[action.payload];
					executorPageDetails.selectExecutors = executorPageDetails.selectExecutors.filter(
						(executor) => executor !== action.payload,
					);
				}
			}
			return {
				...state,
				executors: {
					...state.executors,
				},
				executorPageDetails,
				executorNric: {
					...state.executorNric,
				},
			};
		}

		case EWillsActionType.SET_EXECUTORS: {
			return {
				...state,
				executors: action.payload,
			};
		}

		case EWillsActionType.SET_EXECUTOR_DETAILS: {
			return {
				...state,
				executorPageDetails: action.payload,
				executors: action.payload.additionalExecutors,
				latestStep: 5,
			};
		}

		case EWillsActionType.SET_EXECUTOR_NRIC: {
			const executorNric = { ...state.executorNric };
			if (action.payload.nric) {
				executorNric[action.payload.key] = action.payload.nric;
			} else {
				delete executorNric[action.payload.key];
			}

			return {
				...state,
				executorNric: !isEqual(executorNric, state.executorNric) ? executorNric : state.executorNric,
			};
		}

		case EWillsActionType.SET_EWILLS_DATA: {
			return {
				...state,
				...action.payload,
				hasRedirected: state.hasRedirected ?? false,
				hasAttemptedToFetchSessionStorage: true,
				showDevMenu: state.showDevMenu,
			};
		}

		case EWillsActionType.SET_HAS_REDIRECTED: {
			return {
				...state,
				hasRedirected: action.payload,
			};
		}

		case EWillsActionType.SET_LATEST_STEP: {
			return {
				...state,
				latestStep: action.payload,
			};
		}
		case EWillsActionType.SET_HAS_FETCH_SESSION_STORAGE: {
			return {
				...state,
				hasAttemptedToFetchSessionStorage: true,
			};
		}

		case EWillsActionType.SET_PRE_FORM_STEP: {
			return {
				...state,
				preFormSteps: action.payload,
			};
		}

		default:
			return state;
	}
};

// =============================================================================
// CONTEXT
// =============================================================================
export const EWillsContext = createContext<IEwillContextType>({
	state: initialValues,
	dispatch: () => null,
});

// =============================================================================
// CONTEXT PROVIDER
// =============================================================================
export const EWillsProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
	const [eWillsState, dispatch] = useReducer(eWillsReducer, initialValues);

	return <EWillsContext.Provider value={{ state: eWillsState, dispatch }}>{children}</EWillsContext.Provider>;
};
