import { MaskedInputProps } from "@lifesg/react-design-system/masked-input";
import { useRef } from "react";
import { HiddenSpan, StyledMaskedInput } from "./styles";

const NricLabel = (props: MaskedInputProps): JSX.Element => {
	const { value, maskRange = [1, 4] } = props;
	const spanRef = useRef<HTMLElement>(null);

	return (
		<>
			<StyledMaskedInput
				key={value as string}
				{...props}
				maskRange={maskRange}
				readOnly
				width={spanRef.current?.offsetWidth || 100}
			/>
			<HiddenSpan ref={spanRef}>{value}</HiddenSpan>
		</>
	);
};

export default NricLabel;
