export const getSizeWithUnit = (size: number): string => {
	let finalSize;
	let loopCount = 0;
	while (size > 900) {
		size = size / 1000;
		loopCount++;
	}
	finalSize = Math.ceil(size);

	// @TODO: set a reasonable default finalSize
	switch (loopCount) {
		case 0:
			finalSize = finalSize + "B";
			break;
		case 1:
			finalSize = finalSize + "KB";
			break;
		case 2:
			finalSize = finalSize + "MB";
			break;
		default:
			finalSize = "> 900MB";
			break;
	}
	return finalSize;
};

export const formatFileName = (fileName: string): string => {
	const MAX_FILE_LENGTH = 234;
	const splitFile = fileName.split(".");
	const extension = splitFile.pop();
	let formattedFileName = splitFile
		.join()
		.split(/[^a-zA-Z0-9()]/g)
		.filter(Boolean)
		.join("-");

	if (formattedFileName.length === 0) {
		formattedFileName = "file";
	} else if (formattedFileName.length > MAX_FILE_LENGTH) {
		const extraCharLength = formattedFileName.length - MAX_FILE_LENGTH;
		const breakpoint = Math.floor(formattedFileName.length / 2);
		const firstHalf = formattedFileName.substring(0, breakpoint);
		const secondHalf = formattedFileName.substring(breakpoint + extraCharLength);
		formattedFileName = firstHalf + "-" + secondHalf;
	}

	return formattedFileName + `.${extension}`;
};
