import { IsEmail } from "@wog/mol-lib-api-contract/utils/validators";
import { EMAIL_MAX_CHARS } from "app/common/email";
import { Transform, Type } from "class-transformer";
import { IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength, ValidateIf, ValidateNested } from "class-validator";
import { BaseSharedAccessDO } from "common/api/sharedAccess/domainObjects";
import moment from "moment";
import { IsLocalPhoneNumber, IsMoment, IsNRIC, xorConstraint } from "../../../validators";
import { toMoment, toNumber, toUppercase } from "../../transformers";
import { TrustedConnectionStatusTypes } from "../enums";
export class TrustedPersonDO {
	@IsString()
	@IsNRIC()
	@Transform(toUppercase)
	public nric!: string;

	@IsString()
	@MaxLength(120)
	public nickname!: string;

	@xorConstraint("email", { message: "Enter either mobile phone or email" })
	@ValidateIf((object: TrustedPersonDO) => !object.email || (object.phone !== undefined && object.phone !== null)) // null is to allow get to return phone: null
	@IsNotEmpty()
	@IsLocalPhoneNumber()
	public phone!: string;

	@xorConstraint("phone", { message: "Enter either mobile phone or email" })
	@ValidateIf((object: TrustedPersonDO) => !object.phone || (object.email !== undefined && object.email !== null))
	@IsNotEmpty()
	@IsEmail({ maxLength: EMAIL_MAX_CHARS })
	public email!: string;
}

export class GetTrustedPersonDO extends TrustedPersonDO {
	@IsMoment()
	@Transform(toMoment)
	public invitedOn!: moment.Moment;
	@IsOptional()
	@IsString()
	public status!: TrustedConnectionStatusTypes;
	@Transform(toNumber)
	@IsNumber()
	public inviteAttemptsLeft!: number;
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
	@ValidateNested({ each: true })
	@Type(() => BaseSharedAccessDO)
	public sharedAccess!: BaseSharedAccessDO[];
	@IsOptional()
	@IsMoment()
	@Transform(toMoment)
	public declinedOn?: moment.Moment;
}

export class FullTrustedPersonDO extends TrustedPersonDO {
	@IsMoment()
	@Transform(toMoment)
	public createdAt!: string;
	@IsMoment()
	@Transform(toMoment)
	public updatedAt!: string;
	@Transform(toNumber)
	@IsNumber()
	public id!: number;
	@IsMoment()
	@Transform(toMoment)
	public invitedOn!: moment.Moment;
	@IsString()
	public status!: TrustedConnectionStatusTypes;
	@Transform(toNumber)
	@IsNumber()
	public inviteAttempts!: number;
}
