import React from "react";
import ReadOnlyField from "../../../../common/ReadOnlyField";

interface IProps {
	motherName?: string;
	decIdentificationNumber: string;
	decCertNo: string;
	decDateOfDeath: string;
}

const StillbirthSection = (props: IProps): JSX.Element => {
	const { motherName, decIdentificationNumber, decCertNo, decDateOfDeath } = props;

	return (
		<>
			<h5>Stillbirth details</h5>

			{motherName ? (
				<ReadOnlyField
					id="readonly-field__mother-name"
					title="Full Name (as in NRIC or FIN)"
					value={motherName}
				/>
			) : null}

			<ReadOnlyField
				id="readonly-field__mother-nric"
				title="NRIC or FIN or Passport number"
				value={decIdentificationNumber}
			/>

			<h5 className="no-margin-top">Baby{"'"}s details</h5>

			<ReadOnlyField
				id="readonly-field__stillbirth-cert-no"
				title="Stillbirth identification no"
				value={decCertNo}
			/>

			<ReadOnlyField id="readonly-field__date-of-stillbirth" title="Date of stillbirth" value={decDateOfDeath} />
		</>
	);
};

export default StillbirthSection;
