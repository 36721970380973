import { DateTimeFormatter, LocalDate } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import "@js-joda/timezone";
import { MYSQL_DATE_TIME_FORMAT } from "app/common/date";
import { IDateValue } from "app/components/basic/Date";
import { dateToString } from "app/components/basic/Date/utils";
import moment from "moment";

const format = "D MMM YYYY";

/**
 * Function to convert expiry date
 * Today is day 1, expires on day 21
 * So if invitation is sent on 1 May, the expiry is 22 May 000hrs, display to user as expire on 21 May
 * Therefore it adds 20 days to display the date as 21 May
 */
export const getFormattedExpiryDate = (invitedDate: string | moment.Moment): string => {
	if (moment.isMoment(invitedDate)) {
		// clone the moment object because moment objects are mutable.
		// add days will alter the original object.
		return moment(invitedDate).startOf("day").add(20, "days").format(format);
	}

	return moment(invitedDate, MYSQL_DATE_TIME_FORMAT).startOf("day").add(20, "days").format(format);
};

export const getFormattedDate = (date: string | moment.Moment | IDateValue): string => {
	if (moment.isMoment(date)) {
		return date.format(format);
	}

	if ("string" === typeof date) {
		return moment(date, MYSQL_DATE_TIME_FORMAT).format(format);
	}

	return moment(dateToString(date)).format(format);
};

export const getFormattedJSJodaDate = (date?: string | undefined): string | undefined => {
	try {
		if (!date) {
			return undefined;
		}
		return LocalDate.parse(date).format(DateTimeFormatter.ofPattern("d MMMM yyy").withLocale(Locale.ENGLISH));
	} catch (error) {
		return undefined;
	}
};
