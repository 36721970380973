export enum TrustedConnectionStatusTypes {
	INVITED = "INVITED",
	ACCEPTED = "ACCEPTED",
	DECLINED = "DECLINED",
	REVOKED = "REVOKED",
	EXPIRED = "EXPIRED",
	OTPLOCKED = "OTPLOCKED",
}

export enum AccessSetting {
	NOT_ALLOWED = "NOT_ALLOWED",
	ACCESSIBLE_BEFORE_DEATH = "ACCESSIBLE_BEFORE_DEATH",
	ACCESSIBLE_AFTER_DEATH = "ACCESSIBLE_AFTER_DEATH",
	DOES_NOT_EXIST = "DOES_NOT_EXIST",
}
