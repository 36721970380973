import { EOLErrorCodes } from "app/common/errors";
import ResponseError from "app/common/responseError";
import { EOLErrorMessages } from "app/common/errors";
import _ from "lodash";
import { useErrorHandler } from "../useErrorHandler";
import { useCallback, useState } from "react";

export const useShowErrorPage = (targetCount = 3): (() => void) => {
	const { sendError } = useErrorHandler();
	const [count, setCount] = useState(0);

	const reset = useCallback(() => {
		setCount(0);
	}, []);

	const showErrorPage = useCallback(() => {
		sendError(new ResponseError(EOLErrorCodes.ServerError, EOLErrorMessages.ServerError));
		reset();
	}, [sendError, reset]);

	const addCount = useCallback(() => {
		setCount((val) => {
			return val + 1;
		});
		if (count >= targetCount) showErrorPage();
	}, [count, showErrorPage, targetCount]);

	return addCount;
};
