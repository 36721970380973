import { ChevronRightIcon } from "@lifesg/react-icons/chevron-right";
import BasicLink from "app/components/basic/Link";
import { ILinkItem } from "app/utils/interfaces";
import { lowercaseAndHyphen } from "app/utils/strings";
import * as React from "react";
import { Breadcrumb as SemanticBreadcrumb } from "semantic-ui-react";
import "./Breadcrumb.scss";

interface IProps {
	currentPageName: string;
	pages: ILinkItem[];
	hideFirstCrumbInDesktop?: boolean;
}

interface IState {
	scrolledToEnd: boolean;
}

class Breadcrumb extends React.Component<IProps, IState> {
	public state: IState = {
		scrolledToEnd: false,
	};

	private refCallback = (element: HTMLDivElement): void => {
		if (element) {
			const breadcrumbComponent: Element = element.children[0];

			if (breadcrumbComponent.scrollWidth === breadcrumbComponent.clientWidth) {
				// equals means the breadcrumb is not scrollable
				this.setState({ scrolledToEnd: true });
			}
		}
	};

	private onScroll = (e): void => {
		const scrolledToEnd = e.target.scrollWidth - Math.ceil(e.target.scrollLeft) === e.target.clientWidth;
		this.setState({ scrolledToEnd });
	};

	private generateBreadcrumb = (pages: ILinkItem[], currentPageName: string): JSX.Element[] => {
		const breadcrumbComponents: JSX.Element[] = [];

		pages.forEach((page: ILinkItem, index: number): void => {
			const pageId = `breadcrumb--${lowercaseAndHyphen(page.name)}`;
			const linkKey = `breadcrumb-link-${index}`;
			const dividerKey = `breadcrumb-divider-${index}`;

			const link = <BasicLink id={pageId} href={page.path} name={page.name} />;
			const divider = (
				<div className="divider-icon" key={dividerKey}>
					<ChevronRightIcon />
				</div>
			);

			breadcrumbComponents.push(
				<small key={linkKey} id={linkKey} className="breadcrumb-section">
					{link}
				</small>,
			);
			breadcrumbComponents.push(divider);
		});

		breadcrumbComponents.push(
			<small key="breadcrumb-active" id="breadcrumb-active" className="active">
				{currentPageName}
			</small>,
		);

		return breadcrumbComponents;
	};

	public render = (): JSX.Element => (
		<div
			className={`breadcrumbs ${
				this.props.hideFirstCrumbInDesktop && this.props.pages.length === 1 ? "hide" : ""
			}`}
			ref={this.refCallback}
		>
			<SemanticBreadcrumb
				className={`disable-scrollbars ${this.props.hideFirstCrumbInDesktop ? "hide-first-child" : ""}`}
				onScroll={this.onScroll}
			>
				{this.generateBreadcrumb(this.props.pages, this.props.currentPageName)}
			</SemanticBreadcrumb>
			<div className={!this.state.scrolledToEnd ? "breadcrumbs__fade-right" : ""} />
		</div>
	);
}

export default Breadcrumb;
