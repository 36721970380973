import moment, { Moment } from "moment";
import { IDateValue } from "./";

export const dateToString = (date?: IDateValue): string => {
	if (!date || !date?.day || !date?.month || !date?.year) {
		return "";
	}

	// YYYY-MM-DD
	return `${date.year}-${date.month}-${date.day}`;
};

export const dateToMoment = (date: string | IDateValue): Moment => {
	// could return invalid date (intended so that form can validate for errors)
	if (typeof date === "string") return moment(date, "YYYY-MM-DD");
	return moment(dateToString(date), "YYYY-MM-DD");
};

export const formatDateValue = (date: string | IDateValue | Moment): IDateValue => {
	/**
	 * {
	 *  'year': '',
	 *  'month': '',
	 *  'day': ''
	 * }
	 *
	 * is a valid date because that is the default value and will be this value if field is not required.
	 * Need special handling for this.
	 */
	if (date === "") {
		return { day: "", month: "", year: "" };
	}

	if (date === "Invalid date") {
		return { day: "Invalid date", month: "Invalid date", year: "Invalid date" };
	}

	// to check the date is IDateValue
	if (isIDateValue(date)) {
		return date;
	}

	const momentObj = typeof date === "string" ? dateToMoment(date) : date;
	return { day: momentObj.format("DD"), month: momentObj.format("MM"), year: momentObj.format("YYYY") };
};

const isIDateValue = (object: any): object is IDateValue => {
	return (
		typeof object === "object" &&
		"day" in object &&
		"month" in object &&
		"year" in object &&
		Object.keys(object).length === 3
	);
};
