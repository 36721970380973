import * as React from "react";
import "./Navbar.Vault.scss";
import NavLink from "./NavLink";
class NavbarVault extends React.Component<{}, {}> {
	public render = (): JSX.Element => {
		const isSecureSharingPage = (): boolean => {
			const regexp = new RegExp(/(secure-sharing)/);

			return regexp.test(window.location.pathname);
		};
		return (
			<div className="navbar navbar__vault">
				<div className="navbar__vault__content">
					{/* <div className='navbar__vault__links'> */}
					<NavLink type="secondary" title="Dashboard" href="/vault" active={!isSecureSharingPage()} />
					<NavLink
						type="secondary"
						title="Secure sharing"
						href="/vault/secure-sharing"
						active={isSecureSharingPage()}
					/>
					{/* </div> */}
				</div>
			</div>
		);
	};
}

export default NavbarVault;
