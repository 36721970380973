import { AcpBaseUrl, AcpUrl } from "app/modules/acp/constants";
import { NodBaseUrl, NodUrl } from "app/modules/notice-of-death/constants";

export const showSimplifiedHeaderAndFooter = (pathname: string): boolean => {
	return (
		pathname?.includes(`${AcpBaseUrl}/step`) ||
		pathname?.includes(`${NodBaseUrl}/step`) ||
		pathname?.includes(AcpUrl.nextSteps) ||
		pathname?.includes(AcpUrl.userUnder21Years) ||
		pathname?.includes(NodUrl.nextSteps) ||
		pathname?.includes(NodUrl.under21)
	);
};
