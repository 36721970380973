import { FileScanResult } from "app/common/api/fileManagement";
import * as contracts from "app/common/api/fileManagement/routes";
import axios, { AxiosResponse } from "axios";
import API from "../client.contract";

interface IFile extends Blob {
	name: string;
	size: number;
}

const getSignedPostForm = async (contentType: string): Promise<any> => {
	const q = new contracts.getPutObjectURL.Query();
	q.contentType = contentType;
	return await API.callV2<any>(contracts.getPutObjectURL, { query: q });
};

const scanFile = async (s3UniqueName: string): Promise<FileScanResult> => {
	const p = new contracts.getScanFile.Params();
	p.s3UniqueName = s3UniqueName;

	const response = await API.callV2<contracts.getScanFile.Response>(contracts.getScanFile, { params: p });
	return response.result;
};

const putObjectToBucket = async (
	form: any,
	file: IFile,
	callback: (percentageCompleted: number) => void,
): Promise<object> => {
	const formData = new FormData();

	formData.append("Content-Type", file.type);
	Object.entries(form.fields).forEach(([key, value]: [string, any]) => formData.append(key, value));
	formData.append("file", file);

	const response = await fetch(form.url, {
		method: "POST",
		body: formData,
	});

	callback(100);
	return response;
};

const getFile = async (s3UniqueName: string): Promise<AxiosResponse> => {
	const response = await axios.get(`/api/users/download/${s3UniqueName}`, { responseType: "blob" });
	return response;
};

const FileManagementAPI = {
	getSignedPostForm,
	scanFile,
	putObjectToBucket,
	getFile,
};

export default FileManagementAPI;
