import { Type } from "class-transformer";
import { ArrayMinSize, IsBoolean, IsEnum, IsNumber, ValidateNested } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { SharedAccessTrustedConnectionDO } from "../domainObjects";
import { AccessType } from "../enums";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/users/access";
export class Body {
	@IsBoolean()
	public isAccessibleBeforeDeath!: boolean;

	@IsEnum(AccessType)
	public accessType!: AccessType;

	@ArrayMinSize(1)
	@IsNumber(undefined, { each: true })
	public trustedConnectionIDs!: number[];
}
export class Response {
	@ValidateNested({ each: true })
	@Type(() => SharedAccessTrustedConnectionDO)
	public sharedAccess!: SharedAccessTrustedConnectionDO[];
}
