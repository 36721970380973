import { assignToDomainObject } from "../../../utils/domainObject";
import { Transform, Type } from "class-transformer";
import {
	ArrayMaxSize,
	ArrayMinSize,
	IsBoolean,
	IsNumber,
	IsOptional,
	IsString,
	Max,
	MaxLength,
	ValidateNested,
} from "class-validator";
import moment from "moment";
import { IsDate, IsMoment, IsNotWhiteSpace, IsTodayOrBefore } from "../../../validators";
import { DocumentDO } from "../../commonDomainObjects";
import { type IDateValue } from "../../interface";
import { toMoment, toNumber } from "../../transformers";
import { DoneeDO, FullDoneeDO } from "./donee";

export class LastingPowerOfAttorneyDO {
	@IsNumber(undefined, { always: true })
	@Max(5, { always: true })
	public currStep!: number;

	@IsOptional({ groups: ["step0"] })
	@IsDate({ always: true })
	@IsTodayOrBefore({ always: true })
	public registeredDate!: IDateValue;

	@IsOptional({ groups: ["step0"] })
	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public referenceNumber!: string;

	@ArrayMinSize(1, { groups: ["step2", "step3", "step4", "step5", "edit"] })
	@ArrayMaxSize(3, { always: true })
	@ValidateNested({ each: true, always: true })
	@Type(() => DoneeDO)
	public donees: DoneeDO[] = [];

	@IsOptional({ groups: ["step0", "step1", "step2"] })
	@IsString({ always: true })
	@IsNotWhiteSpace({ always: true })
	@MaxLength(120, { always: true })
	public physicalDocumentLocation!: string;

	@ArrayMinSize(0, { always: true })
	@ArrayMaxSize(10, { always: true })
	@ValidateNested({ each: true })
	@Type(() => DocumentDO)
	public documents: DocumentDO[] = [];

	@IsOptional({ always: true })
	@IsString({ always: true })
	@MaxLength(1000)
	public additionalNotes!: string;
}
const lastingPowerOfAttorneyDOKeys: Array<keyof LastingPowerOfAttorneyDO> = [
	"currStep",
	"registeredDate",
	"referenceNumber",
	"donees",
	"physicalDocumentLocation",
	"documents",
	"additionalNotes",
];

export class FullLastingPowerOfAttorneyDO extends LastingPowerOfAttorneyDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public createdAt!: moment.Moment;
	@IsMoment()
	@Type(() => moment)
	@Transform(toMoment)
	public updatedAt!: moment.Moment;

	@ValidateNested({ each: true })
	@Type(() => FullDoneeDO)
	public donees: FullDoneeDO[] = [];

	@IsOptional({ always: true })
	public getBaseDO = (): LastingPowerOfAttorneyDO => {
		let doneeArray: DoneeDO[] | undefined;
		const { donees, ...rest } = this;
		if (donees) {
			doneeArray = donees.map((donee: FullDoneeDO) => donee.getBaseDO());
		}
		// Returns object with only keys of specified DO.
		// NOTE: new DO(), instantiates to empty {} on backend
		return assignToDomainObject(
			new LastingPowerOfAttorneyDO(),
			{ ...rest, donees: doneeArray },
			lastingPowerOfAttorneyDOKeys,
		);
	};

	@IsOptional({ always: true })
	@IsBoolean({ always: true })
	public isCompleted!: boolean;
}
