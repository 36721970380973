import React from "react";
import { RemarkField, QnProps, YesOrNoShort } from "./Questions";
import { FormKeys } from "./schemas";

export const StillbirthRelationshipQnParent = (props: QnProps): JSX.Element => {
	const { afterSelectItem, form } = props;

	return (
		<YesOrNoShort
			id="isParent"
			question="Are you the parent of the stillborn child?"
			form={form}
			afterSelectItem={afterSelectItem}
		/>
	);
};

export const StillbirthRelationshipQnAuthorised = (props: QnProps): JSX.Element => {
	const { afterSelectItem, form } = props;

	return (
		<YesOrNoShort
			id="hasPermissionFromParent"
			question="Are you authorised by the child’s parent to download the stillbirth certificate?"
			afterSelectItem={afterSelectItem}
			field={FormKeys.HAS_PERMISSION_FROM_GUARDIAN}
			form={form}
		/>
	);
};

export const StillbirthRelationshipFinalQnAuthoriser = (props: QnProps): JSX.Element => {
	const { form } = props;

	return (
		<RemarkField
			question="Enter the full name of the parent who authorised the download"
			field={FormKeys.GUARDIAN_NAME}
			form={form}
		/>
	);
};
