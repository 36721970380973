import Field, { IFieldProps } from "app/components/common/Field";
import * as React from "react";
import "./Input.scss";

interface IProps extends IFieldProps {
	componentId: string;
	type?: string;
	textarea?: boolean;
	className?: string;
	onTextChange: (newValue: string) => void;
	onBlur?: () => void;
	errorMessage?: null | string;
	placeholder?: string;
	maxChar?: number;
	maxLength?: number;
	value?: string;
	inputMode?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
	prefix?: string;
	bindRef?: (ref: HTMLElement, key: string) => void;
	setFieldError?: boolean;
	hideCharCount?: boolean;
	transformUppercase?: boolean;
	ariaLabel?: string;
}

class Input extends React.Component<IProps> {
	public render = (): JSX.Element => {
		const Element: any = this.props.textarea ? "textarea" : "input";
		const extraClass: string = this.props.className || "";
		const elementClass: string = Element === "textarea" ? "textarea" : "";
		const disabledClass: string = this.props.disabled ? "disabled" : "";
		const errorClass: string = this.props.setFieldError || this.props.errorMessage ? "input-error has-error" : "";
		const prefixClass: string = this.props.prefix ? "input--with-prefix" : "";
		// special handling for numberString type.
		// html input type should still set to text to be able to have type as string and use maxLength
		// use regex to prevent user from entering non digit value
		const inputType: string = this.props.type
			? this.props.type === "numberString"
				? "number"
				: this.props.type
			: "text";
		const inputAriaLabel = this.props.ariaLabel ? { "aria-label": this.props.ariaLabel } : {};
		return (
			<Field {...this.props}>
				<div
					className={`input__wrapper ${prefixClass} ${errorClass ? "input__wrapper--error" : ""}`}
					style={{ width: this.props.prefix ? "100%" : "auto", maxWidth: "100%", resize: "none" }}
				>
					{this.props.prefix ? <div className="input__prefix">{this.props.prefix}</div> : ""}
					<Element
						{...inputAriaLabel}
						ref={this.props.bindRef}
						name={this.props.name}
						id={this.props.componentId}
						key={this.props.componentId}
						placeholder={this.props.placeholder}
						type={inputType}
						value={this.props.value || ""}
						className={`first-focusable input ${extraClass} ${elementClass} ${disabledClass} ${errorClass} ${prefixClass}`}
						maxLength={this.props.maxChar || null}
						onChange={this.textChange}
						onInput={this.textChange}
						onBlur={this.props.onBlur}
						onKeyDown={this.checkInputValue}
						onKeyUp={this.checkInputValue}
						onPaste={this.onPaste}
						inputMode={this.props.inputMode}
						style={{ width: this.props.prefix ? "auto" : "100%", maxWidth: "100%", resize: "none" }}
						autoCapitalize={this.props.type === "email" ? "off" : "on"}
						disabled={this.props.disabled}
					/>
				</div>

				{this.props.maxChar && this.props.textarea && !this.props.hideCharCount && this.updateCharLeft()}
			</Field>
		);
	};

	private textChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		let value = e.target.value;

		if (this.props.maxChar) {
			if (e.target.value && e.target.value.length > this.props.maxChar) {
				value = value.slice(0, this.props.maxChar);
			}
		}

		if (this.props.transformUppercase) {
			value = value.toUpperCase();
		}
		this.props.onTextChange(value);
	};

	private checkInputValue = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (this.props.type === "number" || this.props.type === "numberString") {
			const { keyCode, shiftKey, which, ctrlKey, metaKey } = e;
			const isNumericChar =
				(/[0-9]|\./.test(String.fromCharCode(keyCode)) && !shiftKey) || (keyCode >= 96 && keyCode <= 105); // For keyCodes coming form the numeric keypad, you need to compare against the numeric keyCode instead of using a Regex:
			const isInputCommand =
				(keyCode >= 36 && keyCode <= 40) || // Input command entered like one of the 4 direction home, up, down, left and right (36 - 40)
				keyCode === 46 || // or delete (46)
				which === 8 || // or backspace (8)
				ctrlKey ||
				(metaKey && (keyCode === 67 || keyCode === 65 || keyCode === 88 || keyCode === 86)); // or pressed Ctrl/Cmd + C(67)/A(65)/X(88)/V(86)

			if (!isNumericChar && !isInputCommand) {
				// don't allow non numeric value
				e.preventDefault();
			}
		}
	};

	private onPaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
		if (e.type === "paste" && (this.props.type === "number" || this.props.type === "numberString")) {
			const pasteValue = e.clipboardData.getData("text/plain");
			// remove the unwanted characters
			let newValue = pasteValue;
			newValue = newValue.trim();
			newValue = newValue.replace(/[\D\.\+\-]/g, "");

			// trim string to maxChar
			if (this.props.maxChar) {
				newValue = newValue.substring(0, this.props.maxChar);
			}
			e.preventDefault();
			// return the updated value
			this.props.onTextChange(newValue);
		}
	};

	private updateCharLeft = (): JSX.Element => {
		const currentWordCount = this.props.value ? this.props.value.length : 0;
		return (
			<p className="input--max-length">
				{currentWordCount} of {this.props.maxChar!} characters
			</p>
		);
	};
}

export default Input;
