import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { InvitationDO } from "../domainObjects";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/invitations";
export class Response {
	@ValidateNested({ each: true })
	@Type(() => InvitationDO)
	public invitations!: InvitationDO[];
}
