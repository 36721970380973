import "./Display.scss";

interface IProps {
	id: string;
	title?: string;
	content?: string[];
	jsxList?: JSX.Element[];
}

const DisplayList = (props: IProps): JSX.Element => {
	const titleId = props.id + "-title";
	const valueId = props.id + "-value";

	const content = (
		<ul className="list-content" id={valueId}>
			{props.jsxList}
			{props.content?.map((item: string, index: number) => (
				<li key={index}>{item}</li>
			))}
		</ul>
	);

	return (
		<div className="display-content">
			{props.title && (
				<small className="title" id={titleId}>
					{props.title}
				</small>
			)}

			{content}
		</div>
	);
};

export default DisplayList;
