import { Alert } from "@lifesg/react-design-system/alert";
import {
	ChevronRightIcon,
	ExclamationCircleFillIcon,
	ExclamationTriangleFillIcon,
	ICircleFillIcon,
	TickCircleFillIcon,
} from "@lifesg/react-icons";
import BasicLink from "app/components/basic/Link";
import { CSSProperties } from "react";
import "./styles.scss";

interface AlertBannerLink {
	name: string;
	url: string;
}

export type BannerType = "base" | "info" | "success" | "warning" | "error";

export interface AlertBannerProps extends React.HTMLAttributes<HTMLDivElement> {
	/** type of banner */
	type?: BannerType;
	/** link in the banner */
	link?: AlertBannerLink;
	/** banner with icon */
	icon?: boolean;
	textstyle?: CSSProperties | undefined;
}

const AlertBanner = (props: AlertBannerProps): JSX.Element => {
	const type = props.type || "error";
	const className = `alert-banner-container alert-banner-container--${type}`;
	const lifesgType = props.type && props.type != "base" && props.type != "info" ? props.type : "error";

	const LinkIcon = (): JSX.Element => <ChevronRightIcon className="ds-icon ds-icon--size-16 ds-icon--info" />;

	const renderLink = (link: AlertBannerLink) => {
		return (
			<BasicLink
				id="sample"
				name={link.name}
				href={link.url}
				className="semi-bold link-font-sixteen alert-banner-container__link"
				openInNewPage={true}
				IconRight={LinkIcon}
			/>
		);
	};

	const renderIcon = (type: BannerType) => {
		switch (type) {
			case "base":
				return <ICircleFillIcon className="ds-icon ds-icon--size-24" />;
			case "info":
				return <ICircleFillIcon className="ds-icon ds-icon--size-24 ds-icon--info" />;
			case "success":
				return <TickCircleFillIcon className="ds-icon ds-icon--size-24 ds-icon--success" />;
			case "warning":
				return <ExclamationTriangleFillIcon className="ds-icon ds-icon--size-24 ds-icon--warning" />;
			case "error":
				return <ExclamationCircleFillIcon className="ds-icon ds-icon--size-24 ds-icon--error" />;
		}
	};

	return (
		<Alert data-testid={props["data-testid"] as string} type={lifesgType} className={className}>
			<span style={props.textstyle}>
				{props.icon && renderIcon(props.type || "error")}
				{props.children}
			</span>
			{props.link && renderLink(props.link)}
		</Alert>
	);
};

export default AlertBanner;
