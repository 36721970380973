import { AcpStatus } from "app/common/constant/acp";
import { IsEnum, IsBoolean, IsString, IsOptional } from "class-validator";
import { DraftDO } from "common/api/draft";

export class AcpFormDashboardDO {
	@IsBoolean()
	@IsOptional()
	acpPermissionGranted!: boolean;

	@IsEnum(AcpStatus)
	acpStatus!: AcpStatus;

	@IsString()
	acpForm!: string | null;
}

export class AcpStatusDO {
	@IsBoolean()
	hasAcpDraft!: boolean;

	@IsEnum(AcpStatus)
	acpStatus!: AcpStatus;

	draftData!: DraftDO | undefined;
}
