import { Redactor } from "@wog/mol-lib-api-contract/utils/redactor/Redactor";
import ReadOnlyField from "app/components/common/ReadOnlyField";
import React from "react";
import SectionHeader from "../SectionHeader";
interface IProps {
	title: string;
	name?: string;
	identificationNumber?: string;
	phoneNumber?: string;
	email?: string;
	onClickEdit?: () => void;
}

const UserDetailsSection = (props: IProps): JSX.Element => {
	const { title, name, identificationNumber, phoneNumber, email, onClickEdit } = props;

	return (
		<>
			<SectionHeader id="edit-personal-details" title={title} onClickEdit={onClickEdit} />

			<ReadOnlyField id="readonly-field__requestor-name" title="Full Name (as in NRIC or FIN)" value={name} />

			<ReadOnlyField
				id="readonly-field__requestor-nric"
				title="NRIC or FIN"
				value={Redactor.UINFIN_REDACTOR.redactValue(identificationNumber ?? "")}
			/>

			<ReadOnlyField id="readonly-field__requestor-phone-number" title="Mobile Number" value={phoneNumber} />

			<ReadOnlyField id="readonly-field__requestor-email" title="Email address" value={email} />
		</>
	);
};

export default UserDetailsSection;
