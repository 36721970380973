export const NodBaseUrl = "/find-a-service/lodge-notice-of-death";

export const LEGAL_AGE = 21;

export enum NodFormStep {
	PERSONAL_DETAILS = "step1", // TEMP - COULD BE CHANGE
}

export const NodUrl = {
	landingPage: NodBaseUrl,
	under21: NodBaseUrl + "/user-under-21-years-old",
	personalDetails: NodBaseUrl + "/" + NodFormStep.PERSONAL_DETAILS,
	nextSteps: NodBaseUrl + "/next-steps",
	allowAccess: NodBaseUrl + "/allow-access",
};

export const NodStepURLs: { [key in NodFormStep]: string } = {
	[NodFormStep.PERSONAL_DETAILS]: NodUrl.personalDetails,
};
