import { Checkbox as DSCheckbox } from "@lifesg/react-design-system/checkbox";
import { IFieldProps } from "app/components/common/Field";
import InputElement from "app/components/common/InputElement";
import { SelectExecutorErrors } from "app/modules/eWills/pages/Steps/Executor/components/constant";
import { lowercaseAndHyphen } from "app/utils/strings";
import { kebabCase } from "lodash";
import { cloneElement } from "react";
import "./Checkbox.scss";

export interface ICheckboxItem {
	label: string;
	value?: string;
	disabled?: boolean;
	subtitle?: string;
	labelBold?: boolean;
	overwriteRenderedContent?: JSX.Element | JSX.Element[];
	onRemove?: () => void;
}

interface ICheckboxProps extends IFieldProps {
	disabled?: boolean;
	items: ICheckboxItem[];
	horizontal?: boolean;
	selectedValues: string[];
	updateSelectedValues: (newSelectedValues: string[]) => void;
	loading?: boolean;
	className?: string;
	errorMessage?: string;
	addonText?: string;
	/**
	 * Number of lines of a label to display before truncation
	 * Used for very long names
	 */
	labelLines?: number;
}

const Checkbox = (props: ICheckboxProps): JSX.Element => {
	if (props.loading === true) {
		return <div className="checkbox__loading" />;
	}

	const renderCheckboxItem = (item: ICheckboxItem, index: number): JSX.Element => {
		const id = `checkbox-${lowercaseAndHyphen(`${(props.title as string) || "field"}-${item.label}`)}`;
		let className = "checkbox__item";

		if (item.disabled || props.disabled) {
			className += " disabled";
		}

		if (props.errorMessage && props.errorMessage !== SelectExecutorErrors.MORE_THAN_2) {
			className += " error";
		}

		if (props.className) {
			className += ` ${props.className}`;
		}

		const itemValue = item.value ?? item.label;
		const checked = props.selectedValues.indexOf(itemValue) > -1;

		if (checked) {
			className += " checked";
		}

		const toggleItem = (): void => {
			if (item.disabled || props.disabled) return;

			const selectedValue = String(itemValue);
			const newValues = Object.assign([], props.selectedValues);
			const index = newValues.indexOf(selectedValue);

			if (index > -1) {
				newValues.splice(index, 1);
			} else {
				newValues.push(selectedValue);
			}

			props.updateSelectedValues(newValues);
		};

		const renderOverWriteContent = (item: ICheckboxItem) => {
			const Component = cloneElement(item.overwriteRenderedContent as any, {
				disabled: item.disabled || props.disabled,
				item,
				toggleItem,
				checked,
				itemValue,
				labelLines: props.labelLines,
				className,
			});
			return Component;
		};

		if (item.overwriteRenderedContent) {
			return renderOverWriteContent(item);
		}

		return (
			<div
				key={`${id}-${index}`}
				onClick={toggleItem}
				className={className}
				data-testid={`${props.name}__checkbox`}
			>
				<DSCheckbox
					disabled={item.disabled || props.disabled}
					id={id}
					className={"checkbox__item__button"}
					name={id}
					value={itemValue}
					checked={checked}
					displaySize={"small"}
					data-testid={`checkbox-${kebabCase(props.name)}-${index}`}
				/>
				<div
					className="checkbox__option-label-container"
					data-testid={`checkbox-label-${kebabCase(props.name)}-${index}`}
				>
					<label
						className={
							item.labelBold
								? "checkbox__option-title "
								: "checkbox__option-label " + (props.labelLines ? "truncate" : "")
						}
						style={{ WebkitLineClamp: props.labelLines }}
					>
						{item.label}
					</label>
					{item.subtitle && (
						<div className="ds__text__body-x-small radio-box__item-subtitle mt8">{item.subtitle}</div>
					)}
				</div>
			</div>
		);
	};

	let className = "checkbox-group";

	if (props.horizontal) {
		className += " horizontal";
	}

	return (
		<div className={className}>
			{props.items.map((item: ICheckboxItem, index) => renderCheckboxItem(item, index))}
		</div>
	);
};

export default InputElement(Checkbox);
