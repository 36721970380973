import { IsEnum } from "class-validator";
import { AccessType } from "../../sharedAccess/enums";
import { AccessSetting } from "../enums";

export class SharedPlanAccessSetting {
	@IsEnum(AccessType)
	public type!: AccessType;
	@IsEnum(AccessSetting)
	public setting!: AccessSetting;
}
