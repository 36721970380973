import { Type } from "class-transformer";
import { IsEnum } from "class-validator";
import { ValidateNested } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { SharedAccessTrustedConnectionDO } from "../domainObjects";
import { AccessType } from "../enums";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/access/:accessType";
export class Params {
	@IsEnum(AccessType)
	public accessType!: AccessType;
}
export class Response {
	@ValidateNested({ each: true })
	@Type(() => SharedAccessTrustedConnectionDO)
	public sharedAccess!: SharedAccessTrustedConnectionDO[];
}
