import { DoneeDO } from "app/common/api/lpaAcp";
import { createContext, useContext, useState } from "react";
import { DoneeContextType } from "./types";

const DoneeContext = createContext<DoneeContextType>({
	donees: [],
	isSameDonees: true,
	setIsSameDonees: () => false,
	setDonees: () => [],
});

export const DoneeProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
	const [donees, setDonees] = useState<DoneeDO[]>([]);
	const [isSameDonees, setIsSameDonees] = useState<boolean>(true);
	return (
		<DoneeContext.Provider value={{ donees, isSameDonees, setIsSameDonees, setDonees }}>
			{children}
		</DoneeContext.Provider>
	);
};

export const useLpaDonee = () => {
	const { donees, isSameDonees, setDonees, setIsSameDonees } = useContext(DoneeContext);

	const checkIsSameDonees = (currDonees: DoneeDO[], prevDonees: DoneeDO[]) => {
		if (currDonees.length !== prevDonees.length) {
			return false;
		}

		const sortedPrevDonees = [...prevDonees].sort((a, b) => a.nric.localeCompare(b.nric));
		const sortedCurrDonees = [...currDonees].sort((a, b) => a.nric.localeCompare(b.nric));
		return JSON.stringify({ ...sortedPrevDonees }) === JSON.stringify({ ...sortedCurrDonees });
	};

	return {
		checkIsSameDonees,
		setIsSameDonees,
		donees,
		isSameDonees,
		setDonees,
	};
};
