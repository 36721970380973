import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import { validateUinfin } from "@wog/mol-lib-api-contract/utils/data/sg-uinfin";

// tslint:disable-next-line: ban-types
export function IsIdentificationPaper(
	property: string | null,
	validationOptions?: ValidationOptions,
): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "IsIdentificationPaper",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string, args: ValidationArguments): boolean {
					const documentType =
						property && args.object && args.object.hasOwnProperty(property) ? args.object[property] : null;
					return validateIdentification(documentType, value);
				},
				defaultMessage(args: ValidationArguments): string {
					const variable = args.property;
					const documentType =
						property && args.object && args.object.hasOwnProperty(property) ? args.object[property] : null;
					return generateErrorMessage(variable, documentType, args.value);
				},
			},
		});
	};
}

export const validateIdentification = (documentType: string, value: string): boolean => {
	if (!value || value.toString().trim().length > 9) {
		return false;
	}

	const alphaNumericPattern = /^[A-Z0-9]*$/;

	if (documentType === "NRIC/FIN") {
		return alphaNumericPattern.test(value) && validateUinfin(value);
	}

	return true;
};

const generateErrorMessage = (variable: string, documentType: string, value: string): string => {
	if (typeof value !== "string") {
		return `${variable} must be a string`;
	}

	if (value.length === 0) {
		return `${variable} should not be empty`;
	}

	if (value.length !== 9) {
		return `${variable} must be equal to 9 characters`;
	}

	if (documentType === "NRIC/FIN") {
		return `${value} is not a valid nric`;
	}

	if (documentType === "Passport") {
		return `${value} is not a valid passport number`;
	}

	return `${value} is not valid NRIC/FIN/Passport no.`;
};
