import { Color } from "@lifesg/react-design-system/color";
import { Text } from "@lifesg/react-design-system/text";
import { BaseTheme } from "@lifesg/react-design-system/theme";
import styled from "styled-components";

export const CharacterCountLabel = styled(Text.H6)`
	margin-top: 0.25rem;
	color: ${Color.Neutral[3]({ theme: BaseTheme })};
`;

export const CompositeOptionErrorMessage = styled(Text.H6)`
	color: ${Color.Validation.Red.Text};
	margin-top: 0.5rem;
	margin-bottom: 0;
	outline: none;
`;
