import { LocalDate } from "@js-joda/core";
import { FullAppointedPersonDO } from "app/common/api/appointedPerson/domainObjects";
import { DoneeType, DoneeTypeDisplayNames } from "app/common/api/lpa";
import { DoneeDO } from "app/common/api/lpaAcp/domainObjects";
import { getKeyByValue } from "app/utils/object";
import { AppointPersonEnum } from "./types";

export interface IAppointPersonModal<T> {
	personIndex: number | undefined;
	personList: T[];
	closeCallback: () => void;
	updatePersonList: (updatedList: T[]) => void;
	donees?: DoneeDO[];
	type?: AppointPersonEnum;
	userNric?: string;
}

export const formatAsCoordinators = (fetchedCoordinators: FullAppointedPersonDO[]): FullAppointedPersonDO[] => {
	return fetchedCoordinators.map((coordinator: FullAppointedPersonDO) => {
		const formattedPerson = new FullAppointedPersonDO();
		formattedPerson.name = coordinator.name;
		formattedPerson.relationship = coordinator.relationship;
		formattedPerson.contact = coordinator.contact;
		return formattedPerson;
	});
};

export const getDoneeTypeServerName = (doneeTypeLabel: string): DoneeType => {
	return getKeyByValue(DoneeTypeDisplayNames, doneeTypeLabel) as DoneeType;
};

export const convertToLocalDateObject = (dob: { year: number; month: number; day: number }): LocalDate => {
	const { year, month, day } = dob;
	return LocalDate.of(year, month, day);
};

export const isUniquePerson = (nric: string, personList: DoneeDO[]): boolean =>
	personList.every((person) => person.nric !== nric);

export const isUser = (nric: string, userNric: string): boolean => nric === userNric;

export const isMainDonee = (nric: string, mainDonees: DoneeDO[]): boolean =>
	!!mainDonees.find((mainDonee) => mainDonee.nric === nric);
