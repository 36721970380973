import { EMAIL_MAX_CHARS } from "app/common/email";
import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

// tslint:disable-next-line: ban-types
export function IsEmail(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isEmail",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string): boolean {
					return validateEmail(value);
				},
				defaultMessage(args: ValidationArguments): string {
					return args.value && String(args.value).length > EMAIL_MAX_CHARS
						? `Email must not be longer than ${EMAIL_MAX_CHARS} characters.`
						: `${args.value} is not a valid email address.`;
				},
			},
		});
	};
}

export const validateEmail = (value: string): boolean => {
	if (!value || value.length > EMAIL_MAX_CHARS) {
		return false;
	}

	const emailPattern =
		/(^$|(^([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
	return emailPattern.test(value);
};
