import "./Display.scss";

interface IProps {
	className?: string;
	type?: "review-single" | "review-double";
}

// defaults to shape of display text
const DisplayLoading = (props: IProps): JSX.Element => {
	return (
		<div className={`display-content display-content__loading ${props.className}`}>
			{!props.type && (
				<>
					<div className="title__loading" />
					<div className="content__loading" />
				</>
			)}
			{props.type === "review-double" && (
				<>
					<div className="title__loading title__loading--double" />
					<div className="content__loading content__loading--double" />
				</>
			)}
			{props.type === "review-single" && <div className="content__loading content__loading--single" />}
		</div>
	);
};

export default DisplayLoading;
