import { HealthcareSpokespersonDO } from "app/common/api/acp";
import { AppointedPersonDO } from "app/common/api/appointedPerson";
import { DoneeDO } from "app/common/api/lpa";
import { DoneeTypeDisplayNames } from "app/common/api/lpa/enums";
import { ExecutorDO } from "app/common/api/wills/domainObjects";
import Modal from "app/components/basic/Modal";
import ErrorMessage from "app/components/widgets/ErrorMessage";
import { useEffect, useState } from "react";
import AddPersonButton from "./common/AddPersonButton";
import PersonFieldCard from "./common/PersonFieldCard";
import { IAppointedPerson, IFullAppointedPerson, IFullAppointedPersonWithPowers } from "./common/interfaces";
import { TAppointPerson } from "./common/types";
import NewAppointedPersonModal from "./forms/AppointedPerson";
import NewCoordinatorModal from "./forms/Coordinator";
import NewDoneeModal from "./forms/Donee";
import NewExecutorModal from "./forms/Executor";
import NewFullAppointedPersonModal from "./forms/FullAppointedPerson";
import NewReplacementModal from "./forms/Replacement";
import NewSpokespersonModal from "./forms/Spokesperson";
import "./styles.scss";

export type TAppointPersonDO =
	| AppointedPersonDO
	| DoneeDO
	| HealthcareSpokespersonDO
	| ExecutorDO
	| IAppointedPerson
	| IFullAppointedPerson
	| IFullAppointedPersonWithPowers;

const labelMap: { [key in TAppointPerson]: string } = {
	coordinator: "coordinator",
	donee: "donee",
	spokesperson: "spokesperson",
	executor: "executor",
	replacement: "replacement person",
	appointedPerson: "appointed person",
	fullAppointedPersonWithPowers: "appointed person",
};

interface IProps {
	title?: string;
	type: TAppointPerson;
	value: TAppointPersonDO[];
	errorMessage?: null | string;
	updateValue: (updatedList: TAppointPersonDO[]) => void;
	disabled?: boolean;
	max?: number;
}

const AppointPersonGroup = ({ ...props }: IProps): JSX.Element => {
	const [personList, setPersonList] = useState<TAppointPersonDO[]>([]);
	const [editingPersonIndex, setEditingPersonIndex] = useState<number | undefined>(undefined);
	const [coordinatorModal, showCoordinatorModal] = useState(false);
	const [deleteModal, showDeleteModal] = useState(false);

	useEffect(() => setPersonList(props.value || []), [props.value]);

	const updateParentValue = (updatedList: TAppointPersonDO[]): void => {
		props.updateValue(updatedList);
	};

	const openCoordinatorModal = (): void => showCoordinatorModal(true);

	const closeDeleteModal = (): void => {
		showDeleteModal(false);
		setEditingPersonIndex(undefined);
	};

	const closeCoordinatorModal = (): void => {
		showCoordinatorModal(false);
		setEditingPersonIndex(undefined);
	};

	const deleteCoordinator = async (): Promise<void> => {
		const newList: TAppointPersonDO[] = personList;
		if (undefined !== editingPersonIndex) {
			newList.splice(editingPersonIndex, 1);
			props.updateValue(newList);
		}
		setEditingPersonIndex(undefined);
		closeDeleteModal();
	};

	const addButtonLabel = `Add ${labelMap[props.type]}`;
	const deleteModalSubtitle = `You are removing this ${labelMap[props.type]}.`;
	return (
		<>
			{props.title && <p className="paragraph-no-margin">{props.title}</p>}
			<div className="coordinator__button-group">
				{personList.map((person: TAppointPersonDO, index: number) => {
					const { name, relationship } = person;
					let cardSummary: string | undefined;

					if ("doneeType" in person) {
						cardSummary = DoneeTypeDisplayNames[person.doneeType];
					}

					if ("powers" in person && person.powers.length) {
						cardSummary =
							person.powers.length === 2
								? `Both ${person.powers[0].toLowerCase()} and ${person.powers[1].toLowerCase()}`
								: person.powers[0];
					}

					const onEdit = (): void => {
						setEditingPersonIndex(index);
						openCoordinatorModal();
					};
					const onRemove = (): void => {
						setEditingPersonIndex(index);
						showDeleteModal(true);
					};

					return (
						<PersonFieldCard
							key={index}
							index={index}
							name={name}
							relationship={relationship}
							summary={cardSummary}
							onEdit={onEdit}
							onRemove={onRemove}
							disabled={props.disabled}
						/>
					);
				})}
				{(!props.max || personList.length < props.max) && (
					<AddPersonButton
						onClick={openCoordinatorModal}
						disabled={props.disabled}
						errorMessage={props.errorMessage || undefined}
						buttonLabel={addButtonLabel}
					/>
				)}
				{coordinatorModal && props.type === "donee" && (
					<NewDoneeModal
						personIndex={editingPersonIndex}
						personList={personList as DoneeDO[]}
						closeCallback={closeCoordinatorModal}
						updatePersonList={updateParentValue}
					/>
				)}
				{coordinatorModal && props.type === "coordinator" && (
					<NewCoordinatorModal
						personIndex={editingPersonIndex}
						personList={personList as AppointedPersonDO[]}
						closeCallback={closeCoordinatorModal}
						updatePersonList={updateParentValue}
					/>
				)}
				{coordinatorModal && props.type === "spokesperson" && (
					<NewSpokespersonModal
						personIndex={editingPersonIndex}
						personList={personList as HealthcareSpokespersonDO[]}
						closeCallback={closeCoordinatorModal}
						updatePersonList={updateParentValue}
					/>
				)}
				{coordinatorModal && props.type === "executor" && (
					<NewExecutorModal
						personIndex={editingPersonIndex}
						personList={personList as ExecutorDO[]}
						closeCallback={closeCoordinatorModal}
						updatePersonList={updateParentValue}
					/>
				)}
				{coordinatorModal && props.type === "fullAppointedPersonWithPowers" && (
					<NewFullAppointedPersonModal
						personIndex={editingPersonIndex}
						personList={personList as IFullAppointedPersonWithPowers[]}
						closeCallback={closeCoordinatorModal}
						updatePersonList={updateParentValue}
					/>
				)}
				{coordinatorModal && props.type === "appointedPerson" && (
					<NewAppointedPersonModal
						personIndex={editingPersonIndex}
						personList={personList as IAppointedPerson[]}
						closeCallback={closeCoordinatorModal}
						updatePersonList={updateParentValue}
					/>
				)}
				{coordinatorModal && props.type === "replacement" && (
					<NewReplacementModal
						personIndex={editingPersonIndex}
						personList={personList as IFullAppointedPerson[]}
						closeCallback={closeCoordinatorModal}
						updatePersonList={updateParentValue}
					/>
				)}

				{deleteModal && (
					<Modal
						id="modal__delete-coordinator"
						type="message"
						title="Are you sure you want to remove?"
						subTitle={deleteModalSubtitle}
						isOpen={deleteModal}
						closeCallback={closeDeleteModal}
						button1={["Do not remove", "secondary", closeDeleteModal]}
						button2={["Remove", "primary", deleteCoordinator]}
					/>
				)}
			</div>
			{props.errorMessage && <ErrorMessage id="coordinator__error">{props.errorMessage}</ErrorMessage>}
		</>
	);
};

export default AppointPersonGroup;
