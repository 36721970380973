export type TAppointPerson =
	| "coordinator"
	| "donee"
	| "spokesperson"
	| "executor"
	| "fullAppointedPersonWithPowers"
	| "appointedPerson"
	| "replacement";

export enum AppointPersonEnum {
	COORDINATOR = "coordinator",
	DONEE = "Donee",
	SPOKESPERSON = "spokesperson",
	EXECUTOR = "executor",
	APPOINTED_PERSON = "appointed person",
	REPLACEMENT_PERSON = "Replacement Donee",
}

export enum Powers {
	PERSONAL_WELFARE = "Personal Welfare",
	PROPERTY_AND_AFFAIRS = "Property and Affairs",
	CARE_VALUES = "care values",
}
