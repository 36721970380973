import * as React from "react";
import "./ReadOnlyField.scss";

export interface IReadOnlyFieldProps {
	id: string;
	title: string;
	value?: string;
	children?: JSX.Element;
}

const ReadOnlyField = (props: IReadOnlyFieldProps): JSX.Element => {
	const { id, title, value, children } = props;

	if (!value && !children) {
		return <></>;
	}

	return (
		<div id={id} className="read-only-field">
			<div className="read-only-field__title">{title}</div>
			<div className="value">{value ?? children}</div>
		</div>
	);
};

export default ReadOnlyField;
