import { Transform } from "class-transformer";
import { IsNumber } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";
import { AcceptInvitationDO } from "../domainObjects";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/users/invitations/:invitationId";
export class Params {
	@IsNumber()
	@Transform(toNumber)
	public invitationId!: number;
}
export class Response extends AcceptInvitationDO {}
