export enum ProgressBarColors {
	//ProgressBar
	Neutral = "#ECEFEF",
	InformationBackground = "#E8F4FA",
	Success = "#7DDBA3",
	Error = "#DC6363",
	Primary = "#0C7BB3",

	//Labels
	ErrorLabel = "#CB2213",
	PrimaryLabel = "#066391",
	NeutralLabel = "#686868",
	SuccessLabel = "#2A854E",
}
