import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

// tslint:disable-next-line: ban-types
export function IsVehicle(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isVehicle",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string): boolean {
					return validateVehicleNumber(value);
				},
				defaultMessage(args: ValidationArguments): string {
					return `${args.value} is not a valid local license plate number.`;
				},
			},
		});
	};
}

export const validateVehicleNumber = (value: string): boolean => {
	try {
		if (value === null || value === undefined) {
			return false;
		}

		value = value.replace(/\s/g, "");

		if (value.length > 8) {
			return false; // vehicle number length is not valid;
		}

		const finalDigit = value.charAt(value.length - 1); // 'G'
		value = value.slice(0, -1);

		if (!finalDigit.match(/^[A-Z]+$/)) {
			return false; // final digit is not an alphabet
		}

		// reject if vehicle number is not capitalised
		// splitAlphanumeric will only return array of less than 2 if not properly capitalised
		const splitAlphanumeric = value.match(/[A-Z]+|\d+/g); // ['SHA', '1234']

		if (!splitAlphanumeric || splitAlphanumeric.length < 2) {
			return false;
		}

		let alpha = splitAlphanumeric[0]; // 'SHA'
		const numeric = splitAlphanumeric[1]; // '1234'

		const digitArr = [0, 0, 0, 0, 0, 0];
		const multiplierArr = [9, 4, 5, 4, 3, 2];

		if (alpha.length > 3) {
			return false; // can only have max of 3 alpha characters;
		} else if (alpha.length === 3) {
			alpha = alpha.substring(1);
		}

		for (let i = alpha.length - 1; i >= 0; i--) {
			digitArr[i] = alpha[i].charCodeAt(0) - 64;
		}

		for (let i = numeric.length - 1; i >= 0; i--) {
			const position = digitArr.length - (numeric.length - i);
			digitArr[position] = +numeric[i];
		}

		const multipliedSum = multiplierArr.reduce((accumulator: number, currentValue: number, index: number) => {
			return accumulator + currentValue * digitArr[index];
		}, 0);

		const remainder = multipliedSum % 19;
		const validEndAlphaArr = [
			"A",
			"Z",
			"Y",
			"X",
			"U",
			"T",
			"S",
			"R",
			"P",
			"M",
			"L",
			"K",
			"J",
			"H",
			"G",
			"E",
			"D",
			"C",
			"B",
		];
		return validEndAlphaArr[remainder] === finalDigit;
	} catch {
		return false;
	}
};
