export const SelectExecutorErrors = {
	MORE_THAN_2: "Select 2 persons at most.",
	ZERO_EXECUTOR: "Select or add at least 1 person.",
	ZERO_REPLACEMENT_EXECUTOR: "Select or add 1 person.",
	ADDED_BUT_NOT_SELECTED: "Select remove if you do not want this person to be an executor.",
	REPLACEMENT_EXECUTOR_ADDED_BUT_NOT_SELECTED:
		"Select remove if you do not want this person to be a Replacement executor.",
	YES_OR_NO: "Select Yes or No.",
	SELECT_SUBSTITUTE: "Select who the replacement executor should be a substitute for.",
};

export enum ExecutorsKey {
	SELECT_EXECUTOR = "selectExecutor",
	ADDITIONAL_EXECUTORS = "addExecutor",
	HAS_REPLACEMENT_EXECUTOR = "hasReplacementExecutor",
	REPLACEMENT_EXECUTOR = "replacementExecutor",
	ADDITIONAL_REPLACEMENT_EXECUTOR = "additionalReplacementExecutor",
	REPLACEMENT_EXECUTOR_TO_TAKE_PLACE = "replacementExecutorToTakePlace",
	BENEFICIARIES_EXECUTOR = "beneficiariesExecutor",
}

export const executorTitle = "Choose 1 or 2 executors";
