export enum HTTP_VERBS {
	GET = "GET",
	POST = "POST",
	PATCH = "PATCH",
	PUT = "PUT",
	DELETE = "DELETE",
	UNDEFINED = "UNDEFINED",
}

export enum StringBoolean {
	TRUE = "true",
	FALSE = "false",
}
