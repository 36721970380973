import { OTPContactType, OTPType } from "app/common/api/otp";
import { ResponseOtpDO } from "app/common/api/otp/domainObjects";
import * as otpContracts from "app/common/api/otp/routes";
import API from "../client.contract";

const sendOTP = async (type: OTPType, contactType: OTPContactType, contact: string): Promise<ResponseOtpDO> => {
	const b = new otpContracts.postSendOTP.Body();
	b.contact = contact;
	b.contactType = contactType;
	b.type = type;

	return await API.callV2<otpContracts.postSendOTP.Response>(otpContracts.postSendOTP, { body: b });
};

const resendOTP = async (
	type: OTPType,
	contactType: OTPContactType,
	contact: string,
	invitationId?: number,
): Promise<ResponseOtpDO> => {
	const b = new otpContracts.postResendOTP.Body();
	b.contact = contact;
	b.contactType = contactType;
	b.type = type;
	b.invitationId = invitationId;

	return await API.callV2<otpContracts.postResendOTP.Response>(otpContracts.postResendOTP, { body: b });
};

const verifyOTP = async (
	type: OTPType,
	userInputOTP: number,
	contact: string,
	invitationId?: number,
): Promise<otpContracts.postVerifyOTP.Response> => {
	const b = new otpContracts.postVerifyOTP.Body();
	b.contact = contact;
	// input type is set to number to only allow numerical input
	// so we convert it on the api call level to string
	b.userInputOTP = userInputOTP.toString();
	b.type = type;
	b.invitationId = invitationId;

	return await API.callV2<otpContracts.postVerifyOTP.Response>(otpContracts.postVerifyOTP, { body: b });
};

const OTPApi = {
	resendOTP,
	sendOTP,
	verifyOTP,
};

export default OTPApi;
