import { shortenString } from "app/utils/strings";
import "./Display.scss";

interface IProps {
	id: string;
	title?: string;
	content?: string;
	trimString?: boolean;
	truncateText?: boolean;
	isMoney?: boolean;
	isNoRecord?: boolean;
	children?: React.ReactNode;
}

const DisplayText = ({
	id,
	title,
	content,
	trimString,
	truncateText,
	isMoney,
	isNoRecord,
	children,
}: IProps): JSX.Element => {
	const titleId = id + "-title";
	const valueId = id + "-value";

	const renderDisplayContent = () => {
		if (children) {
			return children;
		}

		return content ? formatContent(content) : "-";
	};

	const formatContent = (content: string) => {
		let displayContent = content;

		if (trimString) {
			displayContent = shortenString(displayContent);
		}

		if (isMoney && content === "0") {
			displayContent = "-";
		}

		if (isMoney && content !== "0") {
			displayContent = `$${content}`;
		}

		return displayContent;
	};

	let parentClassName = "display-content";
	let contentClassName = "content";
	if (truncateText) {
		contentClassName = contentClassName + " wrap-text";
		parentClassName = parentClassName + " wrap-text";
	}

	if (isNoRecord) {
		contentClassName = contentClassName + " italic";
	}

	return (
		<div className={parentClassName}>
			{title && (
				<small className="title" id={titleId}>
					{title}
				</small>
			)}
			<div className={contentClassName} id={valueId}>
				{renderDisplayContent()}
			</div>
		</div>
	);
};

export default DisplayText;
