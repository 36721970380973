import {
	ArrowLeftCircleFillIcon,
	ArrowLeftCircleIcon,
	ArrowRightCircleFillIcon,
	ArrowRightCircleIcon,
} from "@lifesg/react-icons";
import Link from "next/link";
import "./LinkNextPrev.scss";

interface IProps {
	id: string;
	href: string;
	name: string;
	direction: "next" | "previous";
	prefetch?: boolean;
	previousButtonLabel?: string;
	nextButtonLabel?: string;
	language: string;
}

const directionElement = (props: IProps): { next: JSX.Element; previous: JSX.Element } => {
	const isMalayTamil = props.language === "ml" || props.language === "ta";
	return {
		next: (
			<>
				<h5 className={`link--next-prev--text${isMalayTamil ? " shrink" : ""}`}>
					{props.nextButtonLabel || "Next"}
				</h5>
				<div>
					<ArrowRightCircleIcon className="link--next-prev__icon" />
				</div>
				<div>
					<ArrowRightCircleFillIcon className="link--next-prev__icon--hover" />
				</div>
			</>
		),
		previous: (
			<>
				<div>
					<ArrowLeftCircleIcon className="link--next-prev__icon" />
				</div>
				<div>
					<ArrowLeftCircleFillIcon className="link--next-prev__icon--hover" />
				</div>
				<h5 className={`link--next-prev--text${isMalayTamil ? " shrink" : ""}`}>
					{props.previousButtonLabel || "Previous"}
				</h5>
			</>
		),
	};
};

const NextPrev = (props: IProps): JSX.Element => (
	<Link href={props.href} passHref={true} prefetch={props.prefetch} legacyBehavior>
		<a id={`${props.direction}-${props.id}`} className={`link anchor--${props.direction} link--next-prev `}>
			<div>
				<div className={`link--next-prev-top link--${props.direction}`}>
					{directionElement(props)[props.direction]}
				</div>
				<span className={`link--next-prev-name link--${props.direction}`}>{props.name}</span>
			</div>
		</a>
	</Link>
);

export default NextPrev;
