import { DoneeDO, DoneePowerDO } from "app/common/api/lpaAcp/domainObjects";
import Modal from "app/components/basic/Modal";
import { CombinedFormKeys, DoneePowerLabel, getRelationshipLabel } from "app/components/templates/LPA-ACP/common/data";
import ErrorMessage from "app/components/widgets/ErrorMessage";
import { IFormFunctions } from "app/hooks/useForm";
import { useEffect, useState } from "react";
import AddPersonButton from "./common/AddPersonButton";
import PersonFieldCard from "./common/PersonFieldCard";
import { AppointPersonEnum, Powers } from "./common/types";
import NewAppointedPersonModalV2 from "./forms/AppointedPersonV2";
import "./styles.scss";
import NewReplacementModalV2 from "app/components/composites/AppointPerson/forms/ReplacementPersonV2";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { identifyPower } from "./helpers";

export type TAppointPersonDOV2 = DoneeDO | DoneePowerDO;
const { DONEES, REPLACEMENT_DONEES } = CombinedFormKeys;

interface IProps {
	title?: string;
	type: AppointPersonEnum;
	typeDescriptor?: string;
	value: DoneeDO[];
	errorMessage?: null | string;
	disabled?: boolean;
	max: number;
	form: IFormFunctions;
	field: string;
	donees?: DoneeDO[];
	userNric?: string;
}

const AppointPersonGroupV2 = (props: IProps): JSX.Element => {
	const [personList, setPersonList] = useState<DoneeDO[]>([]);
	const [editingPersonIndex, setEditingPersonIndex] = useState<number | undefined>(undefined);
	const [personModal, showPersonModal] = useState(false);
	const [deleteModal, showDeleteModal] = useState(false);

	const { title, type, typeDescriptor, value, errorMessage, disabled, max } = props;

	useEffect(() => setPersonList(value || []), [value]);

	const setReplacementDoneePower = (
		doneePower: DoneePowerDO,
		replacementPower: DoneePowerDO,
	): DoneePowerDO | undefined => {
		const mainDoneePower = identifyPower(doneePower.authorizePersonalWelfare, doneePower.authorizePropertyAffairs);
		const replacementDoneePower = identifyPower(
			replacementPower.authorizePersonalWelfare,
			replacementPower.authorizePropertyAffairs,
		);
		// if main donee have two power or main donee and replacement donee have the same power
		if (mainDoneePower === DoneePowerLabel.BOTH_POWER_LABEl || mainDoneePower === replacementDoneePower) {
			return replacementPower;
		}
		// if previously replacement have two power
		else if (replacementDoneePower === DoneePowerLabel.BOTH_POWER_LABEl) {
			replacementPower.authorizePersonalWelfare = doneePower.authorizePersonalWelfare;
			replacementPower.authorizePropertyAffairs = doneePower.authorizePropertyAffairs;
			return replacementPower;
		}
		// replacement donee don have same power with donee
		return undefined;
	};

	const validateAndSetReplacementDonee = (updatedList: DoneeDO[]) => {
		const doneeList = updatedList;
		const replacementDoneeList = props.form.fields[REPLACEMENT_DONEES]
			? (props.form.getValue(REPLACEMENT_DONEES) as DoneeDO[])
			: [];
		const newReplacementDoneeList: DoneeDO[] = [];

		replacementDoneeList.forEach((replacementDonee) => {
			const newReplacementDonee = new DoneeDO();
			Object.assign(newReplacementDonee, omit(replacementDonee, "powers", "isReplacement"));
			newReplacementDonee.powers = [];

			replacementDonee.powers.forEach((replacementDoneePower) => {
				const replacingMainDonee = replacementDoneePower.replacingMainDonee;
				if (replacingMainDonee) {
					const donee = doneeList.filter((donee: DoneeDO) => donee.nric === replacingMainDonee.nric)[0];
					if (donee) {
						const power = setReplacementDoneePower(donee.powers[0], replacementDoneePower);
						if (power) {
							newReplacementDonee.powers.push(power);
						}
					}
				}
			});

			if (newReplacementDonee.powers.length !== 0) {
				newReplacementDoneeList.push(newReplacementDonee);
			}
		});
		return newReplacementDoneeList;
	};

	const updatePersonList = (updatedList: DoneeDO[]): void => {
		const formField = props.form.getField(props.field);
		const key = formField ? formField.key : "";

		if (props.field === DONEES) {
			props.form.updateFieldValue(REPLACEMENT_DONEES, validateAndSetReplacementDonee(updatedList));
		}

		props.form.updateFieldValue(key, updatedList);
	};

	const openPersonModal = (): void => {
		if (type === AppointPersonEnum.REPLACEMENT_PERSON && isEmpty(props.donees)) {
			props.form.updateFieldError(
				REPLACEMENT_DONEES,
				"You must appoint at least 1 Donee before you can add a Replacement Donee",
			);
			return;
		}

		props.form.resetAllFieldError();
		showPersonModal(true);
	};

	const closeDeleteModal = (): void => {
		showDeleteModal(false);
		setEditingPersonIndex(undefined);
	};

	const closePersonModal = (): void => {
		showPersonModal(false);
		setEditingPersonIndex(undefined);
	};

	const deletePerson = async (): Promise<void> => {
		const newList: DoneeDO[] = personList;
		if (undefined !== editingPersonIndex) {
			newList.splice(editingPersonIndex, 1);
			updatePersonList(newList);
		}
		setEditingPersonIndex(undefined);
		closeDeleteModal();
	};

	const addButtonLabel = `Add ${type}`;
	const deleteModalSubtitle = `You are removing this ${type}.`;
	return (
		<>
			{title && <p className="paragraph-no-margin">{title}</p>}
			<div className="coordinator__button-group">
				{personList.map((person: DoneeDO, index: number) => {
					const { name, relationshipCode } = person;
					const powers: Powers[] = [];

					if (type === AppointPersonEnum.DONEE) {
						if (person.powers[0].authorizePersonalWelfare) {
							powers.push(Powers.PERSONAL_WELFARE);
						}
						if (person.powers[0].authorizePropertyAffairs) {
							powers.push(Powers.PROPERTY_AND_AFFAIRS);
						}
					}

					const onEdit = (): void => {
						setEditingPersonIndex(index);
						openPersonModal();
					};
					const onRemove = (): void => {
						setEditingPersonIndex(index);
						showDeleteModal(true);
					};

					const index_text =
						type === AppointPersonEnum.DONEE
							? `donee-${index}`
							: type === AppointPersonEnum.REPLACEMENT_PERSON
							? `replacement-${index}`
							: `${index}`;

					return (
						<PersonFieldCard
							key={index}
							index={index_text}
							name={name}
							relationship={getRelationshipLabel(relationshipCode)}
							onEdit={onEdit}
							onRemove={onRemove}
							disabled={disabled}
							powers={powers}
							replacement={type === AppointPersonEnum.DONEE ? undefined : person.powers}
						/>
					);
				})}

				{personList.length < max && (
					<AddPersonButton
						onClick={openPersonModal}
						disabled={disabled}
						errorMessage={errorMessage || undefined}
						buttonLabel={addButtonLabel}
						buttonDescriptor={typeDescriptor}
					/>
				)}

				{personModal && type === AppointPersonEnum.DONEE && (
					<NewAppointedPersonModalV2
						personIndex={editingPersonIndex}
						personList={personList}
						closeCallback={closePersonModal}
						updatePersonList={updatePersonList}
						type={type}
						userNric={props.userNric}
					/>
				)}

				{personModal && type === AppointPersonEnum.REPLACEMENT_PERSON && (
					<NewReplacementModalV2
						personIndex={editingPersonIndex}
						personList={personList}
						closeCallback={closePersonModal}
						updatePersonList={updatePersonList}
						donees={props.donees}
						type={type}
						userNric={props.userNric}
					/>
				)}

				{deleteModal && (
					<Modal
						id="modal__delete-coordinator"
						type="message"
						title="Are you sure you want to remove?"
						subTitle={deleteModalSubtitle}
						isOpen={deleteModal}
						closeCallback={closeDeleteModal}
						button1={["Do not remove", "secondary", closeDeleteModal]}
						button2={["Remove", "primary", deletePerson]}
					/>
				)}
			</div>
			{errorMessage && <ErrorMessage id="coordinator__error">{errorMessage}</ErrorMessage>}
		</>
	);
};

export default AppointPersonGroupV2;
