import { CrossIcon } from "@lifesg/react-icons";
import Router from "next/router";
import { useEffect, useRef, useState } from "react";
import "./NavbarDrawer.scss";
import { AvatarLogo, NavbarAvatarButton, NavbarDrawerIcon, NavbarUserInfoButton } from "./styles";
import NextLink from "next/link";

interface IProps {
	navigationLinks: JSX.Element;
	closeTerm: string;
	isVault?: boolean;
	isProfile?: boolean;
	userName?: string;
}

const NavbarDrawer = (props: IProps): JSX.Element => {
	const toggleButtonRef = useRef<HTMLButtonElement>(null);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const [isOpen, setOpen] = useState(false);
	const [isParentOpen, setIsParentOpen] = useState(false);
	const openDrawer = (): void => setOpen(true);
	const closeDrawer = (): void => setOpen(false);

	Router.events.on("routeChangeStart", closeDrawer);

	useEffect(() => {
		const nextBody = document.getElementById("__next");
		if (nextBody) {
			if (isOpen) {
				nextBody.classList.add("navbar-drawer--open");
			} else {
				nextBody.classList.remove("navbar-drawer--open");
			}
		}
	});

	useEffect(() => {
		if (!closeButtonRef.current || !toggleButtonRef.current) {
			return;
		}

		if (isOpen) {
			setIsParentOpen(true);
			closeButtonRef.current.focus();
		} else {
			toggleButtonRef.current.focus();
		}
	}, [isOpen]);

	const handleTransitionEnd = (event) => {
		if (event.propertyName === "opacity") {
			setIsParentOpen(false);
		}
	};

	return (
		<>
			{props.isProfile ? (
				<NavbarUserInfoButton
					aria-label="Open navigation drawer"
					aria-expanded={isOpen}
					ref={toggleButtonRef}
					onClick={openDrawer}
				>
					<NavbarAvatarButton id="navbar__avatar__toggle" className="navbar__avatar__button">
						{props.userName!.charAt(0)}
					</NavbarAvatarButton>
				</NavbarUserInfoButton>
			) : (
				<button
					className="navbar-drawer__toggle with-no-margin"
					id="navbar-drawer__toggle"
					aria-label="Open navigation drawer"
					aria-expanded={isOpen}
					ref={toggleButtonRef}
					onClick={openDrawer}
				>
					<NavbarDrawerIcon />
				</button>
			)}

			<div
				className={`navbar-drawer navbar-drawer--${isParentOpen ? "open" : "close"} navbar-drawer--${
					props.isVault ? "vault" : "public"
				}`}
			>
				<div
					className={`navbar-drawer__content navbar-drawer__content--${isOpen ? "open" : "close"}`}
					onTransitionEnd={handleTransitionEnd}
				>
					<div className="navbar-drawer__close-toggle">
						<NextLink href="/" passHref={true} prefetch={true}>
							<AvatarLogo
								src={"/images/simplified-logo.svg"}
								id="navbar__icon--public"
								className="navbar__icon"
								alt="My Legacy - Home"
							/>
						</NextLink>
						<button
							className="navbar-drawer__close-button"
							tabIndex={0}
							id="navbar-drawer__close-toggle"
							onClick={closeDrawer}
							ref={closeButtonRef}
						>
							<div className="navbar-drawer__close-icon">
								<CrossIcon />
							</div>
						</button>
					</div>
					<div
						className="navbar-drawer__navigation-links"
						onBlur={(event) => {
							// if tabbing away from the last nav-link, set the focus back to the close button
							if (
								event.target.parentNode &&
								Array.from(event.target.parentNode.children).indexOf(event.target) ===
									event.target.parentNode.children.length - 1
							) {
								event.stopPropagation();

								if (closeButtonRef.current) {
									closeButtonRef.current.focus();
								}
							}
						}}
					>
						{props.navigationLinks}
					</div>
				</div>
				<div
					onClick={closeDrawer}
					className={`navbar-drawer__overlay navbar-drawer__overlay--${isOpen ? "open" : "close"}`}
				/>
			</div>
		</>
	);
};

export default NavbarDrawer;
