export enum WebEnv {
	DEV = "dev",
	QE = "tst",
	E2E = "e2e",
	STG = "stg",
	LOCAL = "localhost",
}

export enum ConfigEnvVariables {
	CONFIGCAT_KEYS = "CONFIGCAT_KEYS",
	APP_ASSET_URL = "APP_ASSET_URL",
	SEARCHSG_CONFIG_ID = "SEARCHSG_CONFIG_ID",
	ENABLE_EWILLS_DEV_MENU = "ENABLE_EWILLS_DEV_MENU",
	LISTING_LAYER_BASE_URL = "LISTING_LAYER_BASE_URL",
	ASK_GOV_BASE_URL = "ASK_GOV_BASE_URL",
	SERVICESG_LIFE_PLANNING_URL = "SERVICESG_LIFE_PLANNING_URL",
}

const stringToWebEnv = (url: string) => {
	const lowerCasedUrl = url.toLowerCase();
	for (const env of Object.keys(WebEnv)) {
		if (lowerCasedUrl.indexOf(WebEnv[env]) !== -1) {
			return WebEnv[env];
		}
	}
	return null;
};

export const getWebEnv = (): WebEnv | null => {
	if (typeof window === "undefined") {
		return null;
	}

	const { host } = window.location;
	return stringToWebEnv(host);
};

export const getEnvVar = (key: ConfigEnvVariables | keyof typeof ConfigEnvVariables): string => {
	const env = getWebEnv() ?? "";

	// As NextJs will do a string replace for any process.env.xxx when we export
	// static file, we can't access the env using syntax like process.env[key]
	switch (key) {
		case ConfigEnvVariables.CONFIGCAT_KEYS:
			return process.env.CONFIGCAT_KEYS?.[env] ?? process.env.CONFIGCAT_KEYS?.["prd"];
		case ConfigEnvVariables.APP_ASSET_URL:
			return process.env.APP_ASSET_URL?.[env] ?? process.env.APP_ASSET_URL?.["prd"];
		case ConfigEnvVariables.SEARCHSG_CONFIG_ID:
			return process.env.SEARCHSG_CONFIG_ID?.[env] ?? process.env.SEARCHSG_CONFIG_ID?.["prd"];
		case ConfigEnvVariables.ENABLE_EWILLS_DEV_MENU:
			return process.env.ENABLE_EWILLS_DEV_MENU?.[env] ?? process.env.ENABLE_EWILLS_DEV_MENU?.["prd"] ?? false;
		case ConfigEnvVariables.LISTING_LAYER_BASE_URL:
			return process.env.LISTING_LAYER_BASE_URL?.[env] ?? process.env.LISTING_LAYER_BASE_URL?.["prd"];
		case ConfigEnvVariables.ASK_GOV_BASE_URL:
			return process.env.ASK_GOV_BASE_URL?.[env] ?? process.env.ASK_GOV_BASE_URL?.["prd"];
		case ConfigEnvVariables.SERVICESG_LIFE_PLANNING_URL:
			return process.env.SERVICESG_LIFE_PLANNING_URL?.[env] ?? process.env.SERVICESG_LIFE_PLANNING_URL?.["prd"];
		default:
			return "";
	}
};
