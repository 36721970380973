import { AcpStatus } from "app/common/constant/acp";
import { IsEnum, IsString } from "class-validator";

export class AcpFormSimpleDashboardDO {
	@IsEnum(AcpStatus)
	acpStatus!: AcpStatus;

	@IsString()
	acpForm!: string | null;
}
