import styled from "styled-components";
import { Text } from "@lifesg/react-design-system/text";
import { MenuIcon } from "@lifesg/react-icons/menu";
import { Button } from "@lifesg/react-design-system/button";
import { MediaQuery } from "@lifesg/react-design-system/media";
import { Color } from "@lifesg/react-design-system/color";
import { MyLegacyTheme } from "@lifesg/react-design-system/theme";
import { Navbar } from "@lifesg/react-design-system/navbar";

// do not replace these styles, unless there is a issue with masthead breakpoint changes by sgds
export const MastheadContainer = styled.div`
	background-color: #f0f0f0;

	@media screen and (min-width: 1028px) {
		margin-left: calc(-100vw + 900px + 60px);
	}
	@media screen and (min-width: 1200px) {
		margin-left: calc(-100vw + 1140px + 60px);
	}
	@media screen and (min-width: 1400px) {
		margin-left: calc(-100vw + 1320px + 60px);
	}

	@media screen and (min-width: 1500px) {
		margin-left: -8.5rem;
	}

	${MediaQuery.MaxWidth.tablet} {
		> div {
			padding: 0;
		}
	}
`;

export const NavbarContainer = styled.div`
	z-index: 99;
	background-color: white;
	position: sticky;
	top: 0;
`;

export const NavbarAvatarButton = styled(Text.Body)`
	background-color: #e8f4fa !important;
	color: #066391;
	width: 2.5rem;
	height: 2.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	font-weight: 600 !important;

	${MediaQuery.MaxWidth.tablet} {
		width: 1.5rem;
		height: 1.5rem;
		font-size: 0.875rem !important;
		margin-left: 1rem;
	}
`;

export const NotificationAlert = styled(Text.XSmall)`
	width: 1.25rem;
	height: 1.25rem;
	right: -0.625rem;
	border-radius: 3.125rem;
	background-color: #f9cb77;
	font-weight: 700 !important;
	line-height: 1.25rem;
`;

export const AvatarLogo = styled.img`
	width: 183px;
	height: auto;
`;

export const NavbarDrawerIcon = styled(MenuIcon)`
	height: 1.5rem;
	width: 1.5rem !important;
	color: #282828 !important;
`;

export const NavbarDrawerButtonWrapper = styled.div`
	margin: 1.5rem 1.25rem 2.5rem;
`;

export const NavbarDrawerButton = styled(Button.Default)`
	border: 2px solid ${Color.Primary({ theme: MyLegacyTheme })};
	width: 100%;
	padding: 0 1rem;
	font-weight: 600 !important;
`;

export const NavbarUserInfoButton = styled.button`
	background: none;
	padding: 0;
	font-size: 1.0625rem;
	line-height: 1.75rem;
	width: 3rem;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: -0.4375rem;
	cursor: pointer;
	border: 0;

	${MediaQuery.MaxWidth.tablet} {
		width: 2rem;
	}
`;

export const NotificationCountLabel = styled.div`
	display: flex;
	padding: 0;
	width: 1.25rem;
	height: 1.25rem;
	border-radius: 3.125rem;
	font-size: 0.75rem;
	background-color: #f9cb77;
	align-items: center;
	border: none;
	font-weight: 700 !important;
	margin-top: auto;
	margin-bottom: auto;
	justify-content: center;
`;

export const MyLegacyNavbar = styled(Navbar)`
	[data-testid="content-container"] {
		ul {
			margin: 0 !important;
			padding: 0 !important;
		}

		max-width: 95rem;
		${MediaQuery.MaxWidth.tablet} {
			padding: 0;
			max-width: unset;

			li {
				padding: 0 !important;
			}
		}

		@media only screen and (max-width: 1350px) {
			> nav > div {
				margin: 0 !important;
			}
		}
	}

	[data-testid="button__mobile-menu"] {
		padding-left: 1rem;
		> svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}
`;

export const NavbarText = styled.div<{
	$isSelected: boolean;
	$breakpointMaxWidth?: number;
	$useDesktopMaxWidth?: boolean;
}>`
	margin: 0;
	padding: 0;
	color: ${Color.Neutral[1]({ theme: MyLegacyTheme })};
	font-size: 1.125rem;
	line-height: 1.625rem;
	font-weight: ${(props) => (props.$isSelected ? "600" : "400")};
	word-break: initial;
	&:hover {
		color: ${Color.Primary({ theme: MyLegacyTheme })};
	}

	${(props) =>
		props.$breakpointMaxWidth &&
		`
		max-width: ${props.$useDesktopMaxWidth ? `${props.$breakpointMaxWidth}px` : `unset`};

		@media only screen and (max-width: 1350px) {
			max-width: ${props.$breakpointMaxWidth}px;
		}
	`}
`;

export const ProfileNameWrapper = styled(Text.Body)`
	border-bottom: 1px solid #e0e4e5;
	padding-bottom: 1rem;
	padding-left: 1.25rem;
	padding-right: 1rem;
	font-weight: 600 !important;
	margin-bottom: 1rem;
`;

export const ProfileDropdownWrapper = styled.div`
	width: 15rem;
	padding: 0.5rem;
`;
