import Header from "app/components/basic/Header";
import Segment from "app/components/page/Segment";
import { DeathCertFormContext } from "app/components/templates/DeathCert/Forms/DeathCertFormContext";
import { DeathCertStepper } from "app/components/templates/DeathCert/Forms/DeathCertStepper";
import { FormButtons } from "app/components/templates/DeathCert/Forms/FormButtons";
import { isStillBirth, STEPS_TITLE } from "app/components/templates/DeathCert/Forms/formUtils";
import {
	FormKeys,
	relationshipSchema,
	stillbirthHasPermission,
} from "app/components/templates/DeathCert/Forms/schemas";
import PublicPage from "app/components/templates/PublicPage";
import { IForm, IFormFunctions, useForm } from "app/hooks/useForm";
import _ from "lodash";
import router from "next/router";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import {
	DeathCertRelationshipFinalQnPermissionGiver,
	DeathCertRelationshipFinalQnRelationship,
	DeathCertRelationshipQnHavePermission,
	DeathCertRelationshipQnNextOfKin,
} from "./DeathCertRelationshipQns";
import {
	StillbirthRelationshipFinalQnAuthoriser,
	StillbirthRelationshipQnAuthorised,
	StillbirthRelationshipQnParent,
} from "./StillbirthRelationshipQns";

type FinalAnsType = "YES" | "NO" | null;

const RelationshipForm = (): JSX.Element => {
	const [showQuestion2, setShowQuestion2] = useState<boolean>(false);
	const [qn2Answer, setQn2Answer] = useState<FinalAnsType>(null);

	const context = useContext(DeathCertFormContext);
	const { formResponses, goBack, goNext, isEditingForm } = context;

	const _isStillBirth = useMemo(() => isStillBirth(context), [context]);
	const schema = useMemo(
		() => (_isStillBirth ? { ...relationshipSchema, ...stillbirthHasPermission } : relationshipSchema),
		[_isStillBirth],
	);
	const doNavigateToCannotDownloadPage = useRef(false);

	const pageTitle = _isStillBirth ? "Download stillbirth certificate" : "Download death certificate";

	const { form }: IForm = useForm();
	const {
		setupFormFields,
		forceWarnIfExit,
		resetAllFieldError,
		getFields,
		getValue,
		updateFieldError,
		updateFieldsObject,
		ignoreChangesMade,
	} = form;

	useEffect(() => {
		setupFormFields(schema, context.formResponses);
		forceWarnIfExit(true);
		ignoreChangesMade();
	}, [setupFormFields, forceWarnIfExit, context.formResponses, schema]);

	useEffect(() => {
		// For case when user go back and forth, need to trigger state change for re-render to happen
		const isGuardian = formResponses.isGuardian;
		if (!_.isEmpty(isGuardian)) {
			setShowQuestion2(isGuardian === "false");
		}

		const hasPermissionFromGuardian = formResponses.hasPermissionFromGuardian;
		if (!_.isEmpty(hasPermissionFromGuardian)) {
			hasPermissionFromGuardian === "true" ? setQn2Answer("YES") : setQn2Answer("NO");
		}
	}, [formResponses.isGuardian, formResponses.hasPermissionFromGuardian]);

	const backAction = useCallback(() => {
		resetAllFieldError();
		goBack(getFields());
	}, [getFields, resetAllFieldError, goBack]);

	const nextAction = useCallback(() => {
		if (_isStillBirth && doNavigateToCannotDownloadPage.current) {
			void router.push("/death-or-stillbirth-certificate/cannot-download");
			return;
		}

		goNext(getFields());
	}, [getFields, _isStillBirth, goNext]);

	const hasFormValue = useCallback(
		(key: keyof typeof FormKeys, errorMessage: string) => {
			const value = getValue(FormKeys[key]) as string;
			if (_.isEmpty(value)) {
				updateFieldError(FormKeys[key], errorMessage);
				return false;
			}
			return true;
		},
		[getValue, updateFieldError],
	);

	const validateAdditionalConditions = useCallback(async () => {
		doNavigateToCannotDownloadPage.current = false;
		const isGuardian = getValue(FormKeys.IS_GUARDIAN) as string;

		if (isGuardian === "true") return true;

		const hasPermissionFromGuardian = getValue(FormKeys.HAS_PERMISSION_FROM_GUARDIAN) as string;

		if (hasPermissionFromGuardian === "true") {
			return hasFormValue(
				"GUARDIAN_NAME",
				_isStillBirth ? "Enter the name of the child’s parent" : "Enter the name of the next-of-kin",
			);
		} else if (hasPermissionFromGuardian === "false") {
			if (_isStillBirth) {
				doNavigateToCannotDownloadPage.current = true;
				return true;
			}

			return hasFormValue(
				"RELATIONSHIP_DESCRIPTION",
				_isStillBirth
					? "Enter your relationship to the baby or the baby’s parents"
					: "Enter your relationship to the person who has died",
			);
		}

		//user has not selected anything
		updateFieldError(FormKeys.HAS_PERMISSION_FROM_GUARDIAN, "Select one");
		return false;
	}, [_isStillBirth, getValue, hasFormValue, updateFieldError]);

	const resetFinalQn = useCallback(() => {
		updateFieldsObject(FormKeys.GUARDIAN_NAME, { value: "" });
		updateFieldsObject(FormKeys.RELATIONSHIP_DESCRIPTION, { value: "" });
		setQn2Answer(null);
	}, [updateFieldsObject]);

	const resetQn2 = useCallback(() => {
		updateFieldsObject(FormKeys.HAS_PERMISSION_FROM_GUARDIAN, { value: "" });
	}, [updateFieldsObject]);

	const toggleQn2 = useCallback(() => {
		//reset
		resetAllFieldError();
		resetQn2();
		resetFinalQn();

		setShowQuestion2(getValue(FormKeys.IS_GUARDIAN) === "false");
	}, [getValue, resetFinalQn, resetQn2, resetAllFieldError]);

	const toggleFinalQn = useCallback(() => {
		resetFinalQn();
		const hasPermissionFromGuardian = getValue(FormKeys.HAS_PERMISSION_FROM_GUARDIAN) as string;
		hasPermissionFromGuardian === "true" ? setQn2Answer("YES") : setQn2Answer("NO");
	}, [getValue, resetFinalQn]);

	const question1 = (form: IFormFunctions, afterSelectItem: () => void) => {
		if (_isStillBirth) return <StillbirthRelationshipQnParent afterSelectItem={afterSelectItem} form={form} />;

		return <DeathCertRelationshipQnNextOfKin afterSelectItem={afterSelectItem} form={form} />;
	};

	const question2 = (form: IFormFunctions, afterSelectItem: () => void) => {
		if (_isStillBirth) return <StillbirthRelationshipQnAuthorised form={form} afterSelectItem={afterSelectItem} />;

		return <DeathCertRelationshipQnHavePermission form={form} afterSelectItem={afterSelectItem} />;
	};
	const finalQuestion = (form: IFormFunctions) => {
		if (qn2Answer === null) return;

		if (_isStillBirth) {
			if (qn2Answer === "YES") return <StillbirthRelationshipFinalQnAuthoriser form={form} />;
			return;
		}

		if (qn2Answer === "YES") {
			return <DeathCertRelationshipFinalQnPermissionGiver form={form} />;
		}

		return <DeathCertRelationshipFinalQnRelationship form={form} />;
	};

	return (
		<PublicPage title={pageTitle}>
			<Segment paddingBottom={104}>
				<Header title={pageTitle} />
				<Grid className="death-cert-form">
					<Grid.Row columns={12}>
						<DeathCertStepper steps={STEPS_TITLE} currentStep={1} />
						<Grid.Column className="no-margin" mobile={12} tablet={12} computer={8}>
							{question1(form, toggleQn2)}
							{showQuestion2 ? question2(form, toggleFinalQn) : null}
							{finalQuestion(form)}
							<FormButtons
								form={form}
								isEditingForm={isEditingForm}
								backAction={backAction}
								nextAction={nextAction}
								additionalValidations={validateAdditionalConditions}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</PublicPage>
	);
};

export default RelationshipForm;
