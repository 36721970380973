export enum DoneeType {
	MAIN_DONEE = "MAIN_DONEE",
	REPLACEMENT_DONEE = "REPLACEMENT_DONEE",
}

export enum GrantedPowerOptions {
	PERSONAL_WELFARE = "PERSONAL_WELFARE",
	PROPERTY_AND_AFFAIRS = "PROPERTY_AND_AFFAIRS",
	PERSONAL_WELFARE_AND_PROPERTY = "PERSONAL_WELFARE_AND_PROPERTY",
}

export const DoneeTypeDisplayNames: { [key in DoneeType]: string } = {
	MAIN_DONEE: "Donee",
	REPLACEMENT_DONEE: "Replacement donee",
};
