import Button from "app/components/basic/Button";
import "../styles.scss";
import { Powers } from "./types";
import { DoneePowerDO } from "app/common/api/lpaAcp/domainObjects";
import React from "react";
import { PencilFillIcon } from "@lifesg/react-icons";

interface IPersonFieldCardProps {
	index: string | number;
	name: string;
	relationship?: string;
	replacement?: DoneePowerDO[];
	onEdit: () => void;
	onRemove: () => void;
	disabled?: boolean;
	summary?: string;
	powers?: Powers[]; //can be compulsory after removal of V1
}

const PersonFieldCard = (props: IPersonFieldCardProps): JSX.Element => {
	const { index, name, relationship, summary, disabled, onEdit, onRemove, replacement, powers } = props;

	return (
		<div className="coordinator__button-wrapper coordinator-card">
			<div className="coordinator-card__top">
				<p className="semi-bold paragraph-no-margin word-wrap" id={`coordinator__name-${index}`}>
					{name}
				</p>
				{relationship ? (
					<p className="card__relationship mb0 mt4 word-wrap" id={`coordinator__relationship-${index}`}>
						{relationship}
					</p>
				) : (
					""
				)}
				{replacement ? <ReplacementSection replacementDoneePowers={replacement} /> : ""}
				{summary && (
					<p className="card__summary mb0 mt4 word-wrap" id={`coordinator__summary-${index}`}>
						{summary}
					</p>
				)}
				{powers && powers.length > 0 && (
					<ul>
						{powers.map((power, index) => (
							<li key={index}>{power}</li>
						))}
					</ul>
				)}
			</div>
			<div className="coordinator-card__btm">
				<Button
					IconLeft={PencilFillIcon}
					id={`coordinator-edit-${index}`}
					type="borderless"
					name="Edit"
					onClick={onEdit}
					disabled={disabled}
				/>
				<Button
					id={`coordinator-delete-${index}`}
					type="danger-borderless"
					name="Remove"
					onClick={onRemove}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default PersonFieldCard;

type ReplacementSectionProps = {
	replacementDoneePowers: DoneePowerDO[];
};

const ReplacementSection = ({ replacementDoneePowers }: ReplacementSectionProps) => {
	const renderReplacementPowerItem = (
		{ authorizePersonalWelfare, authorizePropertyAffairs, replacingMainDonee }: DoneePowerDO,
		index: number,
	): JSX.Element => {
		if (replacingMainDonee) {
			return (
				<React.Fragment key={`replacement-donee-${index}`}>
					<p
						id={
							replacingMainDonee.id < 0
								? replacingMainDonee.nric
								: replacingMainDonee.id + "-replacement-power-section"
						}
					>
						Can replace <span className="semi-bold">{replacingMainDonee.name}</span> to make decisions about
						your:
					</p>
					<ul>
						{authorizePersonalWelfare ? <li id="replacement-power-1">Personal Welfare</li> : ""}
						{authorizePropertyAffairs ? <li id="replacement-power-2">Property and Affairs</li> : ""}
					</ul>
				</React.Fragment>
			);
		} else {
			return <></>;
		}
	};
	return (
		<>
			{replacementDoneePowers.map((replacementDoneePower: DoneePowerDO, index) =>
				renderReplacementPowerItem(replacementDoneePower, index),
			)}
		</>
	);
};
