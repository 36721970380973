import { AccessType, SharedAccessTrustedConnectionDO } from "app/common/api/sharedAccess";
import * as contracts from "app/common/api/sharedAccess/routes";
import axios, { AxiosResponse } from "axios";
import API from "../client.contract";

const getSharedAccess = async (accessType: AccessType): Promise<SharedAccessTrustedConnectionDO[]> => {
	const params = new contracts.getSharedAccess.Params();
	params.accessType = accessType;

	const response = await API.callV2<contracts.getSharedAccess.Response>(contracts.getSharedAccess, { params });
	return response.sharedAccess;
};

const createSharedAccess = async (
	data: contracts.postSharedAccess.Body,
): Promise<SharedAccessTrustedConnectionDO[]> => {
	const response = await API.callV2<contracts.postSharedAccess.Response>(contracts.postSharedAccess, { body: data });
	return response.sharedAccess;
};

const updateSharedAccess = async (
	id: number,
	isAccessibleBeforeDeath: boolean,
): Promise<contracts.patchSharedAccess.Response> => {
	const params = new contracts.patchSharedAccess.Params();
	params.accessID = id;
	const body = new contracts.patchSharedAccess.Body();
	body.isAccessibleBeforeDeath = isAccessibleBeforeDeath;

	return await API.callV2<contracts.patchSharedAccess.Response>(contracts.patchSharedAccess, { params, body });
};

const getSharedAccessData = async (id: number): Promise<contracts.getSharedAccessData.Response> => {
	const params = new contracts.getSharedAccessData.Params();
	params.accessID = id;

	return await API.callV2<contracts.getSharedAccessData.Response>(contracts.getSharedAccessData, { params });
};

const deleteSharedAccess = async (id: number): Promise<void> => {
	const params = new contracts.deleteSharedAccess.Params();
	params.accessID = id;
	await API.callV2(contracts.deleteSharedAccess, { params });
};

const getSharedDocument = async (accessID: number, s3UniqueName: string): Promise<AxiosResponse> => {
	const response = await axios.get(`/api/users/access/${accessID}/${s3UniqueName}`, { responseType: "blob" });
	return response;
};

const SharedAccessAPI = {
	getSharedAccess,
	createSharedAccess,
	updateSharedAccess,
	getSharedAccessData,
	deleteSharedAccess,
	getSharedDocument,
};

export default SharedAccessAPI;
