export const getCookie = (key: string): string | null => {
	if (typeof window === "undefined") {
		return null;
	}

	let value = "";
	const cookies = document.cookie.split(";");
	for (const keyValuePair of cookies) {
		const [k, v] = keyValuePair.split("=");
		if (key === k.trim()) {
			value = v.trim();
		}
	}
	if (value === "") {
		return null;
	}
	return value;
};
