import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import moment from "moment";
import { type IDateValue } from "../api/interface";
import { ISO_DATE_TIME_STRING_REGEX } from "app/common/date";

// tslint:disable-next-line: ban-types
export function IsTodayOrBefore(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isTodayOrBefore",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: moment.Moment | IDateValue): boolean {
					return validateDate(value);
				},
				defaultMessage(args: ValidationArguments): string {
					return `${args.value} is not a valid date. Date must be before today`;
				},
			},
		});
	};
}

export const validateDate = (value: moment.Moment | IDateValue): boolean => {
	if (value.day && value.month && value.year) {
		const date = moment(`${value.year}-${value.month}-${value.day}`, "YYYY-MM-DD");

		return !date.isAfter(moment().endOf("day"));
	}

	if (!moment.isMoment(value) || !value.isValid()) {
		return false;
	}

	// checks if a given value is a ISOString date
	const inputStr = value.creationData().input as string;
	if (isValidDateInputStr(inputStr) === false) {
		return false;
	}

	if (value.isAfter(moment().endOf("day"))) {
		return false;
	}

	return true;
};

export const isValidDateInputStr = (dateInputStr: string): boolean => {
	return ISO_DATE_TIME_STRING_REGEX.test(dateInputStr);
};
