import { GTMUtils } from "app/utils/analytics/gtm-helper";
import { GTMEvent, GTMFormDownloadParams, GTMFormName } from "app/utils/analytics/types";
import { DeathRecordType } from "app/common/api/deathCert";
import { useCallback } from "react";

type DOWNLOAD_TYPE = "death" | "stillbirth";
enum DOWNLOAD_STATUS {
	SUCCESS = "success",
	FAILED = "failed",
}

interface IDataLayer {
	event: DOWNLOAD_TYPE;
	status: DOWNLOAD_STATUS;
	id: string;
}

const send = (data: IDataLayer) => {
	window["dataLayer"].push(data);
};

export const getGTMFormName = (deathRecordType: DeathRecordType) => {
	return deathRecordType === DeathRecordType.Death ? GTMFormName.DEATH_CERT : GTMFormName.STILLBIRTH_CERT;
};

export const useGAForDeathOrStillbirthDownload = (event: DeathRecordType) => {
	const formName = getGTMFormName(event);

	const sendSuccessEvent = useCallback(
		(id: string) => {
			// UA event
			send({ event, status: DOWNLOAD_STATUS.SUCCESS, id });
			// GA4 event
			GTMUtils.fireEvent<GTMFormDownloadParams>(GTMEvent.FORM_DOWNLOAD, {
				form_name: formName,
				is_successful: true,
				transaction_id: id,
			});
		},
		[event, formName],
	);

	const sendFailedEvent = useCallback(
		(id: string) => {
			// UA event
			send({ event, status: DOWNLOAD_STATUS.FAILED, id });
			// GA4 event
			GTMUtils.fireEvent<GTMFormDownloadParams>(GTMEvent.FORM_DOWNLOAD, {
				form_name: formName,
				is_successful: false,
				transaction_id: id,
			});
		},
		[event, formName],
	);

	return {
		sendSuccessEvent,
		sendFailedEvent,
	};
};
