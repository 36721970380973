import BasicLink from "app/components/basic/Link";
import Segment from "app/components/page/Segment";
import PublicPage from "app/components/templates/PublicPage";
import Divider from "app/components/widgets/Divider";
import { withAuthRedirect } from "app/utils/url";
import React from "react";
import { Grid } from "semantic-ui-react";
import "./VaultIntroPage.scss";

interface IProps {
	redirectURL: string;
}

const VaultIntroPage = (props: IProps): JSX.Element => {
	const SegmentDivider = (): JSX.Element => (
		<Segment>
			<Divider />
		</Segment>
	);

	const Preferences = (): JSX.Element => (
		<Segment paddingBottom={80}>
			<Grid>
				<Grid.Row columns={12}>
					<Grid.Column mobile={12} tablet={12} computer={12} className="vault-intro__center-medium-title">
						<h3 className="vault-intro__intro-title bold">How My Legacy vault works</h3>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={12} className="vault-intro__center">
					<Grid.Column mobile={12} tablet={12} computer={3} className="vault-intro__image-preference-wrapper">
						<img
							className="vault-intro__image-preference"
							src="/images/features-planning-ahead.png"
							alt="Person going through a checklist while making end-of-life plans"
						/>
					</Grid.Column>
					<Grid.Column
						mobile={12}
						tablet={12}
						computer={7}
						className="vault-intro__preference-content no-margin"
					>
						<h5 className="semi-bold vault-intro__preference-title">Simple guide to help you plan</h5>
						<p className="vault-intro__preference-paragraph">
							Start with planning your will, lasting power of attorney, advance care plan, CPF nomination
							and funeral wishes.
						</p>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={12} className="vault-intro__center mt40">
					<Grid.Column mobile={12} tablet={12} computer={3} className="vault-intro__image-preference-wrapper">
						<img
							className="vault-intro__image-preference"
							src="/images/features-personal-info.png"
							alt="A grey file, piggy bank, passport, and house keys"
						/>
					</Grid.Column>
					<Grid.Column
						mobile={12}
						tablet={12}
						computer={7}
						className="vault-intro__preference-content no-margin"
					>
						<h5 className="semi-bold vault-intro__preference-title">Keeping your information safe</h5>
						<p className="vault-intro__preference-paragraph">
							Upload your documents to My Legacy vault for secure storage and share them with those you
							trust.
						</p>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={12} className="vault-intro__center mt40">
					<Grid.Column mobile={12} tablet={12} computer={3} className="vault-intro__image-preference-wrapper">
						<img
							className="vault-intro__image-preference"
							src="/images/features-secured-sharing.png"
							alt="Person sharing his end-of-life plans and personal information with family"
						/>
					</Grid.Column>
					<Grid.Column
						mobile={12}
						tablet={12}
						computer={7}
						className="vault-intro__preference-content no-margin"
					>
						<h5 className="semi-bold vault-intro__preference-title">
							Make sure your family will be taken care of
						</h5>
						<p className="vault-intro__preference-paragraph">
							Make it easy for your loved ones to access your plans and important information when they
							need to.
						</p>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<div className="vault-intro__button-center">
				<div>
					<BasicLink
						type="button-primary"
						id={"intro-login-top"}
						href={withAuthRedirect(props.redirectURL)}
						name="Log in with Singpass"
						disablePrefetch={true}
						fullWidthOnMobile={true}
						isAPIRoute={true}
					/>
				</div>
			</div>
		</Segment>
	);

	const Intro = (): JSX.Element => (
		<PublicPage>
			<Segment paddingBottom={24}>
				<Grid>
					<Grid.Row columns={12}>
						<Grid.Column
							mobile={12}
							tablet={12}
							computer={6}
							className="no-margin vault-intro_header-wrapper"
						>
							<div>
								<h1 className="vault-intro__header-title mb0">Start planning with My Legacy vault</h1>
								<div className="mt24" />
								<p className="vault-intro__intro-para paragraph-no-margin">
									Plan, store and share your legal, healthcare and estate matters securely with people
									you trust.
								</p>
							</div>
						</Grid.Column>
						<Grid.Column mobile={12} tablet={12} computer={6} className="vault-intro__image-wrapper">
							<img
								className="vault-intro__image-intro"
								src="/images/secured-sharing-hero.png"
								alt="Person sharing his end-of-life plans and personal information with family"
							/>
						</Grid.Column>
					</Grid.Row>
					<div className="vault-intro__button-group">
						<div>
							<BasicLink
								type="button-primary"
								id={"intro-login-bottom"}
								onClick={() => {
									window.location.href = withAuthRedirect(props.redirectURL);
								}}
								name="Log in with Singpass"
								disablePrefetch={true}
								fullWidthOnMobile={true}
								isAPIRoute={true}
							/>
						</div>
					</div>
				</Grid>
			</Segment>
		</PublicPage>
	);

	return (
		<>
			<Intro />
			<SegmentDivider />
			<Preferences />
		</>
	);
};

export default VaultIntroPage;
