export const pagesWithoutScrollToTop = [
	"/find-a-service/find-funeral-director/",
	"/find-a-service/find-funeral-director/?religion=non-religious",
	"/find-a-service/find-funeral-director/?religion=buddhist",
	"/find-a-service/find-funeral-director/?religion=catholic",
	"/find-a-service/find-funeral-director/?religion=christian",
	"/find-a-service/find-funeral-director/?religion=hindu",
	"/find-a-service/find-funeral-director/?religion=muslim",
	"/find-a-service/find-funeral-director/?religion=taoist",
	"/find-a-service/find-funeral-director",
	"/find-a-service/find-funeral-director?religion=non-religious",
	"/find-a-service/find-funeral-director?religion=buddhist",
	"/find-a-service/find-funeral-director?religion=catholic",
	"/find-a-service/find-funeral-director?religion=christian",
	"/find-a-service/find-funeral-director?religion=hindu",
	"/find-a-service/find-funeral-director?religion=muslim",
	"/find-a-service/find-funeral-director?religion=taoist",
];
