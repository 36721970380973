// =============================================================================
// TODO: clean up the constants and typings to 1 folder

// =============================================================================
const END_OF_LIFE_PLANNING_MAKE_LPA_URL = "/end-of-life-planning/make-a-lasting-power-of-attorney";
export const LPA_ACP_LANDING_URL = "/find-a-service/lpa-acp";
export const LPA_ONLY_LANDING_URL = "/find-a-service/lpa/";
const LPA_ACP_FORM_URL = "/vault/lpa-acp";
const OPGO_WEBSITE_URL = "https://www.msf.gov.sg/opg";
const OPGO_ESERVICE_WEBSITE_URL = "https://opg-eservice.msf.gov.sg";
const LPA_ACP_NEXT_PAGE = "/vault/lpa-acp/next-steps";
const LPA_ONLY_NEXT_PAGE = "/vault/lpa-acp/lpa-only/next-steps";
const NEXT_PAGE = "next-steps";

export const LPA_URLS = {
	END_OF_LIFE_PLANNING_MAKE_LPA_URL,
	LPA_ACP_LANDING_URL,
	LPA_ONLY_LANDING_URL,
	LPA_ACP_FORM_URL,
	OPGO_WEBSITE_URL,
	OPGO_ESERVICE_WEBSITE_URL,
	LPA_ACP_NEXT_PAGE,
	LPA_ONLY_NEXT_PAGE,
	NEXT_PAGE,
};

// accessible without login
export enum LpaAcpPreFormPage {
	ALLOW_ACCESS = "allow-access",
}

export enum LpaAcpCombinedFormPages {
	LPA_ACP_FORM_1 = "combined-form/step-1",
	LPA_ACP_FORM_2 = "combined-form/step-2",
	LPA_ACP_FORM_3 = "combined-form/step-3",
	LPA_ACP_FORM_4 = "combined-form/step-4",
	LPA_ACP_FORM_5 = "combined-form/step-5",
	LPA_ACP_FORM_6 = "combined-form/step-6",
	LPA_ACP_FORM_7 = "combined-form/step-7",
	LPA_ACP_FORM_8 = "combined-form/step-8",
}

export enum LpaFormPages {
	LPA_FORM_1 = "lpa-only/step-1",
	LPA_FORM_2 = "lpa-only/step-2",
	LPA_FORM_3 = "lpa-only/step-3",
	LPA_FORM_4 = "lpa-only/step-4",
	LPA_FORM_5 = "lpa-only/step-5",
	LPA_FORM_6 = "lpa-only/step-6",
	LPA_FORM_7 = "lpa-only/step-7",
	NEXT_STEPS = "lpa-only/next-steps",
}

export enum LpaOnlyViewActionPage {
	RECORD = "/vault/lpa/record",
	REVIEW = "/vault/lpa/review",
}

export enum AcpStatus {
	NO_RECORD = "No record",
	DRAFT = "Draft",
	COMPLETED = "Completed",
}

export const MAX_STEPS = {
	lpaV1: 5,
	combinedForm: 8,
	lpaOnly: 7,
	acpOnly: 5,
	funeralPlan: 5,
	will: 4,
	cpf: 0,
};

export const Errors = {
	LPA_ACP_SUBMITTING_FAILED: "Error submitting details",
	LPA_ACP_DRAFT_SUBMITTING_FAILED: "Problem loading the form. Please try again later.",
	FETCH_DRAFT_VAULT_ERROR: "Problem getting the status of your plans. Please refresh the page or try again later.",
	FETCH_DRAFT_FORM_ERROR: "Problem getting to your form. Please try again later.",
};

// ACP Module
export const ACPStorageKey = "acp-storage-key";
export const ACPStorageKeySecret = "acp-storage-item";
export const ACPFileName = "Advance-care-plan-draft.pdf";
