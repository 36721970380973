import { HTTP_VERBS } from "../../enums";
import { IsString } from "class-validator";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/users/acp/v2/encrypt-decrypt";

export class Body {
	@IsString()
	payloadToEncrypt?: string | null;

	@IsString()
	payloadToDecrypt?: string | null;
}
export class Response {}
