import { ArrowLeftIcon, ReplayIcon } from "@lifesg/react-icons";
import { ArrowRightIcon } from "@lifesg/react-icons/arrow-right";
import { ComponentLoadingSpinner } from "app/components/ComponentLoadingSpinner";
import Button from "app/components/basic/Button";
import { IFormFunctions } from "app/hooks/useForm";
import "./styles.scss";

interface IWizardButtonGroup {
	className?: string;
	form?: IFormFunctions;
	next?: () => void;
	save?: () => void;
	previous?: () => void;
	complete?: () => void;
	startOver?: () => void;
	nextLoading?: boolean;
	previousLoading?: boolean;
	completeLoading?: boolean;
	isSaving?: boolean;
	startOverLoading?: boolean;
	nextDisabled?: boolean;
	previousDisabled?: boolean;
	completeDisabled?: boolean;
	saveDisabled?: boolean;
	startOverDisabled?: boolean;
	nextButtonText?: string;
	previousButtonText?: string;
	saveButtonText?: string;
	startOverButtonText?: string;
	nextButtonClassName?: string | undefined;
	buttonsOnTop?: boolean;
	lastSavedOn?: string;
}

interface IWizardSingleButton extends IWizardButtonGroup {
	isSubmitting?: boolean;
	onClick: () => void;
}

interface ISaveStatusProps {
	text: string;
	className: string;
}

const NextButton = (props: IWizardSingleButton): JSX.Element => {
	return (
		<Button
			className={props.className}
			id={`wizard-next-button${props.nextLoading || props.nextDisabled ? "--disabled" : ""}`}
			key="next"
			name={props.nextButtonText || "Next"}
			IconRight={() => {
				if (props.isSubmitting) {
					return <ComponentLoadingSpinner color="#FFFFFF" size={16} />;
				}
				return <ArrowRightIcon style={{ marginInline: "0.5rem" }} />;
			}}
			onClick={props.onClick}
			type="primary"
			disabled={props.nextDisabled}
			loading={props.isSubmitting}
			fullWidthOnMobile={true}
		/>
	);
};

const SaveButton = (props: IWizardSingleButton): JSX.Element => {
	return (
		<Button
			className={props.className}
			id={`wizard-save-button${props.isSaving || props.saveDisabled ? "--disabled" : ""}`}
			key="save"
			name={props.saveButtonText || "Save"}
			onClick={props.onClick}
			type="secondary"
			disabled={props.saveDisabled || props.isSubmitting}
		/>
	);
};

const SaveStatus = (props: ISaveStatusProps): JSX.Element => {
	return (
		<small data-testid={"lpa-acp_last-saved"} className={`${props.className} wizard-buttons__save-state`}>
			{props.text}
		</small>
	);
};

const PreviousButton = (props: IWizardSingleButton): JSX.Element => (
	<Button
		id={`wizard-previous-button${props.previousLoading || props.previousDisabled ? "--disabled" : ""}`}
		key="previous"
		name={props.previousButtonText || "Previous"}
		IconLeft={() => {
			if (props.isSubmitting) {
				return <ComponentLoadingSpinner color="#0c7bb3" size={16} />;
			}
			return (
				<ArrowLeftIcon
					style={{ marginRight: "0.5rem", ...(props.buttonsOnTop ? {} : { marginLeft: "0.5rem" }) }}
				/>
			);
		}}
		onClick={props.onClick}
		type="borderless"
		disabled={props.previousDisabled}
		loading={props.isSubmitting}
		fullWidthOnMobile={props.buttonsOnTop ? false : true}
		className={`${props.className} button-no-padding-left`}
	/>
);

export const StartOverButton = (props: IWizardSingleButton): JSX.Element => (
	<Button
		id={`wizard-start-over-button${props.startOverLoading || props.startOverDisabled ? "--disabled" : ""}`}
		key="previous"
		name={props.startOverButtonText || "Start Over"}
		IconLeft={() => {
			if (props.isSubmitting) {
				return <ComponentLoadingSpinner color="#0c7bb3" size={16} />;
			}
			return <ReplayIcon style={{ marginInline: "0.5rem" }} />;
		}}
		type="borderless"
		disabled={props.startOverDisabled}
		loading={props.isSubmitting}
		fullWidthOnMobile={true}
		onClick={props.onClick}
		className={`${props.className} button-no-padding-left`}
	/>
);

const CompleteButton = (props: IWizardSingleButton): JSX.Element => (
	<Button
		id={`wizard-complete-button${props.completeLoading || props.completeDisabled ? "--disabled" : ""}`}
		key="complete"
		name={props.nextButtonText || "Complete form"}
		IconRight={ArrowRightIcon}
		onClick={props.onClick}
		type="primary"
		loading={props.isSubmitting}
		disabled={props.completeDisabled}
		fullWidthOnMobile={true}
	/>
);

const WizardButtonGroup = (props: IWizardButtonGroup): JSX.Element => {
	const isSubmitting = props.form ? props.form.hasSubmittingField() : false;
	const wizardClassName = `wizard-buttons${props.className ? ` ${props.className}` : ""}`;
	const saveStatus = props.isSaving ? "Saving..." : props.lastSavedOn || "";

	const formActionWrapper = async (callback?: () => void): Promise<void> => {
		if (callback && props.form) {
			await props.form.submitForm(callback);
		}
		if (callback && !props.form) {
			callback();
		}
	};

	const onNext = async (): Promise<void> => {
		await formActionWrapper(props.next);
	};

	const onComplete = async (): Promise<void> => {
		await formActionWrapper(props.complete);
	};

	// This will appears on the top of the contents in mobile view
	if (props.buttonsOnTop && props.previous && props.save) {
		return (
			<div className={"wizard-buttons-top"}>
				<SaveStatus className={"hide-on-mobile"} text={saveStatus} />
				<SaveButton
					onClick={props.save}
					isSubmitting={isSubmitting || props.isSaving}
					saveDisabled={props.saveDisabled || props.isSaving}
					className={"hide-on-desktop"}
				/>
				<PreviousButton
					onClick={props.previous}
					isSubmitting={isSubmitting || props.previousLoading}
					previousDisabled={props.previousDisabled || props.isSaving}
					previousButtonText={props.previousButtonText}
					buttonsOnTop={props.buttonsOnTop}
				/>
			</div>
		);
	}

	// This will appears on the bottom of the contents in mobile/desktop view.
	// some buttons will be hidden in mobile view (hideOnMobileClassName)
	if (props.previous && props.next) {
		return (
			<div className={wizardClassName}>
				{props.save ? (
					<div className="wizard-buttons__wrapper">
						<SaveStatus className={`hide-on-mobile wizard-buttons__save`} text={saveStatus} />

						<SaveButton
							className={`hide-on-mobile wizard-buttons__save`}
							onClick={props.save}
							saveDisabled={props.saveDisabled || props.isSaving}
							isSubmitting={isSubmitting || props.isSaving}
						/>
						<NextButton
							nextButtonText={props.nextButtonText}
							onClick={onNext}
							isSubmitting={isSubmitting || props.nextLoading}
							nextDisabled={props.nextDisabled || props.isSaving}
						/>
					</div>
				) : (
					<NextButton
						nextButtonText={props.nextButtonText}
						onClick={onNext}
						isSubmitting={isSubmitting || props.nextLoading}
						nextDisabled={props.nextDisabled || props.isSaving}
					/>
				)}

				<PreviousButton
					className={`hide-on-mobile`}
					onClick={props.previous}
					isSubmitting={isSubmitting || props.previousLoading}
					previousDisabled={props.previousDisabled || props.isSaving}
					previousButtonText={props.previousButtonText}
				/>

				{props.startOver && (
					<StartOverButton
						className={"hide-on-desktop"}
						onClick={props.startOver}
						isSubmitting={props.startOverLoading}
						startOverDisabled={props.startOverDisabled || props.isSaving}
						startOverButtonText={props.startOverButtonText}
					/>
				)}
			</div>
		);
	} else if (props.previous && props.complete) {
		return (
			<div className={wizardClassName}>
				{props.save ? (
					<>
						<div className="wizard-buttons__wrapper">
							<SaveStatus className={`hide-on-mobile wizard-buttons__save `} text={saveStatus} />

							<SaveButton
								className={`hide-on-mobile wizard-buttons__save `}
								onClick={props.save}
								saveDisabled={props.saveDisabled || props.isSaving}
								isSubmitting={isSubmitting || props.previousLoading}
							/>
							<CompleteButton
								nextButtonText={props.nextButtonText}
								onClick={onComplete}
								isSubmitting={isSubmitting || props.completeLoading}
								completeDisabled={props.completeDisabled || props.isSaving}
							/>
						</div>
					</>
				) : (
					<>
						<CompleteButton
							nextButtonText={props.nextButtonText}
							onClick={onComplete}
							isSubmitting={isSubmitting || props.completeLoading}
							completeDisabled={props.completeDisabled || props.isSaving}
						/>
					</>
				)}
				<PreviousButton
					className={`hide-on-mobile`}
					onClick={props.previous}
					isSubmitting={isSubmitting || props.previousLoading}
					previousDisabled={props.previousDisabled || props.isSaving}
					previousButtonText={props.previousButtonText}
				/>

				{props.startOver && (
					<StartOverButton
						className={"hide-on-desktop"}
						onClick={props.startOver}
						isSubmitting={props.startOverLoading}
						startOverDisabled={props.startOverDisabled || props.isSaving}
						startOverButtonText={props.startOverButtonText}
					/>
				)}
			</div>
		);
	} else if (props.next) {
		return (
			<div className={wizardClassName}>
				<NextButton
					nextButtonText={props.nextButtonText}
					onClick={onNext}
					isSubmitting={isSubmitting || props.nextLoading}
					nextDisabled={props.nextDisabled}
					className={props.nextButtonClassName}
				/>
			</div>
		);
	} else if (props.previous) {
		return (
			<div className={wizardClassName}>
				<div key="wizard-space" />
				<PreviousButton
					className={`hide-on-mobile`}
					onClick={props.previous}
					isSubmitting={isSubmitting || props.previousLoading}
					previousDisabled={props.previousDisabled}
					previousButtonText={props.previousButtonText}
				/>

				{props.startOver && (
					<StartOverButton
						className={"hide-on-desktop"}
						onClick={props.startOver}
						isSubmitting={isSubmitting || props.startOverLoading}
						startOverDisabled={props.startOverDisabled}
						startOverButtonText={props.startOverButtonText}
					/>
				)}
			</div>
		);
	} else if (props.complete) {
		return (
			<div className={wizardClassName}>
				<CompleteButton
					nextButtonText={props.nextButtonText}
					onClick={onComplete}
					isSubmitting={isSubmitting || props.completeLoading}
					completeDisabled={props.completeDisabled}
				/>
			</div>
		);
	}

	return <div />;
};

export default WizardButtonGroup;
