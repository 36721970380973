import { HTTP_VERBS } from "app/common/api/enums";
import { Transform } from "class-transformer";
import { IsString } from "class-validator";
import { escapeHTML } from "../../transformers";
export const httpVerb = HTTP_VERBS.DELETE;
export const url = "/api/v1/hdb-parking/sid/:sid";

export class Params {
	@IsString()
	@Transform(escapeHTML)
	sid!: string;
}

export class Response {}
