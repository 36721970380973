import { AccessSetting } from "app/common/api/trustedConnection";
import ShareRadio from "app/components/composites/ShareRadio";
import { IFormFunctions } from "../Form";

interface IFormRadioProps {
	header: [string, string];
	field: string;
	form: IFormFunctions;
	disabled?: boolean;
	disableNow?: boolean;
	disableAfter?: boolean;
	disableNever?: boolean;
}

const FormRadio = (props: IFormRadioProps): JSX.Element => {
	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	const value = formField ? formField.value : "";

	let loading = true;

	if (!props.form.loading) {
		loading = false;
	}

	const selectItemHandler = async (value: string): Promise<void> => {
		await props.form.updateFieldValue(key, value);
	};

	return (
		<ShareRadio
			type="radio"
			header={props.header}
			value={value as AccessSetting}
			disableNow={props.disabled || props.disableNow || loading}
			disableAfter={props.disabled || props.disableAfter || loading}
			disableNever={props.disabled || props.disableNever || loading}
			selectItem={selectItemHandler}
		/>
	);
};

export default FormRadio;
