import { IsEnum } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { FormStatus, FormType } from "../enums";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/formsStatus";
export class Response {
	@IsEnum(FormType, { each: true })
	public statuses!: Record<FormType, FormStatus>;
}
