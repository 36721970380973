import NotificationAPI from "app/api/notification";
import { InboxNotificationDO } from "app/common/api/notification/domainObjects";
import { ReactNode, createContext, useContext, useState } from "react";

const BaseFunction = (): Promise<void> => {
	return new Promise((resolve) => resolve());
};

interface IInboxFunctions {
	fetchNotifications: () => Promise<void>;
	notifications: InboxNotificationDO[] | undefined;
}

const Inbox = createContext<IInboxFunctions>({
	fetchNotifications: BaseFunction,
	notifications: [],
});

export const useInbox = (): IInboxFunctions => useContext(Inbox);

export const InboxProvider = (props: { children: ReactNode }): JSX.Element => {
	const [notifications, setNotifications] = useState<InboxNotificationDO[] | undefined>(undefined);

	const fetchNotifications = async (): Promise<void> => {
		const { notifications: fetchedNotifications } = await NotificationAPI.getAllNotifications();
		setNotifications(fetchedNotifications);
	};

	return <Inbox.Provider value={{ fetchNotifications, notifications }}>{props.children}</Inbox.Provider>;
};
