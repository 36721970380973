import { getEnvVar } from "app/utils/environmentVariable";

export const HdbParkingStorageKey = "hdb-storage-key";
export const HdbParkingStorageKeySecret = "hdb-storage-item";

// hdb parking form steps and page title (used by [steps].tsx to render correct page and page title)
export enum FormSteps {
	PERSONAL_DETAILS = "step1",
	DEATH_DOCUMENT_DETAILS = "step2",
	PARKING_DETAILS = "step3",
	VEHICLE_BOOKING_DETAILS = "step4",
	REVIEW = "step5",
}

export const FormTitle: { [key in FormSteps]: string } = {
	step1: "Personal details",
	step2: "Death document details",
	step3: "Parking details",
	step4: "Vehicle booking details",
	step5: "Review",
};

const hdbParkingBaseUrl = "/find-a-service/apply-for-free-hdb-parking";
export const listingResultPath = "/hdb-carpark/results";

export const hdbParkingUrl = {
	baseStepUrl: `${hdbParkingBaseUrl}/step`,
	landingPage: `${hdbParkingBaseUrl}/`,
	termsAndCondition: `${hdbParkingBaseUrl}/terms-and-conditions/`,
	continueWithSingpass: `${hdbParkingBaseUrl}/continue-with-singpass/`,
	personalDetails: `${hdbParkingBaseUrl}/step1/`,
	deathDocumentsDetails: `${hdbParkingBaseUrl}/step2/`,
	parkingDetails: `${hdbParkingBaseUrl}/step3/`,
	vehicleBookingDetails: `${hdbParkingBaseUrl}/step4/`,
	review: `${hdbParkingBaseUrl}/step5/`,
	submit: `${hdbParkingBaseUrl}/confirmation/`,
	bookingLimit: `${hdbParkingBaseUrl}/booking-limit-reached/`,
};

export const hdbParkingStep = {
	landing: 0,
	continue: 1,
	[FormSteps.PERSONAL_DETAILS]: 2,
	[FormSteps.DEATH_DOCUMENT_DETAILS]: 3,
	[FormSteps.PARKING_DETAILS]: 4,
	[FormSteps.VEHICLE_BOOKING_DETAILS]: 5,
	[FormSteps.REVIEW]: 6,
	submit: 7,
};

export const hdbParkingRedirection = {
	0: hdbParkingUrl.landingPage,
	1: hdbParkingUrl.continueWithSingpass,
	2: hdbParkingUrl.personalDetails,
	3: hdbParkingUrl.deathDocumentsDetails,
	4: hdbParkingUrl.parkingDetails,
	5: hdbParkingUrl.vehicleBookingDetails,
	6: hdbParkingUrl.review,
};

export const FormSubtitle: { [key in FormSteps]: string } = {
	step1: "Check that these details from your Myinfo profile are accurate.",
	step2: "These are in the death certificate or confirmation of death document.",
	step3: "",
	step4: "The vehicle must be registered in Singapore. It can be any vehicle, for example, a motorcycle, car or van.",
	step5: "Funeral arrangements need to take place quickly. As the booking cannot be changed in the time you need, try to make sure that all the details are correct.",
};

export const FormSubtitleNonSingpassFlow: { [key in FormSteps]: string } = {
	...FormSubtitle,
	step1: "Fill in the following details",
};

// eWills next/previous page url (used by cta.tsx to route to correct page after each step)
export const nextStepUrl: { [key in FormSteps]: string } = {
	step1: hdbParkingUrl.deathDocumentsDetails,
	step2: hdbParkingUrl.parkingDetails,
	step3: hdbParkingUrl.vehicleBookingDetails,
	step4: hdbParkingUrl.review,
	step5: hdbParkingUrl.submit,
};

export const previousStepUrl: { [key in FormSteps]: string | undefined } = {
	step1: undefined,
	step2: hdbParkingUrl.personalDetails,
	step3: hdbParkingUrl.deathDocumentsDetails,
	step4: hdbParkingUrl.parkingDetails,
	step5: hdbParkingUrl.vehicleBookingDetails,
};

export const IS_SINGPASS_FLOW_PARAM = "isSingpassFlow";
export const RESET_PARAM = "reset";

export const staticMapDimensions = {
	parkingDetail: {
		desktop: {
			zoom: 19,
			height: 300,
			width: 508,
		},
		mobile: {
			zoom: 19,
			height: 212,
			width: 390,
		},
	},
	review: {
		desktop: {
			zoom: 17,
			height: 300,
			width: 508,
		},
		mobile: {
			zoom: 17,
			height: 160,
			width: 295,
		},
	},
};

export const getHdbCarparkingListingLayerBaseUrl = () => {
	const url = getEnvVar("LISTING_LAYER_BASE_URL") + listingResultPath;

	return url;
};

export const QUERY_PARAMS = {
	step4: [
		"sid",
		"vehicleNum",
		"startDate",
		"address",
		"blockNo",
		"roadName",
		"building",
		"postalCode",
		"lat",
		"lng",
		"x",
		"y",
		"carParkName",
		"carParkNumber",
		"carParkLat",
		"carParkLng",
		"carParkDistance",
		"carParkType",
		"carParkingSystem",
		"isSubsequentBooking",
	],
};

export const checkingContainParams = (listParams: object, listNecessaryParams: Array<string>) => {
	return listNecessaryParams.every((key) => listParams.hasOwnProperty(key) && listParams[key]);
};

export const funeralDatePattern = "dd/MM/yyyy";

export const LIMIT_ERROR_TYPE = {
	VEHICLE_LIMIT: 1,
	DAY_PARKING_LIMIT: 2,
};

export const hdbMaintenanceDateDisplayFormatter = "dd MMMM yyyy, EEEE, h:mma";
