import Stepper from "app/components/basic/Stepper";
import React from "react";
import { Grid } from "semantic-ui-react";

interface Props {
	steps: string[];
	currentStep: number;
}

export const DeathCertStepper = (props: Props) => {
	const { steps, currentStep } = props;

	return (
		<Grid.Column className="no-margin" mobile={12} tablet={12} computer={4}>
			<Stepper
				currentStep={currentStep}
				id="deathcert_stepper"
				lastCompletedStep={currentStep - 1}
				steps={steps}
			/>
			<h3 className="death-cert-form__stepper__header">
				Step {currentStep + 1}: {steps[currentStep]}
			</h3>
		</Grid.Column>
	);
};
