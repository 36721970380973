import { DoneeDO, DoneeType, DoneeTypeDisplayNames } from "app/common/api/lpa";
import Modal from "app/components/basic/Modal";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { useCallback, useEffect } from "react";
import { getDoneeTypeServerName, IAppointPersonModal } from "../common/utils";

const NAME = "name";
const RELATIONSHIP = "relationship";
const CONTACT = "contact";
const DONEE_TYPE = "doneeType";

const fields: IFormSchema = {
	[NAME]: { type: "text", maxChar: 120, constraints: [["isRequired", "Name cannot be empty"]] },
	[RELATIONSHIP]: { type: "text", maxChar: 120, constraints: [["isRequired", "Relationship cannot be empty"]] },
	[CONTACT]: {
		type: "text",
		maxChar: 20,
		constraints: [
			["isRequired", "Phone number cannot be empty"],
			["isInternationalPhoneFormat", "Please enter a valid phone number"],
		],
	},
	[DONEE_TYPE]: {
		type: "radio",
		radioBoxItems: [
			{ label: DoneeTypeDisplayNames[DoneeType.MAIN_DONEE], modifier: "default" },
			{ label: DoneeTypeDisplayNames[DoneeType.REPLACEMENT_DONEE] },
		],
	},
};

const NewDoneeModal = ({
	personIndex,
	personList,
	closeCallback,
	updatePersonList,
}: IAppointPersonModal<DoneeDO>): JSX.Element => {
	const { form } = useForm();
	const { setupFormFields, warnChangesLostIfProceed, getValue } = form;

	const initFormModal = useCallback((): void => {
		if (undefined === personIndex) {
			return setupFormFields(fields, {}, NAME);
		}

		const coordinator: DoneeDO = personList[personIndex];
		const initialData = {
			[NAME]: coordinator.name,
			[RELATIONSHIP]: coordinator.relationship,
			[CONTACT]: coordinator.contact,
			[DONEE_TYPE]: coordinator.doneeType ? DoneeTypeDisplayNames[coordinator.doneeType] : null,
		};
		setupFormFields(fields, initialData, NAME);
	}, [personIndex, personList, setupFormFields]);

	const closeModal = useCallback((): void => {
		warnChangesLostIfProceed(() => closeCallback());
	}, [warnChangesLostIfProceed, closeCallback]);

	const handleUpdatePersonList = useCallback(async (): Promise<void> => {
		const newOrUpdatedDonee = new DoneeDO();
		newOrUpdatedDonee.name = getValue(NAME);
		newOrUpdatedDonee.relationship = getValue(RELATIONSHIP);
		newOrUpdatedDonee.contact = getValue(CONTACT);
		newOrUpdatedDonee.doneeType = getDoneeTypeServerName(getValue(DONEE_TYPE));

		const updatedList: DoneeDO[] = personList;
		if (undefined !== personIndex) {
			updatedList[personIndex] = newOrUpdatedDonee; // for editing
		} else {
			updatedList.push(newOrUpdatedDonee); // for new
		}

		updatePersonList(updatedList);
		closeCallback();
	}, [getValue, closeCallback, personIndex, personList, updatePersonList]);

	useEffect(() => initFormModal(), [initFormModal]);

	return (
		<Modal
			id="modal__add-coordinator"
			form={form}
			type="form"
			title="Add donee"
			isOpen={true}
			closeCallback={closeModal}
			button1={["Cancel", "secondary", closeModal]}
			button2={["Add", "primary", handleUpdatePersonList]}
		>
			<div style={{ marginTop: -24 }}>
				<Form.Input title="Name" field={NAME} form={form} />
				<div className="coordinator__form__row">
					<Form.Input title="Relationship" field={RELATIONSHIP} form={form} />
					<Form.Input title="Phone number" field={CONTACT} form={form} />
				</div>
				<Form.RadioBox title="Donee type" field={DONEE_TYPE} form={form} />
			</div>
		</Modal>
	);
};

export default NewDoneeModal;
