interface ITime {
	minutes: number;
	seconds: number;
}

const getTimeInMinutesAndSeconds = (timeInSeconds: number): ITime => ({
	minutes: Math.floor(timeInSeconds / 60),
	seconds: timeInSeconds % 60
});

export { getTimeInMinutesAndSeconds };
