import { DoneeDO, DoneePowerDO } from "app/common/api/lpaAcp/domainObjects";
import "app/components/basic/Checkbox/Checkbox.scss";
import { IFieldProps } from "app/components/common/Field";
import InputElement from "app/components/common/InputElement";
import { DoneePowerLabel, getRelationshipLabel } from "app/components/templates/LPA-ACP/common/data";
import { Form, IData, IFormFunctions, IFormSchema, useForm } from "app/hooks/useForm";
import { useCallback, useEffect } from "react";
import "./DoneeCard.scss";

export interface IDoneeCardItem {
	label: string;
	value: string;
	relationshipCode?: number;
	disabled?: boolean;
	power?: DoneePowerLabel;
	errorMessage?: string;
}

interface ICheckboxProps extends IFieldProps {
	disabled?: boolean;
	items: IDoneeCardItem[];
	selectedValues: DoneePowerDO[];
	updateSelectedValues: (newSelectedValues: IData) => DoneeDO[];
	loading?: boolean;
	className?: string;
	doneeForm: IFormFunctions;
}

const DoneeCard = (props: ICheckboxProps): JSX.Element => {
	const {
		doneeForm,
		updateSelectedValues,
		items,
		selectedValues,
		loading,
		errorMessage,
		disabled: disabledProp,
		className: _className,
	} = props;
	const { form } = useForm();
	const { setupFormFields, getFields } = form;

	const selectItemHandler = useCallback(() => {
		doneeForm.updateFieldValue("donees", updateSelectedValues(getFields()));
	}, [getFields, doneeForm, updateSelectedValues]);

	const getField = useCallback((items: IDoneeCardItem[], data: DoneePowerDO[]) => {
		const fields: IFormSchema = {};
		items.forEach(({ value, power }: IDoneeCardItem) => {
			fields[value] = {
				type: "radio",
				radioBoxItems: [
					{
						label: "Personal Welfare",
						value: "1",
						disabled: power === DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL ? true : false,
					},
					{
						label: "Property and Affairs",
						value: "2",
						disabled: power === DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL ? true : false,
					},
					{
						label: "Both Personal Welfare and Property and Affairs",
						value: "3",
						disabled: power === DoneePowerLabel.BOTH_POWER_LABEl ? false : true,
					},
				],
			};
		});
		const initialDatas = {};
		if (data) {
			data.forEach(({ authorizePersonalWelfare, authorizePropertyAffairs, replacingMainDonee }: DoneePowerDO) => {
				if (replacingMainDonee) {
					initialDatas[replacingMainDonee.id < 0 ? replacingMainDonee.nric : replacingMainDonee.id] =
						authorizePersonalWelfare && authorizePropertyAffairs
							? "3"
							: authorizePersonalWelfare
							? "1"
							: "2";
				}
			});
		}
		return { fields, initialDatas };
	}, []);

	useEffect(() => {
		const { fields, initialDatas } = getField(items, selectedValues);
		setupFormFields(fields, initialDatas);
	}, [items, getField, selectedValues, setupFormFields]);

	if (loading === true) {
		return <div className="checkbox__loading" />;
	}

	let className = "donee--group";
	if (errorMessage) {
		className += " donee--group__error";
	}

	const renderDoneeCardItem = (
		{ label, value, relationshipCode, disabled, errorMessage }: IDoneeCardItem,
		index: number,
	): JSX.Element => {
		let className = "donee--card";

		if (disabled || disabledProp) {
			className += " donee--card__disabled";
		}

		if (_className) {
			className += ` ${_className}`;
		}

		const doneeRelationship = relationshipCode ? getRelationshipLabel(relationshipCode) : "";

		return (
			<Form.RadioBox
				key={`donee--card-${index}`}
				title={label}
				subtitle={doneeRelationship}
				field={value}
				columns={1}
				form={form}
				className={className}
				disabled={disabled || disabledProp}
				afterSelectItem={selectItemHandler}
				alert={errorMessage}
				labelClassName={"donee--card--label"}
			/>
		);
	};

	return (
		<div className={className}>{items.map((item: IDoneeCardItem, index) => renderDoneeCardItem(item, index))}</div>
	);
};

export default InputElement(DoneeCard);
