import { TickIcon } from "@lifesg/react-icons";
import "./styles.scss";
interface IStepperProps {
	steps: string[];
	currentStep: number;
	/**
	 * This is the index of the last step that user has completed.
	 */
	lastCompletedStep: number;
	renderMinifiedVersion?: boolean;
	renderMinifiedVersionHeader?: boolean;
	className?: string;
	id: string;
	isSaving?: boolean;
	lastSavedOn?: string;
}

const Stepper = (props: IStepperProps): JSX.Element => {
	const nodes: JSX.Element[] = [];
	let mobileNodes: JSX.Element = <></>;

	for (let i = 0; i < props.steps.length; i++) {
		if (i <= props.currentStep) {
			nodes.push(
				<div
					id={`step-${i + 1}`}
					className={`step ${
						i === props.currentStep
							? "active"
							: "completed" + (i === props.lastCompletedStep ? " last" : "")
					}`}
					key={i}
				>
					<div className="step__number"></div>
					<div className="step__label">
						<span>{props.steps[i]}</span>
						{i < props.currentStep && (
							<div className="step__icon">
								<TickIcon />
							</div>
						)}
					</div>
				</div>,
			);
		} else {
			nodes.push(
				<div id={`step-${i + 1}`} className="step inactive" key={i}>
					<div className="step__number"></div>
					<div className="step__label">{props.steps[i]}</div>
				</div>,
			);
		}

		if (props.renderMinifiedVersionHeader && i === props.currentStep) {
			mobileNodes = <div className="minified-stepper-label__title ds__text__display-2">{props.steps[i]}</div>;
		}
	}

	return (
		<>
			<div
				id={props.id}
				className={`stepper${props.renderMinifiedVersion === true ? " stepper--mini" : ""} ${
					props.className ? props.className : ""
				}`}
			>
				{nodes}
			</div>
			<div
				className={`minified-stepper-label ${
					props.renderMinifiedVersion === true ? "minified-stepper-label--mini" : ""
				}`}
			>
				<div className="minified-stepper-label__top-section">
					<div>
						Step {props.currentStep + 1} of {props.steps.length}
					</div>
					{props.isSaving ? (
						<small>Saving...</small>
					) : (
						props.lastSavedOn && <small>{props.lastSavedOn}</small>
					)}
				</div>
				{mobileNodes}
			</div>
		</>
	);
};

export default Stepper;
