import { RefreshToken } from "@wog/mol-lib-api-contract/auth";
import axios from "axios";

const DEFAULT_PARAMS = {
	timeout: 15000,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
};

export const RefreshTokenRoute = RefreshToken.route;
const BASE_URL: string = process.env.MOL_AUTH_FORWARDER_BASE_URL || "";

const client = axios.create({
	baseURL: BASE_URL,
	timeout: DEFAULT_PARAMS.timeout,
	headers: {
		...DEFAULT_PARAMS.headers,
		"Strict-Transport-Security": "max-age=31536000; includeSubDomains",
	},
});

const refreshMOLToken = async () => {
	// NOTE: There should be no response, check if cookie is renewed in browser
	//currently no refresh for guest user is needed
	//await client.post<void>("/auth-forwarder/api/auth-guest/refresh-token/v1");
	await client.post<void>(RefreshTokenRoute);
};

export const AuthAPI = {
	refreshMOLToken,
};
