import { Form as DSForm } from "@lifesg/react-design-system/form";
import { Color } from "@lifesg/react-design-system/color";
import { BaseTheme } from "@lifesg/react-design-system/theme";
import { FormLabelAddonProps } from "@lifesg/react-design-system/form/types";
import React from "react";
import { FormType } from "../types";

export interface FormLabelProps {
	id?: string;
	title?: string | JSX.Element;
	/** The hint of the input */
	subtitle?: string | JSX.Element;
	/** To specify the icon and the popout content */
	popupContent?: FormLabelAddonProps;
	/** component inside label */
	children: JSX.Element | JSX.Element[];
	/** label full width */
	fullWidthLabel?: boolean;
	/** Additional CSS class name */
	labelClassName?: string;
	errorMessageTestId?: string;
	errorMessage?: string;
	formType?: FormType;
}

export const FormLabel = (props: FormLabelProps): JSX.Element => {
	const { title, subtitle, popupContent, children, labelClassName, fullWidthLabel, formType } = props;

	const className =
		(formType !== FormType.ACP ? "form__label " : "") +
		(labelClassName ?? "") +
		(fullWidthLabel ? " form__label--fullWidth" : "");

	return (
		<div className={className}>
			<DSForm.CustomField
				id={props.id}
				label={{
					children: title,
					style: { color: "#424242" }, // for label color
					subtitle: subtitle ? (
						<span
							className="mt8 form__label__subtitle"
							style={{ color: `${Color.Neutral[3]({ theme: BaseTheme })}` }}
						>
							{subtitle}
						</span>
					) : (
						""
					),
					addon: popupContent,
				}}
				errorMessage={props.errorMessage}
				data-error-testid={props.errorMessageTestId}
			>
				{children}
			</DSForm.CustomField>
		</div>
	);
};
