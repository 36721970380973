import { useEffect, useState } from "react";

export const useTimer = (timerDurationInSecond: number) => {
	const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState<number>(timerDurationInSecond);

	useEffect(() => {
		setRemainingTimeInSeconds(timerDurationInSecond);
	}, [timerDurationInSecond]);

	useEffect(() => {
		if (remainingTimeInSeconds > 0) {
			const timeout = setTimeout(() => setRemainingTimeInSeconds(remainingTimeInSeconds - 1), 1000);
			return () => clearTimeout(timeout);
		}
		return () => {};
	}, [remainingTimeInSeconds]);

	return {
		remainingTimeInSeconds,
	};
};
