import NavbarGrey from "./Navbar.Grey";
import NavbarPublic from "./Navbar.Public";
import "./Navbar.scss";
import NavbarVault from "./Navbar.Vault";
import { NavbarContainer } from "./styles";

interface IProps {
	notifications?: number;
	isLoggedIn: boolean;
}

const Navbar = (props: IProps): JSX.Element | null => {
	const { pathname } = window.location;
	const renderNavbarVault =
		pathname.includes("/vault") &&
		// Don't show secondary navbar for '/vault/notification' '/vault/profile' '/vault/settings'
		/^((?!vault\/notification|vault\/profile|vault\/settings).)*$/.exec(pathname) &&
		props.isLoggedIn;

	return (
		<>
			<NavbarContainer id="mylegacy_navbar">
				<NavbarGrey />
				<NavbarPublic notifications={props.notifications} />
			</NavbarContainer>
			{renderNavbarVault && <NavbarVault />}
		</>
	);
};

export default Navbar;
