import { PlusCircleFillIcon } from "@lifesg/react-icons";
import "../styles.scss";

interface IAddPersonProps {
	buttonLabel: string;
	buttonDescriptor?: string;
	onClick: () => void;
	disabled?: boolean;
	errorMessage?: string;
	dataTestId?: string;
	id?: string;
}

const AddPersonButton = (props: IAddPersonProps): JSX.Element => {
	return (
		<div
			id={props.id ?? "add-coordinator-button"}
			className={`coordinator__button-wrapper coordinator-add ${props.disabled ? "disabled" : ""}`}
			onClick={props.disabled ? () => {} : props.onClick}
			data-testid={props.dataTestId}
		>
			<PlusCircleFillIcon />
			<div>
				<h5>{props.buttonLabel}</h5>
				{props.buttonDescriptor && (
					<div className="coordinator-button-descriptor">{props.buttonDescriptor}</div>
				)}
			</div>
		</div>
	);
};

export default AddPersonButton;
