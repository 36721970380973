import { decrypt, encrypt } from "app/common/utils/encryption";
import { ACPStorageKey, ACPStorageKeySecret } from "app/constants/lpaAcp";
import {
	EwillsSessionKeyPreFormSecret,
	EwillsSessionStorageKey,
	PreformSessionStorageKey,
} from "app/modules/eWills/constants";
import { HdbParkingStorageKey, HdbParkingStorageKeySecret } from "app/modules/hdb-car-parking/constants";

enum sessionStorageKeys {
	PREV_PATH = "prevPath",
	CURR_PATH = "currentPath",
	BYPASS = "bypass_ewills_steps",
}

export const useSessionStorage = () => {
	const storage = globalThis?.sessionStorage;

	const setBypassEwillsNavigation = (bypass = "true") => {
		return storage.setItem(sessionStorageKeys.BYPASS, bypass);
	};

	const getBypassEwillsNavigation = () => storage.getItem(sessionStorageKeys.BYPASS);

	const getSessionStorageData = (key: string, secret = "guest") => {
		try {
			const data = storage.getItem(key) ?? "";
			const decryptedData = decrypt(data, secret);
			if (!decryptedData) {
				return null;
			}
			return decryptedData;
		} catch (error) {
			return null;
		}
	};

	const setDataInSessionStorage = (key: string, data: string, secret = "guest") => {
		try {
			const encryptValue = encrypt(data, secret);
			if (encryptValue) {
				storage.setItem(key, encryptValue);
			}
		} catch (error) {}
	};

	const clearDataInSessionStorage = (key: string) => {
		storage.removeItem(key);
	};

	const setHdbparkingDataInSessionStorage = (data: string) => {
		return setDataInSessionStorage(HdbParkingStorageKey, data, HdbParkingStorageKeySecret);
	};

	const getHdbparkingDataInSessionStorage = () => {
		return getSessionStorageData(HdbParkingStorageKey, HdbParkingStorageKeySecret);
	};

	const clearHdbparkingDataInSessionStorage = () => {
		return storage.removeItem(HdbParkingStorageKey);
	};

	const clearEWillDataInSessionStorage = () => {
		storage.removeItem(EwillsSessionStorageKey);
		storage.removeItem(PreformSessionStorageKey);
		storage.removeItem(EwillsSessionKeyPreFormSecret);
		return;
	};

	// For ACP Module
	const setACPDataInSessionStorage = (data: string) => {
		return setDataInSessionStorage(ACPStorageKey, data, ACPStorageKeySecret);
	};

	const getACPDataInSessionStorage = () => {
		return getSessionStorageData(ACPStorageKey, ACPStorageKeySecret);
	};

	const clearACPDataInSessionStorage = () => {
		return storage.removeItem(ACPStorageKey);
	};

	const getPrevPath = storage?.getItem(sessionStorageKeys.PREV_PATH);
	const getCurrPath = storage?.getItem(sessionStorageKeys.CURR_PATH);

	return {
		getSessionStorageData,
		setDataInSessionStorage,
		clearDataInSessionStorage,
		getPrevPath,
		getCurrPath,
		setBypassEwillsNavigation,
		getBypassEwillsNavigation,
		// for hdb parking module
		getHdbparkingDataInSessionStorage,
		setHdbparkingDataInSessionStorage,
		clearHdbparkingDataInSessionStorage,
		//For EWills
		clearEWillDataInSessionStorage,
		// For ACP Module
		getACPDataInSessionStorage,
		setACPDataInSessionStorage,
		clearACPDataInSessionStorage,
	};
};
