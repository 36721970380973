import { Navbar } from "@lifesg/react-design-system/navbar";
import BasicLink from "app/components/basic/Link/BasicLink";
import PageHeader from "app/components/composites/PageHeader";
import Footer from "app/components/page/Footer";
import Segment from "app/components/page/Segment";
import { ServiceMaintenancePeriodKey, useMaintenance } from "app/hooks/useMaintenance";
import { getEnvVar } from "app/utils/environmentVariable";
import { AvailableLanguages } from "app/utils/internationalization/constants";
import Head from "next/head";
import { ReactNode } from "react";
import { Grid } from "semantic-ui-react";
import {
	ContentContainer,
	ContentDivider,
	ContentWrapper,
	DescriptionSection,
	DescriptionTitle,
	Page,
	Section,
	TextBody,
} from "./index.styles";
import { ErrorDisplay } from "@lifesg/react-design-system/error-display";

const LocaleDateLanguageCode = {
	[AvailableLanguages.en]: "en-AU",
	[AvailableLanguages.ml]: "ms",
	[AvailableLanguages.zh]: "zh-CN",
	[AvailableLanguages.ta]: "ta",
};

const Maintenance = (props: { children: ReactNode }): JSX.Element => {
	const { isOnMaintenance, maintenanceEndDatetime } = useMaintenance(
		ServiceMaintenancePeriodKey.SHOW_MAINTENANCE_PAGE_V2,
	);

	const appAssetUrl = getEnvVar("APP_ASSET_URL");
	const mainCssUrl =
		appAssetUrl === "https://assets.life.gov.sg"
			? "https://assets.life.gov.sg/react-design-system/future/css/main.css"
			: "https://nprod.assets.lifesg.io/react-design-system/css/main.css";

	return isOnMaintenance ? (
		<Page id={"maintenance-page"}>
			<PageHeader title={`Maintenance - My Legacy`} />
			<Head>
				<link rel="stylesheet" type="text/css" href={mainCssUrl} />
			</Head>
			<Navbar
				items={{
					desktop: [],
					mobile: [],
				}}
				resources={{
					primary: {
						brandName: "MyLegacy",
						logoSrc: "/images/new-site-logo.svg",
					},
				}}
			/>

			<ContentWrapper>
				<Segment>
					<Grid>
						<Grid.Row>
							<Grid.Column mobile={12} tablet={12} computer={6}>
								<ContentContainer>
									<DescriptionSection>
										<DescriptionTitle weight={"semibold"}>Temporarily unavailable</DescriptionTitle>
										<div>
											<ErrorDisplay
												type="maintenance"
												additionalProps={{
													dateString: localeDateTimeFormatter(
														maintenanceEndDatetime,
														LocaleDateLanguageCode[AvailableLanguages.en],
													),
												}}
											/>
										</div>
									</DescriptionSection>
									<ContentDivider />
									<Section>
										<TextBody>
											生命足跡 (My Legacy) 因定期维护而暂时停用。我们会在
											<TextBody inline={true} weight={"semibold"}>
												{localeDateTimeFormatter(
													maintenanceEndDatetime,
													LocaleDateLanguageCode[AvailableLanguages.zh],
												)}
											</TextBody>
											恢复运作。
										</TextBody>
										<TextBody>
											请稍后再进入网站。如果您需要协助，请发电邮致&nbsp;
											<BasicLink
												id="contact-email"
												href="mailto:enquiries@mylegacy.gov.sg"
												name="enquiries@mylegacy.gov.sg"
												disablePrefetch={true}
											/>
											。
										</TextBody>
									</Section>
									<Section>
										<TextBody>
											Warisanku (My Legacy) kini tidak berfungsi untuk penyelenggaraan berjadual.
											Kami akan kembali berfungsi pada&nbsp;
											<TextBody inline={true} weight={"semibold"}>
												{localeDateTimeFormatter(
													maintenanceEndDatetime,
													LocaleDateLanguageCode[AvailableLanguages.ml],
												)}
											</TextBody>
											.
										</TextBody>
										<TextBody>
											Sila semak kembali nanti untuk mengakses laman. Jika anda memerlukan
											bantuan, anda boleh menghantar e-mel kepada&nbsp;
											<BasicLink
												id="contact-email"
												href="mailto:enquiries@mylegacy.gov.sg"
												name="enquiries@mylegacy.gov.sg"
												disablePrefetch={true}
											/>
											.
										</TextBody>
									</Section>
									<Section>
										<TextBody>
											திட்டமிட்ட பராமரிப்புப் பணிக்காக வாழ்க்கை மரபு இணையத்தளம்
											மூடப்பட்டிருக்கிறது.&nbsp;
											<TextBody inline={true} weight={"semibold"}>
												{localeDateTimeFormatter(
													maintenanceEndDatetime,
													LocaleDateLanguageCode[AvailableLanguages.ta],
												)}
											</TextBody>
											.
										</TextBody>
										<TextBody>
											தயவுசெய்து தளத்தைப் பயன்படுத்த பிற்பாடு வருகை தரவும். உங்களுக்கு உதவி
											தேவைப்பட்டால்,&nbsp;
											<BasicLink
												id="contact-email"
												href="mailto:enquiries@mylegacy.gov.sg"
												name="enquiries@mylegacy.gov.sg"
												disablePrefetch={true}
											/>
											&nbsp; முகவரிக்கு மின்னஞ்சல் அனுப்பலாம்.
										</TextBody>
									</Section>
								</ContentContainer>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</ContentWrapper>
			<Footer isMinimalFooter={true}></Footer>
		</Page>
	) : (
		<>{props.children}</>
	);
};

export default Maintenance;

const localeDateTimeFormatter = (dateString: string, locale: string) => {
	const date = new Date(dateString);

	const dateOption = new Intl.DateTimeFormat(locale, {
		year: "numeric",
		month: "long",
		day: "numeric",
	});

	const weekdayOption = new Intl.DateTimeFormat(locale, {
		weekday: "long",
	});

	// This will be in lower case -> pm/am
	// Alternatively could use en-US which returns PM/AM and transform to lower case after
	const timeOption = new Intl.DateTimeFormat("en-AU", {
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	});

	return `${dateOption.format(date)}, ${weekdayOption.format(date)}, ${timeOption.format(date).replace(" ", "")}`;
};
