import { getTruncateStyle } from "app/modules/common/styles";
import styled, { css } from "styled-components";

export type TTitle = {
	truncate?: boolean;
	lines?: number;
};

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	border-color: #e0e4e5;
	border-style: solid;
	border-width: 0.0625rem;
	border-radius: 0.5rem;
	justify-content: space-between;
	align-items: center;
`;

export const InputGroupContainer = styled.div`
	margin: 1rem;
	* {
		margin: 0 !important;
	}

	.distribution-input--error {
		border: 0.0625rem solid #dc6363;
		:focus-within {
			box-shadow: inset 0 0 0.375rem 0.0625rem rgba(203, 34, 19, 0.5);
		}
	}
`;

export const ErrorMessage = styled.p`
	margin: 0.5rem 0 0 0 !important;
	color: #cb2213;
	font-weight: 600;
	font-size: 0.875rem;

	line-height: 1.625rem;
`;

export const Title = styled.p<TTitle>`
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.625rem;
	margin: 1rem !important;

	${(props) =>
		props.truncate
			? css`
					${getTruncateStyle(props.lines)}
			  `
			: null}
`;
