import { Form as DSForm } from "@lifesg/react-design-system/form";
import { RadioButton as DSRadioButton } from "@lifesg/react-design-system/radio-button";
import { getOtherKey } from "app/components/basic/Radio";
import { INPUT_MESSAGE, INPUT_SEPARATOR, IRadioBoxItem } from "app/components/basic/Radio.Box";
import { IFormFunctions } from "app/hooks/useForm";
import { lowercaseAndHyphen } from "app/utils/strings";
import { kebabCase } from "lodash";
import isNil from "lodash/isNil";
import { useEffect, useMemo } from "react";

interface RadioItemProps {
	item: IRadioBoxItem;
	name: string;
	selectedValue: string;
	selectItem: (string) => void;
	disabled?: boolean;
	updateRadioInputError: (key: string, inputLabel: string) => void;
	inputWithError?: string;
	form?: IFormFunctions;
	field?: string;
	index?: number;
	/**
	 * Number of lines of a label to display before truncation
	 * Used for very long names
	 */
	labelLines?: number;
}

const RadioBoxItem = (props: RadioItemProps): JSX.Element => {
	const { item, form } = props;
	const hasError = useMemo(() => item.warning?.warningCallback(props.selectedValue), [props.selectedValue]);

	const id = `radio-box_${lowercaseAndHyphen(props.name)}_${lowercaseAndHyphen(item.label)}`;
	let radioValue = item.value || item.label;
	if (item.modifier === "input") {
		radioValue += INPUT_SEPARATOR + (props.selectedValue.split(INPUT_SEPARATOR)[1] ?? "");
	}
	const checked =
		!isNil(props.selectedValue) &&
		(getOtherKey(props.selectedValue) === item.value || getOtherKey(props.selectedValue) === item.label);

	const inputErrorMessage =
		props.inputWithError && props.inputWithError === item.label ? "This field cannot be empty" : "";

	const disabled = props.disabled || item.disabled;

	let className = "radio-box__item";

	if (checked) {
		className += " checked";
	}

	if (disabled) {
		className += " disabled";
	}

	if (hasError) {
		className += " radio-box__item--error";
	}
	// RadioBox item's subtitle, not the subtitle above the RadioBoxes
	const label = `${item.label}${item.modifier === "input" ? INPUT_MESSAGE : ""}`;

	const renderRadioInput = () => {
		const id = `radio-box_${lowercaseAndHyphen(props.name)}_${lowercaseAndHyphen(item.label)}-input`;

		const onBlurHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
			const inputValue = event.target.value;
			if (inputValue === "") {
				props.updateRadioInputError(props.name, item.label);
			} else {
				props.updateRadioInputError(props.name, "");
			}
		};

		const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const inputValue = event.target.value;
			const valueToReturn = `${item.value || item.label}${INPUT_SEPARATOR}${inputValue}`;
			props.selectItem(valueToReturn);
		};

		return (
			<DSForm.Input
				id={id}
				className="radio-box__item__input"
				name={id}
				maxLength={120}
				errorMessage={inputErrorMessage}
				onChange={onChangeHandler}
				onBlur={onBlurHandler}
				disabled={props.disabled}
				value={props.selectedValue.split(INPUT_SEPARATOR)[1] ?? ""}
			/>
		);
	};

	const onClickHandler = (event): void => {
		// Clear any input value when changing selection
		const newValue = `${event.target.value.split(":")[0]}${item.modifier === "input" ? INPUT_SEPARATOR : ""}`;
		props.selectItem(newValue);
	};

	const renderWarningMessage = () => {
		if (!form || !hasError) {
			return <></>;
		}
		return item.warning?.warningMessage(form);
	};

	// this useeffect runs to clear custom error shared by all radio boxes
	useEffect(() => {
		if (!item.field) {
			return;
		}
		if (hasError) {
			return form?.updateFieldValue(item.field, "error");
		}
		return form?.updateFieldValue(item.field, "");
	}, [hasError]);

	return (
		<label key={id} htmlFor={id} className={className} data-testid={`${props.name}__radio`}>
			<DSRadioButton
				className="radio-box__item__button"
				disabled={disabled}
				id={id}
				key={id}
				name={props.name}
				value={radioValue}
				checked={checked}
				onClick={onClickHandler}
				data-testid={`radio-input-${kebabCase(props.name)}-${props.index}`}
			/>
			<div className="radio-box__item__label-container">
				<label
					className={item.labelBold ? "radiobox__title " : "" + (props.labelLines ? "truncate" : "")}
					htmlFor={id}
					style={{ WebkitLineClamp: props.labelLines }}
				>
					{label}
				</label>
				{item.subtitle && (
					<div className="ds__text__body-x-small radio-box__item-subtitle mt8">{item.subtitle}</div>
				)}
				{item.modifier === "input" && checked ? renderRadioInput() : <></>}
			</div>
			{renderWarningMessage()}
		</label>
	);
};

export default RadioBoxItem;
