import { LogoutIcon } from "@lifesg/react-icons/logout";
import translations from "app/assets/files/translations/navbar.json";
import Button from "app/components/basic/Button";
import LanguageSelector from "app/components/basic/LanguageSelector";
import BasicLink from "app/components/basic/Link";
import Segment from "app/components/page/Segment";
import { eWillsPreFormStep } from "app/modules/eWills/constants";
import { useEwillsForm } from "app/modules/eWills/hooks/useEWillsForm";
import { useHdbParkingForm } from "app/modules/hdb-car-parking/hooks/useHdbParkingForm";
import { useSessionStorage } from "app/modules/utils/hooks/useSessionStorage";
import { getCookie } from "app/utils/cookies";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import { ITranslatedObject } from "app/utils/internationalization/TranslationPageRenderer";
import { LanguageISOCode } from "app/utils/internationalization/constants";
import { setLogout } from "app/utils/url";
import Head from "next/head";
import { ReactNode, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import OnboardingForm from "./OnboardingForm";
import "./styles.scss";

const OnboardingScreenWrapper = (props: { children: ReactNode }): JSX.Element => {
	const [isOnboardingVisible, setOnboardingVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { clearHdbparkingDataInSessionStorage, clearEWillDataInSessionStorage } = useSessionStorage();
	const { resetFlow } = useHdbParkingForm();
	const { setPreFormSteps } = useEwillsForm();

	const completeOnboarding = (): void => {
		// await UserApi.updateUserEmailOrPhone();
		setOnboardingVisible(false);
	};

	const showOnboardingScreenIfRequired = (): void => {
		const currentPath = window.location.href;
		const isNewLpa = currentPath.includes("/lpa-acp");
		if (currentPath.includes("/vault") && !isNewLpa && getCookie("onboard") === "false") {
			setOnboardingVisible(true);
			setPreFormSteps(eWillsPreFormStep.landing);
			clearEWillDataInSessionStorage();
			clearHdbparkingDataInSessionStorage();
			resetFlow();
		} else {
			setOnboardingVisible(false);
		}
		setIsLoading(false);
	};

	useEffect(showOnboardingScreenIfRequired, [window.location.href]);

	if (isLoading) {
		return <></>;
	}

	if (isOnboardingVisible) {
		return <OnboardingScreen onComplete={completeOnboarding} />;
	}
	return <>{props.children}</>;
};

export default OnboardingScreenWrapper;

type TPageNumbers = 1 | 2;

interface IOnboardingScreenProps {
	onComplete: () => void;
}

const OnboardingScreen = (props: IOnboardingScreenProps): JSX.Element => {
	const [page, changeToPage] = useState<TPageNumbers>(1);
	const [language] = useGlobalLanguageState();
	const [translatedCopies, setTranslatedCopies] = useState<ITranslatedObject[]>(translations["en"]);

	useEffect(() => {
		setTranslatedCopies(translations[language]);
	}, [language]);

	const goToPageTwo = (): void => {
		changeToPage(2);
		window.scrollTo(0, 0);
	};

	const Logout = (props: { className: string }): JSX.Element => <LogoutIcon className={props.className} />;

	const PageHeader = (): JSX.Element => (
		<div className="onboarding__header" lang={LanguageISOCode[language]}>
			<img
				src="/images/simplified-logo.svg"
				className="onboarding__logo"
				id="onboarding__logo"
				alt="My Legacy logo"
			/>
			<BasicLink
				id="onboarding-logout"
				name={translatedCopies[11].translation}
				IconLeft={Logout}
				disablePrefetch={true}
				fontSize={16}
				isAPIRoute={true}
				onClick={setLogout}
			/>
		</div>
	);

	const PageBackground = <div className="onboarding__banner" />;
	let PageBanner = <></>;
	let PageContent = <></>;

	if (page === 1) {
		PageBanner = (
			<div className="onboarding__banner">
				<img
					src="/images/planning-ahead-hero.png"
					className="onboarding__image"
					id="onboarding__image"
					alt="Person going through a checklist while making end-of-life plans"
				/>
			</div>
		);
		PageContent = <OnboardingForm onComplete={goToPageTwo} />;
	} else if (page === 2) {
		PageBanner = (
			<div className="onboarding__banner">
				<img
					src="/images/mylegacy-hero.png"
					className="onboarding__image"
					id="onboarding__image"
					alt="Woman writing down her end-of-life preferences and wishes for her family"
				/>
			</div>
		);
		PageContent = (
			<div>
				<h3 className="semi-bold onboarding__title">Start using My Legacy vault</h3>
				<div className="onboarding__welcome-page">
					<p className="content-title">Use your vault to:</p>
					<ul>
						<li>Record your own plans and information. Then share them with your Trusted Persons.</li>
						<li>View what other people have shared with you.</li>
					</ul>
				</div>
				<div className="onboarding__start">
					<Button id="onboarding_start-button" name="Start" type="primary" onClick={props.onComplete} />
				</div>
			</div>
		);
	}

	return (
		<div className="onboarding__grid">
			<Head>
				<title>Onboarding - Vault - My Legacy</title>
			</Head>
			<div className="onboarding__background">
				<div />
				<div />
				{PageBackground}
			</div>

			<Segment className="onboarding">
				<Grid className="onboarding__grid">
					<Grid.Row>
						<Grid.Column
							className="no-margin no-padding-vertical onboarding__content"
							mobile={12}
							tablet={12}
							computer={8}
						>
							<PageHeader />
							<LanguageSelector
								id="language-selector-top"
								className="mt40"
								backgroundClassName="segment__white"
							/>
							{PageContent}
						</Grid.Column>
						<Grid.Column className="no-margin no-padding-vertical" mobile={12} tablet={4} computer={4}>
							{PageBanner}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</div>
	);
};
