import { RelationshipToDonee, RelationshipCode } from "app/common/api/lpaAcp";
import { DoneeDO, DoneePowerDO } from "app/common/api/lpaAcp/domainObjects";
import { EMAIL_MAX_CHARS } from "app/common/email";
import { validateLocalPhoneNumber } from "app/common/validators";
import Modal from "app/components/basic/Modal";
import { IDoneeCardItem } from "app/components/composites/DoneeCard";
import { CombinedFormKeys, DoneePowerLabel, RelationshipLabel } from "app/components/templates/LPA-ACP/common/data";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import { IAppointPersonModal, isMainDonee, isUniquePerson, isUser } from "../common/utils";
import { identifyPower } from "../helpers";

const { ID, NAME, NRIC, PHONE_NUMBER, EMAIL_ADDRESS, RELATIONSHIP_CODE, DONEES } = CombinedFormKeys;

const fields: IFormSchema = {
	[ID]: {
		type: "number",
	},
	[NAME]: {
		type: "text",
		maxChar: 100,
		constraints: [
			["isRequired", "Enter Replacement Donee’s full name."],
			["isMinLength", 3, "Enter Replacement Donee’s full name."],
		],
	},
	[NRIC]: {
		type: "text",
		maxChar: 9,
		constraints: [
			["isRequired", "Enter Replacement Donee’s NRIC or FIN number."],
			["isNRIC", "Invalid NRIC or FIN number. Try again."],
		],
	},
	[RELATIONSHIP_CODE]: {
		type: "dropdown",
		dropdownItems: Object.values(RelationshipToDonee).map((value) => {
			return {
				label: RelationshipLabel[value],
				value: Number(RelationshipCode[value]),
			};
		}),
		constraints: [["isRequired", "Select Replacement Donee’s relationship to you."]],
	},
	[PHONE_NUMBER]: {
		type: "phone",
		maxChar: 9,
		constraints: [
			["isMobileNumber", "Invalid local mobile number. Enter a Singapore mobile number that begins with 8 or 9."],
			["isRequired", "Enter Replacement Donee’s local mobile number."],
		],
	},
	[EMAIL_ADDRESS]: {
		type: "email",
		maxChar: EMAIL_MAX_CHARS,
		constraints: [["isOPGOEmail", "Invalid email address."]],
	},
	[DONEES]: {
		type: "checkbox",
		doneeCardItems: [
			{ label: "Donee A", value: "1", power: DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL },
			{ label: "Donee B", value: "2", power: DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL },
			{ label: "Donee C", value: "3", power: DoneePowerLabel.BOTH_POWER_LABEl },
		],
		constraints: [["isRequired", "Select the decision powers you wish to give your Replacement Donee."]],
	},
};

const NewReplacementModalV2 = ({
	donees,
	personList,
	personIndex,
	closeCallback,
	updatePersonList,
	userNric,
}: IAppointPersonModal<DoneeDO>): JSX.Element => {
	const { form } = useForm();
	const {
		setupFormFields,
		loading,
		warnChangesLostIfProceed,
		getValue,
		updateFieldError,
		focusFirstError,
		updateFormField,
	} = form;
	const [replacementDoneeName, setReplacementDoneeName] = useState("replacement donee");
	const [powerDisable, setPowerDisable] = useState(false);

	const getFieldsWithUpdatedDoneeList = useCallback((donees: DoneeDO[] | undefined, nric?: string) => {
		//to check is replacing main donee or not
		const isReplacingMainDonee = donees?.filter((donee) => donee.nric === nric)[0];

		const curMainDonees: IDoneeCardItem[] = [];
		donees?.forEach((curDonee) => {
			const doneePower = identifyPower(
				curDonee.powers[0].authorizePersonalWelfare,
				curDonee.powers[0].authorizePropertyAffairs,
			);

			let power = doneePower;

			let error = "";

			if (isReplacingMainDonee) {
				const replacingMainDoneePower = identifyPower(
					isReplacingMainDonee.powers[0].authorizePersonalWelfare,
					isReplacingMainDonee.powers[0].authorizePropertyAffairs,
				);
				if (
					doneePower === replacingMainDoneePower ||
					replacingMainDoneePower === DoneePowerLabel.BOTH_POWER_LABEl
				) {
					error = `Your Replacement Donee has already been appointed as a Donee with this power(s)`;
				}
				if (replacingMainDoneePower === DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL) {
					power = DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL;
				}
				if (replacingMainDoneePower === DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL) {
					power = DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL;
				}
			}

			if (curDonee.nric === nric) {
				error = `You cannot appoint your ${donees.length === 1 ? "only" : ""} Donee as Replacement Donee`;
			}

			curMainDonees.push({
				label: curDonee.name,
				value: curDonee.id < 0 ? curDonee.nric : curDonee.id.toString(),
				relationshipCode: curDonee.relationshipCode,
				power: power,
				disabled: error ? true : false,
				errorMessage: error,
			});
		});

		const updatedFields = { ...fields };
		updatedFields[DONEES].doneeCardItems = curMainDonees;
		return updatedFields;
	}, []);

	const initFormModal = useCallback((): void => {
		if (undefined === personIndex) {
			const updatedFields = getFieldsWithUpdatedDoneeList(donees);
			return setupFormFields(updatedFields, {}, NAME);
		}

		const donee: DoneeDO = personList[personIndex];

		const initialData = {
			[ID]: donee.id,
			[NAME]: donee.name,
			[NRIC]: donee.nric,
			[RELATIONSHIP_CODE]: donee.relationshipCode,
			[PHONE_NUMBER]: donee.contact,
			[EMAIL_ADDRESS]: donee.email,
			[DONEES]: donee.powers,
		};
		const updatedFields = getFieldsWithUpdatedDoneeList(donees, donee.nric);
		setupFormFields(updatedFields, initialData, NAME);
	}, [getFieldsWithUpdatedDoneeList, setupFormFields, donees, personList, personIndex]);

	const closeModal = useCallback((): void => {
		warnChangesLostIfProceed(() => closeCallback());
	}, [warnChangesLostIfProceed, closeCallback]);

	const handleUpdatePersonList = useCallback(() => {
		const isCombinedFormSpokesperson = false;

		const doneeData = {
			id: (getValue(ID) || -1) as number,
			name: getValue(NAME) as string,
			nric: getValue(NRIC) as string,
			relationshipCode: getValue(RELATIONSHIP_CODE) as number,
			contact: getValue(PHONE_NUMBER) as string,
			email: getValue(EMAIL_ADDRESS) as string,
			powers: getValue(DONEES) as DoneePowerDO[],
			isCombinedFormSpokesperson,
		};

		const newOrUpdatedPerson: DoneeDO = Object.assign(new DoneeDO(), doneeData);

		const updatedList: DoneeDO[] = personList;
		if (undefined !== personIndex) {
			updatedList[personIndex] = newOrUpdatedPerson; // for editing
		} else {
			updatedList.push(newOrUpdatedPerson); // for new
		}

		updatePersonList(updatedList);
		closeCallback();
	}, [closeCallback, getValue, personIndex, personList, updatePersonList]);

	const setFormError = useCallback(
		(type: "duplicate" | "isUserNric" | "onlyMainDonee") => {
			let errorMsg: string;
			switch (type) {
				case "duplicate":
					errorMsg = "You’ve already added this person as your Replacement Donee";
					break;
				case "isUserNric":
					errorMsg = "You cannot appoint yourself as Replacement Donee";
					break;
				case "onlyMainDonee":
					errorMsg = "You cannot appoint your only Donee as Replacement Donee";
					break;
			}

			updateFieldError("nric", errorMsg);
			focusFirstError();
		},
		[focusFirstError, updateFieldError],
	);

	const resetPower = useCallback(() => {
		const newNric = getValue(NRIC) as string;
		const updatedFields = getFieldsWithUpdatedDoneeList(donees, newNric);
		updateFormField(DONEES, updatedFields[DONEES]);
	}, [donees, getFieldsWithUpdatedDoneeList, getValue, updateFormField]);

	const validateUniqueDonee = useCallback(async () => {
		/* async to fit type of additionalValidations */
		const newNric = getValue(NRIC) as string;
		if (undefined !== personIndex) {
			const listWithoutCurr = [...personList];
			listWithoutCurr.splice(personIndex, 1);

			if (!isUniquePerson(newNric, listWithoutCurr)) {
				setFormError("duplicate");
				setPowerDisable(true);
				return false;
			}

			if (userNric && isUser(newNric, userNric)) {
				setFormError("isUserNric");
				setPowerDisable(true);
				return false;
			}

			if (donees && donees.length === 1 && isMainDonee(newNric, donees)) {
				resetPower();
				setFormError("onlyMainDonee");
				return false;
			}
		} else {
			if (!isUniquePerson(newNric, personList)) {
				setFormError("duplicate");
				setPowerDisable(true);
				return false;
			}

			if (userNric && isUser(newNric, userNric)) {
				setFormError("isUserNric");
				setPowerDisable(true);
				return false;
			}

			if (donees && donees.length === 1 && isMainDonee(newNric, donees)) {
				resetPower();
				setFormError("onlyMainDonee");
				return false;
			}
		}
		return true;
	}, [donees, getValue, personList, personIndex, resetPower, setFormError, userNric]);

	// get the name of donee
	const getReplacementDoneeName = useCallback(() => {
		const validatePhoneNumber =
			!!(getValue(PHONE_NUMBER) as string) && validateLocalPhoneNumber(getValue(PHONE_NUMBER) as string);

		let name = "this replacement donee";

		if (getValue(NAME) && validatePhoneNumber) {
			name = getValue(NAME);
		}

		setReplacementDoneeName(name);
	}, [getValue]);

	useEffect(() => initFormModal(), [initFormModal]);
	useEffect(() => {
		if (!loading) {
			getReplacementDoneeName();
		}
	}, [loading, getReplacementDoneeName]);

	return (
		<Modal
			id="modal__add-person"
			form={form}
			type="form"
			title="Add Replacement Donee"
			isOpen={true}
			closeCallback={closeModal}
			button1={["Cancel", "secondary", closeModal]}
			button2={["Add Replacement", "primary", handleUpdatePersonList, undefined, validateUniqueDonee]}
		>
			<div className="add-appointed-person">
				<Form.Input
					field={NAME}
					title="Full name (as in NRIC or FIN)"
					form={form}
					onBlurHandler={getReplacementDoneeName}
				/>
				<Form.Input
					field={NRIC}
					title="NRIC or FIN"
					form={form}
					additionalValidations={validateUniqueDonee}
					transformUppercase={true}
					onBlurHandler={resetPower}
					computerWidth={6}
					mobileWidth={12}
					tabletWidth={12}
				/>
				<Form.Dropdown
					field={RELATIONSHIP_CODE}
					title="Relationship to you"
					form={form}
					computerWidth={6}
					mobileWidth={12}
					tabletWidth={12}
				/>
				<Form.PhoneNumber
					field={PHONE_NUMBER}
					title="Local mobile number"
					subtitle={
						<>
							The person will receive a text message (SMS) that you chose them as your Replacement Donee.
							They need to login to OPGO to accept or reject it.
							<br />
							If they don’t have a local number, use yours instead. Please inform them about what they
							need to do.
						</>
					}
					form={form}
					onBlurHandler={getReplacementDoneeName}
					computerWidth={6}
					mobileWidth={12}
					tabletWidth={12}
				/>
				<Form.Input field={EMAIL_ADDRESS} title="Email address (optional)" form={form} />
				<Form.DoneeCard
					field={DONEES}
					title={`In the event that a Donee is unable to act, I authorise ${replacementDoneeName} to replace the following Donee or Donees:`}
					form={form}
					donees={donees}
					disabled={powerDisable}
				/>
			</div>
		</Modal>
	);
};

export default NewReplacementModalV2;
