import RadioBoxItem from "app/components/basic/Radio.Box/components/RadioBoxItem";
import { IFieldProps } from "app/components/common/Field";
import InputElement from "app/components/common/InputElement";
import AlertBanner from "app/components/composites/AlertBanner";
import { IFormFunctions } from "app/hooks/useForm";
import "./Radio.Box.scss";

export const INPUT_SEPARATOR = ": ";
export const INPUT_MESSAGE = " (Please specify)";

export interface IRadioBoxItem {
	label: string;
	modifier?: "input" | "default";
	disabled?: boolean;
	subtitle?: string | JSX.Element;
	value?: string;
	labelBold?: boolean;
	warning?: {
		warningCallback: (value: any) => boolean;
		warningMessage: (form: IFormFunctions) => JSX.Element;
	};
	field?: string;
	type?: "yes" | "no" | "radio";
}

interface IRadioBoxProps extends IFieldProps {
	columns?: 1 | 2;
	items: IRadioBoxItem[];
	updateRadioInputError: (key: string, inputLabel: string) => void;
	inputWithError?: string;
	selectItem: (item: string) => void;
	selectedValue: string;
	errorMessage?: string;
	addonText?: string;
	alert?: string;
	form?: IFormFunctions;
	field?: string;
	/**
	 * Number of lines of a label to display before truncation
	 * Used for very long names
	 */
	labelLines?: number;
}

const RadioBox = (props: IRadioBoxProps): JSX.Element => {
	let className = `radio-box__group`;
	if (props.columns === 2) {
		className += " radio-box__group--column";
	}

	if (props.errorMessage) {
		className += " radio-box__group--error";
	}

	return (
		<div className={className}>
			{props.items.map((item: IRadioBoxItem, index) => (
				<RadioBoxItem
					key={`${item.label}-radio-box-item-${index}`}
					item={item}
					name={props.name}
					field={props.field}
					selectedValue={props.selectedValue}
					disabled={props.disabled ?? item.disabled}
					updateRadioInputError={props.updateRadioInputError}
					inputWithError={props.inputWithError}
					selectItem={props.selectItem}
					form={props.form}
					labelLines={props.labelLines}
					index={index}
				/>
			))}
			{props.alert ? (
				<div className="radio-box__alert" id={`${props.name}-radio-box__alert`}>
					<AlertBanner type="error">{props.alert}</AlertBanner>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default InputElement(RadioBox);
