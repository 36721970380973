import ErrorMessage from "app/components/widgets/ErrorMessage";
import { FORM_FIELD_WITH_ERROR_CLASSNAME } from "app/hooks/useForm";
import * as React from "react";
import "./Field.scss";

export type TWidths = "mini" | "small" | "medium" | "large" | "huge";

export interface IFieldProps {
	name: string;
	title?: string | JSX.Element;
	largeTitle?: boolean;
	smallTitle?: boolean; // 14px
	hideTitle?: boolean;
	width?: TWidths;
	subtitle?: string | JSX.Element;
	disabled?: boolean;
	largeSubtitle?: boolean;
	prefix?: string;
	errorMessage?: null | string | JSX.Element;
	componentId?: string;
	hideErrorMessage?: boolean;
	labelAsDiv?: boolean;
	children?: React.ReactNode;
}

const Field = (props: IFieldProps): JSX.Element => {
	return (
		<div
			className={`field ${props.disabled ? "disabled" : ""} ${
				props.errorMessage ? FORM_FIELD_WITH_ERROR_CLASSNAME : ""
			}`}
		>
			{props.title &&
				!props.hideTitle &&
				renderLabel({
					name: props.name,
					title: props.title,
					componentId: props.componentId,
					largeTitle: props.largeTitle,
					smallTitle: props.smallTitle,
					labelAsDiv: props.labelAsDiv,
				})}
			{props.subtitle && <p className="field__subtitle">{props.subtitle}</p>}
			<div style={calculateWidth(props.width)}>
				<div className={`field__child ${props.prefix !== undefined ? " field--prefixed" : ""}`}>
					{props.children}
				</div>
			</div>
			{props.errorMessage && !props.hideErrorMessage && (
				<ErrorMessage id={(props.componentId ? props.componentId : props.name) + "__error"}>
					{props.errorMessage}
				</ErrorMessage>
			)}
		</div>
	);
};

const calculateWidth = (type?: TWidths): React.CSSProperties => {
	if (!type) {
		return { maxWidth: "100%", width: "100%" };
	}
	switch (type) {
		case "mini":
			return { maxWidth: "100%", width: 88 };
		case "small":
			return { maxWidth: "100%", width: 144 };
		case "medium":
			return { maxWidth: "100%", width: 240 };
		case "large":
			return { maxWidth: "100%", width: 384 };
		case "huge":
			return { maxWidth: "100%", width: 624 };
	}
};

interface IFieldLabelProps {
	name: string;
	title: string | JSX.Element;
	componentId?: string;
	largeTitle?: boolean;
	smallTitle?: boolean;
	labelAsDiv?: boolean;
}

const renderLabel = (props: IFieldLabelProps) => {
	const { name, title, componentId, largeTitle, smallTitle, labelAsDiv } = props;
	const labelForId = componentId ? componentId : name;
	// the logs is that <label /> cannot be tied to non form elements (eg for dropdown it is actually another div)
	// so have to use id here, and aria-labelledby in the form component
	if (labelAsDiv) {
		return (
			<div id={`${componentId ? componentId : name}__label`} className="field__title">
				{title}
			</div>
		);
	}

	if (largeTitle) {
		return (
			<label htmlFor={labelForId}>
				<h4 className="field__title field__title--large">{title}</h4>
			</label>
		);
	}

	return (
		<label htmlFor={labelForId} className={`field__title${smallTitle ? " field__title--small" : ""}`}>
			{title}
		</label>
	);
};

export default Field;
