import { useRouter } from "next/router";
import { createContext, SetStateAction, useContext, useEffect, useState } from "react";
import { AvailableLanguages, checkIfPageHasTranslation, LanguageISOCode } from "./constants";

export const LanguageContext = createContext<
	[AvailableLanguages, AvailableLanguages, (updatedLanguage: AvailableLanguages) => void]
>([AvailableLanguages.en, AvailableLanguages.en, (_: SetStateAction<AvailableLanguages>): void => {}]);

export const GlobalLanguageProvider = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
	const [language, setLanguage] = useState<AvailableLanguages>(AvailableLanguages.en);
	const [selectedLanguage, setSelectedLanguage] = useState<AvailableLanguages>(AvailableLanguages.en);
	const currentPath = useRouter().pathname;

	useEffect(() => {
		const storedLanguage = localStorage.getItem("language");
		if (storedLanguage) {
			setLanguage(AvailableLanguages[storedLanguage]);
			setSelectedLanguage(AvailableLanguages[storedLanguage]);
		} else {
			localStorage.setItem("language", AvailableLanguages.en);
		}
	}, []);

	useEffect(() => {
		if (!checkIfPageHasTranslation(currentPath)) {
			document.documentElement.lang = LanguageISOCode[AvailableLanguages.en];
			setLanguage(AvailableLanguages.en);
			return;
		}
		document.documentElement.lang = LanguageISOCode[AvailableLanguages[language]];
		const storedLanguage = localStorage.getItem("language");
		storedLanguage && setLanguage(AvailableLanguages[storedLanguage]);
	}, [currentPath, language]);

	const setLocalStorage = (selectedLanguage: string) => {
		localStorage.setItem("language", selectedLanguage);
		setLanguage(AvailableLanguages[selectedLanguage]);
		setSelectedLanguage(AvailableLanguages[selectedLanguage]);
	};

	return (
		<LanguageContext.Provider value={[language, selectedLanguage, setLocalStorage]}>
			{children}
		</LanguageContext.Provider>
	);
};

export const useGlobalLanguageState = (): [
	AvailableLanguages,
	AvailableLanguages,
	(updatedLanguage: AvailableLanguages) => void,
] => useContext(LanguageContext);
