/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import metadata from "app/assets/files/metadata.json";
import Head from "next/head";
import { useRouter } from "next/router";
import * as React from "react";

const PageHeader = (props: { title: string }): JSX.Element => {
	const path = useRouter().pathname;
	const { metaDescription = undefined, targetKeywordsToRankFor = undefined } = metadata[path] || {};
	return (
		<Head>
			<title>{props.title}</title>
			{metaDescription && <meta name="description" content={metaDescription} />}
			{targetKeywordsToRankFor && <meta name="keywords" content={targetKeywordsToRankFor} />}
		</Head>
	);
};

export default PageHeader;
