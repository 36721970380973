import "./styles.scss";

interface IProps {
	className?: string;
	type?: "dark";
}

const InlineLoader = (props: IProps): JSX.Element => {
	let className = "spinner";

	if (props.className) {
		className += ` ${props.className}`;
	}

	return (
		<div className={`${className}${props.type ? " spinner__dark" : ""}`}>
			<div className="bar1" />
			<div className="bar2" />
			<div className="bar3" />
			<div className="bar4" />
			<div className="bar5" />
			<div className="bar6" />
			<div className="bar7" />
			<div className="bar8" />
		</div>
	);
};

export default InlineLoader;
