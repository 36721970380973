export enum FormType {
	LPA = "LPA",
	ACP = "ACP",
	LPA_ACP = "LPA_ACP",
}

export enum FormStatus {
	NONE = "NONE",
	DRAFT = "DRAFT",
}

export enum PrivacyAndConsent {
	YES = "1",
	NO = "2",
}

export enum SameMailAddress {
	YES = "Yes",
	NO = "No",
}

export enum RelationshipToDonee {
	SPOUSE = "SPOUSE",
	CHILD = "CHILD",
	PARENT = "PARENT",
	SIBLING = "SIBLING",
	GRANDCHILD = "GRANDCHILD",
	GRANDPARENT = "GRANDPARENT",
	STEP_CHILD = "STEP_CHILD",
	IN_LAW = "IN_LAW",
	PD_ACTING_AS_DONEE = "PD_ACTING_AS_DONEE",
	OTHER_FAMILY_MEMBER = "OTHER_FAMILY_MEMBER",
	NON_FAMILY_MEMBER = "NON_FAMILY_MEMBER",
}

export type TRelationshipCode = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11";

export const RelationshipCode: Record<RelationshipToDonee, TRelationshipCode> = {
	[RelationshipToDonee.SPOUSE]: "1",
	[RelationshipToDonee.CHILD]: "2",
	[RelationshipToDonee.PARENT]: "3",
	[RelationshipToDonee.SIBLING]: "4",
	[RelationshipToDonee.PD_ACTING_AS_DONEE]: "5",
	[RelationshipToDonee.OTHER_FAMILY_MEMBER]: "6",
	[RelationshipToDonee.NON_FAMILY_MEMBER]: "7",
	[RelationshipToDonee.IN_LAW]: "8",
	[RelationshipToDonee.GRANDCHILD]: "9",
	[RelationshipToDonee.GRANDPARENT]: "10",
	[RelationshipToDonee.STEP_CHILD]: "11",
};

export const RelationshipToDoneeFromCode = Object.fromEntries(
	Object.entries(RelationshipCode).map(([key, value]) => [value, key]),
) as Record<TRelationshipCode, RelationshipToDonee>;

export enum MannerOfActing {
	JOINTLY = "1",
	JOINTLY_AND_SEVERALLY = "2",
}

export enum WelfareConditionAllowTreatment {
	YES = "1",
	NO = "2",
}

export enum PropertyConditionCourtApproval {
	YES = "2",
	NO = "1",
}

export enum PropertyConditionMakeGifts {
	RESTRICTED = "3",
	UNRESTRICTED = "2",
	NO = "1",
}

export enum OpgoResidentialStatus {
	ALIEN = "A",
	CITIZEN = "C",
	PR = "P",
	UNKNOWN = "U",
	NOT_APPLICABLE = "",
}

export enum OpgoGender {
	FEMALE = "F",
	MALE = "M",
	UNKNOWN = "U",
}
