import Button from "app/components/basic/Button";
import { IFormFunctions } from "../Form";
import "../Form.scss";

interface IFormButtonGroupProps {
	form: IFormFunctions;
	primaryButton: [string, () => Promise<void>, boolean?];
	secondaryButton?: [string, () => Promise<void> | void, boolean?];
	disabled?: boolean;
}

const emptyButton: [string, () => Promise<void> | void, boolean?] = ["", (): void => {}, false];

const SinglePageFormButtonGroup = (props: IFormButtonGroupProps): JSX.Element => {
	const isSubmitting = props.form.hasSubmittingField();

	const [primaryTitle, primaryAction, primaryDisabled] = props.primaryButton;
	const [secondaryTitle, secondaryAction, secondaryDisabled] = props.secondaryButton || emptyButton;

	const formSubmitAction = async (): Promise<void> => {
		await props.form.submitForm(primaryAction);
	};

	return (
		<div className="button-group__single-page">
			<Button
				id="single-page-form__primary-button"
				name={primaryTitle}
				type="primary"
				loading={isSubmitting}
				onClick={formSubmitAction}
				disabled={primaryDisabled || props.disabled}
			/>
			{props.secondaryButton && (
				<Button
					id="single-page-form__secondary-button"
					name={secondaryTitle}
					type="secondary"
					onClick={secondaryAction}
					disabled={secondaryDisabled || props.disabled}
				/>
			)}
		</div>
	);
};

export default SinglePageFormButtonGroup;
