import { ApplicationStatus } from "app/common/api/hdbParking";
import { RegisteredVehicleDO } from "app/common/api/user";
import { IDeathDocumentPage } from "../../pages/DeathDocumentDetails/types";
import { IHdbparkingPersonalDetails } from "../../pages/PersonalDetails/data";
import { CarParkType, ParkingSystem } from "@wog/mol-lib-api-contract/oneservice";

/**
 * to update this interface to support other booking fields
 */
export interface IBookingDetails {
	nric: string;
	certificationNumber: string;
	/** Vehicle details from any prior bookings */
	readonlyVehicleDetails?: IVehicleDetails[];
	vehicleDetails?: IVehicleDetails[];
	vehicleCount?: number;
	location?: ILocation;
	startDate?: string;
	carparkDetails?: ICarparkDetails;
	isSubsequentBooking?: boolean;
}
export interface IParkingDetails {
	locationOfFuneral: string;
	dateOfFuneral: string;
	carpark?: string;
}

export interface IApplicationResponse {
	referenceId: string;
	status: ApplicationStatus;
}

export interface IVehicleDetails {
	carplate: string;
	iu: string;
}

export interface IRegisteredVehicles {
	vehicles: RegisteredVehicleDO[];
	retrievedOn: string;
	/** `isPrefilled` is set if any registered vehicle has been prefilled before */
	isPrefilled?: boolean;
}

export interface ILocation {
	address: string;
	blockNo: string;
	building: string;
	roadName: string;
	postalCode: string;
	lat: number;
	lng: number;
	x: number;
	y: number;
}

export interface ICarparkDetails {
	number: string;
	name: string;
	lat: number;
	lng: number;
	distance: number;
	type: CarParkType;
	parkingSystem: ParkingSystem;
}

export interface IHdbParkingState {
	isLoading: boolean;
	isSubmitButtonEnabled: boolean;
	isRenderingForm: boolean;
	isSingpassFlow?: boolean;
	hasRedirected: boolean;
	latestStep: number;
	showDevMenu: boolean;
	personalDetails?: IHdbparkingPersonalDetails;
	bookingDetails?: IBookingDetails;
	deathDocumentPage?: IDeathDocumentPage;
	sid?: string;

	/** Registered vehicles retrieved from myInfo at step 1 */
	registeredVehicles?: IRegisteredVehicles;
	applicationResponse?: IApplicationResponse;

	parkingDetails?: IParkingDetails;

	// for session storage and navigation purpose
	hasFetchedSessionStorage: boolean;
	currentStep: number;

	// use for mocking failure / success for review page
	mockHdbApiError: boolean;
	//use for checking limit error
	deathCertificateErrorType?: number;
}

export enum HdbParkingActionType {
	SET_RENDERING_FORM = "SET_RENDERING_FORM",
	SET_LOADING_STATE = "SET_LOADING_STATE",
	SET_IS_SINGPASS_FLOW = "SET_IS_SINGPASS_FLOW",
	SET_HAS_REDIRECTED = "SET_HAS_REDIRECTED",
	SET_LATEST_STEP = "SET_LATEST_STEP",
	SET_RESET_FLOW = "SET_RESET_FLOW",
	RESET_FORM = "RESET_FORM",
	SHOW_DEV_MENU = "SHOW_DEV_MENU",
	SET_PERSONAL_DETAILS = "SET_PERSONAL_DETAILS",
	SET_BOOKING_DETAILS = "SET_BOOKING_DETAILS",
	SET_PARKING_DETAILS = "SET_PARKING_DETAILS",
	SET_SESSION_STORAGE_DATA = "SET_SESSION_STORAGE_DATA",
	SET_REGISTERED_VEHICLES = "SET_REGISTERED_VEHICLES",
	SET_USE_MOCK_HDB_API = "SET_USE_MOCK_HDB_API",
	SET_APPLICATION_RESPONSE = "SET_APPLICATION_RESPONSE",
	SET_SESSION_ID = "SET_SESSION_ID",
	SET_DEATH_CERTIFICATE_ERROR_TYPE = "SET_DEATH_CERTIFICATE_ERROR_TYPE",
	ENABLE_SUBMIT_BUTTON = "ENABLE_SUBMIT_BUTTON",
}
interface ResetFormAction {
	type: HdbParkingActionType.RESET_FORM;
}

interface ResetFlowAction {
	type: HdbParkingActionType.SET_RESET_FLOW;
}

interface SetRenderingAction {
	type: HdbParkingActionType.SET_RENDERING_FORM;
	payload: boolean;
}

interface SetIsSingpassFlowAction {
	type: HdbParkingActionType.SET_IS_SINGPASS_FLOW;
	payload: boolean;
}

interface SetLoadingAction {
	type: HdbParkingActionType.SET_LOADING_STATE;
	payload: boolean;
}
interface enableSubmitButtonAction {
	type: HdbParkingActionType.ENABLE_SUBMIT_BUTTON;
	payload: boolean;
}

interface SetHasRedirectedAction {
	type: HdbParkingActionType.SET_HAS_REDIRECTED;
	payload: boolean;
}

interface SetLatestStepAction {
	type: HdbParkingActionType.SET_LATEST_STEP;
	payload: number;
}

interface ShowDevMenuAction {
	type: HdbParkingActionType.SHOW_DEV_MENU;
	payload: boolean;
}

interface SetPersonalDetailsAction {
	type: HdbParkingActionType.SET_PERSONAL_DETAILS;
	payload: IHdbparkingPersonalDetails;
}
interface SetBookingDetailAction {
	type: HdbParkingActionType.SET_BOOKING_DETAILS;
	payload: IBookingDetails;
}
interface SetParkingDetailAction {
	type: HdbParkingActionType.SET_PARKING_DETAILS;
	payload: IParkingDetails;
}

interface SetDataFromSessionStorage {
	type: HdbParkingActionType.SET_SESSION_STORAGE_DATA;
	payload: IHdbParkingState;
}

interface SetRegisteredVehicles {
	type: HdbParkingActionType.SET_REGISTERED_VEHICLES;
	payload: IRegisteredVehicles;
}

interface SetUseMockHdbApi {
	type: HdbParkingActionType.SET_USE_MOCK_HDB_API;
	payload: {
		mockApiError: boolean;
	};
}

interface SetSessionId {
	type: HdbParkingActionType.SET_SESSION_ID;
	payload: string;
}

interface SetApplicationResponse {
	type: HdbParkingActionType.SET_APPLICATION_RESPONSE;
	payload: IApplicationResponse;
}

interface SetDeathCertificateErrorType {
	type: HdbParkingActionType.SET_DEATH_CERTIFICATE_ERROR_TYPE;
	payload: number;
}

export type HdbParkingAction =
	| ResetFormAction
	| SetRenderingAction
	| SetLoadingAction
	| SetIsSingpassFlowAction
	| SetHasRedirectedAction
	| SetLatestStepAction
	| ShowDevMenuAction
	| SetPersonalDetailsAction
	| SetBookingDetailAction
	| SetParkingDetailAction
	| SetDataFromSessionStorage
	| SetRegisteredVehicles
	| SetUseMockHdbApi
	| ResetFlowAction
	| SetApplicationResponse
	| SetSessionId
	| SetDeathCertificateErrorType
	| enableSubmitButtonAction;

export interface IHdbParkingContextType {
	state: IHdbParkingState;
	dispatch: React.Dispatch<HdbParkingAction>;
}
