import { DeathRecordEntityDO, DeathRecordIdentifierDO, DeathRecordPutRequestDO } from "../..";
import { HTTP_VERBS } from "../../../enums";

export const httpVerb = HTTP_VERBS.PUT;
export const url = "/api/v1/death-records/:certificateNumber";

export class Params extends DeathRecordIdentifierDO {}

export class Body extends DeathRecordPutRequestDO {}

export class Response extends DeathRecordEntityDO {}
