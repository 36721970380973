import { GetUserResponseDO } from "common/api/user";
import { createContext, useState } from "react";
import { IUserContext } from "./types";

// =============================================================================
// CONTEXT
// =============================================================================
export const UserContext = createContext<IUserContext>({
	user: null,
	setUser: () => null,
});

// =============================================================================
// CONTEXT PROVIDER
// =============================================================================
export const UserProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
	const [userDO, setUserDO] = useState<GetUserResponseDO | null>(null);

	return <UserContext.Provider value={{ user: userDO, setUser: setUserDO }}>{children}</UserContext.Provider>;
};
