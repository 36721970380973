import { ReplayIcon } from "@lifesg/react-icons/replay";
import OTPApi from "app/api/otp";
import UserAPI from "app/api/user";
import { OTPContactType, OTPType } from "app/common/api/otp";
import { ResponseOtpDO } from "app/common/api/otp/domainObjects";
import * as userContracts from "app/common/api/user/routes";
import { EMAIL_MAX_CHARS } from "app/common/email";
import { EOLErrorCodes } from "app/common/errors";
import { ActionBoxLoading, ActionBoxWithoutIcon } from "app/components/basic/ActionBox";
import { ICheckboxItem } from "app/components/basic/Checkbox";
import * as Display from "app/components/basic/Display";
import BasicLink from "app/components/basic/Link";
import Message from "app/components/basic/Message";
import OTPModal from "app/components/composites/OTPModal";
import WizardButtonGroup from "app/components/composites/Wizard/ButtonGroup";
import { GenericNetworkErrorMsg } from "app/constants/networkErrors";
import { useAlert } from "app/hooks/useAlert";
import { useErrorHandler } from "app/hooks/useErrorHandler";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { validateUserMyInfoContact } from "app/utils/user";
import { useCallback, useEffect, useState } from "react";
import "./styles.scss";

interface IOnboardingProps {
	onComplete: () => void;
}

const CONTACT_MODE = "notificationPreference";
const EMAIL = "email";
const PHONE = "phone";
const PHONE_LABEL = "Mobile number";
const EMAIL_LABEL = "Email";
const TERMS_AND_POLICY = "termsAndPolicy";

const formFieldSchema: IFormSchema = {
	[CONTACT_MODE]: {
		type: "radio",
		radioBoxItems: [{ label: "SMS" }, { label: EMAIL_LABEL }],
		constraints: [["isRequired", "Please provide one mode of contact"]],
	},
	[EMAIL]: {
		type: "email",
		maxChar: EMAIL_MAX_CHARS,
		disableValidation: true,
		constraints: [
			["isRequired", "Email cannot be empty."],
			["isEmail", "Please enter a valid email."],
		],
	},
	[PHONE]: {
		type: "numberString",
		maxChar: 8,
		disableValidation: true,
		constraints: [
			["isRequired", "Mobile number cannot be empty"],
			["isLocalPhoneNumber", "Please enter an 8-digit local phone number"],
		],
	},
	[TERMS_AND_POLICY]: {
		type: "checkbox",
		checkboxItems: [{ label: "I agree to the privacy statement and terms of use." }],
		disableValidation: true,
		constraints: [["isRequired", "Please read and agree to the privacy statement and terms of use"]],
	},
};

// @TODO: check if this needs to be updated
const myInfoFormSchema: IFormSchema = {
	[EMAIL]: {
		type: "text",
		maxChar: 120,
		disableValidation: true,
	},
	[PHONE]: {
		type: "text",
		maxChar: 120,
		disableValidation: true,
	},
	[TERMS_AND_POLICY]: {
		type: "checkbox",
		checkboxItems: [{ label: "I agree to the privacy statement and terms of use." }],
		constraints: [["isRequired", "Please read and agree to the privacy statement and terms of use"]],
	},
};

const PrivacyPolicy = (): JSX.Element => (
	<BasicLink
		type="inline-link"
		id="onboarding__privacy-statement"
		href="/privacy-statement"
		openInNewPage={true}
		name="privacy statement"
		fontSize={18}
	/>
);
const TermsOfUse = (): JSX.Element => (
	<BasicLink
		type="inline-link"
		id="onboarding__terms-of-use"
		href="/terms-of-use"
		openInNewPage={true}
		name="terms of use"
		fontSize={18}
	/>
);
const TermsAndPolicyHeader = (): JSX.Element => (
	<p className="mt40">
		Before using My Legacy, please review our <PrivacyPolicy /> and <TermsOfUse />.
	</p>
);

const OnBoardForm = ({ onComplete }: IOnboardingProps): JSX.Element => {
	const [fetchingOTP, setFetchingOTP] = useState<boolean>(false);
	const [OTPCreated, setOTPCreated] = useState<ResponseOtpDO | undefined>();
	const [showEmailInput, setShowEmailInput] = useState<boolean>(false);
	const [showPhoneInput, setShowPhoneInput] = useState<boolean>(false);
	const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
	const [showOTPModal, setShowOTPModal] = useState<boolean>(false);
	const [myInfoDetails, setMyInfoDetails] = useState<userContracts.getMyInfo.Response | null>(null);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [showForm, setShowForm] = useState<boolean>(false);
	const [showMyInfoError, setShowMyInfoError] = useState<boolean>(false);
	const [myInfoErrorMessage, setMyInfoErrorMessage] = useState<string>("");

	const closeOTPModal = (): void => setShowOTPModal(false);
	const openOTPModal = (): void => setShowOTPModal(true);

	const { form } = useForm();
	const { setupFormFields, resetAllFieldError, setFieldShouldValidate, setMultipleFieldShouldValidate, getValue } =
		form;
	const { toast, setInlineSnackbarMessage, inlineSnackbar } = useAlert();
	const { sendError } = useErrorHandler();

	const setupForm = useCallback(() => {
		if (!loaded) {
			// shows loading state with disabled checkbox
			const updatedItems = myInfoFormSchema[TERMS_AND_POLICY].checkboxItems!.map((item: ICheckboxItem) => {
				return { ...item, disabled: true };
			});
			const updatedTermsAndPolicy = { ...myInfoFormSchema[TERMS_AND_POLICY], checkboxItems: updatedItems };
			const updatedSchema = { ...myInfoFormSchema, [TERMS_AND_POLICY]: updatedTermsAndPolicy };

			setupFormFields(updatedSchema, {});
			setShowForm(false);
			return;
		}

		// if any of the field (phone/email) from myinfo is undefined, there will be an error thrown
		// myInfoDetails state value will remain as null
		if (myInfoDetails === null) {
			setupFormFields(formFieldSchema, {});
			setShowForm(true);
		} else {
			setupFormFields(myInfoFormSchema, {
				[PHONE]: myInfoDetails.phone,
				[EMAIL]: myInfoDetails.email,
			});
			setShowForm(false);
		}
	}, [loaded, myInfoDetails, setupFormFields]);

	const getMyInfoData = useCallback(async (): Promise<void> => {
		try {
			const details = await UserAPI.getMyInfo();
			validateUserMyInfoContact(details);
			setMyInfoDetails(details);
			setLoaded(true);
		} catch (err) {
			// get call returns object while post call returns array,
			// so this makes sure that errors from both calls in the try block can be captured properly
			const errorObj = Array.isArray(err) ? err[0] : err;

			if (errorObj.name === EOLErrorCodes.UserNotFoundError) {
				return window.location.replace("/");
			}
			if (errorObj.name === EOLErrorCodes.AlreadyOnboardedError) {
				return window.location.replace("/vault");
			}

			if (errorObj.name === EOLErrorCodes.MOLMyInfoError) {
				setMyInfoErrorMessage(
					"We could not retrieve your profile from Myinfo. Please check your connection and try again, or add your contact manually.",
				);
			}

			if (errorObj.name === EOLErrorCodes.MOLMyInfoMissingFieldError) {
				setMyInfoErrorMessage(
					"You do not have existing contact details saved in Myinfo. Please update your contact details manually.",
				);
			}

			setShowMyInfoError(true);
			setLoaded(true);
		}
	}, []);

	const handleAfterSelect = useCallback((): void => {
		setShowCheckbox(true);
		resetAllFieldError();
		setFieldShouldValidate(TERMS_AND_POLICY, true);
		setInlineSnackbarMessage(undefined);

		if (getValue(CONTACT_MODE) === EMAIL_LABEL) {
			setShowEmailInput(true);
			setShowPhoneInput(false);
			setMultipleFieldShouldValidate({
				[TERMS_AND_POLICY]: true,
				[EMAIL]: true,
				[PHONE]: false,
			});
		} else {
			setShowEmailInput(false);
			setShowPhoneInput(true);
			setMultipleFieldShouldValidate({
				[TERMS_AND_POLICY]: true,
				[EMAIL]: false,
				[PHONE]: true,
			});
		}
	}, [
		getValue,
		resetAllFieldError,
		setFieldShouldValidate,
		setInlineSnackbarMessage,
		setMultipleFieldShouldValidate,
	]);

	const addModeOfContactUsingMyInfo = useCallback(async (): Promise<void> => {
		try {
			await UserAPI.updateUserWithMyInfo(true);
			onComplete();
		} catch (err: any) {
			if (err[0].name === EOLErrorCodes.NetworkError) {
				toast("failure", GenericNetworkErrorMsg);
				return;
			}

			sendError(err);
		}
	}, [sendError, toast, onComplete]);

	const resetContact = useCallback((): void => {
		setMyInfoDetails(null);
	}, []);

	// if user wants to use their contact from my info, there is no need for otp verification
	const fetchOTP = useCallback(async (): Promise<void> => {
		if (!showForm) {
			await addModeOfContactUsingMyInfo();
			return;
		}
		setFetchingOTP(true);
		const contactType = showPhoneInput ? OTPContactType.SMS : OTPContactType.EMAIL;
		const contact = showPhoneInput ? getValue(PHONE) : getValue(EMAIL);
		try {
			const otp = await OTPApi.sendOTP(OTPType.ONBOARDING, contactType, contact);
			setOTPCreated(otp);
			openOTPModal();
		} catch (error: any) {
			if (error.name === EOLErrorCodes.UserNotFoundError) {
				return window.location.replace("/");
			}
			if (error[0].details === GenericNetworkErrorMsg) {
				toast("failure", GenericNetworkErrorMsg);
			}
			if (error[0].name === EOLErrorCodes.UserNotFoundError) {
				return window.location.replace("/");
			}
			if (error[0].name === EOLErrorCodes.AlreadyOnboardedError) {
				return window.location.replace("/vault");
			}
			if (error[0].name === EOLErrorCodes.OTPMaxAttemptsForUserReachedError) {
				setInlineSnackbarMessage(
					"Your contact details could not be verified. Please check again or contact us if you need help.",
				);
			}
		}
		setFetchingOTP(false);
	}, [addModeOfContactUsingMyInfo, getValue, setInlineSnackbarMessage, showForm, showPhoneInput, toast]);

	const otpSuccessCallback = useCallback(async (): Promise<void> => {
		onComplete();
	}, [onComplete]);

	// only set up form after the my info call has ended and state is updated so as to get the value
	useEffect(() => void getMyInfoData(), [getMyInfoData]);
	useEffect(() => void setupForm(), [setupForm]);

	const renderForm = (): JSX.Element => {
		if (!loaded) {
			return (
				<>
					<ActionBoxLoading className="mt24" />
					<Display.Loading className="mt24" />
					<Display.Loading className="mt24" />
				</>
			);
		}

		if (!showForm) {
			const NO_RECORD = "No record";
			const ResetIcon = (props: any): JSX.Element => (
				<div className={`${props.className}`}>
					<ReplayIcon className="onboarding__icon" />
				</div>
			);
			return (
				<>
					<ActionBoxWithoutIcon
						message="Your profile has been pre-filled with Myinfo"
						button={{ name: "Reset & add manually", action: resetContact, IconLeft: ResetIcon }}
						className="mt24 onboarding__actionbox"
					/>
					<div className="mt24">
						<Display.Text
							id="display__mobile-number"
							title={PHONE_LABEL}
							content={
								myInfoDetails ? (myInfoDetails.phone ? myInfoDetails.phone : NO_RECORD) : NO_RECORD
							}
							isNoRecord={myInfoDetails ? (myInfoDetails.phone ? false : true) : true}
						/>
						<Display.Text
							id="display__email"
							title={EMAIL_LABEL}
							content={
								myInfoDetails ? (myInfoDetails.email ? myInfoDetails.email : NO_RECORD) : NO_RECORD
							}
							isNoRecord={myInfoDetails ? (myInfoDetails.email ? false : true) : true}
						/>
					</div>
				</>
			);
		}

		const dismissMessage = (): void => {
			setShowMyInfoError(false);
		};

		const resetInlineError = (): void => {
			setInlineSnackbarMessage(undefined);
		};

		return (
			<>
				<Message
					className="mt24"
					type="warning"
					content={myInfoErrorMessage}
					show={showMyInfoError}
					closeMessageCallback={dismissMessage}
				/>
				<div className="mt24">
					<Form.RadioBox
						title="What is your preferred mode of contact?"
						field={CONTACT_MODE}
						columns={2}
						form={form}
						afterSelectItem={handleAfterSelect}
					/>
					{showEmailInput && (
						<Form.Input title={EMAIL_LABEL} field={EMAIL} form={form} onBlurHandler={resetInlineError} />
					)}
					{showPhoneInput && (
						<Form.Input
							computerWidth={6}
							mobileWidth={12}
							tabletWidth={12}
							title={PHONE_LABEL}
							field={PHONE}
							form={form}
							onBlurHandler={resetInlineError}
						/>
					)}
				</div>
			</>
		);
	};

	return (
		<>
			<div className="onboarding__details pageOne">
				<h3 className="semi-bold onboarding__title">Before you start</h3>
				<div className="onboarding__details__hr">
					Add your mobile number or email to receive important updates from people who have shared with you.{" "}
					<br />
				</div>
				{renderForm()}
				{(showCheckbox || !showForm) && (
					<>
						<TermsAndPolicyHeader />
						<Form.Checkbox field={TERMS_AND_POLICY} form={form} />
					</>
				)}
			</div>
			{inlineSnackbar && <div className="mt24">{inlineSnackbar}</div>}
			<div className="onboarding__buttons">
				<WizardButtonGroup form={form} next={fetchOTP} completeLoading={fetchingOTP} />
			</div>
			{showOTPModal && (
				<OTPModal
					isOpen={showOTPModal}
					OTPCreated={OTPCreated!}
					type={OTPType.ONBOARDING}
					contactType={showPhoneInput ? OTPContactType.SMS : OTPContactType.EMAIL}
					contact={showPhoneInput ? getValue(PHONE) : getValue(EMAIL)}
					closeCallback={closeOTPModal}
					successCallback={otpSuccessCallback}
					title={`Verify your ${showPhoneInput ? "mobile number" : "email"}`}
				/>
			)}
		</>
	);
};

export default OnBoardForm;
