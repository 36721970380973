import { HTTP_VERBS } from "../../../enums";
import {
	DeathCertDownloadRequestIdentifierDO,
	DeathCertDownloadRequestPatchDO,
	DeathCertDownloadRequestDO,
} from "../..";

export const httpVerb = HTTP_VERBS.PATCH;
export const url = "/api/v1/death-cert-download-requests/:requestId";

export class Params extends DeathCertDownloadRequestIdentifierDO {}

export class Body extends DeathCertDownloadRequestPatchDO {}

export class Response extends DeathCertDownloadRequestDO {}
