import { DeathRecordEntityDO, DeathRecordGetRequestDO, DeathRecordIdentifierDO } from "../..";
import { HTTP_VERBS } from "../../../enums";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/v1/death-records/:certificateNumber";

export class Params extends DeathRecordIdentifierDO {}

export class Query extends DeathRecordGetRequestDO {}

export class Response extends DeathRecordEntityDO {}
