import Button from "app/components/basic/Button";
import "./style.scss";

interface Props {
	id: string;
	title: string;
	onClickEdit?: () => void;
}

const SectionHeader = ({ id, title, onClickEdit }: Props): JSX.Element => (
	<div className="edit-header">
		<h5>{title}</h5>
		{onClickEdit ? <Button id={id} type="link" name="Edit" onClick={onClickEdit} /> : null}
	</div>
);

export default SectionHeader;
