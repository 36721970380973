import DeathCertAPI from "app/api/deathCert";
import { EOLErrorCodes } from "app/common/errors";
import determineSessionStatus, {
	SessionStatusType,
} from "app/components/composites/SessionModal/determineSessionStatus";
import DeathCertForm from "app/components/templates/DeathCert/Forms/DeathCertForm";
import { DeathCertFormContext, ErrorType } from "app/components/templates/DeathCert/Forms/DeathCertFormContext";
import RelationshipForm from "app/components/templates/DeathCert/Forms/RelationshipForm";
import ReviewForm from "app/components/templates/DeathCert/Forms/ReviewForm";
import { ConsolidatedResponses } from "app/components/templates/DeathCert/Forms/schemas";
import SelectTypeForm from "app/components/templates/DeathCert/Forms/SelectTypeForm";
import { useShowErrorPage } from "app/hooks/useShowErrorPage";
import { getCookie } from "app/utils/cookies";
import _ from "lodash";
import { useRouter } from "next/router";
import { ReactNode, useCallback, useRef, useState } from "react";

interface DeathCertFormContextProps {
	children: ReactNode;
}

const STEPS: React.FC[] = [SelectTypeForm, DeathCertForm, RelationshipForm, ReviewForm];

const DeathCertFormProvider = (props: DeathCertFormContextProps): JSX.Element => {
	const { children } = props;
	const [stepIndex, setStepIndex] = useState(0);
	const formResponses = useRef<ConsolidatedResponses>({});
	const addErrorCount = useShowErrorPage();
	const isEditingForm = useRef(false);
	const [error, setError] = useState<ErrorType>(null);
	const router = useRouter();

	const expiry = getCookie("expiry");
	const sessionStatus = determineSessionStatus(expiry);

	const callSaveRequestApi = useCallback(
		async (responses: ConsolidatedResponses): Promise<string> => {
			if (sessionStatus === SessionStatusType.active) {
				// filter out name, identificationNumber and identificationType if user is logged in
				responses = _.omit(responses, ["name", "identificationNumber", "identificationType"]);
			}
			const { requestId } = await DeathCertAPI.postDeathCertDownloadRequest(responses);
			return requestId;
		},
		[sessionStatus],
	);

	const goToDownloadPage = useCallback(
		(requestId: string) => {
			localStorage.removeItem("startedFormAt");
			sessionStorage.setItem("requestId", requestId);
			void router.replace(`${router.asPath}download`);
		},
		[router],
	);

	const submitCertRequest = useCallback(async () => {
		try {
			const requestId = await callSaveRequestApi(formResponses.current);
			goToDownloadPage(requestId);
		} catch (err: any) {
			if (err?.errorMessage?.includes(EOLErrorCodes.DeathRecordExpiredError)) {
				void router.replace(`${router.asPath}expired/${formResponses.current.certType}`);
			} else {
				setError("ApiError");
				window.scrollTo(0, 0);
				addErrorCount();
			}
		}
	}, [addErrorCount, callSaveRequestApi, goToDownloadPage, router]);

	const jumpTo = useCallback(
		(form: React.FC) => (responses?: ConsolidatedResponses) => {
			formResponses.current = { ...formResponses.current, ...responses };
			isEditingForm.current = true;

			setStepIndex(STEPS.indexOf(form));
			window.scrollTo(0, 0);
		},
		[],
	);

	const goBack = useCallback(
		(responses?: ConsolidatedResponses) => {
			formResponses.current = { ...formResponses.current, ...responses };

			if (stepIndex > 0) {
				setStepIndex((currentIndex) => currentIndex - 1);
				window.scrollTo(0, 0);
			} else {
				router.back();
			}
		},
		[router, stepIndex],
	);

	const goNext = useCallback(
		(responses?: ConsolidatedResponses) => {
			formResponses.current = { ...formResponses.current, ...responses };

			// move to next step if more steps
			const nextIndex = stepIndex + 1;
			if (STEPS.length > nextIndex) {
				if (isEditingForm.current) {
					isEditingForm.current = false;
					setStepIndex(STEPS.length - 1);
				} else {
					setStepIndex(nextIndex);
				}
				window.scrollTo(0, 0);
				void router.push(router.pathname, undefined, { shallow: true });
			} else {
				// the form is completed. Submit to API and proceed to download page
				void submitCertRequest();
			}
		},
		[router, stepIndex, submitCertRequest],
	);

	return (
		<DeathCertFormContext.Provider
			value={{
				formResponses: formResponses.current,
				jumpToPersonalDetails: jumpTo(DeathCertForm),
				jumpToRelationshipForm: jumpTo(RelationshipForm),
				isEditingForm: isEditingForm.current,
				goBack,
				goNext: _.debounce(goNext, 500, { leading: true }),
				error,
				stepIndex,
			}}
		>
			{children}
		</DeathCertFormContext.Provider>
	);
};

export default DeathCertFormProvider;
