import { Transform } from "class-transformer";
import { IsNumber, IsOptional, IsString } from "class-validator";
import { IsNotWhiteSpace } from "../../validators";
import { toNumber } from "./../transformers";

export class DocumentDO {
	@IsNotWhiteSpace({ always: true })
	@IsString({ always: true })
	public displayName!: string;
	@IsNotWhiteSpace({ always: true })
	@IsString({ always: true })
	public s3UniqueName!: string;
	@IsNumber(undefined, { always: true })
	public size!: number;
	@IsOptional({ always: true })
	public isSubmitting?: boolean;
	@IsOptional({ always: true })
	public hasError?: boolean;
}

export class DownloadSharedURLDO {
	@IsString()
	public s3UniqueName!: string;
	@IsNumber()
	@Transform(toNumber)
	public id!: number;
}

export class DownloadSharedURLResponseDO {
	@IsString()
	public url!: string;
}

export class DateRangeQuery {
	@IsString()
	public fromDate!: string;

	@IsString()
	public toDate!: string;
}
