import { IsMoment, IsOTPContact } from "app/common/validators";
import { Transform } from "class-transformer";
import { IsEnum, IsNotEmpty, IsNumber, IsNumberString, IsOptional, IsString, Length } from "class-validator";
import moment from "moment";
import { toMoment, toNumber } from "../../transformers";
import { APIOTPType, OTPContactType, OTPType } from "../enums";
import { IsEmail } from "@wog/mol-lib-api-contract/utils/validators";
import { EMAIL_MAX_CHARS } from "app/common/email";

class BaseOtpDO {
	@IsEnum(OTPType)
	public type!: OTPType;

	@IsNotEmpty()
	@IsString()
	@IsOTPContact("contactType")
	public contact!: string;
}

// validation for type should only allow for onboarding and profile but the actual type needs to be typed to invitation
// so that the same controller and service can be used
export class SendOTPDO {
	@IsEnum(APIOTPType)
	public type!: OTPType;

	@IsNotEmpty()
	@IsString()
	@IsOTPContact("contactType")
	public contact!: string;

	@IsEnum(OTPContactType)
	public contactType!: OTPContactType;
}

export class OtpDO extends BaseOtpDO {
	@IsEnum(OTPContactType)
	public contactType!: OTPContactType;
}

export class VerifyOtpDO extends BaseOtpDO {
	@IsNumberString()
	@Length(6, 6)
	public userInputOTP!: string;

	@IsNumber()
	@Transform(toNumber)
	@IsOptional()
	public invitationId?: number;
}

/**
 * Response DOs
 */

export class ResponseOtpDO {
	@IsMoment()
	@Transform(toMoment)
	public createdAt!: moment.Moment;
	@IsNumber()
	@Transform(toNumber)
	public remainingAttempts!: number;
}

export class VerifyResponseOtpDO {
	@IsString()
	@IsOptional()
	public phone?: string;
	@IsEmail({ maxLength: EMAIL_MAX_CHARS })
	@IsOptional()
	public email?: string;
}
