import { HTTP_VERBS } from "../../../enums";
import { DeathCertDownloadRequestDO } from "../..";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v1/death-cert-download-requests";

export class Body extends DeathCertDownloadRequestDO {}

export class Response extends DeathCertDownloadRequestDO {
	public requestId!: string;
}
