import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import { validateUinfin } from "@wog/mol-lib-api-contract/utils/data/sg-uinfin";

// tslint:disable-next-line: ban-types
export function IsNRIC(validationOptions?: ValidationOptions): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "IsNRIC",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string): boolean {
					return validateUinfin(value);
				},
				defaultMessage(args: ValidationArguments): string {
					return `${args.value} is not a valid nric.`;
				},
			},
		});
	};
}
