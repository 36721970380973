import { Transform } from "class-transformer";
import { IsNumber } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { toNumber } from "../../transformers";
import { SharedAccessDataDO } from "../domainObjects";

export const httpVerb = HTTP_VERBS.GET;
export const url = "/api/users/shared/:accessID";
export class Params {
	@IsNumber()
	@Transform(toNumber)
	public accessID!: number;
}
// CONTRACT_V2 - a bloody headache to type
// look at app/api/sharedAcces/index.ts
export class Response extends SharedAccessDataDO {}
