import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";

// only allows for local mobile number by default

// tslint:disable-next-line: ban-types
export function IsLocalPhoneNumber(
	mobileOnly = true,
	validationOptions?: ValidationOptions,
): (object: object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isLocalPhoneNumber",
			target: object.constructor,
			propertyName,
			constraints: [mobileOnly],
			options: validationOptions,
			validator: {
				validate(value: string, args: ValidationArguments): boolean {
					const mobileOnly = Boolean(args.object[args.constraints[0]]);
					return validateLocalPhoneNumber(value, mobileOnly);
				},
				defaultMessage(args: ValidationArguments): string {
					return `${args.value} is not a valid local mobile number.`;
				},
			},
		});
	};
}

//TODO: deprecate this file and replace decorators with @IsSGPhoneNumber
const SG_NUMBER_REGEX =
	/(?!^\+65\d{6}$)^(?:\+?(?:65)?\D?([9,8,6,3]{1}\d{3}\D?\d{4})|((?:1[8,9]00)\D?\d{3}\D?\d{4})|(800\D?\d{3}\D?\d{4}))$/;

const SG_MOBILE_NUMBER_REGEX =
	/(?!^\+65\d{6}$)^(?:\+?(?:65)?\D?([9,8]{1}\d{3}\D?\d{4})|((?:1[8,9]00)\D?\d{3}\D?\d{4})|(800\D?\d{3}\D?\d{4}))$/;

/**
 * Check if value only starts with 8 or 9, and has only 8 digits
 * also checks for special numbers (does not start wih 99*)
 *
 * @param value mobile number to validate
 */
export const validateLocalPhoneNumber = (value: string, mobileOnly = true): boolean => {
	if (value?.length !== 8) {
		return false;
	}

	return SG_NUMBER_REGEX.test(value);
};

export const validateMobileNumber = (value: string): boolean => {
	if (value?.length !== 8) {
		return false;
	}

	return SG_MOBILE_NUMBER_REGEX.test(value);
};
