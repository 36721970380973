import { DoneePowerLabel } from "app/components/templates/LPA-ACP/common/data";

export const identifyPower = (
	authorizePersonalWelfare: boolean,
	authorizePropertyAffairs: boolean,
): DoneePowerLabel => {
	if (!authorizePersonalWelfare) {
		return DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL;
	}
	if (!authorizePropertyAffairs) {
		return DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL;
	}
	return DoneePowerLabel.BOTH_POWER_LABEl;
};
