import { GTMEvent, GTMEventParams } from "./types";

const initialiseDataLayer = () => {
	// only assign the variable if GTM is not initialised
	// https://www.simoahava.com/gtm-tips/datalayer-declaration-vs-push/
	if (!window["dataLayer"]) {
		window["dataLayer"] = [];
	}
};

const fireEvent = <T extends GTMEventParams>(event: GTMEvent, eventParams?: T) => {
	initialiseDataLayer();
	window["dataLayer"].push({
		event,
		...eventParams,
	});
};

export const GTMUtils = {
	fireEvent,
};
