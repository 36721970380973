import { IRadioItem, Radio } from "app/components/basic/Radio";
import { useEffect, useState } from "react";
import { IFormFunctions } from "../Form";

interface IFormRadioProps {
	field: string;
	form: IFormFunctions;
	horizontal?: boolean;
	horizontalOnDesktop?: boolean;
	title?: string | JSX.Element;
	subtitle?: string;
	largeTitle?: boolean;
	largeSubtitle?: boolean;
	beforeSelectItem?: (...args: any[]) => void;
	afterSelectItem?: (...args: any[]) => void;
	disabled?: boolean;
}

const FormRadio = (props: IFormRadioProps): JSX.Element => {
	const [performAfterAction, setPerformAfterAction] = useState(false);

	useEffect(() => {
		if (performAfterAction && props.afterSelectItem) {
			props.afterSelectItem();
			setPerformAfterAction(false);
		}
	}, [performAfterAction]);

	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	let id = props.field + "__loading";

	if (!props.form.loading && !props.disabled) {
		id = key;
	}

	const className = `form-field-${key}`;
	const value = formField ? formField.value : "";
	const radioItems: IRadioItem[] = formField ? formField.radioItems! : [];
	const isSubmitting = formField ? props.form.submittingFields[formField.key] : false;

	let errorMessage = formField ? props.form.getError(props.field) : "";
	let errorLabel = "";

	if (errorMessage && radioItems.find((item: IRadioItem) => item.label === errorMessage)) {
		errorLabel = errorMessage;
		errorMessage = "";
	}

	const selectItemHandler = async (value: string): Promise<void> => {
		if (props.beforeSelectItem) {
			await props.beforeSelectItem();
		}
		await props.form.updateFieldValue(key, value);
		if (props.afterSelectItem) {
			await setPerformAfterAction(true);
		}
	};

	return (
		<div className={`${className} form-field pb8`}>
			<Radio
				{...props}
				name={id}
				items={radioItems || []}
				selectedValue={value as string}
				validateRadioInput={props.form.updateFieldError}
				inputThatHasError={errorLabel}
				errorMessage={errorMessage}
				selectItem={selectItemHandler}
				disabled={props.disabled || isSubmitting}
			/>
		</div>
	);
};

export default FormRadio;
