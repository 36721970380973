import { useEffect, useState } from "react";
import { WebviewHelper } from "@wog/mol-web-common";
import { ILifeSgAppProps } from "./types";

export const useIsLifeSgApp = (): ILifeSgAppProps => {
	const [isLifeSgApp, setIsLifeSgApp] = useState<boolean | null>(null);
	const [mastheadHeight, setMastheadHeight] = useState<number>(0);

	useEffect(() => {
		setIsLifeSgApp(WebviewHelper.isLifeSgApp());
		if (window?.lifeSgView) {
			setMastheadHeight(Math.floor(window.lifeSgView.mastheadHeight) + 1);
		}
	}, []);

	return { isLifeSgApp, mastheadHeight };
};
